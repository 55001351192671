import { Badge } from "antd";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getDataById } from "../reivew.request";
import { ICourseReviews } from "../types";
import { AxiosError } from "axios";
import ToBack from "components/Common/ToBack";
import { useTranslation } from "react-i18next";

const CourseReviewView = () => {
  const {t} = useTranslation()
  const { id } = useParams();

  const getDataByIdQuery = useQuery<ICourseReviews, AxiosError>({
    queryKey: ["review-by-id", id],
    queryFn: () => getDataById(Number(id)),
    retry: 0,
    staleTime: Infinity,
  });

  return (
    <>
      <ToBack title={t("Back")} />
      <div className="col-span-12 main-card mt-4">
        <div>
          <div className="grid grid-cols-12 mt-4 gap-4">
            <div className="col-span-8">
              <div className="">
                <div className="grid grid-cols-12 gap-8">
                  <div className="xl:col-span-6 md:col-span-6 col-span-12">
                    {getDataByIdQuery.data?.translations.map((lang, i) => (
                      <div className="flex justify-between mb-4">
                        <p className="font- font-bold text-zinc-500">{t("Text")} (lang{i + 1})</p>
                        <p>{lang.text}</p>
                      </div>
                    ))}
                    <div className="flex justify-between mb-4">
                      <p className="font- font-bold text-zinc-500">{t("Sort")}</p>
                      <p>{getDataByIdQuery.data?.sort}</p>
                    </div>
                    <div className="flex justify-between mb-4">
                      <p className="font- font-bold text-zinc-500">{t("Status")}</p>
                      <p>
                        {getDataByIdQuery.data?.is_active ? (
                          <Badge count={"Active"} showZero color="#52c41a" />
                        ) : (
                          <Badge count={"InActive"} showZero color="#faad14" />
                        )}
                      </p>
                    </div>
                    <div className="flex justify-between mb-4">
                      <p className="font- font-bold text-zinc-500">{t("Verified")}</p>
                      <p>
                        {getDataByIdQuery.data?.is_verified ? (
                          <Badge count={"Verified"} showZero color="#52c41a" />
                        ) : (
                          <Badge count={"Not verified"} showZero color="#faad14" />
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="xl:col-span-6 md:col-span-6 col-span-12"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CourseReviewView;
