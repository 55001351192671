import React, { useEffect, useState } from "react";
import { IFileBlock } from "../types";
import { Button, Form,  Switch, Upload, UploadFile, UploadProps } from "antd";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { submitFileBlock } from "../block.request";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { UploadOutlined } from "@ant-design/icons";
import { FILE_URL } from "config/utils";
import { useAppDispatch } from "store/service";
import { BLOCK_ACTIONS } from "store/block";
import { IBlockForm } from "pages/module_lessons/types";

const FileBlock = ({
  uuid,
  sort,
  fileData,
  setBlockForms,
  blockForms,
}: {
  uuid: string;
  sort: number;
  fileData: IFileBlock | undefined;
  setBlockForms: (item: IBlockForm[]) => void;
  blockForms: IBlockForm[];
}) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileError, setFileError] = useState(false);
  const dispatch = useAppDispatch();
  const [newData, setNewData] = useState<IFileBlock | null>(null);

  const { mutate} = useMutation({
    mutationKey: ["add-training-block"],
    mutationFn: (newValues: any) => submitFileBlock(newValues),
    onSuccess: async (data) => {
      setNewData(data);
      dispatch(BLOCK_ACTIONS.changeBlockItem({ id: uuid, loading: false }));
    },
    onError: (error: AxiosError<any>) => {
      dispatch(BLOCK_ACTIONS.changeBlockItem({ id: uuid, loading: false }));
      validationErrors(form, error?.response?.data);
      setFileError(true);
    },
    retry: 0,
  });
  React.useEffect(() => {
    if (fileData) {
      form.setFieldsValue(fileData);
    } else {
      form.resetFields();
      form.setFieldsValue({
        is_active: true,
      });
    }
  }, [fileData?.id]);

  function handleFinish(e: any) {
    e.course_lesson_id = id;
    e.sort = sort;
    if (fileData) {
      e.id = fileData.id;
    } else if (newData) {
      e.id = newData.id;
    }
    mutate({ payload: e, file: fileList[0] });
    dispatch(BLOCK_ACTIONS.changeBlockItem({ id: uuid, loading: true }));
  }

  const props: UploadProps = {
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      setFileError(false);
      return false;
    },
    accept:
      " .gif, .jpeg, .ico, .jfif, .tiff, .ico, .png, .png, .jpg, .apng, .bmp, .webp, .svg .mp3, .mp4, .mpeg, .mov, .avi, .movie, .txt, .csv, .html, .css, .js, .json, .pdf, .ppt, .doc, .docx, .xls, .xlsx, .pptx, .rar, .zip, .7z ",
  };

  useEffect(() => {
    if (!blockForms.map((it) => it.id).includes(uuid)) {
      let arr = blockForms;
      arr.push({ id: uuid, form: form });
      setBlockForms(arr);
    }
  }, [uuid]);

  return (
    <div>
      <Form layout={"vertical"} form={form} onFinish={handleFinish} className="custom_form_style">
        <div className="grid grid-cols-12 justify-between align-middle mb-2">
          <div className="md:col-span-6 col-span-12 flex">
            <div className="me-3">
              <Upload {...props} maxCount={1}>
                <Button style={fileError ? { border: "1px solid red", color: "red" } : {}} icon={<UploadOutlined />}>
                  Select File
                </Button>
              </Upload>
              {fileError ? <span className="mt-2 block text-red-600">File error</span> : ""}
              {fileData?.media?.filename ? (
                <a target="_blank" href={FILE_URL + fileData?.media?.path} className="mt-2 block">
                  {fileData?.media?.filename}
                </a>
              ) : (
                ""
              )}
              <a href=""></a>
            </div>
            <Form.Item
              label={<FormLabelCrud>Status</FormLabelCrud>}
              name="is_active"
              rules={[{ required: false, message: "Please input is status!" }]}
              valuePropName={"checked"}
            >
              <Switch defaultChecked className="bg-[#999]" />
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FileBlock;
