import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Drawer, Form, Input, message, Select, Switch } from "antd";
import { AxiosError } from "axios";
import useWindowSize from "custom-hooks/useWindowSize";
import React from "react";
import { validationErrors } from "utils.functions";
import { submitData } from "./order.request";
import { IOrder } from "./types";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { RButton, SButton } from "components/Common/CustomButtons";
import EditingTitle from "components/Common/EditingTitle";
import PartTitle from "components/Common/PartTitle";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";
import { useTranslation } from "react-i18next";

const paymentTypes = [
  { title: "click" },
  { title: "paycom" },
  { title: "apelsin" },
  { title: "upay" },
]


type TypePropsCreateOrder = {
  element: IOrder | null;
  setElement: React.Dispatch<React.SetStateAction<IOrder | null>>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateOrder = ({ element, setElement, refetch, openSidebarMenu, setOpenSidebarMenu }: TypePropsCreateOrder) => {

  const {t} = useTranslation()
  const [form] = Form.useForm();
  const { width } = useWindowSize();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue({
        amount: element.amount,
        is_paid: element.is_paid,
        // service_type: element.service_type,
      })
    } else {
      form.resetFields()
      form.setFieldsValue({
        is_paid: true,
        service_type: paymentTypes[0].title,
      })
    }
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-orders"],
    mutationFn: (newValues) => submitData(element?.id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["orders"], data);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data)
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    mutate(values);
  }

  const MemoizationFieldsFormOrder = React.memo(() => (
    <Form
      layout={"vertical"}
      form={form}
      onFinish={handleSubmit}
      className="custom_form_style"
    >
      <div className="grid grid-cols-12 gap-4">
        <div className="sm:col-span-6 col-span-12">
          <Form.Item
            label={<FormLabelCrud>Payment system</FormLabelCrud>}
            name="service_type"
            rules={[{ required: true, message: 'Please input Service type!' }]}
          >
            <Select defaultValue={paymentTypes[0].title}>
              {
                paymentTypes.map((item, index) => (
                  <Select.Option key={index} value={item.title}>{item.title}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </div>
        <div className="sm:col-span-6 col-span-12">
          <Form.Item
            label={<FormLabelCrud>Amount</FormLabelCrud>}
            name="amount"
            rules={[{ required: true, message: 'Please input Amout!' }]}
          >
            <Input type="number" />
          </Form.Item>
        </div>
        <div className="col-span-4">
          <Form.Item
            label={<FormLabelCrud>Is paid</FormLabelCrud>}
            name="is_paid"
            rules={[{ required: false, message: 'Please input is paid!' }]}
            valuePropName={'checked'}
          >
            <Switch defaultChecked className="bg-[#999]" />
          </Form.Item>
        </div>
        <div className="col-span-8">
          <div className="mt-6 text-right">
            <RButton
              onClick={() => {
                form.resetFields();
                setElement(null);
              }}
              className="xl:mr-2 xl:mb-0 sm:mb-2 sm:mr-0 mr-1"
            />
            <SButton loading={isLoading} />
           
          </div>
        </div>
      </div>
    </Form>
  ));

  return (
    <div>
      <>{element ? <EditingTitle title={String(element.amount)} /> : <PartTitle children={"Create order"} />}</>
      <hr className="my-3" />
      <MemoizationFieldsFormOrder />
      
      <Drawer
        title={<h3 className=" font-bold">{element?.payment_provider ? element?.payment_provider : t("New")}</h3>}
        placement="right"
        onClose={() => setOpenSidebarMenu(false)}
        open={width > 992 ? false : openSidebarMenu}
      >
        <MemoizationFieldsFormOrder />
      </Drawer>
    </div>
  );
};
export default CreateOrder;
