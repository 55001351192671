import instance from "config/axios.config";

export async function submitData(id: number | undefined, data: any) {

    const url = id ? `/contact/${id}` : `contact/create`;
    const response = await instance({ url, method: id ? 'PUT' : "POST", data });
    return response.data

}

export async function getData(page:number, pageSize: number) {
    const response = await instance({ url: `contact/list/`, method: 'GET', params:{ page_number: page, page_size: pageSize }});
    return response.data;
}


export function deleteData(id: any) {
    return instance({ url: `contact/${id}`, method: 'DELETE' })
}
