import instance from "config/axios.config";

export async function submitData(id: number | undefined, data: any) {

    const url = id ? `/role/${id}` : `role/create`;
    const response = await instance({ url, method: id ? 'PUT' : "POST", data });
    return response.data

}

export async function getData() {
    const response = await instance({ url: `role/list/`, method: 'GET' });
    return response.data;
}


export function deleteData(id: any) {
    return instance({ url: `role/${id}`, method: 'DELETE' })
}

export async function getPermissions() {
    const response = await instance({ url: `permission/list/`, method: 'GET', params:{page_size:500}});
    return response.data;
}

export async function getRoleById(id:any) {
    const response = await instance({ url: `role/${id}`, method: 'GET'});
    return response.data;
}

export async function postPermission(id: any, toward: string, data: any) {
    const url = (toward === 'right') ? `/role/${id}/add/permissions` : `/role/${id}/remove/permissions`;
    const response = await instance({ url, method: "POST", data });
    return response.data

}