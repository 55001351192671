import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { FiEdit } from "react-icons/fi";
import DeleteIcon from "components/Common/deleteIcon";
import { deleteData, getData } from "./permission.request";
import { AxiosError } from "axios";
import { IPermission, IPermissionsWithPagination } from "./types";
import CreateLanguage from "./permission_form_ui";
import { Badge, Button, Pagination } from "antd";
import TableLoader from "components/loaders/table_loader";
import PageTitle from "components/Common/PageTitle";
import { is_permission } from "utils.functions";
import { useTranslation } from "react-i18next";

const Permissions = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<IPermission | null>(null);
  const [openSidebarMenu, setOpenSidebarMenu] = useState<boolean>(false);
  const [page, setpage] = useState<number>(1);
  const [pageSize, setpageSize] = useState<number>(10);

  const deletePost = useMutation({
    mutationFn: deleteData,
  });

  useEffect(() => {
    if (!deletePost.isLoading) {
      refetch();
    }
  }, [deletePost.isLoading]);

  const { isLoading, error, data, refetch } = useQuery<IPermissionsWithPagination, AxiosError>({
    queryKey: ["permissions", page, pageSize],
    queryFn: () => getData(page, pageSize),
    retry: 0,
    staleTime: Infinity,
  });

  if (error) return "An error has occurred: " + error.message;

  return (
    <div>
      <div className="grid grid-cols-12 gap-4">
        <div className="main-card lg:col-span-8 col-span-12">
          <div className="flex mb-3 justify-between">
            <PageTitle title={"Permissions"} />
            {is_permission("create_permission") ? (
              <Button
                onClick={() => {
                  setOpenSidebarMenu(true);
                  setSelected(null);
                }}
                type="default"
                className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763] lg:hidden"
              >
                + {t("Create")}
              </Button>
            ) : null}
          </div>
          <Table className="table-striped table-hover">
            <Thead>
              <Tr>
                <Th className="w-[50px]">№</Th>
                <Th className="w-[50px] text-center px-[0!important]">{t("Id")}</Th>
                <Th>{t("Title")}</Th>
                <Th>{t("Description")}</Th>
                <Th>{t("Status")}</Th>
                <Th>{t("Actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.results?.map((item: IPermission, index: number) => (
                <Tr key={item.id}>
                  <Td>{(page - 1) * pageSize + index + 1}</Td>
                  <Td className="w-[50px] text-center px-[0!important]">{item?.id}</Td>
                  <Td>{item?.title}</Td>
                  <Td>{item?.desc}</Td>
                  <Td>
                    {item?.is_active ? (
                      <Badge count={t("Active")} showZero color="#52c41a" />
                    ) : (
                      <Badge count={t("InActive")} showZero color="#faad14" />
                    )}
                  </Td>
                  <Td>
                    <div className="flex" style={{ alignItems: "center" }}>
                      {is_permission("update_permission") ? (
                        <FiEdit
                          className="mx-2 text-[green] text-[18px] cursor-pointer"
                          onClick={() => {
                            setSelected(item);
                            setOpenSidebarMenu(true);
                          }}
                        />
                      ) : null}
                      {is_permission("delete_permission") ? (
                        <DeleteIcon confirmFunc={() => deletePost.mutate(item?.id)} />
                      ) : null}
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <TableLoader isLoading={isLoading} />
          <div className="text-right mt-3">
            <Pagination
              onChange={(page_num, page_size) => {
                setpage(page_num);
                setpageSize(page_size);
              }}
              defaultCurrent={page}
              pageSize={pageSize}
              total={data?.total_results}
            />
          </div>
        </div>
        {(is_permission("create_permission") && !selected) || (is_permission("update_permission") && selected) ? (
          <div className="main-card col-span-4 lg:block hidden">
            <CreateLanguage
              element={selected}
              setElement={setSelected}
              refetch={refetch}
              openSidebarMenu={openSidebarMenu}
              setOpenSidebarMenu={setOpenSidebarMenu}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Permissions;
