import { useMutation, useQuery } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { FiEdit } from "react-icons/fi";
import DeleteIcon from "components/Common/deleteIcon";
import { deleteData, getOrganizatsions } from "./organization.request";
import { IOrganization } from "./types";
import { Badge, Empty, message } from "antd";
import TableLoader from "components/loaders/table_loader";
import { Link, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { AiOutlineEye } from "react-icons/ai";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";
import PageTitle from "components/Common/PageTitle";
import { CButton } from "components/Common/CustomButtons";
import { is_permission } from "utils.functions";
import { useTranslation } from "react-i18next";

const Organizations = () => {
  const { t } = useTranslation();
  const { _translate } = useLocaleTranslation();
  const navigate = useNavigate();
  const deleteMutation = useMutation({
    mutationFn: (id: string) => deleteData(id),
    onSuccess: () => {
      refetch();
    },
  });

  const { data, isLoading, error, refetch } = useQuery<IOrganization[], AxiosError>({
    queryKey: ["organizations"],
    queryFn: () => getOrganizatsions(),
    retry: 0,
  });

  if (error) return "An error has occurred: " + error.message;
  const copyToClipboard = async (data: any) => {
    await navigator.clipboard.writeText(data);
    message.success("ID copied");
  };
  return (
    <div className="main-card md:overflow-auto sm:overflow-scroll">
      <div className="grid grid-cols-12">
        <div className="col-span-6">
          <PageTitle title="Organizations" />
        </div>
        {is_permission("create_organization") ? (
          <div className="col-span-6 text-end">
            <CButton onClick={() => navigate("/organization/create")} />
          </div>
        ) : null}
        <div className="col-span-12 md:overflow-auto overflow-x-scroll">
          <Table className="table-striped table-hover mt-3 origanizatins-table">
            <Thead>
              <Tr>
                <Th className="w-[50px]">№</Th>
                <Th>{t("Title")}</Th>
                <Th>{t("Identification number")}</Th>
                <Th>{t("Bank account")}</Th>
                <Th>{t("Bank MFO")}</Th>
                <Th>{t("Status")}</Th>
                <Th>{t("Verified")}</Th>
                <Th>{t("Actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading
                ? data?.length > 0
                  ? data?.map((item: IOrganization, index: number) => (
                      <Tr key={item?.id}>
                        <Td onClick={() => copyToClipboard(item?.id)} className="cursor-pointer">{index + 1}</Td>
                        <Td>{_translate(item?.translations, "title")}</Td>
                        <Td>{item?.identification_number}</Td>
                        <Td>{item?.bank_account}</Td>
                        <Td>{item?.bank_mfo}</Td>
                        <Td>
                          {item?.is_active ? (
                            <Badge count={t("Active")} showZero color="#52c41a" />
                          ) : (
                            <Badge count={t("InActive")} showZero color="#faad14" />
                          )}
                        </Td>
                        <Td>
                          {item?.is_verified ? (
                            <Badge count={t("Verified")} showZero color="#52c41a" />
                          ) : (
                            <Badge count={t("Not Verified")} showZero color="#faad14" />
                          )}
                        </Td>
                        <Td>
                          <div className="flex" style={{ alignItems: "center" }}>
                            {is_permission("read_organization") ? (
                              <Link to={`/organization/view/${item?.id}`}>
                                <AiOutlineEye className=" mr-2 text-[#faad14] text-[22px] cursor-pointer" />
                              </Link>
                            ) : null}
                            {is_permission("update_organization") ? (
                              <Link to={`/organization/update/${item?.id}`}>
                                <FiEdit className="mx-2 text-[green] text-[18px] cursor-pointer" />
                              </Link>
                            ) : null}
                            {is_permission("delete_organization") ? (
                              <DeleteIcon confirmFunc={() => deleteMutation.mutate(item?.id)} />
                            ) : null}
                          </div>
                        </Td>
                      </Tr>
                    ))
                  : null
                : null}
            </Tbody>
          </Table>
        </div>
      </div>

      {data?.length === 0 ? <Empty className="w-[100%]" /> : null}
      <TableLoader isLoading={isLoading} />
    </div>
  );
};
export default Organizations;
