import { useEffect, useState } from "react";
import { Form, FormInstance, Select, Switch } from "antd";
import FormLabelCrud from "components/FormLabel/FormLabel";
import useGetData from "custom-hooks/useGetData";
import { InformerDRDT } from "types/base.models";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import { submitQuizBlock } from "../block.request";
import { useParams } from "react-router-dom";
import { IQuizBlock } from "../types";
import { IQuestion } from "pages/questions/types";
import useDebounce from "custom-hooks/useDebounce";
import "./style.scss";
import { quizTypes } from "utils/variables";
import { useAppDispatch } from "store/service";
import { BLOCK_ACTIONS } from "store/block";
import { IBlockForm } from "pages/module_lessons/types";

const QuizBlock = ({
  uuid,
  sort,
  quizData,
  setBlockForms,
  blockForms,
}: {
  uuid: string;
  sort: number;
  quizData: IQuizBlock | undefined;
  setBlockForms: (item: IBlockForm[]) => void;
  blockForms: IBlockForm[];
}) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [filterQuery, setFilterQuery] = useState("");
  const debouncedValue = useDebounce<string>(filterQuery, 400);
  const [searcVal, setsearcVal] = useState<string>("");
  const dispatch = useAppDispatch();
  const [newData, setNewData] = useState<IQuizBlock | null>(null);

  const { data: questions } = useGetData<InformerDRDT<IQuestion>>({
    queryKey: ["questions", searcVal],
    url: `question/list/?page_number=1&is_active=true&page_size=20&q=${searcVal}`,
    options: { staleTime: Infinity, refetchOnWindowFocus: false },
  });
  useEffect(() => {
    setsearcVal(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    if (quizData) {
      form.setFieldsValue(quizData);
      form.setFieldsValue({
        questions_ids: quizData?.questions?.sort((a, b) => a.sort - b.sort).map((item) => item.id),
      });
      setSelectedItems(quizData?.questions?.sort((a, b) => a.sort - b.sort).map((item) => item.id));
    } else {
      form.setFieldsValue({
        is_active: true,
      });
    }
  }, [quizData]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-training-block"],
    mutationFn: (newValues: any) => submitQuizBlock(newValues),
    onSuccess: async (data) => {
      setNewData(data);
      dispatch(BLOCK_ACTIONS.changeBlockItem({ id: uuid, loading: false }));
    },
    onError: (error: AxiosError<any>) => {
      dispatch(BLOCK_ACTIONS.changeBlockItem({ id: uuid, loading: false }));
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  const onSearch = (value: string) => {
    setFilterQuery(value);
  };

  const onFinish = (values: any) => {
    mutate({ ...values, sort, course_lesson_id: id, quiz_block_id: quizData?.id || newData?.id });
    dispatch(BLOCK_ACTIONS.changeBlockItem({ id: uuid, loading: true }));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (!blockForms.map((it) => it.id).includes(uuid)) {
      let arr = blockForms;
      arr.push({ id: uuid, form: form });
      setBlockForms(arr);
    }
  }, [uuid]);

  return (
    <div className="lesson__quiz__block">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        <div className="grid grid-cols-12 justify-between align-middle gap-x-4">
          <div className="md:col-span-12 col-span-12">
            <Form.Item
              className="w-[calc(100%-55px)] mb-3 "
              label={<FormLabelCrud>Questions</FormLabelCrud>}
              name={["questions_ids"]}
              rules={[{ required: true, message: "Please select language" }]}
            >
              <Select
                className="quiz__select"
                mode="multiple"
                placeholder="Select a question"
                optionFilterProp="children"
                value={selectedItems}
                onChange={setSelectedItems}
                onSearch={onSearch}
                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                options={questions?.results?.map((item) => ({
                  value: item?.id,
                  label: item?.translations[0]?.title,
                }))}
              />
            </Form.Item>
          </div>
        </div>
        <div className="grid grid-cols-12 justify-between align-middle gap-x-4">
          <div className="md:col-span-2 col-span-12">
            <Form.Item
              label={<FormLabelCrud>Is active</FormLabelCrud>}
              name={["is_active"]}
              rules={[{ required: true, message: "Please input is status!" }]}
              valuePropName={"checked"}
              className="flex"
              initialValue={true}
            >
              <Switch defaultChecked className="bg-[#999]" />
            </Form.Item>
          </div>
          <div className="md:col-span-4 col-span-12">
            <Form.Item
              className="mb-3 "
              label={<FormLabelCrud>Types</FormLabelCrud>}
              name={["quiz_type"]}
              rules={[{ required: true, message: "Please select language" }]}
            >
              <Select
                className="quiz__select"
                placeholder="Select a type"
                optionFilterProp="children"
                options={quizTypes.map((item) => {
                  return { value: item.value, label: item.label };
                })}
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default QuizBlock;
