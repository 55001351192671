import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { FiEdit } from "react-icons/fi";
import DeleteIcon from "components/Common/deleteIcon";
import { deleteData, getCourseModules } from "./mudule.request";
import { ICourseModules } from "./types";
import CreateCourseModules from "./mudule_form_ui";
import { Badge, Button, Empty } from "antd";
import TableLoader from "components/loaders/table_loader";
import { AxiosError } from "axios";
import { Link, useParams } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { is_permission } from "utils.functions";
import { BiCheck } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import PageTitle from "components/Common/PageTitle";

const CourseModules = () => {

  const {t} = useTranslation()
  const { id } = useParams();

  const [selected, setSelected] = React.useState<ICourseModules | null>(null);
  const [openSidebarMenu, setOpenSidebarMenu] = useState<boolean>(false);

  const deletePost = useMutation({
    mutationFn: deleteData,
  });

  const { isLoading, error, data, refetch } = useQuery<
    ICourseModules[],
    AxiosError
  >({
    queryKey: ["course-modules"],
    queryFn: () => getCourseModules(id),
    retry: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!deletePost.isLoading) {
      refetch();
    }
  }, [deletePost.isLoading]);

  if (error) return <>'An error has occurred: ' {error}</>;

  return (
    <div>
      <div className="">
        <div className="grid grid-col-12 items-center">
        <div className="col-span-6">
          <PageTitle title={t("Course Modules")} />
        </div>
          {is_permission("create_course_module") ? (
            <div className="col-span-6 text-right">
            <Button
              onClick={() => {
                setOpenSidebarMenu(true);
                setSelected(null);
              }}
              type="default"
              className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]"
            >
              + {t("Create")}
            </Button>
            </div>
          ) : null}
          <div className="col-span-12 md:overflow-auto overflow-x-auto">
          <Table className="table-striped table-hover mt-3">
          <Thead>
            <Tr>
              <Th className="w-[50px]">№</Th>
              <Th className="w-[50px] text-center px-[0!important]">{t("Id")}</Th>
              <Th>{t("Title")}</Th>
              <Th>{t("Price")}</Th>
              <Th>{t("Slug")}</Th>
              <Th>{t("Level")}</Th>
              <Th>{t("Sort")}</Th>
              <Th>{t("Status")}</Th>
              <Th>{t("Free")}</Th>
              <Th>{t("Actions")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading
              ? data?.length > 0
                ? data?.map((item: ICourseModules, index: number) => (
                    <Tr key={item.id}>
                      <Td>{index + 1}</Td>
                      <Td className="w-[50px] text-center px-[0!important]">{item?.id}</Td>
                      <Td>{item?.translations[0]?.title}</Td>
                      <Td>{item?.price}</Td>
                      <Td>{item?.slug}</Td>
                      <Td>{item?.level}</Td>
                      <Td>{item?.sort ? item?.sort : index+1}</Td>
                      <Td>
                        {item?.is_active ? (
                          <BiCheck className="text-[24px] text-green-700" />
                        ) : (
                          <IoMdClose className="text-[22px] text-rose-600" />
                        )}
                      </Td>
                      <Td>
                        {item?.is_free ? (
                          <BiCheck className="text-[24px] text-green-700" />
                        ) : (
                          <IoMdClose className="ml-2 text-[22px] text-rose-600" />
                        )}
                      </Td>
                      <Td>
                        <div className="flex" style={{ alignItems: "center" }}>
                          {is_permission("read_course_module") ? (
                            <Link to={`/course/modules/view/${item?.id}`}>
                              <AiOutlineEye className=" mr-2 text-[#faad14] text-[22px] cursor-pointer" />
                            </Link>
                          ) : null}
                          {is_permission("update_course_module") ? (
                            <FiEdit
                              className="mx-2 text-[green] text-[18px] cursor-pointer"
                              onClick={() => {
                                setSelected(item);
                                setOpenSidebarMenu(true);
                              }}
                            />
                          ) : null}
                          {is_permission("delete_course_module") ? (
                            <DeleteIcon
                              confirmFunc={() => deletePost.mutate(item?.id)}
                            />
                          ) : null}
                        </div>
                      </Td>
                    </Tr>
                  ))
                : null
              : null}
          </Tbody>
        </Table>
          </div>
        </div>
        
        {data?.length === 0 ? <Empty className="w-[100%]" /> : null}
        <TableLoader isLoading={isLoading} />
      </div>
      {(is_permission("create_course_module") && !selected) ||
      (is_permission("update_course_module") && selected) ? (
        <CreateCourseModules
          element={selected}
          setElement={setSelected}
          refetch={refetch}
          openSidebarMenu={openSidebarMenu}
          setOpenSidebarMenu={setOpenSidebarMenu}
        />
      ) : null}
    </div>
  );
};
export default CourseModules;
