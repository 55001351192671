import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Drawer, Form, Input, Switch, message } from "antd";
import useWindowSize from "custom-hooks/useWindowSize";
import { submitLanguage } from "./language.request";
import { ILanguage } from "./types";
// import FormLabel from "components/FormLabel";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import { RButton, SButton } from "components/Common/CustomButtons";
import PartTitle from "components/Common/PartTitle";
import EditingTitle from "components/Common/EditingTitle";
import MultipleLangInputsForLang from "components/MultipleLangInput/multipleLangsForLang";
import { FILE_URL } from "config/utils";
import { FaRegEdit } from "react-icons/fa";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { useTranslation } from "react-i18next";

type TypeLanguageFormProps = {
  element: ILanguage | null;
  setElement: React.Dispatch<React.SetStateAction<ILanguage | null>>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateLanguage = ({
  element,
  setElement,
  refetch,
  openSidebarMenu,
  setOpenSidebarMenu,
}: TypeLanguageFormProps) => {
  const [form] = Form.useForm();
  const { width } = useWindowSize();
  const [imageSrc, setImageSrc] = useState({ url: "", file: "" });
const {t} = useTranslation()
  React.useEffect(() => {
    if (element) {
      let arr: any = [];
      element &&
        Object.entries(element.title).map(([key, value]: any) => {
          arr.push({ title: element.title[key], code: key });
        });
      form.setFieldsValue({ ...element, title: arr });
      if (!imageSrc.file && element?.flag) {
        setImageSrc({ url: FILE_URL + element?.flag?.path, file: "" });
      } else {
        setImageSrc({ url: "", file: "" });
      }
    } else {
      form.resetFields();
      form.setFieldsValue({
        is_active: true,
        title: [{}],
      });
      setImageSrc({ url: "", file: "" });
    }
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-language"],
    mutationFn: (newVals: any) => submitLanguage(element?.id, newVals),
    onSuccess: async (data) => {

      // queryClient.setQueryData(["languages"], data);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    let obj: any = {};
    values.title.forEach((item: any) => {
      obj[item.code] = item.title;
    });
    values.title = obj;
    console.log({ payload: values, flag: imageSrc.file });
    mutate({ payload: values, flag: imageSrc.file });
  }
  const handleImageChange = (e: any) => {
    setImageSrc({ url: URL.createObjectURL(e.target.files[0]), file: e.target.files[0] });
  };

  const MemoizationFieldsFormLanguage = React.memo(() => (
    <Form layout={"vertical"} form={form} onFinish={handleSubmit} className="custom_form_style">
      <MultipleLangInputsForLang form={form} />
      <div className="col-span-6">
        <div className="flex gap-x-3 mt-3">
          <Form.Item label={t("Code")} name="code" rules={[{ required: true, message: "Please input code!" }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={<FormLabelCrud>{t("Status")}</FormLabelCrud>}
            name="is_active"
            rules={[{ required: false, message: "Please input is status!" }]}
            valuePropName={"checked"}
          >
            <Switch defaultChecked className="bg-[#999]" />
          </Form.Item>
        </div>
      </div>
      <div className="col-span-6">
        <div className="relative">
          {imageSrc?.url ? (
            <img className="lg:h-[200px] h-[100px] rounded-xl w-full object-contain" src={imageSrc?.url} alt="" />
          ) : (
            <h2 className="lg:h-[200px] h-[100px]  rounded-xl w-full bg-slate-100 text-center align-middle lg:text-[30px] sm:text-2xl text-lg text-[#999]">
              {t("Image Not Uploaded")}
            </h2>
          )}
          <input
            onChange={(e) => handleImageChange(e)}
            style={{ display: "none" }}
            accept="image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/tiff, image/webp, image/x-icon"
            type="file"
            id="course-image"
          />
          <label htmlFor="course-image" className="absolute bottom-5 right-5 cursor-pointer">
            <FaRegEdit className="text-[22px] text-[#045140]" />
          </label>
        </div>
      </div>
      <div className="mt-2 text-right">
        <RButton
          onClick={() => {
            form.resetFields();
            form.setFieldsValue({
              is_active: true,
              title: [{}],
            });
            setElement(null);
          }}
          className="sm:mr-1 sm:mb-0 mb-1"
        />
        <SButton loading={isLoading} />
      </div>
    </Form>
  ));

  return (
    <div>
      <>{element ? <EditingTitle title={element.code} /> : <PartTitle children={"Create language"} />}</>
      <hr className="my-3" />
      <MemoizationFieldsFormLanguage />
      <Drawer
        title={<h3 className="font-bold">{element?.title ? element?.title : t("New")}</h3>}
        placement="right"
        onClose={() => setOpenSidebarMenu(false)}
        open={width > 992 ? false : openSidebarMenu}
      >
        <MemoizationFieldsFormLanguage />
      </Drawer>
    </div>
  );
};
export default CreateLanguage;
