import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormInstance, Input, Row, Select } from "antd";
import { BiPlus, BiX } from "react-icons/bi";
import { ILanguage } from "pages/languages/types";
import useGetData from "custom-hooks/useGetData";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { useTranslation } from "react-i18next";
import LanguageTitle from "utils/LanguageTitle";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { IQuestion } from "pages/questions/types";

const { Option } = Select;

const MultipleLangInputQuestions = ({ form, element }: { form: FormInstance; element: IQuestion | null }) => {
  const { t } = useTranslation();

  const { data: languages } = useGetData<ILanguage[]>({
    queryKey: ["languages"],
    url: "language/list/",
    options: { staleTime: Infinity, refetchOnWindowFocus: false },
  });

  const [languageIds, setLanguageIds] = React.useState<(number | undefined)[]>([]);
  const filterLanguage = () => {
    const tranlations = (form.getFieldValue("translations") ?? []) as Array<{
      title: string;
      language_id: number;
      content: string;
    }>;

    const ids = tranlations?.map((e) => {
      if (e?.language_id) {
        return e.language_id;
      }
    });
    setLanguageIds(ids);
  };
  const handleEditorChange = (event: any, editor: any, index: number) => {
    const data = editor.getData();
    const tranlations = (form.getFieldValue("translations") ?? []) as Array<{
      title: string;
      language_id: number;
      content: string;
    }>;
    tranlations[index].content = data;
    form.setFieldValue("translations", tranlations);
  };

  return (
    <>
      <Form.List name="translations">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div className="grid grid-cols-12 justify-between align-middle ">
                <div className="md:col-span-5 col-span-12">
                  <Form.Item
                    className="mb-0 md:pr-2"
                    label={<FormLabelCrud>Name</FormLabelCrud>}
                    {...restField}
                    name={[name, "title"]}
                    rules={[{ required: index === 0, message: "Please input title!" }]}
                  >
                    <Input />
                  </Form.Item>
                </div>

                <div className="md:col-span-6 col-span-12 flex">
                  <Form.Item
                    className="w-[calc(100%-55px)] mb-0 "
                    label={<FormLabelCrud>Languages</FormLabelCrud>}
                    {...restField}
                    name={[name, "language_id"]}
                    rules={[{ required: index === 0, message: "Please select language" }]}
                  >
                    <Select onClick={filterLanguage}>
                      {languages?.map((element: ILanguage) => (
                        <Option key={element.id} value={element.id} disabled={languageIds.includes(element.id)}>
                          <LanguageTitle element={element} />
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item className="w-[50px] mb-0 ml-2 mt-[24px] text-end">
                    <Button
                      disabled={fields.length == 1}
                      onClick={() => {
                        remove(name);
                      }}
                      danger
                    >
                      <BiX />
                    </Button>
                  </Form.Item>
                </div>

                <div className="col-span-12">
                  <CKEditor
                    editor={Editor}
                    data={form?.getFieldValue("translations")[index]?.content || ""}
                    onChange={(e: any, ed: any) => handleEditorChange(e, ed, index)}
                  />
                </div>
              </div>
            ))}
            <div className="text-right mb-2">
              <Button
                type="link"
                className="inline-flex align-middle items-center lg:text-sm text-xs font-medium uppercase sm:px-[15px] pl-0"
                onClick={() => add()}
                disabled={fields.length === languages?.length}
              >
                <BiPlus className="mt-[2px] mr-1 lg:text-sm text-xs font-bold" />
                {t("Add translation")}
              </Button>
            </div>
          </>
        )}
      </Form.List>
    </>
  );
};
export default MultipleLangInputQuestions;
