import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Form } from "antd";
import { getDataById, submitData } from "./discount.request";
import ToBack from "components/Common/ToBack";
import { useParams } from "react-router-dom";
import { IDiscount } from "./types";
import FormItems from "./discount_form_ui";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import { useTranslation } from "react-i18next";

dayjs.extend(weekday)
dayjs.extend(localeData)

const UpdateDiscount = () => {

  const {t} = useTranslation()
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const { mutate, isLoading } = useMutation({
    mutationKey: ["update-discount"],
    mutationFn: (newValues: IDiscount) => submitData(id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["discounts"], data);
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data)
    },
    retry: 0,
  });

  useQuery<IDiscount, AxiosError>({
    queryKey: ['discounts-by-id', id],
    queryFn: () => getDataById(id),
    onSuccess: (data:IDiscount) => {
        form.setFieldsValue({...data, start: dayjs(data.start, 'YYYY-MM-DD'), stop: dayjs(data.stop, 'YYYY-MM-DD')})
    },
    retry: 0,
});

  function handleSubmit(values: IDiscount) {
    values.start = dayjs(values.start).format('YYYY-MM-DD')
    values.stop = dayjs(values.stop).format('YYYY-MM-DD')
    mutate(values);
  }
  
  function saveAndAnother() {
    form.submit()
    setTimeout(() => {
        form.resetFields();
        form.setFieldsValue({
            is_active: true,
            is_archive: false,
            translations:[{title:"", desc:""}]
        })
    }, 300);
}
    function saveAndEdit() {
        form.submit()
    }

  return (
    <div className="main-card">
        <ToBack title={t("Edit discount")} />
        <hr className="my-3" />
        <FormItems form={form} handleSubmit={handleSubmit} />
        <div className="md:flex justify-end mt-2">
            <Button onClick={saveAndAnother} loading={isLoading} type="default" className='md:w-auto w-[100%] bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]' htmlType="submit">{t("Save and add another")}</Button>
            <Button onClick={saveAndEdit} loading={isLoading} type="default" className='md:w-auto w-[100%] bg-[#045140] text-white hover:text-white hover:bg-[#1a7763] md:ml-3 md:my-0 my-3' htmlType="submit">{t("Save and continue editing")}</Button>
        </div>
    </div>
  );
};

export default UpdateDiscount;