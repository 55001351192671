import { Button, Skeleton, Transfer } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { IOrganization, IOrganizationGet } from '../types';
import { ICourse } from 'pages/courses/types';
import useDebounce from 'custom-hooks/useDebounce';
import { AttachCourseToOrganization } from '../organization.request';
import { useMutation, useQuery } from '@tanstack/react-query';
import { InformerDRDT } from 'types/base.models';
import { AxiosError } from 'axios';
import { getData } from 'pages/courses/course.request';

const AccessCourse = ({data,organizationLoading}:{data:IOrganizationGet | undefined,organizationLoading:any}) => {

    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [mockData, setMockData] = useState<RecordType[]>([]);
    const [currentMockData, setCurrentMockData] = useState<RecordType[]>([]);
    const { id } = useParams();
    const [search_course_name, set_search_course_name] = useState<string>("");
    const [selectedItems,setSelectedItems] = useState()


    useEffect(() => {
        if (data) {
          let new_data: any = [];
          data?.allowed_courses?.map((item) => {
            new_data.push(item?.id);
          });
          setSelectedItems(new_data);
          setTargetKeys(new_data);
        }
      }, [data]);


      const debouncedValue = useDebounce<string>(search_course_name, 1000);

      interface RecordType {
        key: string;
        title: string;
        description: string;
      }

      const {
        data: courses,
        isLoading,
        refetch,
      } = useQuery<InformerDRDT<ICourse>, AxiosError>({
        queryKey: ["courses"],
        queryFn: () => getData(1, 20),
        retry: 0,
      });
      
      const { mutate: organizationCourse } = useMutation({
        mutationKey: ["update-organization-course"],
        mutationFn: (course_ids: any) =>
          AttachCourseToOrganization(String(id), course_ids),
        retry: 0,
      });

      useEffect(() => {
        const tempMockData: any[] = [];
        courses?.results?.map((item: ICourse) => {
          const data = {
            key: item?.id,
            title: `${item?.translations[0]?.title}`,
          };
          tempMockData.push(data);
        });
        data?.allowed_courses?.map((item: any) => {
          const data = {
            key: item?.id,
            title: `${item?.translations[0]?.title}`,
          };
          tempMockData.push(data);
        });
        
    
        setMockData([
          ...tempMockData,
          ...currentMockData.filter(
            (item) => !tempMockData.map((it) => it.key).includes(item.key)
          ),
        ]);
      }, [courses, debouncedValue,data,selectedItems]);
      const handleChange = (newTargetKeys: string[]) => {
        let all_mock_data = [...mockData];
        currentMockData.forEach((item) => {
          
          if (!mockData.map((obj) => obj.key).includes(item.key)) {
            all_mock_data.push(item);
          }
        });
        setCurrentMockData(
          all_mock_data.filter((item) => newTargetKeys.includes(item.key))
        );
        setTargetKeys(newTargetKeys);
      };
      function handleSubmit() {
        organizationCourse({ courses_ids: targetKeys });
      }
      const handleSearch = (d: string, value: string) => {
        if (d === "left") {
          set_search_course_name(value);
        }
      };
  return (
    <div className="w-full">
    <div className="flex gap-3 relative">
      <Transfer
        disabled={isLoading}
        dataSource={mockData}
        showSearch
        onSearch={handleSearch}
        listStyle={{
          minWidth: 350,
          minHeight: 450,
        }}
        operations={["to right", "to left"]}
        targetKeys={targetKeys}
        onChange={handleChange}
        render={(item) => `${item.title}`}
        pagination={{ pageSize: 10 }}
      />
      {organizationLoading && (
          <div className="absolute top-[19.5%] h-[350px] left-[12px] w-[326px] bg-white flex flex-col justify-around">
            <Skeleton active />
            <Skeleton active />
          </div>
      )}
      <Button
        loading={isLoading}
        onClick={() => handleSubmit()}
        className="ml-3 text-end"
      >
        Save
      </Button>
    </div>
  </div>
  )
}

export default AccessCourse