import React, { ReactElement, useState } from 'react';
import {
    DesktopOutlined,
    FileOutlined,
    PieChartOutlined,
    TeamOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu, MenuProps, theme } from 'antd';
import "./styles.css";
import CSidebar from './Sidebar';
import CHeader from './Header';
import { useTranslation } from 'react-i18next';

const { Header, Sider, Content } = Layout;

type PropsTypeCLayout = {
    children: ReactElement
}


const CLayout = ({ children }: PropsTypeCLayout) => {

    const { t, i18n } = useTranslation()
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    type MenuItem = Required<MenuProps>['items'][number];
    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
        } as MenuItem;
    }

    const items: MenuItem[] = [
        getItem('Option 1', '1', <PieChartOutlined />),
        getItem('Option 2', '2', <DesktopOutlined />),
        getItem('User', 'sub1', <UserOutlined />, [
            getItem('Tom', '3'),
            getItem('Bill', '4'),
            getItem('Alex', '5'),
        ]),
        getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
        getItem('Files', '9', <FileOutlined />),
    ];



    return (
        <Layout>
            <CSidebar />
            <Layout className="site-layout">
                <CHeader />
                <Content
                    className='py-1 px-1'
                    style={{
                        margin: '10px 10px',
                        // padding: 10,
                        minHeight: 280,
                        // background: colorBgContainer,
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
};

export default CLayout;