import { useTranslation } from "react-i18next";






const EditingTitle = ({ title }: { title: string }) => {

    const { t } = useTranslation();

    return (
        <div>
            <strong className="text-[#DB7705] uppercase font-semibold text-[13px] tracking-wider">{t('Editing')}</strong>
            &nbsp;/ <span className="text-[#045140] font-semibold text-[15px] tracking-wider">{title}</span>
        </div>
    )

}


export default EditingTitle;