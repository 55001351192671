import { ReactElement } from "react";
import { useTranslation } from "react-i18next";





const PartTitle = ({ children }: { children: any }) => {

    const { t } = useTranslation();

    if (typeof children !== 'string') return <>{children}</>

    return (
        <h3 className="text-[#DB7705] uppercase font-semibold text-[14px] tracking-wider">{t(children)}</h3>
    )
}


export default PartTitle;