import { useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from 'config/i18n.config'
import { useAppDispatch, useAppSelector } from "store/service";
import MainLoader from "components/loaders/main_loader";
import { initialStateAuth, signIn } from "store/auth";
import RoutesMiddleware from "routes/routes_middleware";

function App() {

  const dispatch: any = useAppDispatch();
  const auth = useAppSelector(state => state.auth) || initialStateAuth;

  useEffect(() => {
    dispatch(signIn({}));
  }, [])


  if (!auth.isAuthenticated && auth.isLoading && localStorage.getItem("access_token")) {
    return <MainLoader />
  }

  return (
    <I18nextProvider i18n={i18n}>
      <RoutesMiddleware />
    </I18nextProvider>
  );
}

export default App;
