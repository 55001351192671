import instance from "config/axios.config";
import { ICourseFormat, ICoursePlan, ICourseWhom, IWillLearn } from "./types";


export async function submitFormat(id: number | undefined, data: ICourseFormat) {
    const url = id ? `/course-about-format/${id}` : `course-about-format/create`;
    const response = await instance({ url, method: id ? 'PUT' : "POST", data });
    return response.data
}

export function deleteFormat(id: any) {
    return instance({ url: `course-about-format/${id}`, method: "DELETE" });
}

export async function submitWhoFor(id: number | undefined, data: ICourseWhom) {
    const url = id ? `/course-whom/${id}` : `course-whom/create`;
    const response = await instance({ url, method: id ? 'PUT' : "POST", data });
    return response.data
}

export function deleteForWhom(id: any) {
    return instance({ url: `course-whom/${id}`, method: "DELETE" });
}

export async function submitCourseCategory(id: string | undefined, data: {categories_id: number[]}) {
    const response = await instance({ url: `course/${id}/update/categories`, method: "POST", data });
    return response.data
}

export async function getAllWillLearn(id: string | undefined) {
    const response = await instance({ url: `course-learn/list/${id}`, method: "GET" });
    return response.data
}

export async function deleteWillLearn(id: number) {
    const response = await instance({ url: `course-learn/${id}`, method: "DELETE" });
    return response.data
}


export async function submitWillLearn(id: number | undefined, data: IWillLearn) {
    const url = id ? `/course-learn/${id}` : `course-learn/create`;
    const response = await instance({ url, method: id ? 'PUT' : "POST", data });
    return response.data
}

export async function submitCoursePlan(id: number | undefined, data: ICoursePlan) {
    const url = id ? `/course-plan/${id}` : `course-plan/create`;
    const response = await instance({ url, method: id ? 'PUT' : "POST", data });
    return response.data
}

export function deleteCoursePlan(id: any) {
    return instance({ url: `course-plan/${id}`, method: "DELETE" });
}


export async function submitCourseStudentsWorks(id: number | undefined, data: any) {

    const formdata = new FormData()

    for (const key in data) {
        formdata.append(key, data[key])
    }
    const url = id ? `/student-work/${id}` : `/student-work/create`;
    const response = await instance({ url, method: id ? 'PUT' : "POST", data: formdata });
    return response.data
}

export async function deleteStudentWork(id: number) {
    const response = await instance({ url: `student-work/${id}`, method: "DELETE" });
    return response.data
}
