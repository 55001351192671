import instance from "config/axios.config";
import { IDiscount } from "./types";

export async function submitData(id: string | undefined, data: IDiscount) {
    const url = id ? `/discount/${id}` : `discount/create`;
    const response = await instance({ url, method: id ? 'PUT' : "POST", data });
    return response.data
}

export async function getDiscounts() {
    const response = await instance({ url: `/discount/list/`, method: 'GET' });
    return response.data;
}

export async function getDataById(id: string | undefined) {

    const response = await instance({ url: `/discount/${id}`, method: 'GET' });
    return response.data;
}


export function deleteData(id: number) {
    return instance({ url: `discount/${id}`, method: 'DELETE' })
}