import { configureStore } from "@reduxjs/toolkit"
import authSlice from "./auth";
import uiSlice from './ui';
import blockSlice from "./block";



const store = configureStore({
    reducer: {
        ui: uiSlice.reducer,
        auth: authSlice.reducer,
        block: blockSlice.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck:false
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ReturnType<typeof store.dispatch>;

export default store;