import { Button, Image } from 'antd';
import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';

const AvatarFileUpload = ({imageSrc,  setImageSrc, mutate}:{imageSrc:{url: string, file: any}, setImageSrc:Dispatch<{url: string, file: any}>, mutate:any}) => {
  const {t} = useTranslation()

  const handleImageChange = (e:any) =>{
    let obj = {url: URL.createObjectURL(e.target.files[0]), file: e.target.files[0]}
    setImageSrc(obj)
}
  return (
    <>
      <div className="relative lg:h-[150px] lg:w-[150px] h-[120px] w-[120px] mx-auto">
          {
              imageSrc?.url ? 
              <Image className="w-full h-full rounded-full object-cover" src={imageSrc?.url} alt={"image"} />
              : <div className="w-full h-full rounded-full bg-slate-50 border border-dashed text-center align-middle"></div>
          }
          <input onChange={(e) => handleImageChange(e)} className="hidden" accept="image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/tiff, image/webp, image/x-icon" type="file" id="course-image"  /> 
          <label htmlFor="course-image" className="cursor-pointer bg-white shadow-md p-2 rounded-full mt-5 absolute bottom-2 right-2">
              <BiPlus className="text-[20px]" />
          </label>
      </div>
          <Button onClick={() => mutate(imageSrc.file)} className='w-[100%] mt-4 border-dashed'>{t("Save")}</Button>
    </>
  );
};

export default AvatarFileUpload;
