import instance from "config/axios.config";
import { IAccessCourse, IAccessCourseForSubmit } from "./types";

export async function submitData(data: IAccessCourseForSubmit) {
    const response = await instance({ url: "user-purchase/open-access", method: "POST", data });
    return response.data
}

export async function getDataById(id: number) {

    const response = await instance({ url: `user-purchase/${id}`, method: 'GET' });
    return response.data;
}


export function deleteData(id: number) {
    return instance({ url: `user-purchase/${id}`, method: 'DELETE' })
}