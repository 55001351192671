import instance from "config/axios.config";
import { IOrganization } from "./types";

export async function submitData(id: string | undefined, data: IOrganization) {

    const url = id ? `/organization/${id}` : `organization/create`;
    const response = await instance({ url, method: id ? 'PUT' : "POST", data });
    return response.data

}

export async function changaPassword(data:{current_password: string, new_password: string}) {
    const response = await instance({ url:"auth/jwt/change-password", method: "POST", data });
    return response.data
}


export async function changeImage(file: any) {    
    const formdata = new FormData()
    formdata.append('image_file', file)
    const response = await instance({ url:"users/upload/me", method: "POST", data: formdata });
    return response.data
}
