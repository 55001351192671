import { useQuery } from "@tanstack/react-query";
import { Button, Form, FormInstance, Input, Select, Switch } from "antd";
import FormLabelCrud from "components/FormLabel/FormLabel";
import useFilterLanguage from "custom-hooks/useFilterLanguage";
import { BiX } from "react-icons/bi";
import { PlusOutlined } from '@ant-design/icons';
import { ILanguage } from "pages/languages/types";
import { IRegion } from "pages/regions/types";
import { IDistrictWithPagination } from "pages/districts/types";
import { AxiosError } from "axios";
import { getDistrict } from "pages/districts/district.request";
import { useEffect, useState } from "react";
import useGetData from "custom-hooks/useGetData";
import LanguageTitle from "utils/LanguageTitle";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const FormItems = ({ form, handleSubmit, regionId }: { form: FormInstance, handleSubmit: any, regionId?: number | undefined }) => {

    const {t} = useTranslation()
    const [selctedRegionId, setselctedRegionId] = useState<number | undefined>(regionId)
    const { data: languages } = useGetData<ILanguage[]>({ queryKey: ['languages'], url: 'language/list/', options: { staleTime: Infinity, refetchOnWindowFocus: false } });
    const { data: allRegions } = useGetData<IRegion[]>({ queryKey: ['IRegion'], url: 'region/list/', options: { staleTime: Infinity, refetchOnWindowFocus: false } });

    const { fl, languageIds } = useFilterLanguage(form);


    const districtQuery = useQuery<IDistrictWithPagination, AxiosError>({
        queryKey: ['district-by-id', selctedRegionId],
        queryFn: () => getDistrict(selctedRegionId),
        retry: 0,
        staleTime: Infinity,
        refetchOnWindowFocus:false,
    })

    useEffect(() => {
        setselctedRegionId(regionId)
        districtQuery.refetch();
    }, [regionId])


    const validateFormRules = (rule:any, value:string,callback:any) => {
        if (value && !value.includes("http://") || !value.includes("https://")) {
            callback('Please start the url with https:// or http://'); 
        }else if(!value.length) {
            callback('Please input Domain name!'); 
        }
        else {
          callback(); 
        }
      };

    return (
        <Form layout={"vertical"} form={form} onFinish={handleSubmit} >
            <div className="grid grid-cols-12 gap-4">
                <Form.List name="translations">
                    {(fields, { add, remove }) => (
                        <div className="lg:col-span-6 col-span-12 ">
                            {fields.map(({ key, name, ...restField }, index) => (
                                <div key={index} className='grid grid-cols-12 gap-4 mb-4 bg-slate-50 p-2 rounded-md'>
                                    <div className="lg:col-span-6 md:col-span-6 col-span-12">
                                        <Form.Item
                                            className='mb-0'
                                            label={<FormLabelCrud>Title</FormLabelCrud>}
                                            {...restField}
                                            name={[name, 'title']}
                                            rules={[{ required: index === 0, message: 'Please input title!' }]}
                                        >
                                            <Input placeholder="Ma'lumot kiriting" />
                                        </Form.Item>
                                    </div>

                                    <div className="lg:col-span-6 md:col-span-6 col-span-12 flex items-end">
                                        <Form.Item
                                            label={<FormLabelCrud>Language</FormLabelCrud>}
                                            {...restField}
                                            name={[name, 'language_id']}
                                            rules={[{ required: index === 0, message: 'Please select language' }]}
                                            className="w-[calc(100%-55px)] mb-0"
                                        >
                                            <Select onClick={fl}>
                                                {
                                                    languages?.map(element => <Option key={element.id} value={element.id} disabled={languageIds.includes(element.id)}><LanguageTitle element={element}/></Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item className="w-[50px] mb-0 ml-2 mt-[25px]">
                                            <Button disabled={fields.length == 1} onClick={() => { remove(name); }} danger><BiX /></Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            ))}
                            <div className="col-span-12">
                                <Button type="dashed" className="w-[200px] block text-[#959595]" onClick={() => { add() }} block icon={<PlusOutlined />} disabled={fields.length === languages?.length}>
                                    {t("Add field")}
                                </Button>
                            </div>
                        </div>
                    )}
                </Form.List>
                <div className="lg:col-span-6 col-span-12">
                    <div className="grid grid-cols-12 gap-x-4">
                    <div className="md:col-span-6 col-span-12">
                        <Form.Item
                                label={t("Brand name")}
                                name="brand_name"
                                rules={[{ required: false, message: 'Please input your Brand Name!' }]}
                            >
                                <Input type="text" className="w-[100%]" />
                            </Form.Item>
                        </div>
                        <div className="md:col-span-6 col-span-12">
                        <Form.Item
                                label={t("Domain name")}
                                name="domain_name"
                                rules={[
                                    {required:true, message:""},
                                    { validator: validateFormRules },
                                ]}
                                
                            >
                                <Input type="text" className="w-[100%]"  placeholder="http:// or https://"  />
                            </Form.Item>
                        </div>
                        <div className="md:col-span-6 col-span-12">
                            <Form.Item
                                label={t("Regions")}
                                name="legal_address_region_id"
                                rules={[{ required: false, message: 'Please input Region!' }]}
                            >
                                <Select
                                
                                    onChange={(e) => { setselctedRegionId(e); districtQuery.refetch() }}
                                    className='mb-3 w-[100%]'
                                    options={allRegions?.map(region => { return { value: region.id, label: region.translations[0].title } })}
                                    
                                />
                            </Form.Item>
                        </div>
                        <div className="md:col-span-6 col-span-12">
                            <Form.Item
                                label={t("District")}
                                name="legal_address_district_id"
                                rules={[{ required: false, message: 'Please input District!' }]}
                            >
                                <Select
                                    disabled={selctedRegionId === undefined}
                                    className='mb-3 w-[100%]'
                                    options={districtQuery.data?.results?.map(data => { return { value: data.id, label: data.translations[0].title } })}
                                />
                            </Form.Item>
                        </div>
                        <div className="md:col-span-6 col-span-12">
                            <Form.Item
                                label={t("Identification number")}
                                name="identification_number"
                                rules={[{ required: true, message: 'Please input Identification number!' }]}
                            >
                                <Input type="number" className="w-[100%]" />
                            </Form.Item>
                        </div>
                        <div className="md:col-span-6 col-span-12">
                            <Form.Item
                                label={t("Bank account")}
                                name="bank_account"
                                rules={[{ required: false, message: 'Please input Bank account!' }]}
                            >
                                <Input type="number" className="w-[100%]" />
                            </Form.Item>
                        </div>
                        <div className="md:col-span-6 col-span-12">
                            <Form.Item
                                label={t("Bank mfo")}
                                name="bank_mfo"
                                rules={[{ required: false, message: 'Please input Bank mfo!' }]}
                            >
                                <Input type="number" className="w-[100%]" />
                            </Form.Item>
                        </div>
                        <div className="md:col-span-2 col-span-6">
                            <Form.Item
                                label={t("Status")}
                                name="is_active"
                                rules={[{ required: false, message: 'Please input is status!' }]}
                                valuePropName={'checked'}
                            >
                                <Switch defaultChecked className="bg-[#999]" />
                            </Form.Item>
                        </div>
                        <div className="lg:col-span-2 md:col-span-2 col-span-6">
                            <Form.Item
                                label={t("Verified")}
                                name="is_verified"
                                rules={[{ required: false, message: 'Please input is Verified!' }]}
                                valuePropName={'checked'}
                            >
                                <Switch defaultChecked className="bg-[#999]" />
                            </Form.Item>
                        </div>
                     
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default FormItems;