import Dashboard from "pages/dashboard";
import Login from "pages/login";
import { HiOutlineCode, HiOutlineHome } from "react-icons/hi";
import { MdOutlineEditLocationAlt, MdOutlineTopic, MdOutlineCategory, MdPayment } from "react-icons/md";
import { CgReorder } from "react-icons/cg";
import { FaDiscourse, FaDotCircle, FaUserShield, FaUsers } from "react-icons/fa";
import { CiDiscount1 } from "react-icons/ci";
import { AiOutlineQuestionCircle, AiOutlineSolution, AiOutlineTag, AiOutlineUserSwitch } from "react-icons/ai";
import { TbDiscount2 } from "react-icons/tb";
import { FiBriefcase } from "react-icons/fi";
import { RxBackpack } from "react-icons/rx";
import { IoMdContact } from "react-icons/io";
import { RoutesTypeElement } from "./types";
import Languages from "pages/languages";
import Orders from "pages/orders";
import Permissions from "pages/permissions";
import Regions from "pages/regions";
import Tags from "pages/tags";
import Roles from "pages/roles";
import RolePermissions from "pages/roles/role_permissions";
import Districts from "pages/districts";
import Professions from "pages/professions";
import Topics from "pages/topics";
import Categories from "pages/categories";
import Courses from "pages/courses";
import Users from "pages/users";
import UserCreate from "pages/users/create";
import CreateCourse from "pages/courses/create_data";
import Discounts from "pages/discounts";
import CreateDiscount from "pages/discounts/create_data";
import UpdateDiscount from "pages/discounts/update_data";
import Organizations from "pages/organizations";
import CreateOrganization from "pages/organizations/create_data";
import UpdateOrganization from "pages/organizations/update_data";
import { RiOrganizationChart } from "react-icons/ri";
import ViewOrganization from "pages/organizations/view";
import Promocodes from "pages/promocodes";
import UpdateCourse from "pages/courses/update_data";
import CourseViewTab from "pages/courses/view/tab_links";
import CourseModuleView from "pages/course_modules/view";
import CreateLesson from "pages/module_lessons/create_data";
import UserUpdate from "pages/users/update";
import UpdateLesson from "pages/module_lessons/update_data";
import Profile from "pages/profile";
import LessonView from "pages/module_lessons/Components";
import UserView from "pages/users/view";
import InstallmentPayment from "pages/installmentPayment";
import CreateCourseAccess from "pages/course_access/create_data";
import PromocodeView from "pages/promocodes/Components/tabs";
import UpdateCourseAccess from "pages/course_access/update_data";
import Course_group from "pages/course_groups/Components/course_group";
import Contacts from "pages/contacts";
import CourseReviewView from "pages/courses/course_reviews/view";
import Trainings from "pages/trainings";
import Questions from "pages/questions";
import QuestionsView from "pages/questions/view";
import Resumes from "pages/resumes";
import ViewResume from "pages/resumes/view/resume_form_ui";
import Spheres from "pages/spheres";
import ProfessionView from "pages/spheres/view";

export const public_routes: RoutesTypeElement[] = [
  {
    name: "Login",
    path: "/signin",
    component: Login,
    config: {
      key: "unlock",
      icon: HiOutlineHome,
      structure: "nonlayout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
];

export const protected_routes: RoutesTypeElement[] = [
  {
    name: "Main page",
    path: "/",
    component: Dashboard,
    config: {
      key: "unlock",
      icon: HiOutlineHome,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },

  // profile
  {
    name: "Profile",
    path: "/profile/me",
    component: Profile,
    config: {
      key: "unlock",
      icon: HiOutlineHome,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  // Organizations
  {
    name: "Administrator",
    path: "",
    component: Organizations,
    config: {
      key: "unlock",
      icon: FaUserShield,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [
      // roles
      {
        name: "Roles",
        path: "/roles",
        component: Roles,
        config: {
          key: "read_role",
          icon: FaDotCircle,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      // Permissions
      {
        name: "Permissions",
        path: "/permissions",
        component: Permissions,
        config: {
          key: "read_permission",
          icon: FaDotCircle,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      //regions
      {
        name: "Regions",
        path: "/regions",
        component: Regions,
        config: {
          key: "read_region",
          icon: FaDotCircle,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Districts",
        path: "/districts",
        component: Districts,
        config: {
          key: "read_district",
          icon: FaDotCircle,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
      {
        name: "Languages",
        path: "/languages",
        component: Languages,
        config: {
          key: "read_language",
          icon: FaDotCircle,
          structure: "layout",
          exact: true,
          isShowLink: true,
        },
      },
    ],
  },
  {
    name: "Organizations",
    path: "/organizations",
    component: Organizations,
    config: {
      key: "read_organization",
      icon: RiOrganizationChart,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },
  {
    name: "Organization create",
    path: "/organization/create",
    component: CreateOrganization,
    config: {
      key: "unlock",
      icon: FaDiscourse,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Organization update",
    path: "/organization/update/:id",
    component: UpdateOrganization,
    config: {
      key: "unlock",
      icon: FaDiscourse,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Organization view",
    path: "/organization/view/:id",
    component: ViewOrganization,
    config: {
      key: "unlock",
      icon: FaDiscourse,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },

  //users
  {
    name: "Users",
    path: "/users",
    component: Users,
    config: {
      key: "read_user",
      icon: FaUsers,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },
  {
    name: "User Create",
    path: "/user/create",
    component: UserCreate,
    config: {
      key: "unlock",
      icon: MdOutlineEditLocationAlt,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "User Update",
    path: "/user/update/:user_id",
    component: UserUpdate,
    config: {
      key: "unlock",
      icon: MdOutlineEditLocationAlt,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "User View",
    path: "/users/view/:user_id",
    component: UserView,
    config: {
      key: "unlock",
      icon: MdOutlineEditLocationAlt,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  // orders
  {
    name: "Orders",
    path: "/orders",
    component: Orders,
    config: {
      key: "read_order",
      icon: CgReorder,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },

  // tags
  {
    name: "Tags",
    path: "/tags",
    component: Tags,
    config: {
      key: "read_tag",
      icon: AiOutlineTag,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },
  {
    name: "Roles permission",
    path: "/roles/permissions/:id",
    component: RolePermissions,
    config: {
      key: "unlock",
      icon: FiBriefcase,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },

  // Professions
  {
    name: "Professions",
    path: "/professions",
    component: Professions,
    config: {
      key: "read_profession",
      icon: RxBackpack,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },

  // Topics
  {
    name: "Topics",
    path: "/topics",
    component: Topics,
    config: {
      key: "read_topic",
      icon: MdOutlineTopic,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },

  // Categories
  {
    name: "Categories",
    path: "/categories",
    component: Categories,
    config: {
      key: "read_category",
      icon: MdOutlineCategory,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },

  // Courses
  {
    name: "Courses",
    path: "/courses",
    component: Courses,
    config: {
      key: "read_course",
      icon: FaDiscourse,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },
  {
    name: "Course add",
    path: "/courses/create",
    component: CreateCourse,
    config: {
      key: "unlock",
      icon: FaDiscourse,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Course edit",
    path: "/courses/update/:id",
    component: UpdateCourse,
    config: {
      key: "unlock",
      icon: FaDiscourse,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Course view",
    path: "/courses/view/:id",
    component: CourseViewTab,
    config: {
      key: "unlock",
      icon: FaDiscourse,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Course group view",
    path: "/courses/group/view/:course_group_id",
    component: Course_group,
    config: {
      key: "unlock",
      icon: TbDiscount2,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Course module view",
    path: "/course/modules/view/:id",
    component: CourseModuleView,
    config: {
      key: "unlock",
      icon: FaDiscourse,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Lesson create",
    path: "/course/module/lesson/create/:module_id",
    component: CreateLesson,
    config: {
      key: "unlock",
      icon: FaDiscourse,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Lesson update",
    path: "/course/module/lesson/update/:id",
    component: UpdateLesson,
    config: {
      key: "unlock",
      icon: FaDiscourse,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Lesson view",
    path: "/course/module/lesson/view/:id",
    component: LessonView,
    config: {
      key: "unlock",
      icon: FaDiscourse,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Course review view",
    path: "/course/reviews/view/:id",
    component: CourseReviewView,
    config: {
      key: "unlock",
      icon: FaDiscourse,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },

  // Discounts
  {
    name: "Discounts",
    path: "/discounts",
    component: Discounts,
    config: {
      key: "read_discount",
      icon: CiDiscount1,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },
  {
    name: "Course add",
    path: "/discount/create",
    component: CreateDiscount,
    config: {
      key: "unlock",
      icon: FaDiscourse,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Course add",
    path: "/discount/update/:id",
    component: UpdateDiscount,
    config: {
      key: "unlock",
      icon: FaDiscourse,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },

  // Promocodes
  {
    name: "Promocodes",
    path: "/promocodes",
    component: Promocodes,
    config: {
      key: "read_promocode",
      icon: TbDiscount2,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },

  {
    name: "Promocode view",
    path: "/promocode/view/:promocode_id",
    component: PromocodeView,
    config: {
      key: "unlock",
      icon: TbDiscount2,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },

  // installment payments
  {
    name: "Installment payment",
    path: "/installment-payment",
    component: InstallmentPayment,
    config: {
      key: "read_installment_payment",
      icon: MdPayment,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },

  // installment payments
  {
    name: "CreateCourseAccess",
    path: "/create/course-access/:course_id",
    component: CreateCourseAccess,
    config: {
      key: "unlock",
      icon: MdPayment,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },

  {
    name: "CreateCourseAccess",
    path: "/update/course-access/:course_id",
    component: UpdateCourseAccess,
    config: {
      key: "unlock",
      icon: MdPayment,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },

  // Contacts
  {
    name: "Contacts",
    path: "/contacts",
    component: Contacts,
    config: {
      key: "read_contact",
      icon: IoMdContact,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },
  {
    name: "Trainings",
    path: "/trainings",
    component: Trainings,
    config: {
      key: "read_contact",
      icon: HiOutlineCode,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },
  {
    name: "Questions",
    path: "/questions",
    component: Questions,
    config: {
      key: "read_question",
      icon: AiOutlineQuestionCircle,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },
  {
    name: "Question view",
    path: "/question/view/:question_id",
    component: QuestionsView,
    config: {
      key: "unlock",
      icon: TbDiscount2,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Resumes",
    path: "/resumes",
    component: Resumes,
    config: {
      key: "read_resume",
      icon: AiOutlineSolution,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },
  {
    name: "Resume view",
    path: "/resume/view/:resume_id",
    component: ViewResume,
    config: {
      key: "unlock",
      icon: TbDiscount2,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
  {
    name: "Spheres",
    path: "/spheres",
    component: Spheres,
    config: {
      key: "read_sphere",
      icon: AiOutlineUserSwitch,
      structure: "layout",
      exact: true,
      isShowLink: true,
    },
    submenu: [],
  },
  {
    name: "Sphere view",
    path: "/sphere/view/:sphere_id",
    component: ProfessionView,
    config: {
      key: "unlock",
      icon: TbDiscount2,
      structure: "layout",
      exact: true,
      isShowLink: false,
    },
    submenu: [],
  },
];
