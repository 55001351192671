import { Tabs, TabsProps } from "antd";
import CourseModules from "pages/course_modules";
import ViewCourseDetails from "./details/view_details";
import ViewCourse from "./view_data";
import CourseMentors from "pages/course_mentors";
import ToBack from "components/Common/ToBack";
import CourseGroups from "pages/course_groups";
import CourseAccess from "pages/course_access";
import useUrlQueryParams from "custom-hooks/useUrlQueryParams";
import { is_permission } from "utils.functions";
import CourseReviews from "../course_reviews";
import CourseRatings from "../course_ratings";
import { useTranslation } from "react-i18next";
import GlobalBack from "components/Common/GlobalBack";

const CourseViewTab = () => {
  const { t } = useTranslation();

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t(`Main`),
      children: <ViewCourse />,
    },
    {
      key: "2",
      label: t(`Details`),
      children: <ViewCourseDetails />,
    },
    {
      key: "3",
      label: t(`Modules`),
      children: <CourseModules />,
    },
    {
      key: "4",
      label: t(`Mentors`),
      children: <CourseMentors />,
    },
    {
      key: "5",
      label: t(`Groups`),
      children: <CourseGroups />,
    },
    {
      key: "6",
      label: t(`Access`),
      children: <CourseAccess />,
    },
    {
      key: "7",
      label: t(`Reviews`),
      children: <CourseReviews />,
    },
    {
      key: "8",
      label: t(`Ratings`),
      children: <CourseRatings />,
    },
  ];

  const { value, writeToUrl } = useUrlQueryParams({});
  let filtered_items: TabsProps["items"] = [];
  items.forEach((item) => {
    if (
      !(!is_permission("read_course_module") && item.label === "Modules") &&
      !(!is_permission("read_course_group") && item.label === "Groups") &&
      !(!is_permission("read_course_review") && item.label === "Reviews") &&
      !(!is_permission("read_course_rating") && item.label === "Ratings")
    ) {
      filtered_items?.push(item);
    }
  });

  const onChange = (key: string) => {
    writeToUrl({ name: "tab_id", value: key });
  };

  return (
    <>
      <GlobalBack title={t("Back")} href="/courses" />
      <div className="col-span-12 main-card mt-4">
        <Tabs activeKey={String(value.filter.tab_id ?? 1)} items={filtered_items} onChange={onChange} />
      </div>
    </>
  );
};
export default CourseViewTab;
