import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Drawer, Form, Input, message } from "antd";
import useWindowSize from "custom-hooks/useWindowSize";
import { submitData } from "./tags.request";
import { ITag } from "./types";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import PartTitle from "components/Common/PartTitle";
import EditingTitle from "components/Common/EditingTitle";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { RButton, SButton } from "components/Common/CustomButtons";
import { useTranslation } from "react-i18next";


type TypeTagFormProps = {
  element: ITag | null;
  setElement: React.Dispatch<React.SetStateAction<ITag | null>>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: React.Dispatch<React.SetStateAction<boolean>>;
}


const CreateTag = ({ element, setElement, refetch, openSidebarMenu, setOpenSidebarMenu }: TypeTagFormProps) => {
  
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const { width } = useWindowSize();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue({
        title: element.title,
        code: element.code,
      })
    } else {
      form.resetFields()
    }
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-tag"],
    mutationFn: (newVals) => submitData(element?.id, newVals),
    onSuccess: async (data) => {
      queryClient.setQueryData(["tags"], data);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data)
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    mutate(values);
  }



  const MemoizationFieldsFormTag = React.memo(() => (
    <Form
      layout={"vertical"}
      form={form}
      onFinish={handleSubmit}
      className="custom_form_style"
    >
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Form.Item
            label={<FormLabelCrud>Name</FormLabelCrud>}
            name="title"
            rules={[
              { required: true, message: "Please input language title!" },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
      </div>
      <div className="mt-2 text-right">
        <RButton
          onClick={() => {
            form.resetFields();
            setElement(null);
          }}
          className="mr-2"
        />
        <SButton loading={isLoading} />
      </div>
    </Form>
  ));

  return (
    <div>
      <>{element ? <EditingTitle title={element.title} /> : <PartTitle children={"Create tag"} />}</>
      <hr className="my-3" />
      <MemoizationFieldsFormTag />
      <Drawer
        title={<h3 className=" font-bold">{element?.title ? element?.title : t("New")}</h3>}
        placement="right"
        onClose={() => setOpenSidebarMenu(false)}
        open={width > 992 ? false : openSidebarMenu}
      >
        <MemoizationFieldsFormTag />
      </Drawer>
    </div>
  );
};
export default CreateTag;
