import { useMutation } from "@tanstack/react-query";
import { Button, Form, Select, message } from "antd";
import useGetData from "custom-hooks/useGetData";
import { IRole } from "pages/roles/types";
import { cf_filterOption } from "utils.functions";
import { attachRoleFn } from "../users.request";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { useParams } from "react-router-dom";
import React from "react";
import { IUser } from "types/data.models";






const AttachRole = ({ roles }: { roles: IUser['roles'] }) => {


    const { user_id } = useParams();
    const [form] = Form.useForm();

    const _roles = useGetData<IRole[]>({ queryKey: ['roles'], url: 'role/list/', options:{
        refetchOnWindowFocus:false
    } });

    const attach_role = useMutation({
        mutationKey: ['attach-role-to-user'],
        mutationFn: (data: any) => attachRoleFn(user_id, data),
    })


    React.useMemo(() => {
        if (roles.length) {
            const roles_ids = roles.map(e => e.id);
            if (roles_ids.length) {
                form.setFieldValue('roles_id', roles_ids)
            }
        }
    }, [roles])


    return (
        <div>
            <div className="grid grid-cols-12 gap-4">
                <div className="col-span-"></div>
            </div>
            <Form
                form={form}
                className="flex"
                layout="vertical"
                onFinish={(values) => attach_role.mutate(values)}
            >
                <Form.Item
                    label={<FormLabelCrud>FOYDALANUVCHI UCHUN ROL (LAR) TANLANG</FormLabelCrud>}
                    name="roles_id"
                    className="w-[100%] mr-2"
                    rules={[{ required: true, message: '' }]}
                >
                    <Select
                        mode="multiple"
                        showSearch
                        allowClear
                        filterOption={cf_filterOption}
                        placeholder="Foydalanuvchi uchun rol tanlang!"
                    >
                        {
                            _roles.data?.map(element => <Select.Option key={element.id} value={element.id} >{element.title}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Button className="bg-[#045140] text-white mt-[30px]" htmlType="submit" loading={attach_role.isLoading} >Save</Button>
            </Form>
        </div >
    )


}


export default AttachRole;