
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';




const StyledTableHead = ({ children }: { children: any }) => {


    const { t } = useTranslation();

    if (typeof children !== 'string') return <>{children}</>;


    return (
        <span className='text-[#045140] uppercase text-[12px] tracking-wider'>{t(children)}</span>
    )

}


export default StyledTableHead;