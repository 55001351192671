import { Form, FormInstance, Input, Select, Switch } from "antd";
import useGetData from "custom-hooks/useGetData";
import { useState } from "react";
import { ICourseModules } from "pages/course_modules/types";
import { useParams } from "react-router-dom";
import { IUser } from "types/data.models";
import { InformerDRDT } from "types/base.models";
import { IInstallmentPayment } from "pages/installmentPayment/types";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { CLIENT_API } from "service/clieant.request";
import useDebounce from "custom-hooks/useDebounce";
import { useTranslation } from "react-i18next";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";




const paymentTypes = [
    { value: "cash", label: "Cash" },
    { value: "plastic", label: "Plastic" }
]

const paymentprivider = [
    { value: "click", label: "Click" },
    { value: "paycom", label: "Paycom" },
    { value: "uzum", label: "Uzum" },
    { value: "rahmat", label: "Rahmat" }
]

const FormItems = ({ form, handleSubmit }: { form: FormInstance, handleSubmit: any }) => {

    const {t} = useTranslation()
    const { course_id } = useParams()
    const [courseVals, setcourseVals] = useState<{value: boolean | number, disabled: boolean}>({value: false, disabled: false})
    const [moduleVals, setmoduleVals] = useState<{value: number | undefined, disabled: boolean}>({value: undefined, disabled: false})
    const [paymentTypeValue, setpaymentTypeValue] = useState<string>()
    const [searcVal, setsearcVal] = useState<string>("")
    const debouncedValue = useDebounce<string>(searcVal, 200)
    const {_translate} = useLocaleTranslation()

    const { data: modules } = useGetData<ICourseModules[]>({ queryKey: ['course-modules'], url: `course-module/list/by-course/${course_id}`, options: { staleTime: Infinity, refetchOnWindowFocus: false } });
    const { data: installmentPayments } = useGetData<IInstallmentPayment[]>({ queryKey: ['installment-payments'], url: `installment-payment/list/`, options: { staleTime: Infinity, refetchOnWindowFocus: false } });
    const { data: promocodes } = useGetData<IInstallmentPayment[]>({ queryKey: ['promocodes'], url: `promocode/list/`, options: { staleTime: Infinity, refetchOnWindowFocus: false } });
    

    const {data: users} = useQuery<InformerDRDT<IUser>, AxiosError>({
        queryKey: ['users', debouncedValue],
        queryFn: () => CLIENT_API.getAll({ url: `users/list/`, _params:{q: debouncedValue}}),
        retry: 1
    });

    const coursePartHandler = (e: any, type:string) => {
        switch (type) {
            case "course":
                if(e){
                    setcourseVals({value: e, disabled: false})
                    setmoduleVals({value: undefined, disabled: true})
                }else{
                    setcourseVals({value: false, disabled: false})
                    setmoduleVals({value: undefined, disabled: false})
                }
                break;
            case "module":
                if(e){
                    setmoduleVals({value: e, disabled: false})
                    setcourseVals({value: false, disabled: true})
                }else{
                    setmoduleVals({value: undefined, disabled: false})
                    setcourseVals({value: false, disabled: false})
                }
                break;
        }
    }
    
    return (
        <Form layout={"vertical"} form={form} onFinish={handleSubmit}>
            <div className="grid grid-cols-12 gap-4">
                <div className="lg:col-span-2 sm:col-span-6 col-span-12">
                    <Form.Item
                        label={t("Full course")}
                        name="is_full"
                        rules={[{ required: false, message: t('Please input is archive')! }]}
                        valuePropName={'checked'}
                    >
                        <Switch disabled={moduleVals?.value !== undefined} onChange={(e) => coursePartHandler(e, "course")} defaultChecked className="bg-[#999]" />
                    </Form.Item>
                </div>
                <div className="lg:col-span-3 sm:col-span-6 col-span-12">
                    <Form.Item
                        label={t("Modules")}
                        name="course_module_id"
                        rules={[{ required: false, message: t('Please select module!')! }]}
                    >
                        <Select
                            disabled={courseVals?.value === true}
                            allowClear
                            onChange={(e) => coursePartHandler(e, "module")}
                            options={modules?.map((i) => {return { value: i?.id, label: _translate(i?.translations,"title") }})}
                        />
                    </Form.Item>
                </div>
                <div className="lg:col-span-3 sm:col-span-6 col-span-12">
                    <Form.Item
                        label={t("Users")}
                        name="user_id"
                        rules={[{ required: true, message: t('Please select user')! }]}
                    >
                        <Select
                            showSearch
                            allowClear
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            onSearch={e => setsearcVal(e)}
                            options={users?.results?.map(i => {return { value: i?.id, label: `${_translate(i?.translations,"first_name") || 'not named'}  ${_translate(i?.translations,"last_name") || "not surnamed"}` }})}
                        />
                    </Form.Item>
                </div>
                <div className="lg:col-span-2 sm:col-span-6 col-span-12">
                    <Form.Item
                        label={t("Payment type")}
                        name="payment_type"
                        rules={[{ required: true, message: t('Please select payment')! }]}
                    >
                        <Select
                            allowClear
                            onChange={(e) => setpaymentTypeValue(e)}
                            options={paymentTypes}
                        />
                    </Form.Item>
                </div>
                <div className="lg:col-span-2 sm:col-span-6 col-span-12">
                    <Form.Item
                        label={t("Payment provider")}
                        name="payment_provider"
                        rules={[{ required: paymentTypeValue === "plastic", message: t('Please select payment provider')! }]}
                    >
                        <Select
                            allowClear
                            disabled={paymentTypeValue !== "plastic"}
                            options={paymentprivider}
                        />
                    </Form.Item>
                </div>
                <div className="lg:col-span-3 sm:col-span-6 col-span-12">
                    <Form.Item
                        label={t("Installment payment")}
                        name="installment_payment_id"
                        rules={[{ required: false, message: t('Please select payment provider')! }]}
                    >
                        <Select
                            allowClear
                            options={installmentPayments?.map(i => {return { value: i?.id, label: _translate(i?.translations,"title") }})}
                        />
                    </Form.Item>
                </div>
                <div className="lg:col-span-3 sm:col-span-6 col-span-12">
                    <Form.Item
                        label={t("Promocode")}
                        name="promocode_id"
                        rules={[{ required: false, message: t('Please select payment provider')! }]}
                    >
                        <Select
                            allowClear
                            options={promocodes?.map(i => {return { value: i?.id, label: _translate(i?.translations,"title") }})}
                        />
                    </Form.Item>
                </div>
                <div className="md:col-span-6 col-span-12">
                    <Form.Item
                        label={t("Detail")}
                        name="detail"
                        rules={[{ required: true, message: t('Please input is detail')! }]}
                    >
                        <Input.TextArea  />
                    </Form.Item>
                </div>
            </div>
        </Form>
    );
};

export default FormItems;