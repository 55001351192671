export const quizTypes = [
  {
    value: "last_public",
    label: "Last public",
  },
  {
    value: "every_public",
    label: "Every public",
  },
  {
    value: "every_private",
    label: "Every private",
  },
  {
    value: "last_private",
    label: "Last private",
  },
];

export const resumeTypes = ["publish", "private", "pending", "draft", "reject"];
