import { Navigate, Route, Routes } from "react-router-dom";
import CLayout from "components/Dashboard/Layout";
import { useAppSelector } from "store/service";
import { protected_routes, public_routes } from "./routes";
import { RoutesTypeElement } from "./types";
import { initialStateAuth } from "store/auth";

function RenderComponent(MyComponent: RoutesTypeElement | Omit<RoutesTypeElement, 'submenu'>) {


    const Component = MyComponent.component;

    if (MyComponent.config.structure === 'layout') {

        return (
            <CLayout>
                <Component />
            </CLayout>
        )
    }

    return <Component />

}

const RoutesMiddleware = () => {

    const auth = useAppSelector(state => state.auth) || initialStateAuth;

    const checking_route = (auth_key: string) => {

        const isAuth = auth.permissions.includes(auth_key) || auth_key === 'unlock' || auth_key === 'admin';

        return isAuth;
    }


    const render_route = (element: RoutesTypeElement | Omit<RoutesTypeElement, 'submenu'>) => {


        if (!checking_route(element.config.key)) return null

        return <Route key={element.config.key} path={element.path} element={RenderComponent(element)} />


    }


    if (auth.isAuthenticated) {

        return (
            <Routes>
                {
                    protected_routes.length && protected_routes.map((element) => {

                        if (element.submenu.length) {

                            return element.submenu.map((childElement) => render_route(childElement))
                        }

                        return render_route(element)

                    })
                }
                <Route path="/signin" element={<Navigate to="/" />} />
            </Routes>
        )
    }


    return (
        <Routes>
            {
                public_routes.length && public_routes.map((element) => {

                    if (element.submenu.length) {
                        return element.submenu.map((childElement) => render_route(childElement))
                    }

                    return render_route(element)
                })
            }
            <Route path="*" element={<Navigate to={localStorage.getItem("access_token") ? window.location.search? window.location.pathname+ window.location.search:window.location.pathname : "/signin"} replace />} />

        </Routes>
    )




}



export default RoutesMiddleware;