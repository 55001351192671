import { Button, Checkbox, Form, Input } from 'antd';
import "./styles.css";
import { useAppDispatch } from './../../store/service';
import logo_xl_svg_login from 'assets/images/logo_xl_svg_login.svg'
import { signIn } from 'store/auth';

const Login = () => {

    const dispatch: any = useAppDispatch();

    const onFinish = (values: { username: string, password: string }) => {
        dispatch(signIn({ username: values.username, password: values.password }))
    };


    return (
        <div>
            <section className="relative isolate overflow-hidden bg-white py-24 px-6 sm:py-32 lg:px-8 h-[100vh]">
                <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20"></div>
                <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center"></div>
                <div className="mx-auto max-w-2xl lg:max-w-4xl">
                    <figure className="flex justify-center">
                        <section className="py-10 px-5 min-h-[400px] w-[700px] bg-slate-100 rounded-xl">
                            <div className="flex justify-center">
                                <div className="h-full w-[400px] items-center justify-center">
                                    <div className="flex justify-center">
                                        <img src={logo_xl_svg_login} className="w-[50%]" alt="Sample image" />
                                    </div>
                                    <Form
                                        layout='vertical'
                                        onFinish={onFinish}
                                        className="login-box"
                                    >
                                        <div className="mt-10">
                                            <Form.Item label="Username" name="username" className='w-[100%]'>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item label="Password" name="password">
                                                <Input.Password />
                                            </Form.Item>
                                            <div className="text-end">
                                                <button
                                                    type="submit"
                                                    className="inline-block rounded w-[130px] bg-[#045140] active:bg-[#033B2F] py-2 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                                                    data-te-ripple-init
                                                    data-te-ripple-color="light">
                                                    Login
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </section>
                    </figure>
                </div>
            </section>
        </div>
    )
}
export default Login;