import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type blockItemType = {
  id: string;
  loading: boolean;
};

const initialState: blockItemType[] = [];

const blockSlice = createSlice({
  name: "block",
  initialState,
  reducers: {
    changeBlockItem(state, action: PayloadAction<{ id: string; loading: boolean }>) {
      // state.collapsed = action.payload.collapsed;
      let block = state.find((item) => item.id === action.payload.id);
      if (block) {
        block.loading = action.payload.loading;
      }
    },
    addBlockItem(state, action: PayloadAction<{ id: string; loading: boolean }>) {
      state.push(action.payload);
    },
    removeBlockItem(state, action: PayloadAction<{ id: string }>) {
      let index = state.findIndex((it) => it.id === action.payload.id);
      state.splice(index, 1);
    },
    clearBlockItem(state) {
      state = [];
    },
  },
});

export const BLOCK_ACTIONS = blockSlice.actions;

export default blockSlice;
