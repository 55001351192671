import { Skeleton } from "antd"

const TableLoader = ({isLoading}:{isLoading:boolean}) => {
    return (
        <>
            <Skeleton loading={isLoading} active />
            <Skeleton loading={isLoading} active />
            <Skeleton loading={isLoading} active />
            <Skeleton loading={isLoading} active />
        </>
    )
}
export default TableLoader;