import instance from "config/axios.config";
import { IStateParams } from "custom-hooks/useUrlQueryParams";

type TypeGetAll = <T>({
  url,
  urlParams,
  _params,
}: {
  url: string;
  urlParams?: IStateParams;
  _params?: Record<string | number, any>;
  role?: string;
  is_staff?: string;
  is_paid?:boolean | null;
}) => Promise<T>;

const getAll: TypeGetAll = async ({
  url,
  urlParams,
  _params,
  role,
  is_staff,
  is_paid
}) => {
  let params: Record<string | number, any> = {};

  if (urlParams) {
    const { perPage, currentPage, filter, filter_like } = urlParams;
    params = {
      filter: JSON.stringify(filter),
      "filter-like": JSON.stringify(filter_like),
      "per-page": perPage,
      page: currentPage,
      role,
      is_staff,
      is_paid,
      ..._params,
    };
  } else {
    params = { ..._params };
  }

  const response = await instance({ url, method: "GET", params });

  return response.data;
};

type TypeGetOne = <T>({
  url,
  _params,
}: {
  url: string;
  _params?: Record<string | number, any>;
}) => Promise<T>;

const getOne: TypeGetOne = async ({ url, _params }) => {
  const response = await instance({ url, method: "GET", params: _params });

  const data = await response.data;

  return data;
};

export const CLIENT_API = {
  getAll,
  getOne,
};

export async function getAllRegions() {
  const response = await instance({ url: `region/list/`, method: "GET" });
  return response.data;
}

export async function getAllLanguages() {
  const response = await instance({ url: `language/list/`, method: "GET" });
  return response.data;
}
