import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Menu } from 'antd';
import { useAppSelector } from 'store/service';
import logo_xl from "assets/images/logo_xl_svg.svg"
import { filterLinksForSidebar } from './utils';
import "../styles.css";

const { Sider } = Layout;
const CSidebar: React.FC = () => {

    const ui = useAppSelector(state => state.ui);
    
    return (
        <>
            <Sider trigger={null} collapsible collapsed={ui.collapsed} theme="light" style={{ height: '100vh', backgroundColor: '#045140', position: "sticky", top: 0 }} >
                {/* <Sider collapsed={ui.collapsed} theme="light" style={{ backgroundColor: '#045140' }}> */}
                <div className='w-[100%] h-[64px] flex justify-center align-middle' >
                    <img src={logo_xl} className='w-[75%]' alt="" />
                </div>
                <Menu theme="light" mode="inline" items={filterLinksForSidebar()} className="bg-[#045140]" />
                {/* </Sider> */}
            </Sider>
        </>
    );
};

export default CSidebar;