import instance from "config/axios.config";
import { IGroup } from "./types";

export async function submitData(course_id: string | undefined, data: any, element: IGroup | null) {
  if (element) {
    data.students = element.students.map(item=>item.id);
    const response = await instance({ url: `/course-group/${element.id}`, method: "PUT", data });
    return response.data;
  } else {
    data.course_id = course_id;
    data.students = [];
    const response = await instance({ url: `/course-group/create`, method: "POST", data });
    return response.data;
  }
}

export async function getGroups(id: string | undefined) {
  const response = await instance({ url: `/course/${id}/groups/list/`, method: "GET" });
  return response.data;
}

export async function getCurators() {
  const response = await instance({ url: `/users/curator/list`, method: "GET" });
  return response.data;
}

export function deleteData(id: number | undefined) {
  return instance({ url: `/course-group/${id}`, method: "DELETE" });
}
