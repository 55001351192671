import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, FormInstance, Input, Row, Select } from "antd";
import { BiPlus, BiX } from "react-icons/bi";
import { ILanguage } from "pages/languages/types";
import useGetData from "custom-hooks/useGetData";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { useTranslation } from "react-i18next";
import LanguageTitle from "utils/LanguageTitle";

const { Option } = Select;

const MultipleLangInputsForReview = ({ form, additionalItems }: { form: FormInstance; additionalItems?: string[] }) => {
  const { t } = useTranslation();

  const { data: languages } = useGetData<ILanguage[]>({
    queryKey: ["languages"],
    url: "language/list/",
    options: { staleTime: Infinity, refetchOnWindowFocus: false },
  });

  const [languageIds, setLanguageIds] = React.useState<(number | undefined)[]>([]);
  const filterLanguage = () => {
    const tranlations = (form.getFieldValue("translations") ?? []) as Array<{ title: string; language_id: number }>;

    const ids = tranlations?.map((e) => {
      if (e?.language_id) {
        return e.language_id;
      }
    });
    setLanguageIds(ids);
  };

  return (
    <div>
      <Form.List name="translations">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row key={key} gutter={[12, 12]}>
                <Col lg={14} xs={24}>
                  <Form.Item
                    className="mb-0"
                    label={<FormLabelCrud>Text</FormLabelCrud>}
                    {...restField}
                    name={[name, "text"]}
                    rules={[{ required: index === 0, message: "Please input title!" }]}
                  >
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>

                <Col lg={7} xs={20}>
                  <Form.Item
                    className="mb-0"
                    label={<FormLabelCrud>Languages</FormLabelCrud>}
                    {...restField}
                    name={[name, "language_id"]}
                    rules={[{ required: index === 0, message: "Please select language" }]}
                  >
                    <Select onClick={filterLanguage} >
                      {languages?.map((element: ILanguage) => (
                        <Option key={element.id} value={element.id} disabled={languageIds.includes(element.id)}>
                          <LanguageTitle element={element}/>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={3} xs={2}>
                  <Form.Item className="w-[50px] mb-0 ml-2 mt-[24px]">
                    <Button
                      disabled={fields.length == 1}
                      onClick={() => {
                        remove(name);
                      }}
                      danger
                    >
                      <BiX />
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            ))}
            <div className="text-right mb-2">
              <Button
                type="link"
                size="small"
                className="inline-flex align-middle items-center lg:text-sm text-xs font-medium uppercase sm:px-[15px] pl-0"
                onClick={() => add()}
                disabled={fields.length === languages?.length}
              >
                <BiPlus size={20} className="mt-[2px] mr-1" />
                {t("Add translation")}
              </Button>
            </div>
          </>
        )}
      </Form.List>
    </div>
  );
};
export default MultipleLangInputsForReview;
