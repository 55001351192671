import { useMutation, useQuery } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import DeleteIcon from "components/Common/deleteIcon";
import { deleteData } from "./course_access.request";
import { IAccessCourse } from "./types";
import { Button, Empty, Input, Pagination, Select } from "antd";
import TableLoader from "components/loaders/table_loader";
import { Link, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { InformerDRDT } from "types/base.models";
import { numberFormatter } from "utils.functions";
import { CgAddR } from "react-icons/cg";
import { AiOutlineEye } from "react-icons/ai";
import { useEffect, useState } from "react";
import { CLIENT_API } from "service/clieant.request";
import PageTitle from "components/Common/PageTitle";
import useDebounce from "custom-hooks/useDebounce";
import { useTranslation } from "react-i18next";

const CourseAccess = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [searcVal, setsearcVal] = useState<string>("");
  const [page, setpage] = useState<number>(1);
  const [pageSize, setpageSize] = useState<number>(10);
  const [paid, setPaid] = useState<boolean | null>(true);

  const debouncedValue = useDebounce<string>(searcVal, 1000);

  const deleteMutation = useMutation({
    mutationFn: (id: number) => deleteData(id),
    onSuccess: () => {
      refetch();
    },
  });

  const { data, isLoading, error, refetch } = useQuery<
    InformerDRDT<IAccessCourse>,
    AxiosError
  >({
    queryKey: ["course-access", page, pageSize, debouncedValue],
    queryFn: () =>
      CLIENT_API.getAll({
        url: `course/${id}/user-purchase/list/`,
        _params: {
          page_number: page,
          page_size: pageSize,
          q: debouncedValue,
          is_paid: paid,
        },
      }),
    retry: 0,
    enabled: !!id,
  });

  useEffect(() => {
    if (typeof paid == "boolean" || paid === null) {
      refetch();
    }
  }, [paid]);

  if (error) return <p>An error has occurred: {error.message}</p>;

  return (
    <div>
      <div className="grid grid-cols-12 items-center">
        {data?.results[0]?.course?.translations[0]?.title ? (
          <div className="col-span-5">
            <PageTitle
              title={data?.results[0]?.course?.translations[0]?.title}
            />
          </div>
        ) : (
          <span className="col-span-5"></span>
        )}
        <div className="col-span-7 flex gap-3">
          <Input
            className="w-1/3"
            onChange={(e) => setsearcVal(e.target.value)}
            placeholder="Search..."
          />
          <Select
            className="w-1/3"
            allowClear
            options={[
              {
                value: true,
                label: "Paid",
              },
              {
                value: false,
                label: "Not Paid",
              },
            ]}
            placeholder="Sort by Paid"
            onChange={(e) => {
              if (typeof e == "boolean") {
                setPaid(e);
              } else {
                setPaid(null);
              }
            }}
          />
          <Link to={`/create/course-access/${id}`}>
            <Button
              type="default"
              className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]"
            >
              + {t("Create")}
            </Button>
          </Link>
        </div>
        <div className="col-span-12 md:overflow-auto overflow-x-scroll">
          <Table className="table-striped table-hover mt-3">
            <Thead>
              <Tr>
                <Th className="w-[50px]">№</Th>
                <Th className="w-[50px] text-center px-[0!important]">
                  {t("Id")}
                </Th>
                <Th>{t("Fullname")}</Th>
                <Th>{t("Email")}</Th>
                <Th>{t("Phone")}</Th>
                <Th>{t("Amount")}</Th>
                <Th>{t("Paid percent")}</Th>
                <Th>{t("Amount paid")}</Th>
                <Th>{t("Actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading
                ? data?.results?.length > 0
                  ? data?.results?.map((item, index: number) => (
                      <Tr key={item?.id}>
                        <Td>{index + 1}</Td>
                        <Td className="w-[50px] text-center px-[0!important]">
                          {item?.id}
                        </Td>
                        <Td>
                          <Link to={`/users/view/${item?.user?.id}`}>
                            {item?.user?.translations[0]?.first_name}{" "}
                            {item?.user?.translations[0]?.last_name}
                          </Link>
                        </Td>
                        <Td>
                          <a href={`mailto:${item?.user?.email}`}>
                            {item?.user?.email}
                          </a>
                        </Td>
                        <Td>
                          <a href={`tel:${item?.user?.phone}`}>
                            {item?.user?.phone}
                          </a>
                        </Td>
                        <Td>{numberFormatter(item?.amount)}</Td>
                        <Td className="text-center">{item?.paid_percent}%</Td>
                        {/* <Td className='text-center'>{numberFormatter(item?.amount * item?.paid_percent / 100)}</Td> */}
                        {/* <Td className='text-center'>{item?.histories?.reduce((acc, item) => (item.is_paid ? acc + item.paid_money : acc), 0)}</Td> */}
                        <Td className="text-center">
                          {item.is_full_amount
                            ? item.amount
                            : item?.installment_payment &&
                              Math.floor(
                                item.paid_percent *
                                  item?.installment_payment?.parts_count *
                                  0.01
                              ) *
                                (item.amount /
                                  item?.installment_payment?.parts_count)}
                        </Td>
                        <Td>
                          <div
                            className="flex"
                            style={{ alignItems: "center" }}
                          >
                            <Link to={`/update/course-access/${item?.id}`}>
                              {item?.is_full_amount ? (
                                <AiOutlineEye className="mx-2 text-[#faad14] text-[18px] cursor-pointer" />
                              ) : (
                                <CgAddR className="mx-2 text-[green] text-[18px] cursor-pointer" />
                              )}
                            </Link>
                            <DeleteIcon
                              confirmFunc={() =>
                                deleteMutation.mutate(item?.id)
                              }
                            />
                          </div>
                        </Td>
                      </Tr>
                    ))
                  : null
                : null}
            </Tbody>
          </Table>
        </div>
      </div>

      <div className="text-right mt-3">
        <Pagination
          onChange={(page_num, page_size) => {
            setpage(page_num);
            setpageSize(page_size);
          }}
          defaultCurrent={page | 1}
          pageSize={pageSize}
          total={data?.total_results}
        />
      </div>

      {data?.results?.length === 0 ? <Empty className="w-[100%]" /> : null}
      <TableLoader isLoading={isLoading} />
    </div>
  );
};
export default CourseAccess;
