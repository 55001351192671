
import { useTranslation } from 'react-i18next';




const PageTitle = ({ title }: { title: any}) => {



    const { t } = useTranslation();

    if (typeof title !== 'string') return <>{title}</>

    return <h5 className='text-[15px] text-[#045140] font-medium tracking-wider uppercase'>{t(title)}</h5>
}


export default PageTitle;