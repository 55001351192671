import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { BASE_URL } from '../utils';
import { ResponseError } from './errors';
import { message } from 'antd';
import jest from 'jest';

// jest.mock('axios', () => {
//     return {
//       create: jest.fn(() => ({
//         get: jest.fn(),
//         interceptors: {
//           request: { use: jest.fn(), eject: jest.fn() },
//           response: { use: jest.fn(), eject: jest.fn() }
//         }
//       }))
//     }
//   })


let instance = axios.create();

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const access_token: string = localStorage.getItem('access_token') ?? '';
    config.baseURL = BASE_URL;

    if (!config.url?.includes('auth/jwt/login')) {
        config.headers!.Authorization = `Bearer ${access_token}`;
    }
    config.headers!.Accept = 'application/json';
    // config.headers!['Content-Type'] = 'application/x-www-form-urlencoded';
    config.headers!['Access-Control-Allow-Origin'] = '*';
    config.headers!['Access-Control-Allow-Credentials'] = true;
    config.headers!["Cache-Control"] = "public";
    return config;
}

const onRequestError = async (error: AxiosError): Promise<AxiosError> => {
    new ResponseError(error);
    return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
    switch (response.status) {
        case 201:
            message.success("Ma'lumot qo'shildi!");
            break;
        case 200:
            if (response.config.method?.toUpperCase().includes('PUT')) {
                message.success("Ma'lumot o'zgartirildi!");
            } else if (response.config.method?.toUpperCase().includes('POST')) {
                message.success("Ma'lumot qo'shildi!");
            }
            break;
        case 204:
            message.success("Ma'lumot o'chirildi!");
            break;
    }
    return response;
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {

    const originalRequest = error.config as InternalAxiosRequestConfig;

    if (error.response?.status === 401) {
        // instance(originalRequest)
    }

    new ResponseError(error);
    return Promise.reject(error);
}

instance.interceptors.request.use(onRequest, onRequestError)
instance.interceptors.response.use(onResponse, onResponseError)



export default instance;
