import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Form, FormInstance } from "antd";
import {  submitLesson } from "./lesson.request";
import ToBack from "components/Common/ToBack";
import { useParams } from "react-router-dom";
import { ILesson } from "./types";
import FormItems from "./lesson_edit_ui";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import { CLIENT_API } from "service/clieant.request";
import { RButton, SButton } from "components/Common/CustomButtons";
import { useTranslation } from "react-i18next";

const UpdateLesson = () => {

  const {t} = useTranslation()
  const { id } = useParams();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  
  
  const [translations, setTranslations] = React.useState<ILesson["translations"]>([]);
  
  const { mutate, isLoading } = useMutation({
    mutationKey: ["update-lesson"],
    mutationFn: (form: FormInstance) => submitLesson({ form, lesson_id: id }),
    onSuccess: async (data) => {
      queryClient.setQueryData(["lessons"], data);
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });
  

  const course_lesson = useQuery<ILesson>({
    queryKey: ["lesson-by-id", id],
    queryFn: () => CLIENT_API.getOne({ url: `/course-lesson/${id}` }),
    onSuccess: (data: ILesson) => {
      form.setFieldsValue(data);
      if (!translations.length) {
        setTranslations(data.translations);
      } else {
        setTranslations([{ title: "", desc: "", content: "", language_id: 1 }]);
      }
    },
    retry: 2,
    refetchOnWindowFocus: false,
  });

  function handleFinish() {
    form.setFieldValue("translations", translations);
    if (!form.getFieldValue("discount_id")) {
      form.setFieldValue("discount_id", null);
    }
    mutate(form);
  }
  

  return (
    <div className="main-card">
      <ToBack title={t("Lesson update")} />
      <hr className="my-3" />
      <Form layout={"vertical"} form={form} onFinish={handleFinish} className="custom_form_style">
        <FormItems
          form={form}
          values={course_lesson.data}
          translations={translations}
          setTranslations={setTranslations}
        />
        <div className="md:flex justify-end mt-2">
          <RButton onClick={() => form.resetFields()} className="sm:mr-2 mr-1" />
          <SButton loading={isLoading} />
        </div>
      </Form>
    </div>
  );
};

export default UpdateLesson;
