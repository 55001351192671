import { Link } from "react-router-dom"

type IBreadCump = {
    title: string
    path: string
}


const BreadcrumbCustom = ({routes} : {routes: IBreadCump[]}) => {

    return(
        <div>
            {
                routes.map((item:IBreadCump, index) => {
                    const isLast = index !== (routes.length - 1);
                    return <div className="inline-block ">
                        {
                            isLast ? 
                            <Link className="px-2 py-1 rounded-md hover:bg-neutral-100 text-zinc-400 hover:text-zinc-900" to={item.path}>{item.title}</Link>:
                            <p className="px-2 py-1 rounded-md text-zinc-400">{item.title}</p>
                        }
                        {isLast ? <span className="inline-block mx-1 text-zinc-400">/</span> : "" } 
                    </div>
                    })
            }
        </div>
    )
}
export default BreadcrumbCustom;