import React, { Dispatch, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Drawer, Form, Input, message, Select, Switch } from "antd";
import { getStudentsForCourseReview, submitData } from "./reivew.request";
import { ICourseReviews } from "./types";
import MultipleLangInputs from "components/MultipleLangInput/multipleLangs";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import MultipleLangInputsForReview from "components/MultipleLangInput/multipleLangsForReview";
import { IUser } from "types/data.models";
import useDebounce from "custom-hooks/useDebounce";
import { useTranslation } from "react-i18next";

const CreateCourseModules = ({
  element,
  setElement,
  refetch,
  openSidebarMenu,
  setOpenSidebarMenu,
}: {
  element: ICourseReviews | null;
  setElement: Dispatch<ICourseReviews | null>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: Dispatch<boolean>;
}) => {
  const {t} = useTranslation()
  const { id } = useParams();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [searcVal, setsearcVal] = useState<string>("");
  const debouncedValue = useDebounce<string>(searcVal, 400);
  const [studentsList, setStudentsList] = useState<IUser[]>([]);

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue(element);
    } else {
      form.resetFields();
      form.setFieldsValue({
        is_active: true,
        is_verified: false,
        translations: [{ title: "", desc: "" }],
      });
    }
    // student_mutate("");
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-course-review"],
    mutationFn: (newValues) => submitData(element?.id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["course-reviews"], data);
      setOpenSidebarMenu(false);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  useEffect(() => {
    student_mutate(debouncedValue);
  }, [debouncedValue]);

  const {
    mutate: student_mutate,
    isLoading: review_student_loading,
    data: students,
  } = useMutation({
    mutationKey: ["course-students-review"],
    mutationFn: (newData: any) => getStudentsForCourseReview(id, newData),
    onSuccess: async (data) => {
      // refetch();
      setStudentsList(data.results);
      console.log(data.results);
    },
    onError: (error: AxiosError<any>) => {
      // validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    values.course_id = id;
    mutate(values);
  }

  const onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  console.log(studentsList);

  // console.log(
  //   students?.results?.map((i: IUser) => {
  //     console.log(i);
  //     return { value: i?.id, label: i?.translations[0]?.first_name };
  //   })
  // );

  const onSearch = (value: string) => {
    console.log("search:", value);
    setsearcVal(value);
  };
  const formFields = (
    <Form layout={"vertical"} form={form} onFinish={handleSubmit}>
      <MultipleLangInputsForReview form={form} />
      <div className="grid grid-cols-12 gap-x-4">
        <div className="col-span-6">
          <Form.Item label={t("Sort")} name="sort" rules={[{ required: false, message: "Please input sort!" }]}>
            <Input placeholder="Sort" type="number" />
          </Form.Item>
        </div>
        <div className="col-span-6">
          <Form.Item
            required
            label="Student"
            name="student_id"
            rules={[{ required: false, message: "Please input sort!" }]}
          >
            <Select
              showSearch
              placeholder={t("Select a student")}
              // optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              loading={review_student_loading}
              filterOption={false}
              options={studentsList?.map((i: IUser) => {
                return { value: i?.id, label: i?.translations[0]?.first_name + " " + i?.translations[0]?.last_name };
              })}
            />
          </Form.Item>
        </div>

        <div className="col-span-6">
          <Form.Item
            label={t("Status")}
            name="is_active"
            rules={[{ required: false, message: "Please input is status!" }]}
            valuePropName={"checked"}
          >
            <Switch defaultChecked className="bg-[#999]" />
          </Form.Item>
        </div>
        <div className="col-span-6">
          <Form.Item
            label={t("Verified")}
            name="is_verified"
            rules={[{ required: false, message: "Please input is status!" }]}
            valuePropName={"checked"}
          >
            <Switch defaultChecked className="bg-[#999]" />
          </Form.Item>
        </div>
      </div>

      <div className="mt-2 text-right">
        <Button
          onClick={() => {
            form.resetFields();
            setElement(null);
            form.setFieldsValue({
              is_active: true,
              translations: [{ title: "" }],
            });
          }}
          type="default"
          className="bg-[red] text-white hover:text-white hover:bg-[#FF2A2A] mr-2"
        >
          Reset
        </Button>
        <Button
          loading={isLoading}
          type="default"
          className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]"
          htmlType="submit"
        >
          {t("Save")}
        </Button>
      </div>
    </Form>
  );

  return (
    <Drawer
      title={<h3 className="font-bold">{t("Izoh")}</h3>}
      placement="right"
      onClose={() => setOpenSidebarMenu(false)}
      open={openSidebarMenu}
      width={800}
    >
      {formFields}
    </Drawer>
  );
};
export default CreateCourseModules;
