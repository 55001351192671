import { Editor } from "@monaco-editor/react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, FormInstance, Input, Row, Select, Switch } from "antd";
import FormLabelCrud from "components/FormLabel/FormLabel";
import useGetData from "custom-hooks/useGetData";
import { InformerDRDT } from "types/base.models";
import { ITraining } from "pages/trainings/types";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import { submitTrainingBlock } from "../block.request";
import { useParams } from "react-router-dom";
import { ITrainingBlock } from "../types";
import { useAppDispatch } from "store/service";
import { BLOCK_ACTIONS } from "store/block";
import { IBlockForm } from "pages/module_lessons/types";
const { Option } = Select;

const CodeBlock = ({
  uuid,
  sort,
  trainingData,
  setBlockForms,
  blockForms,
}: {
  uuid: string;
  sort: number;
  trainingData: ITrainingBlock | undefined;
  setBlockForms: (item: IBlockForm[]) => void;
  blockForms: IBlockForm[];
}) => {
  const [codeValue, setCodeValue] = useState<string | undefined>("");
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [newData, setNewData] = useState<ITrainingBlock | null>(null);

  const { data: trainings } = useGetData<InformerDRDT<ITraining>>({
    queryKey: ["trainings"],
    url: "training/list/",
    options: { staleTime: Infinity, refetchOnWindowFocus: false },
  });
  const onChange = (value: string) => {
    setCodeValue(trainings?.results?.find((it) => String(it.id) == value)?.example);
  };

  useEffect(() => {
    if (trainingData) {
      form.setFieldsValue(trainingData);
      form.setFieldsValue({
        is_active: trainingData.is_active,
        training_id: trainingData.training.id,
      });
      setCodeValue(trainingData.training.example);
    } else {
      form.setFieldsValue({
        is_active: true,
      });
    }
  }, [trainingData]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-training-block"],
    mutationFn: (newValues: any) => submitTrainingBlock(newValues),
    onSuccess: async (data) => {
      // setSaveInfo(false);
      setNewData(data);
      dispatch(BLOCK_ACTIONS.changeBlockItem({ id: uuid, loading: false }));
    },
    onError: (error: AxiosError<any>) => {
      dispatch(BLOCK_ACTIONS.changeBlockItem({ id: uuid, loading: false }));
      // setSaveInfo(false);
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const onFinish = (values: any) => {
    mutate({ ...values, sort, course_lesson_id: id, training_block_id: trainingData?.id || newData?.id });
    dispatch(BLOCK_ACTIONS.changeBlockItem({ id: uuid, loading: true }));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (!blockForms.map((it) => it.id).includes(uuid)) {
      let arr = blockForms;
      arr.push({ id: uuid, form: form });
      setBlockForms(arr);
    }
  }, [uuid]);

  return (
    <div className="lesson__code__block">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        <div className="grid grid-cols-12 justify-between align-middle gap-x-4">
          <div className="md:col-span-8 col-span-12">
            <Editor defaultLanguage="python" value={codeValue} theme="vs-dark" className="myEditor h-[240px]" />
          </div>

          <div className="md:col-span-3 col-span-12">
            <Form.Item
              className="w-[calc(100%-55px)] mb-3 "
              label={<FormLabelCrud>Trainings</FormLabelCrud>}
              name={["training_id"]}
              rules={[{ required: true, message: "Please select language" }]}
            >
              <Select
                showSearch
                placeholder="Select a training"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                options={trainings?.results?.map((element: ITraining) => {
                  return { value: element.id, label: element.title };
                })}
              />
            </Form.Item>
            <Form.Item
              label={<FormLabelCrud>Is active</FormLabelCrud>}
              name={["is_active"]}
              rules={[{ required: true, message: "Please input is status!" }]}
              valuePropName={"checked"}
              className="flex"
              initialValue={true}
            >
              <Switch defaultChecked className="bg-[#999]" />
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CodeBlock;
