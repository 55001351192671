import React, { Dispatch } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Drawer, Form, Input, message, Select, Switch } from "antd";
import { submitData } from "./mudule.request";
import { ICourseModules } from "./types";
import MultipleLangInputs from "components/MultipleLangInput/multipleLangs";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import { useTranslation } from "react-i18next";

const levelArr = [
  {value: "beginner", label: "Beginner"},
  {value: "intermediate", label: "Intermediate"},
  {value: "expert", label: "Expert"},
]

const CreateCourseModules = ({
  element,
  setElement,
  refetch,
  openSidebarMenu, 
  setOpenSidebarMenu,
}: {
  element: ICourseModules | null;
  setElement: Dispatch<ICourseModules | null>;
  refetch: Function;
  openSidebarMenu:boolean;
  setOpenSidebarMenu:Dispatch<boolean>;
}) => {
  const {t} = useTranslation()
  const {id} = useParams()
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue(element)
    }else{
        form.resetFields()
        form.setFieldsValue({
          is_active: true,
          is_free: false,
          translations:[{title:"", desc: ""}]
        })
    }
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-course-module"],
    mutationFn: (newValues) => submitData(element?.id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["course-modules"], data);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data)
    },
    retry: 1
  });
  
  function handleSubmit(values: any) {
    values.course_id = id
    mutate(values);
  }

  const formFields = (
    <Form layout={"vertical"} form={form} onFinish={handleSubmit}>
      <MultipleLangInputs form={form} additionalItems={['desc']} />
      <div className="grid grid-cols-12 gap-x-4">
          <div className="col-span-6">
            <Form.Item
                label={t("Level")}
                name="level"
                rules={[{ required: false, message: 'Please select level!' }]}
              >
                <Select
                  className='mb-3 w-[100%]'
                  options={levelArr}
                />
            </Form.Item>
          </div>
          <div className="col-span-6">

          <Form.Item
                  label={t("Slug")}
                  name="slug"
                  rules={[{ required: false, message: 'Please input slug!' }]}
              >
                  <Input placeholder="Slug" />
              </Form.Item>
              
          </div>
          <div className="col-span-6">
          <Form.Item
                  label={t("Price")}
                  name="price"
                  rules={[{ required: false, message: 'Please input price!' }]}
              >
                  <Input placeholder="Price" type="number"/>
              </Form.Item>
          </div>
          <div className="col-span-6">
              <Form.Item
                  label={t("Sort")}
                  name="sort"
                  rules={[{ required: false, message: 'Please input sort!' }]}
              >
                  <Input placeholder="Sort" type="number"/>
              </Form.Item>
          </div>
          

          <div className="col-span-6">
              <Form.Item
                  label={t("Status")}
                  name="is_active"
                  rules={[{ required: false, message: 'Please input is status!' }]}
                  valuePropName={'checked'}
              >
                  <Switch defaultChecked className="bg-[#999]" />
              </Form.Item>
          </div>
          <div className="col-span-6">
              <Form.Item
                  label={t("Free")}
                  name="is_free"
                  rules={[{ required: false, message: 'Please input is status!' }]}
                  valuePropName={'checked'}
              >
                  <Switch defaultChecked className="bg-[#999]" />
              </Form.Item>
          </div>
          <div className="col-span-6">
              <Form.Item
                  label={t("Final")}
                  name="is_final"
                  rules={[{ required: false, message: 'Please input is status!' }]}
                  valuePropName={'checked'}
              >
                  <Switch  className="bg-[#999]" />
              </Form.Item>
          </div>
      <div className="col-span-6 text-end">
          <Button
            onClick={() => {
              form.resetFields();
              setElement(null);
              form.setFieldsValue({
                is_active: true,
                translations:[{title:""}]
              })
            }}
            type="default"
            className="lg:w-[88px] w-[74px] lg:text-[14px] text-[12px] lg:px-[15px] px-2 bg-[red] text-white hover:text-white hover:bg-[#FF2A2A] lg:mt-0 lg:mb-2 lg:mr-0 mt-6 mr-1"
          >
            {t("Reset")}
          </Button>
          <Button
            loading={isLoading}
            type="default"
            className="lg:w-[88px] w-[74px] lg:text-[14px] text-[12px] lg:px-[15px] px-2 bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]"
            htmlType="submit"
          >
            {t("Save")}
          </Button>
      </div>
      </div>
      
    </Form>
  );

  return (
      <Drawer
        title={<h3 className=" font-bold">{element?.translations[0]?.title ? element?.translations[0]?.title : t("New")}</h3>}
        placement="right"
        onClose={() => setOpenSidebarMenu(false)}
        open={openSidebarMenu}
        className="w-full"
      >
        {formFields}
      </Drawer>
  );
};
export default CreateCourseModules;
