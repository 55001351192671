import React, { useEffect, useState } from "react";
import { IVideoBlock } from "../types";
import { Button, Checkbox, Form, FormInstance, Input, Select, Switch } from "antd";
import { BiPlay } from "react-icons/bi";
import FormLabelCrud from "components/FormLabel/FormLabel";

import { submitTextBlock, submitVideoBlock } from "../block.request";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import FreeVideoModal from "components/modals/videoModal";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "store/service";
import { BLOCK_ACTIONS } from "store/block";
import { IBlockForm } from "pages/module_lessons/types";
const VideoBlock = ({
  uuid,
  sort,
  videoData,
  setBlockForms,
  blockForms,
}: {
  uuid: string;
  sort: number;
  videoData: IVideoBlock | undefined;
  setBlockForms: (item: IBlockForm[]) => void;
  blockForms: IBlockForm[];
}) => {
  const [videoId, setVideoId] = useState("");
  const [form] = Form.useForm();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [newData, setNewData] = useState<IVideoBlock | null>(null);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-training-block"],
    mutationFn: (newValues: any) => submitVideoBlock(newValues),
    onSuccess: async (data) => {
      setNewData(data);
      dispatch(BLOCK_ACTIONS.changeBlockItem({ id: uuid, loading: false }));
    },
    onError: (error: AxiosError<any>) => {
      dispatch(BLOCK_ACTIONS.changeBlockItem({ id: uuid, loading: false }));
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  React.useEffect(() => {
    if (videoData) {
      form.setFieldsValue(videoData);
    } else {
      form.resetFields();
      form.setFieldsValue({
        is_active: true,
      });
    }
  }, [videoData?.id]);

  function handleFinish(e: any) {
    e.course_lesson_id = id;
    e.sort = sort;
    if (videoData) {
      e.id = videoData.id;
    } else if (newData) {
      e.id = newData.id;
    }
    mutate(e);
    dispatch(BLOCK_ACTIONS.changeBlockItem({ id: uuid, loading: true }));
  }

  useEffect(() => {
    if (!blockForms.map((it) => it.id).includes(uuid)) {
      let arr = blockForms;
      arr.push({ id: uuid, form: form });
      setBlockForms(arr);
    }
  }, [uuid]);

  return (
    <div>
      <Form layout={"vertical"} form={form} onFinish={handleFinish} className="custom_form_style">
        <div className="grid grid-cols-12 justify-between align-middle mb-2">
          <div className="md:col-span-6 col-span-12 flex">
            <Form.Item
              className="mb-0 w-[calc(100%-55px)]"
              label={<FormLabelCrud>Video ID</FormLabelCrud>}
              name={["ks_uuid"]}
              rules={[{ required: false, message: "Please input title!" }]}
            >
              <Input placeholder="Ma'lumot kiriting" />
            </Form.Item>
            <Form.Item className="w-[40px] mb-0 ml-2 mr-2 mt-[25px]">
              <Button
                onClick={() => {
                  setVideoId(form.getFieldValue("ks_uuid"));
                }}
                className="border-lime-500 px-2"
              >
                <BiPlay size={20} color="green" />
              </Button>
            </Form.Item>
            <Form.Item
              label={<FormLabelCrud>Status</FormLabelCrud>}
              name="is_active"
              rules={[{ required: false, message: "Please input is status!" }]}
              valuePropName={"checked"}
            >
              <Switch defaultChecked className="bg-[#999]" />
            </Form.Item>
          </div>
        </div>
      </Form>
      {videoId && <FreeVideoModal videoId={videoId} setVideoId={setVideoId} />}
    </div>
  );
};

export default VideoBlock;
