import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Form } from "antd";
import { getDataById, submitData } from "./organization.request";
import ToBack from "components/Common/ToBack";
import { useParams } from "react-router-dom";
import { IOrganization, IOrganizationGet } from "./types";
import FormItems from "./organization_form_ui";
import { AxiosError } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const UpdateOrganization = () => {

  const {t} = useTranslation()
  const [regionId, setRegionId] = useState<number | undefined>()
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const { mutate, isLoading } = useMutation({
    mutationKey: ["update-organization"],
    mutationFn: (newValues: IOrganization) => submitData(id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["organizations"], data);
    },
    retry: 0,
  });

  useQuery<IOrganizationGet, AxiosError>({
    queryKey: ['discounts-by-id', id],
    queryFn: () => getDataById(id),
    onSuccess: (data:IOrganizationGet) => {
        form.setFieldsValue({...data, legal_address_region_id: data?.legal_address_region?.id, legal_address_district_id: data?.legal_address_district?.id})
        setRegionId(data?.legal_address_region?.id)
    },
    retry: 0,
  });

  function handleSubmit(values: IOrganization) {
    mutate(values);
  }
  
  function saveAndAnother() {
    form.submit()
    setTimeout(() => {
        form.resetFields();
        form.setFieldsValue({
            is_active: true,
            is_verified: true,
            translations:[{title:""}]
        })
    }, 300);
}
    function saveAndEdit() {
        form.submit()
    }

  return (
    <div className="main-card">
        <ToBack title={t("Edit organization")} />
        <hr className="my-3" />
        <FormItems form={form} handleSubmit={handleSubmit} regionId={regionId} />
        <div className="md:flex justify-end mt-2">
            <Button onClick={saveAndAnother} loading={isLoading} type="default" className='md:w-auto w-[100%] bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]' htmlType="submit">{t("Save and add another")}</Button>
            <Button onClick={saveAndEdit} loading={isLoading} type="default" className='md:w-auto w-[100%] bg-[#045140] text-white hover:text-white hover:bg-[#1a7763] md:ml-3 md:my-0 my-3' htmlType="submit">{t("Save and continue editing")}</Button>
        </div>
    </div>
  );
};

export default UpdateOrganization;