import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { FiEdit } from "react-icons/fi";
import DeleteIcon from "components/Common/deleteIcon";
import { deleteData, getCourseRatings } from "./rating.request";
import { ICourseRatingWithPagination, ICourseRating } from "./types";
import CreateCourseRating from "./mudule_form_ui";
import { Button, Empty, Pagination } from "antd";
import TableLoader from "components/loaders/table_loader";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import { is_permission } from "utils.functions";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";
import { useTranslation } from "react-i18next";
import PageTitle from "components/Common/PageTitle";

const CourseRatings = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { _translate } = useLocaleTranslation();
  const [page, setpage] = useState<number>(1);
  const [pageSize, setpageSize] = useState<number>(10);

  const [selected, setSelected] = React.useState<ICourseRating | null>(null);
  const [openSidebarMenu, setOpenSidebarMenu] = useState<boolean>(false);

  const deletePost = useMutation({
    mutationFn: deleteData,
  });

  const { isLoading, error, data, refetch } = useQuery<ICourseRatingWithPagination, AxiosError>({
    queryKey: ["course-ratings", id, page, pageSize],
    queryFn: () => getCourseRatings(id, page, pageSize),
    retry: 0,
    staleTime: Infinity,
    onSuccess: () => {
      // mutate();
    },
  });

  useEffect(() => {
    if (!deletePost.isLoading) {
      refetch();
    }
  }, [deletePost.isLoading]);

  if (error) return <div>'An error has occurred: '</div>;

  return (
    <div>
      <div className="">
        <div className="flex mb-3 justify-between">
          <PageTitle title={"Course ratings"} />
          {is_permission("create_course_rating") ? (
            <Button
              onClick={() => {
                setOpenSidebarMenu(true);
                setSelected(null);
              }}
              type="default"
              className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]"
            >
              + {t("Create")}
            </Button>
          ) : null}
        </div>
        <Table className="table-striped table-hover">
          <Thead>
            <Tr>
              <Th className="w-[50px]">№</Th>
              <Th className="w-[50px] text-center px-[0!important]">{t("Id")}</Th>
              <Th>{t("User")}</Th>
              <Th>{t("Rating")}</Th>
              <Th>{t("Actions")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading
              ? data?.results?.length > 0
                ? data?.results?.map((item: ICourseRating, index: number) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td className="w-[50px] text-center px-[0!important]">{item?.id}</Td>
                      <Td>
                        {_translate(item?.user.translations, "last_name")}{" "}
                        {_translate(item?.user.translations, "first_name")}
                      </Td>
                      <Td>{item?.rating}</Td>
                      <Td>
                        <div className="flex" style={{ alignItems: "center" }}>
                          {is_permission("update_course_rating") ? (
                            <FiEdit
                              className="mx-2 text-[green] text-[18px] cursor-pointer"
                              onClick={() => {
                                setSelected(item);
                                setOpenSidebarMenu(true);
                              }}
                            />
                          ) : null}
                          {is_permission("delete_course_rating") ? (
                            <DeleteIcon confirmFunc={() => deletePost.mutate(item?.id)} />
                          ) : null}
                        </div>
                      </Td>
                    </Tr>
                  ))
                : null
              : null}
          </Tbody>
        </Table>
        {data?.results?.length === 0 ? <Empty className="w-[100%]" /> : null}
        <TableLoader isLoading={isLoading} />
      </div>
      <div className="text-right mt-3">
        <Pagination
          onChange={(page_num, page_size) => {
            setpage(page_num);
            setpageSize(page_size);
          }}
          defaultCurrent={page | 1}
          pageSize={pageSize}
          total={data?.total_results}
        />
      </div>
      {(is_permission("create_course_rating") && !selected) || (is_permission("update_course_rating") && selected) ? (
        <CreateCourseRating
          element={selected}
          setElement={setSelected}
          refetch={refetch}
          openSidebarMenu={openSidebarMenu}
          setOpenSidebarMenu={setOpenSidebarMenu}
        />
      ) : null}
    </div>
  );
};
export default CourseRatings;
