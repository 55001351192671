import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { Drawer, Form, Select, Switch } from "antd";
import useWindowSize from "custom-hooks/useWindowSize";
import { submitData } from "./profession.request";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import PartTitle from "components/Common/PartTitle";
import EditingTitle from "components/Common/EditingTitle";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { RButton, SButton } from "components/Common/CustomButtons";
import { useTranslation } from "react-i18next";
import { IProfession } from "../types";
import MultipleLangInputs from "components/MultipleLangInput/multipleLangs";
import { useParams } from "react-router-dom";
const { Option } = Select;

type TypeTagFormProps = {
  professions: IProfession[] | undefined;
  element: IProfession | null;
  setElement: React.Dispatch<React.SetStateAction<IProfession | null>>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateProfession = ({
  professions,
  element,
  setElement,
  refetch,
  openSidebarMenu,
  setOpenSidebarMenu,
}: TypeTagFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { width } = useWindowSize();
  const { sphere_id } = useParams();

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue(element);
    } else {
      form.resetFields();
      form.setFieldsValue({
        is_active: true,
        is_default: false,
        translations: [{ title: "", description: "" }],
      });
    }
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-profession"],
    mutationFn: (newVals: any) => submitData(element?.id, newVals),
    onSuccess: async (data) => {
      // queryClient.setQueryData(["trainings"], data);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    values.sphere_id = sphere_id;
    mutate({ payload: values });
  }
 

  const MemoizationFieldsFormTag = React.memo(() => (
    <Form
      layout={"vertical"}
      form={form}
      onFinish={handleSubmit}
      className="custom_form_style"
    >
      <MultipleLangInputs form={form} additionalItems={["description"]} />
      <div className="grid grid-cols-12 gap-4">
        <div className="md:col-span-3 col-span-6">
          <Form.Item
            name="is_default"
            label={<FormLabelCrud>Default</FormLabelCrud>}
            rules={[{ required: true, message: "Please input is default!" }]}
            valuePropName={"checked"}
            initialValue={false}
          >
            <Switch defaultChecked className="bg-[#999]" />
          </Form.Item>
        </div>
        <div className="md:col-span-3 col-span-6">
          <Form.Item
            name="is_active"
            label={<FormLabelCrud>Active</FormLabelCrud>}
            rules={[{ required: true, message: "Please input is active!" }]}
            valuePropName={"checked"}
            initialValue={true}
          >
            <Switch defaultChecked className="bg-[#999]" />
          </Form.Item>
        </div>
        <div className="md:col-span-6 col-span-6">
          <Form.Item
            className="w-[calc(100%-55px)] mb-0 "
            label={<FormLabelCrud>Professions</FormLabelCrud>}
            name="parent_id"
            rules={[{ required: false, message: "Please input parent!" }]}
          >
            <Select>
              {professions
                ?.filter((p) => p.id !== element?.id)
                ?.map((element: IProfession) => (
                  <Option key={element.id} value={element.id}>
                    {element.translations[0].title}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
      </div>
      <div className="flex gap-2 mt-2 text-end">
        <RButton
          onClick={() => {
            form.resetFields();
            setElement(null);
          }}
        />
        <SButton loading={isLoading} onClick={()=>setOpenSidebarMenu(true)}/>
      </div>
    </Form>
  ));

  return (
    <div className="bg-red-600">
      <>
        {element ? (
          <EditingTitle title={element?.translations[0]?.title} />
        ) : (
          <PartTitle children={"Create training"} />
        )}
      </>
      <hr className="my-3" />
      {/* <MemoizationFieldsFormTag /> */}
      <Drawer
        title={
          <h3 className=" font-bold">
            {element?.translations[0]?.title
              ? element?.translations[0]?.title
              : t("New")}
          </h3>
        }
        placement="right"
        onClose={() => setOpenSidebarMenu(false)}
        open={openSidebarMenu}
        width={width > 600 ? 600 : "100%"}
      >
        <MemoizationFieldsFormTag />
      </Drawer>
    </div>
  );
};
export default CreateProfession;
