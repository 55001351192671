import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Drawer, Form, Input, message, Select, Switch } from "antd";
import useWindowSize from "custom-hooks/useWindowSize";
import { submitData } from "./district.request";
import { IDistrict, IRegionSelect } from "./types";
import MultipleLangInputs from "components/MultipleLangInput/multipleLangs";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { RButton, SButton } from "components/Common/CustomButtons";
import PartTitle from "components/Common/PartTitle";
import EditingTitle from "components/Common/EditingTitle";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";
import { useTranslation } from "react-i18next";


type TypePropsCreateDistricts = {
  element: IDistrict | null;
  setElement: React.Dispatch<React.SetStateAction<IDistrict | null>>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: React.Dispatch<React.SetStateAction<boolean>>;
  allRegions: IRegionSelect[]
}
const CreateDistrict = ({ allRegions, element, setElement, refetch, openSidebarMenu, setOpenSidebarMenu }: TypePropsCreateDistricts) => {
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { width } = useWindowSize();
  const { _translate } = useLocaleTranslation();

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue(element)
    } else {
      form.resetFields()
      form.setFieldsValue({
        is_active: true,
        translations: [{ title: "" }]
      })
    }
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-district"],
    mutationFn: (newValues) => submitData(element?.id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["districts"], data);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data)
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    mutate(values);
  }

  const MemoizationFieldsFormDistrict = React.memo(() => (
    <Form
      layout={"vertical"}
      form={form}
      onFinish={handleSubmit}
      className="custom_form_style"
    >
      <MultipleLangInputs form={form} />
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-6">
          <Form.Item
            label={<FormLabelCrud>Sort</FormLabelCrud>}
            name="sort"
            rules={[{ required: false, message: 'Please input sort!' }]}
          >
            <Input type="number" />
          </Form.Item>
        </div>
        <div className="col-span-6">
          <Form.Item
            label={<FormLabelCrud>Code</FormLabelCrud>}
            name="code"
            rules={[{ required: true, message: 'Please input code!' }]}
          >
            <Input type="number" />
          </Form.Item>
        </div>
        <div className="col-span-6">
          <Form.Item
            label={<FormLabelCrud>Regions</FormLabelCrud>}
            name="region_id"
            rules={[{ required: true, message: 'Please select region!' }]}
          >
            {
              allRegions ?
                <Select
                  className='mb-3 w-[100%]'
                  options={allRegions}
                /> : 'loading...'
            }
          </Form.Item>
        </div>
        <div className="col-span-6">
          <Form.Item
            label={<FormLabelCrud>Status</FormLabelCrud>}
            name="is_active"
            rules={[{ required: false, message: 'Please input is status!' }]}
            valuePropName={'checked'}
          >
            <Switch defaultChecked className="bg-[#999]" />
          </Form.Item>
        </div>
      <div className="col-span-12 text-right">
        <RButton
          onClick={() => {
            form.resetFields();
            setElement(null);
            form.setFieldsValue({
              is_active: true,
              translations: [{ title: "" }]
            })
          }}
          className="sm:mr-1 sm:mb-0 mb-1"
        />
        <SButton loading={isLoading} />
      </div>
      </div>

    </Form>
  ));

  return (
    <div>
      <>{element ? <EditingTitle title={_translate(element?.translations ?? [], 'title')} /> : <PartTitle children={"Create district"} />}</>
      <hr className="my-3" />
      <MemoizationFieldsFormDistrict />
      <Drawer
        title={<h3 className=" font-bold">{element?.translations[0]?.title ? element?.translations[0]?.title : t("New")}</h3>}
        placement="right"
        onClose={() => setOpenSidebarMenu(false)}
        open={width > 992 ? false : openSidebarMenu}
      >
        <MemoizationFieldsFormDistrict />
      </Drawer>
    </div>
  );
};
export default CreateDistrict;
