import { IOrganizationGet } from '../types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { Badge, Button } from 'antd';
import { is_permission } from 'utils.functions';

const OrganizationDetails = ({data}:{data:IOrganizationGet| undefined}) => {
    
    const {t} = useTranslation()

  return (
    <div className="w-full ">
      <div className="grid grid-cols-12 md:gap-8 gap-3">
        <div className="md:col-span-6 col-span-12">
          <div className="flex items-center justify-between md:gap-8 gap-1 mb-4">
            <p className="font-bold text-zinc-500">{t("Brand name")}</p>
            <p>
              {data?.brand_name ? (
                data?.brand_name
              ) : (
                <MdClose className="text-lg text-[#ff0000]" />
              )}
            </p>
          </div>
          <div className="flex items-center justify-between md:gap-8 gap-1 mb-4">
            <p className="font-bold text-zinc-500">{t("Domain name")}</p>
            <p>
              {data?.domain_name ? (
                data?.domain_name
              ) : (
                <MdClose className="text-lg text-[#ff0000]" />
              )}
            </p>
          </div>
          <div className="flex items-center justify-between md:gap-8 gap-1 mb-4">
            <p className="font-bold text-zinc-500">
              {t("Identification number")}
            </p>
            <p>
              {data?.identification_number ? (
                data?.identification_number
              ) : (
                <MdClose className="text-lg text-[#ff0000]" />
              )}
            </p>
          </div>
          <div className="flex items-center justify-between md:gap-8 gap-1 mb-4">
            <p className="font-bold text-zinc-500">{t("Bank account")}</p>
            <p>
              {data?.bank_account ? (
                data?.bank_account
              ) : (
                <MdClose className="text-lg text-[#ff0000]" />
              )}
            </p>
          </div>
          <div className="flex items-center justify-between md:gap-8 gap-1 mb-4">
            <p className="font-bold text-zinc-500">{t("Bank MFO")}</p>
            <p>
              {data?.bank_mfo ? (
                data?.bank_mfo
              ) : (
                <MdClose className="text-lg text-[#ff0000]" />
              )}
            </p>
          </div>
        </div>
        <div className="md:col-span-6 col-span-12">
          <div className="flex items-center justify-between md:gap-8 gap-1 mb-4">
            <p className="font-bold text-zinc-500">{t("Regions")}</p>
            <p>
              {data?.legal_address_region?.translations[0]?.title ? (
                data?.legal_address_region?.translations[0]?.title
              ) : (
                <MdClose className="text-lg text-[#ff0000]" />
              )}
            </p>
          </div>
          <div className="flex items-center justify-between md:gap-8 gap-1 mb-4">
            <p className="font-bold text-zinc-500">{t("District")}</p>
            <p>
              {data?.legal_address_district?.translations[0]?.title ? (
                data?.legal_address_district?.translations[0]?.title
              ) : (
                <MdClose className="text-lg text-[#ff0000]" />
              )}
            </p>
          </div>
          <div className="flex items-center justify-between md:gap-8 gap-1 mb-4">
            <p className="font-bold text-zinc-500">{t("Status")}</p>
            <p>
              {data?.is_active ? (
                <Badge count={t("Active")} showZero color="#52c41a" />
              ) : (
                <Badge count={t("InActive")} showZero color="#faad14" />
              )}
            </p>
          </div>
          <div className="flex items-center justify-between md:gap-8 gap-1 mb-4">
            <p className="font-bold text-zinc-500">{t("Verified")}</p>
            <p>
              {data?.is_verified ? (
                <Badge count={t("Verified")} showZero color="#52c41a" />
              ) : (
                <Badge count={t("Not Verified")} showZero color="#faad14" />
              )}
            </p>
          </div>
        </div>
        {is_permission("update_organization") ? (
          <Link
            to={`/organization/update/${data?.id}`}
            className="col-span-4"
          >
            <Button>{t("Edit item")}</Button>
          </Link>
        ) : null}
      </div>
    </div>
  )
}

export default OrganizationDetails