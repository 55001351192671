import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { IResume } from "../types";
import { useNavigate, useParams } from "react-router-dom";
import { getResumeById } from "../resume.request";
import { Divider, Skeleton } from "antd";
import { FILE_URL } from "config/utils";
import instance from "config/axios.config";

const ViewResume = () => {
  const { t } = useTranslation();
  const { resume_id } = useParams();
  const navigate = useNavigate();

  const { isLoading, error, data, refetch } = useQuery<IResume, AxiosError>({
    queryKey: ["resume", resume_id],
    queryFn: () => getResumeById(resume_id),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  const checkResume = async (status: string) => {
    try {
      const response = await instance({
        url: `topjob/resume/${resume_id}`,
        method: "PUT",
        data: { status },
      });
      navigate("/resumes");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="grid grid-cols-12 gap-3">
        <div className="main-card lg:col-span-12 col-span-12">
          {isLoading ? (
            <div>
              <Skeleton active />
              <div className="my-12"></div>
              <Skeleton active />
              <div className="my-12"></div>
              <Skeleton active />
              <div className="my-12"></div>
              <Skeleton active />
              <div className="my-12"></div>
            </div>
          ) : (
            <div>
              <h2 className="text-lg text-blue-700 mb-1">Main data</h2>
              <div className="border-stone-400 border rounded-sm p-2 mb-2">
                <div className="grid grid-cols-3 gap-3">
                  <div>
                    <h2 className="text-sm text-green-500 mb-0">First name</h2>
                    <span className="text-xs">{data?.first_name || "---"}</span>
                  </div>
                  <div>
                    <h2 className="text-sm text-green-500 mb-0">Last name</h2>
                    <span className="text-xs">{data?.last_name || "---"}</span>
                  </div>
                  <div>
                    <h2 className="text-sm text-green-500 mb-0">Lavozimi</h2>
                    <span className="text-xs">{data?.job_title || "---"}</span>
                  </div>
                  <div>
                    <h2 className="text-sm text-green-500 mb-0">Elektron pochta</h2>
                    <span className="text-xs">{data?.email || "---"}</span>
                  </div>
                  <div>
                    <h2 className="text-sm text-green-500 mb-0">Telefon raqami</h2>
                    <span className="text-xs">{data?.phone || "---"}</span>
                  </div>
                  <div>
                    <h2 className="text-sm text-green-500 mb-0">Mamlakat</h2>
                    <span className="text-xs">{data?.country || "---"}</span>
                  </div>
                  <div>
                    <h2 className="text-sm text-green-500 mb-0">Shahar</h2>
                    <span className="text-xs">{data?.city || "---"}</span>
                  </div>
                  <div>
                    <h2 className="text-sm text-green-500 mb-0">Manzil</h2>
                    <span className="text-xs">{data?.address || "---"}</span>
                  </div>
                  <div>
                    <h2 className="text-sm text-green-500 mb-0">Zipkod</h2>
                    <span className="text-xs">{data?.zipcode || "---"}</span>
                  </div>
                  <div>
                    <h2 className="text-sm text-green-500 mb-0">Millat</h2>
                    <span className="text-xs">{data?.nationality || "---"}</span>
                  </div>
                  <div>
                    <h2 className="text-sm text-green-500 mb-0">Haydovchilik Guvohnomasi</h2>
                    <span className="text-xs">{data?.driving_license || "---"}</span>
                  </div>
                  <div>
                    <h2 className="text-sm text-green-500 mb-0">Yashash joyi</h2>
                    <span className="text-xs">{data?.place_of_residence || "---"}</span>
                  </div>
                  <div>
                    <h2 className="text-sm text-green-500 mb-0">Tug'ilgan sana</h2>
                    <span className="text-xs">{data?.date_of_birth || "---"}</span>
                  </div>
                </div>
              </div>
              <hr className="my-3 h-[1px] bg-red-500 border-0" />
              <div className="grid grid-cols-1 gap-3">
                <div>
                  <h2 className="text-lg text-blue-700 mb-0">Kasbiy xulosa</h2>
                  <span className="text-xs" dangerouslySetInnerHTML={{ __html: data?.professional_summary || "---" }} />
                </div>
              </div>
              <hr className="my-3 h-[1px] bg-red-500 border-0" />
              <div className="grid grid-cols-1 gap-3">
                <div>
                  <h2 className="text-xl text-blue-700 mb-2">Ta'lim</h2>
                  {data?.educations?.map((item, index) => (
                    <div key={index} className="border-stone-400 border rounded-sm p-2 mb-2">
                      <div className="grid grid-cols-5 gap-3">
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Tashkilot</h3>
                          <span className="text-xs">{item?.organization_title || "---"}</span>
                        </div>
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Daraja</h3>
                          <span className="text-xs">{item?.degree || "---"}</span>
                        </div>
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Shaxar</h3>
                          <span className="text-xs">{item?.city || "---"}</span>
                        </div>
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Start date</h3>
                          <span className="text-xs">{item?.start_date || "---"}</span>
                        </div>
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">End date</h3>
                          <span className="text-xs">{item?.end_date || "---"}</span>
                        </div>
                        <div className="col-span-12">
                          <h3 className="text-sm text-green-500 mb-0">Tavsif</h3>
                          <span className="text-xs">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi magni modi, expedita quis
                            nulla obcaecati doloremque aliquid numquam libero iusto.
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <hr className="my-3 h-[1px] bg-red-500 border-0" />
              <div className="grid grid-cols-1 gap-3">
                <div>
                  <h2 className="text-xl text-blue-700 mb-2">Ta'jriba</h2>
                  {data?.experiences?.map((item, index) => (
                    <div key={index} className="border-stone-400 border rounded-sm p-2 mb-2">
                      <div className="grid grid-cols-5 gap-3">
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Lavozim</h3>
                          <span className="text-xs">{item?.job_title || "---"}</span>
                        </div>
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Ish beruvchi</h3>
                          <span className="text-xs">{item?.employer || "---"}</span>
                        </div>
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Shaxar</h3>
                          <span className="text-xs">{item?.city || "---"}</span>
                        </div>
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Start date</h3>
                          <span className="text-xs">{item?.start_date || "---"}</span>
                        </div>
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">End date</h3>
                          <span className="text-xs">{item?.end_date || "---"}</span>
                        </div>
                        <div className="col-span-12">
                          <h3 className="text-sm text-green-500 mb-0">Tavsif</h3>
                          <span className="text-xs">
                            <span
                              className="text-xs"
                              dangerouslySetInnerHTML={{ __html: item?.description || "---" }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <hr className="my-3 h-[1px] bg-red-500 border-0" />
              <div className="grid grid-cols-1 gap-3">
                <div>
                  <h2 className="text-xl text-blue-700 mb-2">Kurs</h2>
                  {data?.courses?.map((item, index) => (
                    <div key={index} className="border-stone-400 border rounded-sm p-2 mb-2">
                      <div className="grid grid-cols-5 gap-3">
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Kurs</h3>
                          <span className="text-xs">{item?.name || "---"}</span>
                        </div>
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Tashkilot nomi</h3>
                          <span className="text-xs">{item?.organization_title || "---"}</span>
                        </div>
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Start date</h3>
                          <span className="text-xs">{item?.start_date || "---"}</span>
                        </div>
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">End date</h3>
                          <span className="text-xs">{item?.end_date || "---"}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <hr className="my-3 h-[1px] bg-red-500 border-0" />
              <div className="grid grid-cols-1 gap-3">
                <div>
                  <h2 className="text-xl text-blue-700 mb-2">Mahorat</h2>
                  {data?.skills?.map((item, index) => (
                    <div key={index} className="border-stone-400 border rounded-sm p-2 mb-2">
                      <div className="grid grid-cols-5 gap-3">
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Mahorat</h3>
                          <span className="text-xs">{item?.name || "---"}</span>
                        </div>
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Daraja</h3>
                          <span className="text-xs">{item?.level ? item.level + "%" : "" || "---"}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <hr className="my-3 h-[1px] bg-red-500 border-0" />
              <div className="grid grid-cols-1 gap-3">
                <div>
                  <h2 className="text-xl text-blue-700 mb-2">Tillar</h2>
                  {data?.languages?.map((item, index) => (
                    <div key={index} className="border-stone-400 border rounded-sm p-2 mb-2">
                      <div className="grid grid-cols-5 gap-3">
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Til nomi</h3>
                          <span className="text-xs">{item?.name || "---"}</span>
                        </div>
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Daraja</h3>
                          <span className="text-xs">{item?.level ? item.level + "%" : "" || "---"}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <hr className="my-3 h-[1px] bg-red-500 border-0" />
              <div className="grid grid-cols-1 gap-3">
                <div>
                  <h2 className="text-xl text-blue-700 mb-2">Ijtimoiy havolalar</h2>
                  {data?.social_links?.map((item, index) => (
                    <div key={index} className="border-stone-400 border rounded-sm p-2 mb-2">
                      <div className="grid grid-cols-5 gap-3">
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Havola nomi</h3>
                          <span className="text-xs">{item?.title || "---"}</span>
                        </div>
                        <div className="col-span-3">
                          <h3 className="text-sm text-green-500 mb-0">Havola url</h3>
                          <a target="_blank" href={item?.url || "---"}>
                            <span className="text-xs">{item?.url || "---"}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <hr className="my-3 h-[1px] bg-red-500 border-0" />
              <div className="grid grid-cols-1 gap-3">
                <div>
                  <h2 className="text-xl text-blue-700 mb-2">Portfolio havolalar</h2>
                  {data?.portfolio_links?.map((item, index) => (
                    <div key={index} className="border-stone-400 border rounded-sm p-2 mb-2">
                      <div className="grid grid-cols-5 gap-3">
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Havola nomi</h3>
                          <span className="text-xs">{item?.title || "---"}</span>
                        </div>
                        <div className="col-span-3">
                          <h3 className="text-sm text-green-500 mb-0">Havola url</h3>
                          <a target="_blank" href={item?.url || "---"}>
                            <span className="text-xs">{item?.url || "---"}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <hr className="my-3 h-[1px] bg-red-500 border-0" />
              <div className="grid grid-cols-1 gap-3">
                <div>
                  <h2 className="text-xl text-blue-700 mb-2">Sertifikatlar</h2>
                  {data?.certificates?.map((item, index) => (
                    <div key={index} className="border-stone-400 border rounded-sm p-2 mb-2">
                      <div className="grid grid-cols-5 gap-3">
                        <div>
                          <h3 className="text-sm text-green-500 mb-0">Sertifikat nomi</h3>
                          <span className="text-xs">{item?.name || "---"}</span>
                        </div>
                        <div className="col-span-3">
                          <h3 className="text-sm text-green-500 mb-0">Fayl</h3>
                          <a target="_blank" href={item?.file?.path ? FILE_URL + item?.file?.path : "---"}>
                            <span className="text-xs">{item?.file?.path || "---"}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="text-right">
                <button className="rounded-md bg-green-600 p-2 text-white" onClick={() => checkResume("publish")}>
                  Tasdiqlash
                </button>
                <button className="rounded-md bg-orange-600 ml-3 p-2 text-white" onClick={() => checkResume("reject")}>
                  Qayta ishlash
                </button>
                <button className="rounded-md bg-red-600 ml-3 p-2 text-white" onClick={() => checkResume("trash")}>
                  Bekor qilish
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ViewResume;
