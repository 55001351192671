import { Badge } from "antd";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getDataById } from "../mudule.request";
import { ICourseModules } from "../types";
import { AxiosError } from "axios";
import { IoMdClose } from "react-icons/io";
import { BiCheck } from "react-icons/bi";
import { useTranslation } from "react-i18next";

const ViewModuleDetails = () => {
    
    const {t} = useTranslation()
    const {id} = useParams()

    const getDataByIdQuery = useQuery<ICourseModules, AxiosError>({
        queryKey: ['module-by-id', id],
        queryFn: () => getDataById(Number(id)),
        retry: 0,
        staleTime: Infinity
    });

    
    return(
        <div>
            <div className="grid grid-cols-12 mt-4 gap-4">
                <div className="col-span-8">
                    <div className="">
                        <div className="grid grid-cols-12 gap-8">
                            <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                <div className="flex justify-between mb-4">
                                    <p className="font-bold text-zinc-500">{t("Title")}</p>
                                    <p>{getDataByIdQuery.data?.translations[0].title}</p>
                                </div>
                                <div className="flex justify-between mb-4">
                                    <p className="font-bold text-zinc-500">{t("Price")}</p>
                                    <p>{getDataByIdQuery.data?.price}</p>
                                </div>
                                <div className="flex justify-between mb-4">
                                    <p className="font-bold text-zinc-500">{t("Level")}</p>
                                    <p>{getDataByIdQuery.data?.level}</p>
                                </div>
                                <div className="flex justify-between mb-4">
                                    <p className="font-bold text-zinc-500">{t("Slug")}</p>
                                    <p>{getDataByIdQuery.data?.slug}</p>
                                </div>
                                <div className="flex justify-between mb-4">
                                    <p className="font-bold text-zinc-500">{t("Description")}</p>
                                    <p>{getDataByIdQuery.data?.translations[0]?.desc}</p>
                                </div>
                            </div>
                            <div className="xl:col-span-6 md:col-span-6 col-span-12">
                                <div className="flex justify-between mb-4">
                                    <p className="font-bold text-zinc-500">{t("Status")}</p>
                                    <p>{getDataByIdQuery.data?.is_active ?<Badge count={t('Active')} showZero color='#52c41a' />:<Badge count={t('InActive')} showZero color='#faad14' />}</p>
                                </div>
                                <div className="flex justify-between mb-4">
                                    <p className="font-bold text-zinc-500">{t("Free")}</p>
                                    <p>{getDataByIdQuery.data?.is_free ?( <BiCheck className="text-[24px] text-green-700" />) : (<IoMdClose className="ml-2 text-[22px] text-rose-600" />
                       )}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ViewModuleDetails;