import { AxiosRequestConfig } from "axios";
import instance from "config/axios.config"





export const submitUser = async (data: Record<string, any>, user_id?: string) => {

    const options: AxiosRequestConfig = user_id ? { url: `/users/${user_id}`, method: 'PUT', data } : { url: '/users/create', method: 'POST', data }
    const response = await instance(options);

    return response.data
}


export const NewPassword = async (data: string, user_id:string|undefined) => {
    const options: AxiosRequestConfig = { url: `/users/${user_id}/reset-password`, method: 'POST', data } 
    const response = await instance(options);
    return response.data
}



export const attachRoleFn = async (user_id: string | undefined, data: any) => {

    if (user_id) {

        const response = await instance({ url: `/users/${user_id}/update/roles`, method: "POST", data });

        return response.data
    } else {
        throw new Error("User ma'lumoti topilmadi!")
    }
}


export const attachPermissionFn = async (user_id: string | undefined, permissions_id: string[]) => {

    if (user_id) {

        const response = await instance({ url: `/users/${user_id}/update/permissions`, method: "POST", data: { permissions_id } });

        return response.data
    } else {
        throw new Error("User ma'lumoti topilmadi!")
    }
}


export const attachCourseFn = async (user_id: string | undefined, permissions_id: string[], type: 'create' | 'update') => {

    if (user_id) {

        const response = await instance({
            url: type === 'create' ? `/users/${user_id}/update/permissions` : `/users/remove/${user_id}/courses`,
            method: "POST",
            data: { permissions_id }
        });

        return response.data
    } else {
        throw new Error("User ma'lumoti topilmadi!")
    }
}

export function deleteData(id: string) {
    return instance({ url: `users/${id}`, method: 'DELETE' })
}