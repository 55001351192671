import { useMutation } from "@tanstack/react-query";
import { Button, Form, Input } from "antd";
import { AxiosError } from "axios";
import { useState } from "react";
import { validationErrors } from "utils.functions";
import { changaPassword } from "./profile.request";


const PasswordChange = () => {

    const [form] = Form.useForm();

    const {mutate} = useMutation({
        mutationFn: (data:{current_password: string, new_password: string}) => changaPassword(data),
        onSuccess: async (data) => {
          localStorage.removeItem("refresh_token")
          localStorage.removeItem("access_token")
          localStorage.setItem('refresh_token', data?.refresh_token)
          localStorage.setItem('access_token', data?.access_token)
        },
        onError: (error: AxiosError<any>) => {
            validationErrors(form, error?.response?.data)
        },
        retry: 0,
    });

    const handleSubmit = (values:{current_password: string, new_password: string, new_password_again: string}) => {
        if(values.new_password === values.new_password_again){
            mutate({current_password: values.current_password, new_password:values.new_password})
        }else{
            form.setFields([{name: "new_password_again", errors:["Passwords not smiliar"]}])
        }
    }

    return (
        <div>
            <p className="font-bold">Change password</p>
            <hr className="my-3" />
            <Form
                layout={'vertical'}
                form={form}
                onFinish={handleSubmit}
            >
                 <Form.Item
                    label="Current password"
                    name="current_password"
                    rules={[{ required: true, message: 'Please input your Current password!' }]}
                >
                    <Input.Password minLength={5} placeholder="Password" />
                </Form.Item>
                <Form.Item
                    label="New Password"
                    name="new_password"
                    rules={[{ required: true, message: 'Please input your New Password!' }]}
                >
                    <Input.Password minLength={5} placeholder="New Password" />
                </Form.Item>
                <Form.Item
                    label="Repeat new password"
                    name="new_password_again"
                    className="mb-0"
                    rules={[{ required: true, message: 'Please repeat new password!' }]}
                >
                    <Input.Password minLength={5} placeholder="new password" />
                </Form.Item>
                <Button htmlType="submit" className="w-[100%]">Save Password</Button>
            </Form>
        </div>
    )
}
export default PasswordChange;