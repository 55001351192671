import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import KinescopePlayer from "@kinescope/react-kinescope-player";
import "./styles.scss";

const FreeVideoModal = ({ videoId, setVideoId }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(videoId);

  const handleCancel = () => {
    setVideoId("");
  };

  return (
    <div>
      <Modal className="freeVideoModal" title={"Video"} footer={false} open={isModalOpen} onCancel={handleCancel}>
        <KinescopePlayer className="mb-4" videoId={videoId} />
      </Modal>
    </div>
  );
};

export default FreeVideoModal;
