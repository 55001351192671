import instance from "config/axios.config";
import React from "react";

export async function submitData(id: string | undefined, data: React.Key[]) {
    const response = await instance({ url:`/course/${id}/update/mentors`, method: "POST", data: { mentors_id: data } });
    return response.data
}

export async function getData() {
    const response = await instance({ url: `/users/mentor/list`, method: 'GET',params: {page_size:15} });
    return response.data;
}