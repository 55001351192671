import { Button, Checkbox, Form, FormInstance, Input, Select } from "antd";
import FormLabelCrud from "components/FormLabel/FormLabel";
import useFilterLanguage from "custom-hooks/useFilterLanguage";
import { BiX } from "react-icons/bi";
import { PlusOutlined } from '@ant-design/icons';
import { ILanguage } from "pages/languages/types";
import { IDiscount } from "pages/discounts/types";
import useGetData from "custom-hooks/useGetData";
import LanguageTitle from "utils/LanguageTitle";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const statusArr = [
    { value: "public", label: "Public" },
    { value: "private", label: "Private" },
    { value: "premiere", label: "Premiere" },
]
const levelArr = [
    { value: "beginner", label: "Beginner" },
    { value: "intermediate", label: "Intermediate" },
    { value: "expert", label: "Expert" },
]

const FormItems = ({ form, handleSubmit }: { form: FormInstance, handleSubmit: any }) => {
    const {t} = useTranslation()    

    const { fl, languageIds } = useFilterLanguage(form);
    const { data: languages } = useGetData<ILanguage[]>({ queryKey: ['languages'], url: 'language/list/', options: { staleTime: Infinity, refetchOnWindowFocus: false } });
    const { data: discounts } = useGetData<IDiscount[]>({ queryKey: ['discounts'], url: 'discount/list/', options: { staleTime: Infinity, refetchOnWindowFocus: false } });
    
    return (
        <Form layout={"vertical"} form={form} onFinish={handleSubmit}>
            <div className="grid grid-cols-12 gap-x-4">
                <Form.List name="translations">
                    {(fields, { add, remove }) => (
                        <div className="col-span-12 mb-4 ">
                            {fields.map(({ key, name, ...restField }, index) => (
                                <div key={index} className='grid grid-cols-12 gap-4 mb-4 bg-slate-50 p-2 rounded-md'>
                                    <div className="lg:col-span-3 md:col-span-6 col-span-12">
                                        <Form.Item
                                            className='mb-0'
                                            label={<FormLabelCrud>Title</FormLabelCrud>}
                                            {...restField}
                                            name={[name, 'title']}
                                            rules={[{ required: index === 0, message: 'Please input title!' }]}
                                        >
                                            <Input placeholder="Ma'lumot kiriting" />
                                        </Form.Item>
                                    </div>
                                    <div className="lg:col-span-3 md:col-span-6 col-span-12">
                                        <Form.Item
                                            className='mb-0'
                                            label={<FormLabelCrud>Subtitle</FormLabelCrud>}
                                            {...restField}
                                            name={[name, 'sub_title']}
                                        >
                                            <Input placeholder="Ma'lumot kiriting" />
                                        </Form.Item>
                                    </div>
                                    <div className="lg:col-span-3 md:col-span-6 col-span-12">
                                        <Form.Item
                                            className='mb-0'
                                            label={<FormLabelCrud>Description</FormLabelCrud>}
                                            {...restField}
                                            name={[name, 'desc']}
                                            rules={[{ required: true, message: 'Please input Description!' }]}
                                        >
                                            <Input placeholder="Description" />
                                        </Form.Item>
                                    </div>

                                    <div className="lg:col-span-3 md:col-span-6 col-span-12 flex items-end">
                                        <Form.Item
                                            label={<FormLabelCrud>Language</FormLabelCrud>}
                                            {...restField}
                                            name={[name, 'language_id']}
                                            rules={[{ required: index === 0, message: 'Please select language' }]}
                                            className="w-[calc(100%-55px)] mb-0"
                                        >
                                            <Select onClick={fl}>
                                                {
                                                    languages?.map(element => <Option key={element.id} value={element.id} disabled={languageIds.includes(element.id)}><LanguageTitle element={element}/></Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item className="w-[50px] mb-0 ml-2 mt-[25px]">
                                            <Button disabled={fields.length == 1} onClick={() => { remove(name); }} danger><BiX /></Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            ))}
                            <div className="col-span-12">
                                <Button type="dashed" className="w-[200px] block text-[#959595]" onClick={() => { add() }} block icon={<PlusOutlined />} disabled={fields.length === languages?.length}>
                                    {t("Add field")}
                                </Button>
                            </div>
                        </div>
                    )}
                </Form.List>
                <div className="lg:col-span-3 sm:col-span-4 col-span-12">
                    <Form.Item
                        label={t("Discounts")}
                        name="discount_id"
                        rules={[{ required: false, message: 'Please select discount!' }]}
                    >
                        <Select
                            allowClear
                            className='mb-3 w-[100%]'
                            options={discounts && discounts?.map(item => { return { value: item.id, label: item.translations[0].title } })  }
                        />
                    </Form.Item>
                </div>
                <div className="lg:col-span-3 sm:col-span-4 col-span-12">
                    <Form.Item
                        label={t("Status")}
                        name="status"
                        rules={[{ required: false, message: 'Please select status!' }]}
                    >
                        <Select
                            className='mb-3 w-[100%]'
                            options={statusArr}
                        />
                    </Form.Item>
                </div>
                <div className="lg:col-span-3 sm:col-span-4 col-span-12">
                    <Form.Item
                        label={t("Level")}
                        name="level"
                        rules={[{ required: false, message: 'Please select level!' }]}
                    >
                        <Select
                            className='mb-3 w-[100%]'
                            options={levelArr}
                        />
                    </Form.Item>
                </div>
                <div className="lg:col-span-3 sm:col-span-4 col-span-12">
                    <Form.Item
                        label={t("Price")}
                        name="price"
                        rules={[{ required: true, message: 'Please input price!' }]}
                    >
                        <Input type="number" className="w-[100%]" min={0} />
                    </Form.Item>
                </div>
                <div className="lg:col-span-3 sm:col-span-4 col-span-12">
                    <Form.Item
                        label={t("Sort")}
                        name="sort"
                        rules={[{ required: false, message: 'Please input sort!' }]}
                    >
                        <Input type="number" className="w-[100%]" />
                    </Form.Item>
                </div>
                <div className="lg:col-span-3 sm:col-span-4 col-span-12">
                    <Form.Item
                        label={t("Slug")}
                        name="slug"
                        rules={[{ required: false, message: 'Please input slug!' }]}
                    >
                        <Input className="w-[100%]" />
                    </Form.Item>
                </div>
                <div className="lg:col-span-3 sm:col-span-4 col-span-12">
                    <Form.Item
                        label={t("Telegram Url")}
                        name="telegram_url"
                        rules={[{ required: false, message: 'Please input telegram_url!' }]}
                    >
                        <Input className="w-[100%]" />
                    </Form.Item>
                </div>
                <div className="lg:col-span-3 sm:col-span-4 col-span-12">
                    <Form.Item
                        label={t("Course duration")}
                        name="duration"
                        rules={[{ required: false, message: 'Please input course duration!' }]} 
                    >
                        <Input className="w-[100%]"  />
                    </Form.Item>
                </div>
                <div className="lg:col-span-2 md:col-span-3 lg:hidden block"></div>
                <div className="lg:col-span-2 md:col-span-3 col-span-6">
                    <Form.Item
                        name="is_active"
                        rules={[{ required: false, message: 'Please input is status!' }]}
                        valuePropName={'checked'}
                    >
                        <Checkbox>{t("Status")}</Checkbox>
                    </Form.Item>
                </div>
                <div className="lg:col-span-2 md:col-span-3 col-span-6">
                    <Form.Item
                        name="is_free"
                        rules={[{ required: false, message: 'Please check!' }]}
                        valuePropName={'checked'}
                    >
                        <Checkbox>{t("Free")}</Checkbox>
                    </Form.Item>
                </div>
                <div className="lg:col-span-2 md:col-span-3 col-span-6">
                    <Form.Item
                        name="is_verified"
                        rules={[{ required: false, message: 'Please check!' }]}
                        valuePropName={'checked'}
                    >
                        <Checkbox>{t("Verified")}</Checkbox>
                    </Form.Item>
                </div>
                <div className="lg:col-span-2 md:col-span-3 col-span-6">
                    <Form.Item
                        name="is_for_child"
                        rules={[{ required: false, message: 'Please check!' }]}
                        valuePropName={'checked'}
                    >
                        <Checkbox>{t("For child")}</Checkbox>
                    </Form.Item>
                </div>
                <div className="lg:col-span-2 md:col-span-3 col-span-6">
                    <Form.Item
                        
                        name="language_id"
                        rules={[{ required: false, message: 'Please select language!' }]}
                    >
                        <Select
                        placeholder="Ta'lim tili"
                            className='w-[100%]'
                            options={languages?.map(item => { return { value: item.id, label: (<LanguageTitle element={item}/>) } })}
                        />
                    </Form.Item>
                </div>
            </div>
        </Form>
    );
};

export default FormItems;