import instance from "config/axios.config";

export async function submitData(id: number | undefined, data: any) {
  const url = id ? `/topic/${id}` : `topic/create`;
  const response = await instance({ url, method: id ? "PUT" : "POST", data });
  return response.data;
}

export async function getData(profession_id: number | undefined | null) {
  const response = await instance({
    url: `topic/list/`,
    method: "GET",
    params: { profession_id },
  });
  return response.data;
}

export function deleteData(id: any) {
  return instance({ url: `topic/${id}`, method: "DELETE" });
}

export async function getProfessions() {
  const response = await instance({ url: `profession/list/`, method: "GET" });
  return response.data;
}
