import React, { Dispatch, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Drawer, Form, Input, Switch, message } from "antd";
import { IMentorInfo } from "./types";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { FaRegEdit } from "react-icons/fa";
import { RButton, SButton } from "components/Common/CustomButtons";
import { submitMentorInfo } from "./rating.request";
import { FILE_URL } from "config/utils";
import { useTranslation } from "react-i18next";

const CreateCourseRating = ({
  element,
  setElement,
  refetch,
  openSidebarMenu,
  setOpenSidebarMenu,
}: {
  element: IMentorInfo | null;
  setElement: Dispatch<IMentorInfo | null>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: Dispatch<boolean>;
}) => {
  const {t} = useTranslation()
  const { user_id: mentor_id } = useParams();
  const [form] = Form.useForm();
  const [imageSrc, setImageSrc] = useState({ url: "", file: "" });

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue(element);
      if (!imageSrc.file && element?.resume) {
        setImageSrc({ url: FILE_URL + element?.resume?.path, file: "" });
      } else {
        setImageSrc({ url: "", file: "" });
      }
    } else {
      form.resetFields();
      form.setFieldsValue({
        is_active: true,
        is_verified: false,
        translations: [{ title: "", desc: "" }],
      });
    }
    // student_mutate("");
  }, [element?.mentor_info_id, openSidebarMenu]);

  const { mutate, isLoading, data } = useMutation({
    mutationKey: ["course-students-review"],
    mutationFn: (newData: any) => submitMentorInfo(mentor_id, newData, element ? mentor_id : ""),
    onSuccess: async (data) => {
      // setStudentsList(data.results);
      setOpenSidebarMenu(false);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      message.error("Xatolik");
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    values.user_id = mentor_id;
    mutate({ payload: values, resume: imageSrc.file });
  }

  const handleImageChange = (e: any) => {
    setImageSrc({ url: URL.createObjectURL(e.target.files[0]), file: e.target.files[0] });
  };

  const formFields = (
    <Form layout={"vertical"} form={form} onFinish={handleSubmit} className="custom_form_style">
      <div className="col-span-6">
        <div className="flex gap-x-6 mt-3">
          <Form.Item
            label={<FormLabelCrud>Contract number</FormLabelCrud>}
            name="contract_number"
            rules={[{ message: "Please input contact number!" }]}
            className="w-full"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={<FormLabelCrud>{t("Level")}</FormLabelCrud>}
            name="level"
            rules={[{ message: "Please input level!" }]}
            className="w-full"
          >
            <Input />
          </Form.Item>
        </div>
        <div className="flex gap-x-6 mt-3">
          <Form.Item
            label={<FormLabelCrud>{t("Main work")}</FormLabelCrud>}
            name="main_work"
            rules={[{ message: "Please input main work!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={<FormLabelCrud>{t("Is main")}</FormLabelCrud>}
            name="is_main"
            rules={[{ required: false, message: "Please input is status!" }]}
            valuePropName={"checked"}
          >
            <Switch className="bg-[#999]" />
          </Form.Item>
        </div>
      </div>
      <div className="col-span-6">
        <div className="relative">
          {imageSrc?.url ? (
            <img className="lg:h-[200px] h-[100px] rounded-xl w-[100%] object-contain" src={imageSrc?.url} alt="" />
          ) : (
            <h2 className="lg:h-[200px] h-[100px] rounded-xl w-[100%] bg-slate-100 text-center align-middle text-[30px] text-[#999]">
              {t("Image Not Uploaded")}
            </h2>
          )}
          <input
            onChange={(e) => handleImageChange(e)}
            style={{ display: "none" }}
            accept="image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/tiff, image/webp, image/x-icon"
            type="file"
            id="course-image"
          />
          <label htmlFor="course-image" className="absolute bottom-5 right-5 cursor-pointer">
            <FaRegEdit className="text-[22px] text-[#045140]" />
          </label>
        </div>
      </div>
      <div className="mt-2 text-right">
        <RButton
          onClick={() => {
            form.resetFields();
            // form.setFieldsValue({
            //   is_active: true,
            //   title: [{}],
            // });
            setImageSrc({ url: "", file: "" });
            setElement(null);
          }}
          className="mr-2"
        />
        <SButton loading={isLoading} />
      </div>
    </Form>
  );

  return (
    <Drawer
      title={<h3 className=" font-bold">{t("Mentor info")}</h3>}
      placement="right"
      onClose={() => setOpenSidebarMenu(false)}
      open={openSidebarMenu}
      width={800}
    >
      {formFields}
    </Drawer>
  );
};
export default CreateCourseRating;
