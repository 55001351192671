import { Link, useParams } from "react-router-dom";

import type { ColumnsType } from "antd/es/table";
import CreateCourseGroup from "./group_form_ui";
import { useMutation, useQueries } from "@tanstack/react-query";
import { deleteData, getCurators, getGroups } from "./course_groups.requests";
import { IGroup } from "./types";
import { useEffect, useState } from "react";
import { IUser } from "types/data.models";
import PageTitle from "components/Common/PageTitle";
import { Badge, Button, Empty } from "antd";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import DeleteIcon from "components/Common/deleteIcon";
import TableLoader from "components/loaders/table_loader";
import { is_permission } from "utils.functions";
import { useTranslation } from "react-i18next";

const CourseGroups = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();

  const [selected, setSelected] = useState<IGroup | null>(null);
  const [openSidebarMenu, setOpenSidebarMenu] = useState<boolean>(false);
  const [groupsArr, setGroups] = useState<IGroup[]>([]);
  const [curatorsArr, setCurators] = useState<IUser[]>([]);

  const deletePost = useMutation({
    mutationFn: deleteData,
  });

  useEffect(() => {
    if (!deletePost.isLoading) {
      courseGroupQuery.refetch();
    }
  }, [deletePost.isLoading]);

  const [courseGroupQuery, curatorsQuery] = useQueries({
    queries: [
      {
        queryKey: ["course-groups"],
        queryFn: () => getGroups(id),
        retry: 0,
        staleTime: Infinity,
      },
      {
        queryKey: ["curators"],
        queryFn: () => getCurators(),
        retry: 0,
        staleTime: Infinity,
      },
    ],
  });

  useEffect(() => {
    if (courseGroupQuery.data) {
      setGroups(courseGroupQuery.data);
    }
  }, [courseGroupQuery.data]);

  useEffect(() => {
    if (curatorsQuery.data) {
      setCurators(
        curatorsQuery.data.results.map((i: IUser) => {
          return { value: i?.id, label: i?.translations[0]?.first_name };
        })
      );
    }
  }, [curatorsQuery.data]);
  
  if (courseGroupQuery.error) return <div>"An error has occurred: " + courseGroupQuery.error</div>;
console.log(courseGroupQuery.data)
  return (
    <div>
      <div className="grid grid-cols-12 gap-4">
        <div className="main-card lg:col-span-8 col-span-12">
          <div className="flex justify-between">
            <div className="col-span-6">
              <PageTitle title={t("Course groups")} />
            </div>
            {is_permission("create_course_group") ? (
              <div className="col-span-6 text-right">
                <Button
                  onClick={() => {
                    setOpenSidebarMenu(true);
                    setSelected(null);
                  }}
                  type="default"
                  className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763] lg:hidden"
                >
                  + {t("Create")}
                </Button>
              </div>
            ) : null}
          </div>
          <Table className="table-striped table-hover mt-2">
            <Thead>
              <Tr>
                <Th className="w-[50px]">№</Th>
                <Th className="w-[50px] text-center px-[0!important]">{t("Id")}</Th>
                <Th>{t("Curator")}</Th>
                <Th>{t("Is Main")}</Th>
                <Th>{t("Actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!courseGroupQuery.isLoading
                ? courseGroupQuery?.data?.length > 0
                  ? courseGroupQuery?.data?.map((item: IGroup, index: number) => (
                      <Tr key={item.id}>
                        <Td>{index + 1}</Td>
                        <Td className="w-[50px] text-center px-[0!important]">{item?.id}</Td>
                        <Td>{item?.curator?.translations[0]?.first_name}</Td>
                        <Td>{item?.is_main ? (<Badge showZero color='#52c41a' count="true"/>): <Badge count="false" color="#faad14"/>}</Td>
                        <Td>
                          <div className="flex" style={{ alignItems: "center" }}>
                            {is_permission("read_course_group") ? (
                              <Link to={`/courses/group/view/${item?.id}`}>
                                <AiOutlineEye className=" mr-2 text-[#faad14] text-[22px] cursor-pointer" />
                              </Link>
                            ) : null}
                            {is_permission("update_course_group") ? (
                              <FiEdit
                                className="mx-2 text-[green] text-[18px] cursor-pointer"
                                onClick={() => {
                                  setSelected(item);
                                  setOpenSidebarMenu(true);
                                }}
                              />
                            ) : null}
                            {is_permission("delete_course_group") ? (
                              <DeleteIcon confirmFunc={() => deletePost.mutate(item?.id)} />
                            ) : null}
                          </div>
                        </Td>
                      </Tr>
                    ))
                  : null
                : null}
            </Tbody>
          </Table>
          {courseGroupQuery?.data?.length === 0 ? <Empty className="w-[100%]" /> : null}
          <TableLoader isLoading={courseGroupQuery.isLoading} />
        </div>
        {(is_permission("create_course_group") && !selected) || (is_permission("update_course_group") && selected) ? (
          <div className="main-card col-span-4 lg:block hidden">
            <CreateCourseGroup
              course_id={id}
              allCurators={curatorsArr}
              element={selected}
              setElement={setSelected}
              refetch={courseGroupQuery.refetch}
              openSidebarMenu={openSidebarMenu}
              setOpenSidebarMenu={setOpenSidebarMenu}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default CourseGroups;
