import instance from "config/axios.config";

export async function submitMentorInfo(mentor_id: string | undefined, data: any, info_id: string | undefined) {
  const url = info_id ? `mentor/info/${info_id}` : `mentor/info`;

  const formData = new FormData();
  if (data.resume) {
    formData.append("resume", data.resume);
  }
  formData.append("payload", JSON.stringify(data.payload));

  const response = await instance({ url, method: info_id ? "PUT" : "POST", data: formData });
  return response.data;
}

export async function getMentorInfo(mentor_id: string | undefined) {
  const response = await instance({ url: `mentor/info/${mentor_id}`, method: "GET" });
  return response.data;
}

export function deleteData({ mentor_id }: { mentor_id: any }) {
  return instance({ url: `mentor/info/${mentor_id}`, method: "DELETE" });
}
