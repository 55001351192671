

import { useMutation, useQuery } from "@tanstack/react-query";
import { UploadFile } from "antd";
import ToBack from "components/Common/ToBack";
import AvatarFileUpload from "components/fileUploader/avatar";
import { FILE_URL } from "config/utils";
import { useState } from "react";
import PasswordChange from "./change_password";
import { changeImage } from "./profile.request";
import { CLIENT_API } from "service/clieant.request";
import { IUser } from "types/data.models";
import { AxiosError } from "axios";
import useGetData from "custom-hooks/useGetData";
import { useTranslation } from "react-i18next";

const Profile = () => {

    const {t} = useTranslation()
    const [imageSrc,  setImageSrc] = useState<{url: string, file: any}>({url:"", file:""})

    const meQuery = useGetData({queryKey: ['user-me'], url: "/users/me", options: {retry: 0, staleTime: Infinity}})
    
    const {data} = useQuery<IUser, AxiosError>({
        queryKey: ['user-data'],
        queryFn: () => CLIENT_API.getOne({ url: `/users/get/${meQuery.data?.id}`}),
        onSuccess: (res: IUser) => {
            if(res?.image){
                setImageSrc({url:FILE_URL + res?.image?.path, file:""})
            }
        },
        retry: 0,
        enabled: !!meQuery.data?.id,
        refetchOnWindowFocus: false
});

    const imageMutation = useMutation({
        mutationFn: (file) => changeImage(file),
        onSuccess: async (data) => {

        },
        retry: 0,
    });

    return (
        <>
            <ToBack title={t("Back")} />
            <div className="grid grid-cols-12 gap-4 mt-4">
                <div className="col lg:col-span-2 md:col-span-4 col-span-12 text-center main-card">
                    <div className="py-6">
                        <AvatarFileUpload imageSrc={imageSrc}  setImageSrc={setImageSrc} mutate={imageMutation.mutate} />
                        <p className="mt-4">{data?.translations[0]?.first_name} {data?.translations[0]?.last_name}</p>
                        <p className="mt-2 opacity-60">{data?.email}</p>
                    </div>
                </div>
                <div className="col lg:col-span-3 md:col-span-8 col-span-12 main-card lg:hidden">
                    <PasswordChange />
                </div>
                <div className="col lg:col-span-7 col-span-12 main-card">
                    <div className="grid grid-cols-12 gap-8">
                        <div className="xl:col-span-6 md:col-span-6 col-span-12">
                            <div className="flex justify-between mb-4">
                                <p className="font- font-bold text-zinc-500">Identification number</p>
                                {/* <p>{data?.identification_number}</p> */}
                            </div>
                            <div className="flex justify-between mb-4">
                                <p className="font- font-bold text-zinc-500">Bank account</p>
                                {/* <p>{data?.bank_account}</p> */}
                            </div>
                            <div className="flex justify-between mb-4">
                                <p className="font- font-bold text-zinc-500">Bank mfo</p>
                                {/* <p>{data?.bank_mfo}</p> */}
                            </div>
                        </div>
                        <div className="xl:col-span-6 md:col-span-6 col-span-12">
                            <div className="flex justify-between mb-4">
                                <p className="font- font-bold text-zinc-500">Regions</p>
                                {/* <p>{data?.legal_address_region?.translations[0]?.title}</p> */}
                            </div>
                            <div className="flex justify-between mb-4">
                                <p className="font- font-bold text-zinc-500">District</p>
                                {/* <p>{data?.legal_address_district?.translations[0]?.title}</p> */}
                            </div>
                            <div className="flex justify-between mb-4">
                                <p className="font- font-bold text-zinc-500">Status</p>
                                {/* <p>{data?.is_active ?<Badge count={'Active'} showZero color='#52c41a' />:<Badge count={'InActive'} showZero color='#faad14' />}</p> */}
                            </div>
                            <div className="flex justify-between mb-4">
                                <p className="font- font-bold text-zinc-500">Verified</p>
                                {/* <p>{data?.is_verified ?<Badge count={'Verified'} showZero color='#52c41a' />:<Badge count={'Not Verified'} showZero color='#faad14' />}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col lg:col-span-3 md:col-span-2 col-span-12 main-card lg:block hidden">
                    <PasswordChange />
                </div>
            </div>
        </>
    )
}
export default Profile