import React, { Dispatch } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, DatePicker, Drawer, Form, Input, message, Select, Switch } from "antd";
import useWindowSize from "custom-hooks/useWindowSize";
import { submitData } from "./promocode.request";
import { IProfessionSelect, ITopic } from "./types";
import MultipleLangInputs from "components/MultipleLangInput/multipleLangs";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import { RButton, SButton } from "components/Common/CustomButtons";
import FormLabelCrud from "components/FormLabel/FormLabel";
import EditingTitle from "components/Common/EditingTitle";
import PartTitle from "components/Common/PartTitle";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { useTranslation } from "react-i18next";

dayjs.extend(weekday);
dayjs.extend(localeData);

type TypePropsCreatePromocode = {
  allProfessions: IProfessionSelect[];
  element: ITopic | null;
  setElement: React.Dispatch<React.SetStateAction<ITopic | null>>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreatePromocode = ({ allProfessions, element, setElement, refetch, openSidebarMenu, setOpenSidebarMenu }: TypePropsCreatePromocode) => {

  const {t} = useTranslation()
  const [form] = Form.useForm();
  const { width } = useWindowSize();
  const { _translate } = useLocaleTranslation();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue({ ...element, start: dayjs(element.start, "YYYY-MM-DD"), stop: dayjs(element.stop, "YYYY-MM-DD") });
    } else {
      form.resetFields();
      form.setFieldsValue({
        is_active: true,
        is_archive: false,
        translations: [{ title: "", desc: "" }],
      });
    }
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-promocode"],
    mutationFn: (newValues) => submitData(element?.id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["promocodes"], data);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    values.start = dayjs(values.start).format("YYYY-MM-DD");
    values.stop = dayjs(values.stop).format("YYYY-MM-DD");
    mutate(values);
  }
  console.log(element);

  const MemoizationFieldsFormPromocode = React.memo(() => (
    <Form layout={"vertical"} form={form} onFinish={handleSubmit} className="custom_form_style">
      <MultipleLangInputs form={form} additionalItems={["desc"]} />
      <div className="grid grid-cols-12 gap-x-4">
        <div className="col-span-6">
          <Form.Item label={<FormLabelCrud>Code</FormLabelCrud>} name="code" rules={[{ required: true, message: "Please input code!" }]}>
            <Input type="text" />
          </Form.Item>
        </div>
        <div className="col-span-6">
          <Form.Item
            label={<FormLabelCrud>Discounts</FormLabelCrud>}
            name="discount_id"
            rules={[{ required: true, message: "Please select discount!" }]}
          >
            {allProfessions ? <Select className="mb-3 w-[100%]" options={allProfessions} /> : "loading..."}
          </Form.Item>
        </div>
        <div className="md:col-span-6 col-span-12">
          <Form.Item label={<FormLabelCrud>Start date</FormLabelCrud>} name="start" rules={[{ required: true, message: "Please select date!" }]}>
            <DatePicker format={"YYYY-MM-DD"} className="w-[100%]" />
          </Form.Item>
        </div>
        <div className="md:col-span-6 col-span-12">
          <Form.Item label={<FormLabelCrud>End date</FormLabelCrud>} name="stop" rules={[{ required: true, message: "Please select date!" }]}>
            <DatePicker format={"YYYY-MM-DD"} className="w-[100%]" />
          </Form.Item>
        </div>
        <div className="col-span-6">
          <Form.Item
            label={<FormLabelCrud>Status</FormLabelCrud>}
            name="is_active"
            rules={[{ required: false, message: "Please input is status!" }]}
            valuePropName={"checked"}
          >
            <Switch defaultChecked className="bg-[#999]" />
          </Form.Item>
        </div>
        <div className="col-span-6">
          <Form.Item
            label={<FormLabelCrud>Archieve</FormLabelCrud>}
            name="is_archive"
            rules={[{ required: false, message: "Please input is status!" }]}
            valuePropName={"checked"}
          >
            <Switch defaultChecked className="bg-[#999]" />
          </Form.Item>
        </div>
        <div className="col-span-12 mt-2 sm:text-right">
        <RButton
          onClick={() => {
            form.resetFields();
            setElement(null);
            form.setFieldsValue({
              is_active: true,
              is_archive: false,
              translations: [{ title: "", desc: "" }],
            });
          }}
          className="sm:mr-2"
        />
        <SButton loading={isLoading} />
      </div>
      </div>
      
    </Form>
  ));

  return (
    <div>
      <>{element ? <EditingTitle title={_translate(element?.translations ?? [], "title")} /> : <PartTitle children={"Create promocode"} />}</>
      <hr className="my-3" />
      <MemoizationFieldsFormPromocode />
      <Drawer
        title={<h3 className=" font-bold">{element?.translations[0]?.title ? element?.translations[0]?.title : t("New")}</h3>}
        placement="right"
        onClose={() => setOpenSidebarMenu(false)}
        open={width > 992 ? false : openSidebarMenu}
      >
        <MemoizationFieldsFormPromocode />
      </Drawer>
    </div>
  );
};
export default CreatePromocode;
