import CourseFormat from "./format";
import CourseFor from "./course_for";
import CourseWillLearn from "./will_learn";
import CoursePlan from "./plan";
import { is_permission } from "utils.functions";
// @ts-ignore
import StudentPortfolios from "./portfolio";

const ViewCourseDetails = () => {
  return (
    <div>
      <div className="grid grid-cols-12 mt-4 gap-5">
        {is_permission("read_about_course_format_block") ? (
          <div className="col-span-12">
            <CourseFormat />
          </div>
        ) : null}
        {is_permission("read_for_whom_course_block") ? (
          <div className="col-span-12">
            <CourseFor />
          </div>
        ) : null}
        {is_permission("read_what_learn_course_block") ? (
          <div className="col-span-12">
            <CourseWillLearn />
          </div>
        ) : null}
        {is_permission("read_course_plan_block") ? (
          <div className="col-span-12">
            <CoursePlan />
          </div>
        ) : null}
        {is_permission("read_course_students_work_block") ? (
          <div className="col-span-12">
            <StudentPortfolios />
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default ViewCourseDetails;
