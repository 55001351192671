import React, { useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Drawer, Form, Input, Switch, Upload, UploadFile, UploadProps } from "antd";
import useWindowSize from "custom-hooks/useWindowSize";
import { submitData } from "./training.request";
import { ITraining } from "./types";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import PartTitle from "components/Common/PartTitle";
import EditingTitle from "components/Common/EditingTitle";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { RButton, SButton } from "components/Common/CustomButtons";
import { useTranslation } from "react-i18next";
import { Editor } from "@monaco-editor/react";
import { FILE_URL } from "config/utils";
import { UploadOutlined } from "@ant-design/icons";

type TypeTagFormProps = {
  element: ITraining | null;
  setElement: React.Dispatch<React.SetStateAction<ITraining | null>>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateTraining = ({ element, setElement, refetch, openSidebarMenu, setOpenSidebarMenu }: TypeTagFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { width } = useWindowSize();
  const editorRef: any = useRef(null);
  const [codeData, setCodeData] = useState("print('Hello world')");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue({
        title: element?.title,
        case_count: element?.case_count,
        is_active: element?.is_active,
        // phone: element?.phone
      });
      setCodeData(element.example);
    } else {
      form.resetFields();
    }
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-training"],
    mutationFn: (newVals: any) => submitData(element?.id, newVals),
    onSuccess: async (data) => {
      // queryClient.setQueryData(["trainings"], data);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    let input = values?.input?.length ? values?.input[0] : "";
    let output = values?.output?.length ? values?.output[0] : "";
    delete values.input;
    delete values.output;
    if (!values.case_count) {
      delete values.case_count;
    } else {
      values.case_count = +values.case_count;
    }
    values.example = editorRef.current.getValue();
    setCodeData(values.example);

    mutate({ payload: values, input, output });
  }
  const normInputFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const normOutputFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  function handleEditorDidMount(editor: any, monaco: any) {
    editorRef.current = editor;
  }

  const propsInput: UploadProps = {
    onRemove: (file) => {},
    beforeUpload: (file) => {
      return false;
    },
    fileList,
    maxCount: 1,
  };
  const propsOutput: UploadProps = {
    onRemove: (file) => {},
    beforeUpload: (file) => {
      return false;
    },
    fileList,
    maxCount: 1,
  };

  const MemoizationFieldsFormTag = React.memo(() => (
    <Form layout={"vertical"} form={form} onFinish={handleSubmit} className="custom_form_style">
      <div className="grid grid-cols-12 gap-4">
        <div className="md:col-span-12 col-span-12">
          <Form.Item label={<FormLabelCrud>Title</FormLabelCrud>} name="title" rules={[{ required: true, message: "Please input title!" }]}>
            <Input />
          </Form.Item>
        </div>
        <div className="md:col-span-6 col-span-6">
          <Form.Item
            label={<FormLabelCrud>Case count</FormLabelCrud>}
            name="case_count"
            rules={[{ required: false, message: "Please input case count!" }]}
          >
            <Input type="number" />
          </Form.Item>
        </div>
        <div className="md:col-span-3 col-span-6">
          <Form.Item
            name="is_active"
            label={<FormLabelCrud>Active</FormLabelCrud>}
            rules={[{ required: false, message: "Please input is active!" }]}
            valuePropName={"checked"}
            initialValue={true}
          >
            <Switch defaultChecked className="bg-[#999]" />
          </Form.Item>
        </div>
        <div className="md:col-span-12 col-span-12">
          <Editor defaultLanguage="python" defaultValue={codeData} onMount={handleEditorDidMount} theme="vs-dark" className="myEditor h-[200px]" />
        </div>
        <div className="md:col-span-6 col-span-12">
          <Form.Item style={{ marginBottom: "0" }} name="input" label="Input" valuePropName="fileList" getValueFromEvent={normInputFile}>
            <Upload {...propsInput}>
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
          {element?.input && (
            <a download href={FILE_URL + element?.input?.path}>
              {element.input.filename}
            </a>
          )}
        </div>
        <div className="md:col-span-6 col-span-12">
          <Form.Item style={{ marginBottom: "0" }} name="output" label="Output" valuePropName="fileList" getValueFromEvent={normOutputFile}>
            <Upload {...propsOutput}>
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
          {element?.output && (
            <a download href={FILE_URL + element?.output?.path}>
              {element.output.filename}
            </a>
          )}
        </div>
      </div>
      <div className="flex gap-2 mt-2 text-end">
        <RButton
          onClick={() => {
            form.resetFields();
            setElement(null);
          }}
        />
        <SButton loading={isLoading} />
      </div>
    </Form>
  ));

  return (
    <div className="bg-red-600">
      <>{element ? <EditingTitle title={element?.title} /> : <PartTitle children={"Create training"} />}</>
      <hr className="my-3" />
      {/* <MemoizationFieldsFormTag /> */}
      <Drawer
        title={<h3 className=" font-bold">{element?.title ? element?.title : t("New")}</h3>}
        placement="right"
        onClose={() => setOpenSidebarMenu(false)}
        open={openSidebarMenu}
        width={width > 600 ? 600 : "100%"}
      >
        <MemoizationFieldsFormTag />
      </Drawer>
    </div>
  );
};
export default CreateTraining;
