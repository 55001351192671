import React, { Dispatch, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Drawer, Form, Input, message, Select, Switch } from "antd";
import useWindowSize from "custom-hooks/useWindowSize";
import { submitData } from "./category.request";
import { ITopicSelect, ICategory } from "./types";
import MultipleLangInputs from "components/MultipleLangInput/multipleLangs";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import EditingTitle from "components/Common/EditingTitle";
import PartTitle from "components/Common/PartTitle";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { RButton, SButton } from "components/Common/CustomButtons";
import { FaRegEdit } from "react-icons/fa";
import { FILE_URL } from "config/utils";
import { useTranslation } from "react-i18next";

const CreateCategory = ({
  alltopics,
  element,
  setElement,
  refetch,
  openSidebarMenu,
  setOpenSidebarMenu,
}: {
  alltopics: ITopicSelect[];
  element: ICategory | null;
  setElement: Dispatch<ICategory | null>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: Dispatch<boolean>;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { width } = useWindowSize();
  const { _translate } = useLocaleTranslation();
  const queryClient = useQueryClient();
  const [imageSrc, setImageSrc] = useState({ url: "", file: "" });

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue(element);
      if (!imageSrc.file && element?.logo) {
        setImageSrc({ url: FILE_URL + element?.logo?.path, file: "" });
      } else {
        setImageSrc({ url: "", file: "" });
      }
    } else {
      form.resetFields();
      form.setFieldsValue({
        is_active: true,
        translations: [{ title: "" }],
      });
      setImageSrc({ url: "", file: "" });
    }
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-topic"],
    mutationFn: (newValues: any) => submitData(element?.id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["topics"], data);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    console.log({ ...values, logo: imageSrc.file });

    mutate({ payload: values, logo: imageSrc.file });
  }
  const handleImageChange = (e: any) => {
    setImageSrc({ url: URL.createObjectURL(e.target.files[0]), file: e.target.files[0] });
  };

  const MemoizationFieldsFormCategory = React.memo(() => (
    <Form layout={"vertical"} form={form} onFinish={handleSubmit} className="custom_form_style">
      <MultipleLangInputs form={form} additionalItems={["desc"]} />
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-6">
          <Form.Item
            label={<FormLabelCrud>{t("Sort")}</FormLabelCrud>}
            name="sort"
            rules={[{ required: false, message: "Please input sort!" }]}
          >
            <Input type="number" />
          </Form.Item>
        </div>
        <div className="col-span-6">
          <Form.Item
            label={<FormLabelCrud>Slug</FormLabelCrud>}
            name="slug"
            rules={[{ required: false, message: "Please input slug!" }]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="sm:col-span-6 col-span-10">
          <Form.Item
            label={<FormLabelCrud>Topics</FormLabelCrud>}
            name="topic_id"
            rules={[{ required: true, message: "Please select topic!" }]}
          >
            {alltopics ? <Select className="mb-3 w-[100%]" options={alltopics} /> : "loading..."}
          </Form.Item>
        </div>
        <div className="col-span-6">
          <Form.Item
            label={<FormLabelCrud>Status</FormLabelCrud>}
            name="is_active"
            rules={[{ required: false, message: "Please input is status!" }]}
            valuePropName={"checked"}
          >
            <Switch defaultChecked className="bg-[#999]" />
          </Form.Item>
        </div>
        <div className="lg:col-span-8 md:col-span-6 col-span-12 relative">
          {imageSrc?.url ? (
            <img className="lg:h-[200px] h-[100px] rounded-xl w-full object-contain" src={imageSrc?.url} alt="" />
          ) : (
            <h2 className="lg:h-[200px] h-[100px]  rounded-xl w-full bg-slate-100 text-center align-middle p-2 lg:text-[30px] sm:text-2xl text-lg text-[#999]">
              {t("Image Not Uploaded")}
            </h2>
          )}
          <input
            onChange={(e) => handleImageChange(e)}
            style={{ display: "none" }}
            accept="image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/tiff, image/webp, image/x-icon"
            type="file"
            id="course-image"
          />
          <label htmlFor="course-image" className="absolute bottom-5 right-5 cursor-pointer">
            <FaRegEdit className="text-[22px] text-end text-[#045140]" />
          </label>
        </div>
      </div>
      <div className="mt-2 text-right col-span-12">
        <RButton
          onClick={() => {
            form.resetFields();
            setElement(null);
            form.setFieldsValue({
              is_active: true,
              translations: [{ title: "" }],
            });
          }}
          className="sm:mr-2 mr-1"
        />
        <SButton loading={isLoading} />
      </div>
    </Form>
  ));

  return (
    <div>
      <>
        {element ? (
          <EditingTitle title={_translate(element?.translations ?? [], "title")} />
        ) : (
          <PartTitle children={"Create category"} />
        )}
      </>
      <hr className="my-3" />
      <MemoizationFieldsFormCategory />
      <Drawer
        title={
          <h3 className="font-bold">{(element?.translations[0]?.title) ? _translate(element?.translations,"title") : t("New")}</h3>
        }
        placement="right"
        onClose={() => setOpenSidebarMenu(false)}
        open={width > 992 ? false : openSidebarMenu}
      >
        <MemoizationFieldsFormCategory />
      </Drawer>
    </div>
  );
};
export default CreateCategory;
