

import { MenuProps } from "antd";
import i18next from "i18next";
import { NavLink } from "react-router-dom";
import { protected_routes } from "routes/routes";
import store from "store";


function IconComponent(IconName: string) {

    const IconComponent = IconName;

    return <IconComponent />
}

type MenuItem = Required<MenuProps>['items'][number];
/**
 * @goal filtering by permissions private routes for sidebar
 * @returns filtered array
 */
export const filterLinksForSidebar = (): MenuItem[] => {

    const auth = store.getState().auth;




    const _permissions = auth.permissions;

    // if (_permissions.length) {

    const sidebar_links: MenuItem[] = [];

    for (let index = 0; index < protected_routes.length; index++) {

        const parent_element = protected_routes[index];

        if ((_permissions.includes(parent_element.config.key) && parent_element.config.isShowLink) || (!parent_element.config.key && !parent_element.component) || (parent_element.config.key === "unlock" && parent_element.config.isShowLink)) {

            let new_sub_element: MenuItem[] = [];

            if (parent_element.submenu.length) {

                parent_element.submenu.forEach((element) => {

                    if ((_permissions.includes(element.config.key) && element.config.isShowLink) || (element.config.key === 'unlock' && element.config.isShowLink)) {
                        new_sub_element.push({
                            key: `${element.config.key}_${element.name}`,
                            icon: IconComponent(element.config.icon),
                            label: <NavLink to={element.path}>{i18next.t(element.name)}</NavLink>,
                        })
                    }
                })
            }

            if (new_sub_element.length === 1) {

                sidebar_links.push(new_sub_element[0]);


            } else {
                if (new_sub_element.length) {
                    sidebar_links.push({
                        key: `${parent_element.config.key}_${parent_element.name}`,
                        icon: IconComponent(parent_element.config.icon),
                        children: new_sub_element,
                        label: <NavLink to={parent_element.path}>{i18next.t(parent_element.name)}</NavLink>,
                    });
                } else {
                    sidebar_links.push({
                        key: `${parent_element.config.key}_${parent_element.name}`,
                        icon: IconComponent(parent_element.config.icon),
                        label: <NavLink to={parent_element.path}>{i18next.t(parent_element.name)}</NavLink>,
                    });
                }

            }
        }

    }

    return sidebar_links;

    // }

    return [];
}