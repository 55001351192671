import React, { Dispatch } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Drawer, Form, Input, message, Select, Switch } from "antd";
import useWindowSize from "custom-hooks/useWindowSize";
import { submitData } from "./topic.request";
import { IProfessionSelect, ITopic } from "./types";
import MultipleLangInputs from "components/MultipleLangInput/multipleLangs";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { RButton, SButton } from "components/Common/CustomButtons";
import PartTitle from "components/Common/PartTitle";
import EditingTitle from "components/Common/EditingTitle";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";
import { useTranslation } from "react-i18next";


type TypePropsCreateTopic = {
  allProfessions: IProfessionSelect[],
  element: ITopic | null;
  setElement: React.Dispatch<React.SetStateAction<ITopic | null>>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: React.Dispatch<React.SetStateAction<boolean>>;
}


const CreateTopic = ({ allProfessions, element, setElement, refetch, openSidebarMenu, setOpenSidebarMenu }: TypePropsCreateTopic) => {
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const { width } = useWindowSize()
  const { _translate } = useLocaleTranslation();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue(element)
    } else {
      form.resetFields()
      form.setFieldsValue({
        is_active: true,
        translations: [{ title: "" }]
      })
    }
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-topic"],
    mutationFn: (newValues) => submitData(element?.id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["topics"], data);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data)
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    mutate(values);
  }

  const MemoizationFieldsFormTopic = React.memo(() => (
    <Form
      layout={"vertical"}
      form={form}
      onFinish={handleSubmit}
      className="custom_form_style"
    >
      <MultipleLangInputs form={form} additionalItems={['desc']} />
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-6">
          <Form.Item
            label={<FormLabelCrud>Sort</FormLabelCrud>}
            name="sort"
            rules={[{ required: false, message: 'Please input sort!' }]}
          >
            <Input type="number" />
          </Form.Item>
        </div>
        <div className="col-span-6">
          <Form.Item
            label={<FormLabelCrud>Slug</FormLabelCrud>}
            name="slug"
            rules={[{ required: false, message: 'Please input slug!' }]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="sm:col-span-6 col-span-10">
          <Form.Item
            label={<FormLabelCrud>Professions</FormLabelCrud>}
            name="profession_id"
            rules={[{ required: true, message: 'Please select region!' }]}
          >
            {
              allProfessions ?
                <Select
                  className='mb-3 w-[100%]'
                  options={allProfessions}
                /> : 'loading...'
            }
          </Form.Item>
        </div>
        <div className="col-span-4 ">
          <Form.Item
            label={<FormLabelCrud>Status</FormLabelCrud>}
            name="is_active"
            rules={[{ required: false, message: 'Please input is status!' }]}
            valuePropName={'checked'}
          >
            <Switch defaultChecked className="col-span-2 bg-[#999] w" />
          </Form.Item>
        </div>
        <div className="sm:col-span-12 col-span-6 text-end">
        <RButton
          onClick={() => {
            form.resetFields();
            setElement(null);
            form.setFieldsValue({
              is_active: true,
              translations: [{ title: "" }]
            })
          }}
          className="sm:mr-2 sm:mb-0 mb-1"
        />
        <SButton loading={isLoading} />
      </div>
      </div>
     
    </Form>
  ));

  return (
    <div>
      <>{element ? <EditingTitle title={_translate(element?.translations ?? [], 'title')} /> : <PartTitle children={"Create topic"} />}</>
      <hr className="my-3" />
      <MemoizationFieldsFormTopic />
      <Drawer
        title={<h3 className=" font-bold">{element?.translations[0]?.title ? element?.translations[0]?.title : t("New")}</h3>}
        placement="right"
        onClose={() => setOpenSidebarMenu(false)}
        open={width > 992 ? false : openSidebarMenu}
      >
        <MemoizationFieldsFormTopic />
      </Drawer>
    </div>
  );
};
export default CreateTopic;
