import { Badge, Button, Checkbox, Input, Pagination, Select, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IUser } from "types/data.models";
import { CLIENT_API } from "service/clieant.request";
import { InformerDRDT } from "types/base.models";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { FiEdit } from "react-icons/fi";
import DeleteIcon from "components/Common/deleteIcon";
import { is_permission } from "utils.functions";
import useGetData from "custom-hooks/useGetData";
import { useEffect, useState } from "react";
import PageTitle from "components/Common/PageTitle";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";
import StyledTableHead from "components/Common/SyledTableHead";
import { BiCheck } from "react-icons/bi";
import { CButton } from "components/Common/CustomButtons";
import { deleteData } from "./users.request";
import useDebounce from "custom-hooks/useDebounce";
import { AiOutlineEye } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const Users = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { _translate } = useLocaleTranslation();

  const [searcVal, setsearcVal] = useState<string>("");
  const [page, setpage] = useState<number>(1);
  const [pageSize, setpageSize] = useState<number>(10);
  const [byStaff, setByStaff] = useState<any>([]);
  const [selctedRolesName, setselctedRolesName] = useState<number | null>(null);
  const debouncedValue = useDebounce<string>(searcVal, 1000);

  const users = useQuery<InformerDRDT<IUser>>({
    queryKey: ["users", page, pageSize, debouncedValue],
    queryFn: () =>
      CLIENT_API.getAll({
        url: "/users/list/",
        _params: {
          page_number: page,
          page_size: pageSize,
          q: debouncedValue,
          role: selctedRolesName,
          is_staff: byStaff,
        },
      }),
    refetchOnWindowFocus: false,
    retry: 0,
  });
  useEffect(() => {
    if (selctedRolesName || selctedRolesName === undefined) {
      users.refetch();
    }
  }, [selctedRolesName]);
  useEffect(() => {
    if (byStaff || byStaff === undefined) {
      users.refetch();
    }
  }, [byStaff]);

  const deleteMutation = useMutation({
    mutationFn: (id: string) => deleteData(id),
    onSuccess: () => {
      users?.refetch();
    },
  });

  const { data: _roles } = useGetData({
    queryKey: ["roles"],
    url: "role/list/",
    options: {
      refetchOnWindowFocus: false,
    },
  });
  const copyToClipboard = async (data: any) => {
    await navigator.clipboard.writeText(data);
    message.success("ID copied");
  };

  return (
    <div className="users bg-white rounded-md p-3 ">
      <div className="grid grid-cols-12">
        <div className="col-span-6">
          <PageTitle title={t("USERS")} />
        </div>
        {is_permission("create_user") ? (
          <div className="col-span-6 text-end">
            <CButton onClick={() => navigate("/user/create")} />
          </div>
        ) : null}
      </div>
      <div className="grid grid-cols-12 gap-4 mb-4 mt-4 custom_form_style">
        <div className="col-span-4">
          <Select
            allowClear
            className="w-[100%]"
            placeholder="Filter by roles"
            value={selctedRolesName}
            onChange={(e) => setselctedRolesName(e)}
            options={_roles?.map((item: any) => ({
              value: item?.title,
              label: item?.title,
            }))}
          />
        </div>
        <div className="col-span-4">
          <Select
            allowClear
            value={byStaff}
            className="w-[100%]"
            placeholder="Filter by staff of the company"
            onChange={(e) => setByStaff(e)}
            options={[{ value: true, label: "Staff" }]}
          />
        </div>
        <div className="col-span-4">
          <Input security="1" onChange={(e) => setsearcVal(e.target.value)} placeholder="Search..." />
        </div>

        <div className="col-span-12 md:overflow-auto sm:overflow-x-scroll">
          <Table id="tech-companies-1" className="table table-striped table-bordered table-hover">
            <Thead>
              <Tr>
                <Th className="w-[50px]">
                  <StyledTableHead>№</StyledTableHead>
                </Th>
                <Th>
                  <StyledTableHead>{t("First name")}</StyledTableHead>
                </Th>
                <Th>
                  <StyledTableHead>{t("Last name")}</StyledTableHead>
                </Th>
                <Th>
                  <StyledTableHead>{t("Phone")}</StyledTableHead>
                </Th>
                <Th>
                  <StyledTableHead>{t("Email")}</StyledTableHead>
                </Th>
                <Th className="text-center max-w-[60px]">
                  <StyledTableHead>{t("Staff")}</StyledTableHead>
                </Th>

                <Th>
                  <StyledTableHead>{t("Status")}</StyledTableHead>
                </Th>
                <Th>
                  <StyledTableHead>{t("Actions")}</StyledTableHead>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {users?.data?.results?.length
                ? users?.data?.results?.map((item, index: number) => (
                    <Tr key={item?.id}>
                      <Td onClick={() => copyToClipboard(item?.id)} className="cursor-pointer">{index + 1}</Td>
                      <Td>{_translate(item?.translations, "first_name")}</Td>
                      <Td>{_translate(item?.translations, "last_name")}</Td>
                      <Td className="text-center">
                        <a href={`tel:${item?.phone}`}>{item?.phone}</a>
                      </Td>
                      <Td className="text-center">
                        <a href={`mailto: ${item?.email}`}>{item?.email}</a>
                      </Td>
                      <Td className="text-center mx-auto">
                        {item?.is_staff ? <BiCheck color="green" size={24} /> : <IoMdClose size={20} color="#ff0000" />}
                      </Td>
                      <Td>
                        {item?.is_active ? (
                          <Badge count={t("Active")} showZero color="#52c41a" />
                        ) : (
                          <Badge count={t("InActive")} showZero color="#faad14" />
                        )}
                      </Td>
                      <Td>
                        <div className="flex" style={{ alignItems: "center" }}>
                          <Button.Group>
                            {is_permission("read_user") ? (
                              <Button type="link" size="small" onClick={() => navigate(`/users/view/${item?.id}`)}>
                                <AiOutlineEye className="text-[22px] text-[#faad14]" />
                              </Button>
                            ) : null}
                            {is_permission("update_user") ? (
                              <Button type="link" size="small" onClick={() => navigate(`/user/update/${item?.id}`)}>
                                <FiEdit className="text-[green] cursor-pointer" size={18} />
                              </Button>
                            ) : null}
                            {is_permission("delete_user") ? (
                              <DeleteIcon confirmFunc={() => deleteMutation.mutate(item?.id)} />
                            ) : null}
                            {/* <Button type='link' size='small' ><FaTrashAlt color='red' size={16} /></Button> */}
                          </Button.Group>
                        </div>
                      </Td>
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>
        </div>
      </div>

      <div className="text-right mt-3">
        <Pagination
          onChange={(page_num, page_size) => {
            setpage(page_num ? page_num : 1);
            setpageSize(page_size);
          }}
          defaultCurrent={page | 1}
          pageSize={pageSize}
          total={users?.data?.total_results}
        />
      </div>
    </div>
  );
};

export default Users;
