
import { Button, Select } from "antd";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import useGetData from "custom-hooks/useGetData";
import { getPromocodeCourseModules, getPromocodeCourses, submitCourseModules, submitCourses } from "./request";
import { IPromocodeCourse } from "./types";
import { InformerDRDT } from "types/base.models";
import { ICourse } from "pages/courses/types";
import { useTranslation } from "react-i18next";

const PromocodeCourse = () => {

    const {t} = useTranslation()
    const queryClient = useQueryClient();
    const {promocode_id} = useParams();

    const [selectedItems, setSelectedItems] = useState<number[]>([]);

    const { data: allCourses } = useGetData<InformerDRDT<ICourse>>({queryKey: ['courses'], url: 'course/list/', options:{ staleTime: Infinity, refetchOnWindowFocus: false }});

    const dataQuery = useQuery<IPromocodeCourse[], AxiosError>({
        queryKey: ['promocode-courses'],
        queryFn: () => getPromocodeCourses(promocode_id),
        retry: 0,
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if(dataQuery?.data?.length){
            setSelectedItems(dataQuery?.data?.map(i => i?.id))
        }
    }, [dataQuery?.data])

    const { mutate, isLoading } = useMutation({
        mutationFn: (newValues: {id_list: number[]}) => submitCourses(promocode_id, newValues),
        onSuccess: async (data) => {
          queryClient.setQueryData(["promocode-courses"], data);
          dataQuery.refetch()
        },
        retry: 0,
      });
          
    function handleSubmit() {
        mutate({id_list: selectedItems})
    }

    return(
        <div>
            <div className="flex justify-between mb-3">
                <h3 className="font-bold text-[18px] opacity-60">{t("Courses")}</h3>
            </div>
            <div className="flex">
                <Select
                    className="w-[100%]"
                    mode="multiple"
                    placeholder="Inserted are removed"
                    value={selectedItems}
                    onChange={setSelectedItems}
                    style={{ width: '100%' }}
                    options={allCourses?.results?.map((item) => ({
                        value: item?.id,
                        label: item?.translations[0]?.title,
                    }))}
                />
                <Button loading={isLoading} onClick={() => handleSubmit()} className="ml-3">{t("Save")}</Button>
            </div>
        </div>
    )
}
export default PromocodeCourse;