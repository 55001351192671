import instance from "config/axios.config";

export async function submitLanguage(id: number | undefined, data: any) {
  const url = id ? `/language/${id}` : `language/create`;

  const formData = new FormData();
  if (data.flag) {
    formData.append("flag", data.flag);
  }
  formData.append("payload", JSON.stringify(data.payload));

  const response = await instance({ url, method: id ? "PUT" : "POST", data: formData });
  return response.data;
}

export async function getData() {
  const response = await instance({ url: `language/list/`, method: "GET" });
  return response.data;
}

export function deleteData(id: any) {
  return instance({ url: `language/${id}`, method: "DELETE" });
}
