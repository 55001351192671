import React, { useEffect, useState } from "react";
import { useMutation, useQueries } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { FiEdit } from "react-icons/fi";
import DeleteIcon from "components/Common/deleteIcon";
import { deleteData, getData, getDiscounts } from "./promocode.request";
import { IProfessionSelect, ITopic } from "./types";
import CreateTopic from "./promocode_form_ui";
import { Badge, Button, Empty, Select, message } from "antd";
import TableLoader from "components/loaders/table_loader";
import { IProfession } from "pages/professions/types";
import PageTitle from "components/Common/PageTitle";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { is_permission } from "utils.functions";
import { useTranslation } from "react-i18next";

const Promocodes = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<ITopic | null>(null);
  const [openSidebarMenu, setOpenSidebarMenu] = useState<boolean>(false);
  const [professionsArr, setprofessionsArr] = useState<IProfessionSelect[]>([]);

  const deletePost = useMutation({
    mutationFn: deleteData,
  });

  useEffect(() => {
    if (!deletePost.isLoading) {
      promocodesQuery.refetch();
    }
  }, [deletePost.isLoading]);

  const [discountQuery, promocodesQuery] = useQueries({
    queries: [
      {
        queryKey: ["discounts"],
        queryFn: () => getDiscounts(),
        retry: 0,
        staleTime: Infinity,
      },
      {
        queryKey: ["promocodes"],
        queryFn: () => getData(),
        retry: 0,
        staleTime: Infinity,
      },
    ],
  });

  useEffect(() => {
    if (discountQuery.data) {
      setprofessionsArr(
        discountQuery.data?.map((i: IProfession) => {
          return { value: i?.id, label: i?.translations[0]?.title };
        })
      );
    }
  }, [discountQuery.data]);

  if (promocodesQuery.error) return "An error has occurred: " + promocodesQuery.error;
  const copyToClipboard = async (data: any) => {
    await navigator.clipboard.writeText(data);
    message.success("ID copied");
  };
  return (
    <div>
      <div className="grid grid-cols-12 gap-4">
        <div className="main-card lg:col-span-8 col-span-12">
          <div className="flex mb-3 justify-between">
            <PageTitle title={"Promocodes"} />
            {is_permission("create_promocode") ? (
              <Button
                onClick={() => {
                  setOpenSidebarMenu(true);
                  setSelected(null);
                }}
                type="default"
                className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763] lg:hidden"
              >
                + {t("Create")}
              </Button>
            ) : null}
          </div>
          <Table className="table-striped table-hover">
            <Thead>
              <Tr>
                <Th className="w-[50px]">№</Th>
                <Th>{t("Title")}</Th>
                <Th>{t("Start date")}</Th>
                <Th>{t("End date")}</Th>
                <Th>{t("Status")}</Th>
                <Th>{t("Archive")}</Th>
                <Th>{t("Actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!promocodesQuery.isLoading
                ? promocodesQuery.data?.length > 0
                  ? promocodesQuery.data?.map((item: ITopic, index: number) => (
                    <Tr key={item.id}>
                      <Td>{index + 1}</Td>
                      <Td>{item?.code}</Td>
                      <Td>{item?.start}</Td>
                      <Td>{item?.stop}</Td>
                      <Td>
                        {item?.is_active ? (
                          <Badge count={t("Active")} showZero color="#52c41a" />
                        ) : (
                          <Badge count={t("InActive")} showZero color="#faad14" />
                        )}
                      </Td>
                      <Td>
                        {item?.is_archive ? (
                          <Badge count={t("Archive")} showZero color="#faad14" />
                        ) : (
                          <Badge count={t("Not Archive")} showZero color="#52c41a" />
                        )}
                      </Td>
                      <Td>
                        <div className="flex" style={{ alignItems: "center" }}>
                          {is_permission("read_promocode") ? (
                            <Link to={`/promocode/view/${item?.id}`}>
                              <AiOutlineEye className=" mr-2 text-[#faad14] text-[22px] cursor-pointer" />
                            </Link>
                          ) : null}
                          {is_permission("update_promocode") ? (
                            <FiEdit
                              className="mx-2 text-[green] text-[18px] cursor-pointer"
                              onClick={() => {
                                setSelected(item);
                                setOpenSidebarMenu(true);
                              }}
                            />
                          ) : null}
                          {is_permission("delete_promocode") ? <DeleteIcon confirmFunc={() => deletePost.mutate(item?.id)} /> : null}
                        </div>
                      </Td>
                    </Tr>
                  ))
                  : null
                : null}
            </Tbody>
          </Table>
          {promocodesQuery.data?.length === 0 ? <Empty className="w-[100%]" /> : null}
          <TableLoader isLoading={promocodesQuery.isLoading} />
        </div>
        {(is_permission("create_promocode") && !selected) || (is_permission("update_promocode") && selected) ? (
          <div className="main-card col-span-4 lg:block hidden">
            <CreateTopic
              allProfessions={professionsArr}
              element={selected}
              setElement={setSelected}
              refetch={promocodesQuery.refetch}
              openSidebarMenu={openSidebarMenu}
              setOpenSidebarMenu={setOpenSidebarMenu}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Promocodes;
