import instance from "config/axios.config";

export async function submitData(id: number | undefined, data: any) {
  const url = id ? `/topjob/profession/${id}` : `topjob/profession/create`;
  const response = await instance({
    url,
    method: id ? "PUT" : "POST",
    data: data?.payload,
  });
  return response.data;
}

export async function getData(page: number, pageSize: number, sphere_id: string | undefined) {
  const response = await instance({
    url: `topjob/profession/list/`,
    method: "GET",
    params: { page_number: page, page_size: pageSize, sphere_id },
  });
  return response.data;
}


export function deleteData(id: any) {
  return instance({ url: `topjob/profession/${id}`, method: "DELETE" });
}
