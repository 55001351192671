import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const Statistic = () => {
  const {t} = useTranslation()

  const [chartOptions] = useState({
    title: {
      text: t("2023 sales statistics"),
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    },
  });
  const [chartData] = useState<Array<{name: string, data: Array<number>}>>([
    {
      name: "Sotuv",
      data: [30, 40, 25, 50, 49, 21, 70, 51, 30, 40, 25, 50],
    },
  ]);

  return (
    <div className="mt-5 overflow-scroll">
      <div id="chart" className="w-full max-w-full">
        <ReactApexChart options={chartOptions} series={chartData} type="bar" height={350} />
      </div>
    </div>
  );
};

export default Statistic;
