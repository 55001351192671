import instance from "config/axios.config";
import { ICategory } from "./types";

export async function submitData(id: number | undefined, data: any) {
  const url = id ? `/category/${id}` : `category/create`;

  const formData = new FormData();
  formData.append("logo", data.logo);
  formData.append("payload", JSON.stringify(data.payload));

  const response = await instance({ url, method: id ? "PUT" : "POST", data: formData });
  return response.data;
}

export async function getData(topic_id: number | undefined | null, page:number, pageSize:number) {
  const response = await instance({ url: `/category/list/`, method: "GET", params: { topic_id, page_number: page, page_size: pageSize } });
  return response.data;
}

export function deleteData(id: any) {
  return instance({ url: `category/${id}`, method: "DELETE" });
}

export async function getTopics() {
  const response = await instance({ url: `topic/list/`, method: "GET" });
  return response.data;
}
