import { FormInstance, message } from "antd"
import { logOut, signOut } from "pages/login/service.request"
import store from "store"
import { IAuth } from "store/auth"
import { IServerResponseError } from "types/base.models"



// filter function of ant design select. 
export const cf_filterOption = (input: string, option: any) => {

    return String(option?.children).toLowerCase().indexOf(String(input).toLowerCase()) >= 0
}


// destructuring server errors data for ant design form.
export const validationErrors = (form: FormInstance, errors: IServerResponseError) => {

    if (Array.isArray(errors.detail) && errors.detail.length) {

        let _errors: Array<{ name: string, errors: string[] }> = []

        errors.detail.forEach((element) => {
            _errors.push({
                name: element.loc[1],
                errors: [element.msg]
            })
        })
        form.setFields(_errors)
    } else if (!Array.isArray(errors.detail)) {
        message.error(errors.detail)
    }

}


export const is_permission = (key: string) => {
    const auth: IAuth = store.getState().auth as IAuth;
    if (Array.isArray(auth?.permissions) && auth.permissions.length > 0) {
        return auth.permissions?.includes(key);
    } else {
        signOut();
        // store.dispatch(signOut)
        sessionStorage.removeItem("access_token");
    }
}

export const numberFormatter = (num: number) => {
    let formattedNumber = num?.toString()?.split('').reverse().join('').replace(/([0-9]{3})/g, "$1 ").split('').reverse().join('');
    return formattedNumber
}
