
import instance from "config/axios.config";

export async function submitCourses(promocode_id: string | undefined, data: {id_list: number[]}) {
    const response = await instance({ url : `promocode/${promocode_id}/effect-update/courses`, method: "POST", data });
    return response.data
}

export async function getPromocodeCourses(promocode_id: string | undefined) {
    const response = await instance({ url: `promocode/${promocode_id}/effect/courses`, method: 'POST'});
    return response.data;
}

export async function submitCourseModules(promocode_id: string | undefined, data: any) {
    const response = await instance({ url : `promocode/${promocode_id}/effect-update/course_modules`, method: "POST", data });
    return response.data
}

export async function getPromocodeCourseModules(promocode_id: string | undefined) {
    const response = await instance({ url: `promocode/${promocode_id}/effect/course_modules`, method: 'POST'});
    return response.data;
}

export async function submitCourseLessons(promocode_id: string | undefined, data: any) {
    const response = await instance({ url : `promocode/${promocode_id}/effect-update/course_module_lessons`, method: "POST", data });
    return response.data
}

export async function getPromocodeCourseLessons(promocode_id: string | undefined) {
    const response = await instance({ url: `promocode/${promocode_id}/effect/course_module_lessons`, method: 'POST'});
    return response.data;
}