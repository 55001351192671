import React, { useEffect, useState } from "react";
import { useMutation, useQueries } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { FiEdit } from "react-icons/fi";
import DeleteIcon from "components/Common/deleteIcon";
import { deleteData, getData, getProfessions } from "./topic.request";
import { IProfessionSelect, ITopic } from "./types";
import CreateTopic from "./topic_form_ui";
import { Badge, Button, Empty, Select } from "antd";
import TableLoader from "components/loaders/table_loader";
import { IProfession } from "pages/professions/types";
import PageTitle from "components/Common/PageTitle";
import { is_permission } from "utils.functions";
import { useTranslation } from "react-i18next";

const Topics = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<ITopic | null>(null);
  const [openSidebarMenu, setOpenSidebarMenu] = useState<boolean>(false);
  const [professionsArr, setprofessionsArr] = useState<IProfessionSelect[]>([]);
  const [selctedProfessionId, setselctedProfessionId] = useState<number | null>(null);

  const deletePost = useMutation({
    mutationFn: deleteData,
  });

  useEffect(() => {
    if (!deletePost.isLoading) {
      topicsQuery.refetch();
    }
  }, [deletePost.isLoading]);

  const [professionQuery, topicsQuery] = useQueries({
    queries: [
      {
        queryKey: ["professions"],
        queryFn: () => getProfessions(),
        retry: 0,
        staleTime: Infinity,
      },
      {
        queryKey: ["topics"],
        queryFn: () => getData(selctedProfessionId),
        retry: 0,
        staleTime: Infinity,
      },
    ],
  });

  useEffect(() => {
    if (selctedProfessionId || selctedProfessionId === undefined) {
      topicsQuery.refetch();
    }
  }, [selctedProfessionId]);

  useEffect(() => {
    if (professionQuery.data) {
      setprofessionsArr(
        professionQuery.data?.map((i: IProfession) => {
          return { value: i?.id, label: i?.translations[0]?.title };
        })
      );
    }
  }, [professionQuery.data]);

  if (topicsQuery.error) return "An error has occurred: " + topicsQuery.error;

  return (
    <div>
      <div className="grid grid-cols-12 gap-4">
        <div className="main-card lg:col-span-8 col-span-12">
          <div className="flex mb-3 justify-between">
            <PageTitle title={"Topics"} />
            {is_permission("create_topic") ? (
              <Button
                onClick={() => {
                  setOpenSidebarMenu(true);
                  setSelected(null);
                }}
                type="default"
                className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763] lg:hidden"
              >
                + {t("Create")}
              </Button>
            ) : null}
          </div>
          {t("By profession")}:
          <Select
            allowClear
            className="mb-3 ml-3"
            value={selctedProfessionId}
            style={{ width: 150 }}
            onChange={(e) => setselctedProfessionId(e)}
            options={professionsArr}
          />
          <Table className="table-striped table-hover">
            <Thead>
              <Tr>
                <Th className="w-[50px]">№</Th>
                <Th className="w-[50px] text-center px-[0!important]">{t("Id")}</Th>
                <Th>{t("Title")}</Th>
                <Th>{t("Slug")}</Th>
                <Th>{t("Status")}</Th>
                <Th>{t("Actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!topicsQuery.isLoading
                ? topicsQuery.data?.length > 0
                  ? topicsQuery.data?.map((item: ITopic, index: number) => (
                      <Tr key={item.id}>
                        <Td>{index + 1}</Td>
                        <Td className="w-[50px] text-center px-[0!important]">{item?.id}</Td>
                        <Td>{item?.translations[0]?.title}</Td>
                        <Td>{item?.slug}</Td>
                        <Td>
                          {item?.is_active ? (
                            <Badge count={t("Active")} showZero color="#52c41a" />
                          ) : (
                            <Badge count={t("InActive")} showZero color="#faad14" />
                          )}
                        </Td>
                        <Td>
                          <div className="flex" style={{ alignItems: "center" }}>
                            {is_permission("update_topic") ? (
                              <FiEdit
                                className="mx-2 text-[green] text-[18px] cursor-pointer"
                                onClick={() => {
                                  setSelected(item);
                                  setOpenSidebarMenu(true);
                                }}
                              />
                            ) : null}
                            {is_permission("delete_topic") ? (
                              <DeleteIcon confirmFunc={() => deletePost.mutate(item?.id)} />
                            ) : null}
                          </div>
                        </Td>
                      </Tr>
                    ))
                  : null
                : null}
            </Tbody>
          </Table>
          {topicsQuery.data?.length === 0 ? <Empty className="w-[100%]" /> : null}
          <TableLoader isLoading={topicsQuery.isLoading} />
        </div>
        {(is_permission("create_topic") && !selected) || (is_permission("update_topic") && selected) ? (
          <div className="main-card col-span-4 lg:block hidden">
            <CreateTopic
              allProfessions={professionsArr}
              element={selected}
              setElement={setSelected}
              refetch={topicsQuery.refetch}
              openSidebarMenu={openSidebarMenu}
              setOpenSidebarMenu={setOpenSidebarMenu}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Topics;
