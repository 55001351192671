import { Button, Form } from "antd";
import UserFormUi from "./user_form_ui";
import { useMutation } from "@tanstack/react-query";
import { submitUser } from "./users.request";
import { AxiosError } from "axios";
import ToBack from "components/Common/ToBack";
import { SButton } from "components/Common/CustomButtons";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import { validationErrors } from "utils.functions";
import { useTranslation } from "react-i18next";

dayjs.extend(weekday)
dayjs.extend(localeData)

const UserCreate = () => {

    const [form] = Form.useForm();
    const {t} = useTranslation()

    const user_create = useMutation({
        mutationKey: ['user-create'],
        mutationFn: (data: Record<string, any>) => submitUser(data),
        onSuccess: (data) => {
            console.log("saved data -> ", data)
        },
        onError: (error: AxiosError<any>) => {
            validationErrors(form, error?.response?.data)
        }
    })
    function handleSubmit(values: Record<string, any>) {
        values.birthday = dayjs(values.birthday).format('YYYY-MM-DD')
        if(values.phone == '' ){
            delete values.phone
        }
        if(values.email == '' ){
            delete values.email
        }
        user_create.mutate(values);
    }

    return (
        <div className="bg-white rounded-md p-3">
            <ToBack title="Create user" />
            <hr className="mt-2 mb-5" />
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    gender: 'male',
                    is_active: true,
                    is_staff: false,
                    is_verified: false,
                    is_superuser: false,
                }}
                onFinish={handleSubmit}
                className="custom_form_style"
            >
                <UserFormUi form={form} is_action="create" />
                <div className="text-end">
                    <Button
                        onClick={() => {
                            form.resetFields();
                            form.setFieldsValue({
                                is_active: true,
                                translations: [{ title: "" }]
                            })
                        }}
                        type="default"
                        className="bg-[#FF0000] text-white sm:text-[14px] text-xs rounded-md text-center sm:w-[120px] w-[89px] py-[8px] active:bg-[#D50000] disabled:bg-[#F1F5F9] disabled:text-[#8A8C8E] disabled:cursor-not-allowed mr-2"
                    >
                        {t("Reset")}
                    </Button>
                    <SButton loading={user_create.isLoading}/>
                </div>
            </Form>
        </div>
    )
}



export default UserCreate;