import instance from "config/axios.config";

export async function submitData(id: number | undefined, data: any) {

    const url = id ? `/course-module/${id}` : `course-module/create`;
    const response = await instance({ url, method: id ? 'PUT' : "POST", data });
    return response.data

}

export async function getCourseModules(course_id:string | undefined | null) {
    if(course_id){
        const response = await instance({ url: `/course-module/list/by-course/${course_id}`, method: 'GET', params:{order_by: 'sort', desc: false} });
        return response.data;
    }else{
        return {}
    }
}

export async function getDataById(id: number) {

    const response = await instance({ url: `/course-module/${id}`, method: 'GET' });
    return response.data;
}


export function deleteData(id: any) {
    return instance({ url: `course-module/${id}`, method: 'DELETE' })
}
