import React, { Dispatch } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Drawer, Form, Input, message, Switch } from "antd";
import useWindowSize from "custom-hooks/useWindowSize";
import { submitData } from "./region.request";
import { IRegion } from "./types";
import MultipleLangInputs from "components/MultipleLangInput/multipleLangs";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import PartTitle from "components/Common/PartTitle";
import { useTranslation } from "react-i18next";

const CreateRegion = ({
  element,
  setElement,
  refetch,
  openSidebarMenu,
  setOpenSidebarMenu
}: {
  element: IRegion | null;
  setElement: Dispatch<IRegion | null>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: Dispatch<boolean>;
}) => {

  const {t} = useTranslation()
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { width } = useWindowSize()

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue(element)
    } else {
      form.resetFields()
      form.setFieldsValue({
        is_active: true,
        translations: [{ title: "" }]
      })
    }
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-region"],
    mutationFn: (newValues) => submitData(element?.id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["regions"], data);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data)
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    mutate(values);
  }

  const formFields = (
    <Form layout={"vertical"} form={form} onFinish={handleSubmit} className="custom_form_style">
      <MultipleLangInputs form={form} />
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-6">
          <Form.Item
            label={t("Code")}
            name="code"
            rules={[{ required: true, message: 'Please input code!' }]}
          >
            <Input placeholder="Code" type="number" />
          </Form.Item>
        </div>
        <div className="col-span-6">
          <Form.Item
            label={t("Sort")}
            name="sort"
            rules={[{ required: false, message: 'Please input sort!' }]}
          >
            <Input placeholder="Sort" type="number" />
          </Form.Item>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-6">
          <Form.Item
            name="is_active"
            rules={[{ required: false, message: 'Please input is status!' }]}
            valuePropName={'checked'}
          >
            <Switch defaultChecked className="bg-[#999]" checkedChildren={t("Active")} unCheckedChildren={t("Inactive")} size="default" />
          </Form.Item>
        </div>
        <div className="col-span-6 text-right gap-2">
          <Button
            onClick={() => {
              form.resetFields();
              setElement(null);
              form.setFieldsValue({
                is_active: true,
                translations: [{ title: "" }]
                
              })
            }}
            type="default"
            className="bg-[red] text-white hover:text-white hover:bg-[#FF2A2A] xxl:mr-2 xl:mb-0  mb-1"
          >
            {t("Reset")}
          </Button>
          <Button
            loading={isLoading}
            type="default"
            className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]"
            htmlType="submit"
          >
            {t("Save")}
          </Button>
        </div>
      </div>
    </Form>
  );

  return (
    <div>
      <PartTitle>Create region</PartTitle>
      <hr className="my-3" />
      {formFields}
      <Drawer
        title={<h3 className=" font-bold">{element?.translations[0]?.title ? element?.translations[0]?.title : t("New")}</h3>}
        placement="right"
        onClose={() => setOpenSidebarMenu(false)}
        open={width > 992 ? false : openSidebarMenu}
      >
        {formFields}
      </Drawer>
    </div>
  );
};
export default CreateRegion;
