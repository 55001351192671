import instance from "config/axios.config";
import { createBrowserHistory } from "history";
import store from "store";
import { AUTH_ACTIONS, signIn } from "store/auth";

export const signOut = async () => {
  const response = await instance({ url: "/auth/jwt/logout", method: "GET" });

  if (response?.status === 200) {
    store.dispatch(AUTH_ACTIONS.signOut());
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    // navigate('/signin')
    // createBrowserHistory().push('/')
    window.history.pushState({ someprop: "somevalue" }, "", "/signin");
  }
};

export const logOut = () => {
  store.dispatch(AUTH_ACTIONS.signOut());
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  createBrowserHistory().push("/");
};

export const signMe = () => {};

export const refreshToken = async () => {
  try {
    const refresh_token = localStorage.getItem("refresh_token");

    if (refresh_token) {
      const response = await instance({ url: "/auth/jwt/refresh", method: "POST", data: { refresh_token } });

      if (response.status === 200) {
        localStorage.setItem("access_token", response.data.access_token);
        store.dispatch(signIn({}));
      }
    } else {
      logOut();
    }
  } catch (error) {
    window.location.href = "/signin";
  }
};
