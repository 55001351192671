import { useMutation, useQuery } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { FiEdit } from "react-icons/fi";
import DeleteIcon from "components/Common/deleteIcon";
import { deleteData, getData } from "./course.request";
import { ICourse } from "./types";
import {Empty, Pagination } from "antd";
import TableLoader from "components/loaders/table_loader";
import { Link, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { AiOutlineEye } from "react-icons/ai";
import { CButton } from "components/Common/CustomButtons";
import PageTitle from "components/Common/PageTitle";
import { InformerDRDT } from "types/base.models";
import { is_permission } from "utils.functions";
import { BiCheck } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const Courses = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const deleteMutation = useMutation({
    mutationFn: (id: number) => deleteData(id),
    onSuccess: () => {
      refetch();
    },
  });
  const [page, setpage] = useState<number>(1);
  const [pageSize, setpageSize] = useState<number>(10);

  const { data, isLoading, error, refetch } = useQuery<
    InformerDRDT<ICourse>,
    AxiosError
  >({
    queryKey: ["courses", page, pageSize],
    queryFn: () => getData(page,pageSize),
    retry: 0,
  });

  if (error) return "An error has occurred: " + error.message;

  return (
    <div className="main-card">
      <div className="grid grid-cols-12 items-center">
        <div className="col-span-6">
          <PageTitle title="Courses" />
        </div>
        {is_permission("create_course") ? (
          <div className="col-span-6 text-end">
            <CButton onClick={() => navigate("/courses/create")} />
          </div>
        ) : null}
        <div className="col-span-12 md:overflow-auto overflow-x-auto">
          <Table className="table-striped table-hover mt-3">
            <Thead>
              <Tr>
                <Th className="w-[50px]">№</Th>
                <Th className="w-[50px] text-center px-[0!important]">{t("Id")}</Th>
                <Th>{t("Title")}</Th>
                <Th>{t("Level")}</Th>
                <Th>{t("Price")}</Th>
                <Th>{t("Security")}</Th>
                <Th>{t("Status")}</Th>
                <Th>{t("Verified")}</Th>
                <Th>{t("Actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading
                ? data?.results?.length > 0
                  ? data?.results?.map((item, index: number) => (
                      <Tr key={item?.id}>
                        <Td>{(page-1)* pageSize + index + 1}</Td>
                        <Td className="w-[50px] text-center px-[0!important]">{item?.id}</Td>
                        <Td>{item?.translations[0]?.title}</Td>
                        <Td>{item?.level}</Td>
                        <Td>{item?.price}</Td>
                        <Td>{item?.status}</Td>
                        <Td>
                          {item?.is_active ? (
                            <BiCheck className="text-[24px] text-green-700" />
                          ) : (
                            <IoMdClose className="ml-2 text-[22px] text-rose-600" />
                          )}
                        </Td>
                        <Td>
                          {item?.is_verified ? (
                            <BiCheck className="text-[24px] text-green-700" />
                          ) : (
                            <IoMdClose className="ml-2 text-[22px] text-rose-600" />
                          )}
                        </Td>
                        <Td>
                          <div
                            className="flex"
                            style={{ alignItems: "center" }}
                          >
                            {is_permission("read_course") ? (
                              <Link to={`/courses/view/${item?.id}`}>
                                <AiOutlineEye className=" mr-2 text-[#faad14] text-[22px] cursor-pointer" />
                              </Link>
                            ) : null}
                            {is_permission("update_course") ? (
                              <Link to={`/courses/update/${item?.id}`}>
                                <FiEdit className="mx-2 text-[green] text-[18px] cursor-pointer" />
                              </Link>
                            ) : null}
                            {is_permission("delete_course") ? (
                              <DeleteIcon
                                confirmFunc={() =>
                                  deleteMutation.mutate(item?.id)
                                }
                              />
                            ) : null}
                          </div>
                        </Td>
                      </Tr>
                    ))
                  : null
                : null}
            </Tbody>
          </Table>
        </div>
      </div>

      {data?.results?.length === 0 ? <Empty className="w-[100%]" /> : null}
      <TableLoader isLoading={isLoading} />
      <div className="text-right mt-3">
        <Pagination
          onChange={(page_num, page_size) => {
            setpage(page_num);
            setpageSize(page_size);
          }}
          defaultCurrent={page | 1}
          pageSize={pageSize}
          total={data?.total_results}
        />
      </div>
    </div>
  );
};
export default Courses;
