import { RiDeleteBin6Line } from 'react-icons/ri'
import { Popconfirm } from 'antd';

const DeleteIcon = ({confirmFunc}:{confirmFunc:any}) => {

    return (
        <Popconfirm
            placement='topLeft'
            title="Delete the task"
            description="Are you sure to delete this task?"
            okText={'Ok'}
            okType='default'
            cancelText={'Cancel'}
            onConfirm={confirmFunc}
        >
            <RiDeleteBin6Line className='text-[red] mx-2 text-[19px] cursor-pointer' />
        </Popconfirm>
    )
}
export default DeleteIcon;