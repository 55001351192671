import instance from "config/axios.config";

export async function submitData(id: number | undefined, data: any) {
  const url = id ? `/answer/${id}` : `answer/create`;
  const response = await instance({
    url,
    method: id ? "PUT" : "POST",
    data: data?.payload,
  });
  return response.data;
}

export async function getData(page: number, pageSize: number, question_id: string | undefined) {
  const response = await instance({
    url: `answer/${question_id}/list/`,
    method: "GET",
    params: { page_number: page, page_size: pageSize },
  });
  return response.data;
}
export async function getQuestion(question_id: string | undefined) {
  const response = await instance({
    url: `question/${question_id}`,
    method: "GET",
  });
  return response.data;
}

export function deleteData(id: any) {
  return instance({ url: `answer/${id}`, method: "DELETE" });
}
