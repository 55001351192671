import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Form } from "antd";
import { submitData } from "./organization.request";
import ToBack from "components/Common/ToBack";
import { useEffect } from "react";
import { IOrganization } from "./types";
import FormItems from "./organization_form_ui";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import { useTranslation } from "react-i18next";

const CreateOrganization = () => {

  const {t} = useTranslation()
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-organization"],
    mutationFn: (newValues: IOrganization) => submitData(undefined, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["organizations"], data);
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data)
    },
    retry: 0,
  });

  useEffect(() => {
    form.setFieldsValue({
        is_active: true,
        is_verified: true,
        translations:[{title:"", desc:""}]
      })
  }, [])

  function handleSubmit(values: IOrganization) {
    mutate(values);
  }
  
  function saveAndAnother() {
    form.submit()
    setTimeout(() => {
        form.resetFields();
        form.setFieldsValue({
            is_active: true,
            is_archive: false,
            translations:[{title:"", desc:""}]
        })
    }, 300);
}
    function saveAndEdit() {
        form.submit()
    }

  return (
    <div className="main-card">
        <ToBack title={t("Organization create")} />
        <hr className="my-3" />
        <FormItems form={form} handleSubmit={handleSubmit} />
        <div className="md:flex justify-end mt-2">
            <Button onClick={saveAndAnother} loading={isLoading} type="default" className='md:w-auto w-[100%] bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]' htmlType="submit">{t("Save and add another")}</Button>
            <Button onClick={saveAndEdit} loading={isLoading} type="default" className='md:w-auto w-[100%] bg-[#045140] text-white hover:text-white hover:bg-[#1a7763] md:ml-3 md:my-0 my-3' htmlType="submit">{t("Save and continue editing")}</Button>

        </div>
    </div>
  );
};

export default CreateOrganization;