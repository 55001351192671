
import { Button, Select } from "antd";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import useGetData from "custom-hooks/useGetData";
import { getPromocodeCourseModules, submitCourseModules } from "./request";
import { InformerDRDT } from "types/base.models";
import { ICourse } from "pages/courses/types";
import { IPromocodeCourse } from "./types";
import { ICourseModules } from "pages/course_modules/types";
import { useTranslation } from "react-i18next";

const PromocodeCourseModule = () => {

    const {t}= useTranslation()
    const queryClient = useQueryClient();
    const {promocode_id} = useParams();

    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    
    const { data: allCourses } = useGetData<InformerDRDT<ICourse>>({queryKey: ['courses'], url: 'course/list/', options:{ staleTime: Infinity, refetchOnWindowFocus: false }});
    const [course_id, setcourse_id] = useState<number | undefined>(allCourses?.results[0]?.id);
    
    const moduleQuery = useGetData<ICourseModules[]>({queryKey: ['course-modules'], url: `course-module/list/by-course/${course_id}`, options:{ refetchOnWindowFocus: false, enabled: !!course_id }});
    

    const courseQuery = useQuery<IPromocodeCourse[], AxiosError>({
        queryKey: ['promocode-modules'],
        queryFn: () => getPromocodeCourseModules(promocode_id),
        retry: 0,
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        moduleQuery.refetch()
        console.log("course_id", course_id);
        
    }, [course_id])

    useEffect(() => {
        setcourse_id(allCourses?.results[0]?.id)
    }, [allCourses])

    useEffect(() => {
        if(courseQuery?.data?.length){
            setSelectedItems(courseQuery?.data?.map(i => i?.id))
        }

    }, [courseQuery?.data])

    const { mutate, isLoading } = useMutation({
        mutationFn: (newValues: {id_list: number[]}) => submitCourseModules(promocode_id, newValues),
        onSuccess: async (data) => {
          queryClient.setQueryData(["promocode-modules"], data);
          courseQuery.refetch()
        },
        retry: 0,
      });
          
    function handleSubmit() {
        mutate({id_list: selectedItems})
    }

    return(
        <div>
            <div className="flex justify-between mb-3">
                <h3 className="font-bold text-[18px] opacity-60">{t("Course modules")}</h3>
            </div>
            <div className="flex">
                <Select
                    className="w-[100%]"
                    placeholder="Inserted are removed"
                    value={course_id}
                    onChange={setcourse_id}
                    style={{ width: '100%' }}
                    options={allCourses?.results?.map((item) => ({
                        value: item?.id,
                        label: item?.translations[0]?.title,
                    }))}
                />
                <Select
                    className="w-[100%] mx-3"
                    mode="multiple"
                    placeholder="Inserted are removed"
                    value={selectedItems}
                    onChange={setSelectedItems}
                    style={{ width: '100%' }}
                    options={moduleQuery?.data?.map((item) => ({
                        value: item?.id,
                        label: item?.translations[0]?.title,
                    }))}
                />
                <Button loading={isLoading} onClick={() => handleSubmit()}>{t("Save")}</Button>
            </div>
        </div>
    )
}
export default PromocodeCourseModule;