import instance from "config/axios.config";

export async function submitData(id: number | undefined, data: any) {

    const url = id ? `/profession/${id}/` : `profession/create`;
    const response = await instance({ url, method: id ? 'PUT' : "POST", data });
    return response.data

}

export async function getData() {

    const response = await instance({ url: `profession/list/`, method: 'GET' });
    return response.data;
}


export function deleteData(id: any) {
    return instance({ url: `profession/${id}`, method: 'DELETE' })
}
