import useLocaleTranslation from "custom-hooks/useLocaleTranslation";
import { useParams } from "react-router-dom";
import useGetData from "custom-hooks/useGetData";

const UserAttachCourses = () => {
  const { _translate } = useLocaleTranslation();

  const { user_id } = useParams();

  const { data: courses } = useGetData({
    queryKey: ["user-courses", user_id],
    url: `/users/${user_id}/courses/list/`,
  });
  

  return (
    <div className="h-[50vh] overflow-y-scroll">
      {courses?.map((item: any) => (
        <div className="rounded-md bg-[#F8FAFC] text-[#045140] hover:cursor-pointer hover:bg-slate-200  py-3 px-3 my-3">
          <h5 className="uppercase font-medium">
            {_translate(item?.translations, "title")}
          </h5>
        </div>
      ))}
    </div>
  );
};

export default UserAttachCourses;
