import { Tabs, TabsProps } from "antd";
import ToBack from "components/Common/ToBack";
import PromocodeCourse from "./courses";
import PromocodeCourseModule from "./course_module";
import { useTranslation } from "react-i18next";
import useUrlQueryParams from "custom-hooks/useUrlQueryParams";
import GlobalBack from "components/Common/GlobalBack";

const PromocodeView = ({ id }: { id: string | undefined }) => {
  const { t } = useTranslation();

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t(`Permission to course`),
      children: <PromocodeCourse />,
    },
    {
      key: "2",
      label: t(`Permission to course module`),
      children: <PromocodeCourseModule />,
    },
    // {
    //     key: '3',
    //     label: `Permission to course module lesson`,
    //     children: <PromocodeCourseLessons />,
    //   },
  ];
  const { value, writeToUrl } = useUrlQueryParams({});

  const onChange = (key: string) => {
    writeToUrl({ name: "tab_id", value: key });
  };
  return (
    <>
      <GlobalBack title={t("Back")} href="/promocodes" />
      <div className="col-span-12 main-card mt-4">
        <Tabs activeKey={String(value.filter.tab_id ?? 1)} items={items} onChange={onChange} />
      </div>
    </>
  );
};
export default PromocodeView;
