import instance from "config/axios.config";
import { ILesson, IMediaCreate } from "./types";
import { AxiosRequestConfig } from "axios";
import { FormInstance } from "antd";
import { FILE_URL } from "config/utils";

export async function submitData(id: number | undefined, data: ILesson) {
    const url = id ? `/course-lesson/${id}` : `course-lesson/create`;
    const response = await instance({ url, method: id ? 'PUT' : "POST", data });
    return response.data
}

export async function getLessons(module_id: string | undefined) {
    const response = await instance({ url: `/course-lesson/list/by-course-module/${module_id}`, method: 'GET', params:{order_by: 'sort', desc: false} });
    return response.data;
}

export async function getDataById(id: string | undefined) {
    const response = await instance({ url: `/course-lesson/${id}`, method: 'GET' });
    return response.data;
}
export async function getBlocksByCourseLessonId(id: string | undefined) {
    const response = await instance({ url: `/course-lesson/${id}/blocks`, method: 'GET' });
    return response.data;
}


export function deleteData(id: number) {
    return instance({ url: `course-lesson/${id}`, method: 'DELETE' })
}

export function deleteAtttachmentFile(id: string) {
    return instance({ url: `course-lesson/remove/${id}`, method: 'POST' })
}

export async function saveAttachmentFile(id: string | undefined, data: any) {
    const formdata = new FormData()
    for (const file of data) {
        if(file?.originFileObj){
            formdata.append("attachments", file?.originFileObj)
        }
    }
    const response = await instance({ url: `/course-lesson/${id}/add/attachment-files`, method: "POST", data: formdata });
    return response.data
}




const convertBase64ToBinaryFile = async (_base64: string) => {

    if (!_base64.startsWith("data:image/")) return null;

    const base64File = _base64.split('base64,')[1];
    
    const binaryString = window.atob(base64File);

    const fileData = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {

        fileData[i] = binaryString.charCodeAt(i);

    }

    const blobData = new Blob([fileData], { type: 'image/png' });

    const data = new FormData();

    data.append("media_file", blobData);

    return data;

}


const convertBase64ToLink = async (translations: ILesson['translations']) => new Promise(async (resolve, reject) => {

    if (!translations.length) reject(new Error("Translations not found!"));

    let fullContentString = translations.map(e => e.content).join('AHRORUSHKA');

    const htmlElement = document.createElement('div');

    htmlElement.innerHTML = fullContentString;

    const htmlImgTags = htmlElement.getElementsByTagName('img');

    const htmlImgTagsArray = Array.from(htmlImgTags).filter(e => e.src.startsWith('data:image/'));

    if (!htmlImgTagsArray.length) resolve(translations);

    await Promise.all(htmlImgTagsArray.map(async (imgElement) => {

        const data = await convertBase64ToBinaryFile(imgElement.src);

        if (data) {

            const response = await instance({ url: "/media/create", method: "POST", data });

            const responseElement = response?.data as IMediaCreate;

            imgElement.src = FILE_URL + responseElement.path;
            imgElement.setAttribute("image-id", responseElement.id);
            return responseElement;
        }
    })
    ).then(() => {

        fullContentString = htmlElement.innerHTML;

        fullContentString.split('AHRORUSHKA').forEach((element, index) => {

            translations[index].content = element

        })
    })



    resolve(translations);

})


export const submitLesson = async ({ form, course_module_id, lesson_id }: { form: FormInstance, course_module_id?: string | undefined, lesson_id?: string | undefined }) => {

    const _translations = await convertBase64ToLink(form.getFieldValue('translations'));

    const data = lesson_id ?
        {

            ...form.getFieldsValue(),
            translations: _translations,
            ks_uuids: (form.getFieldValue('ks_uuids') as Array<{ ks_uuid: string }>).filter(e => e.ks_uuid).map(e => e.ks_uuid)
        } : {
            ...form.getFieldsValue(),
            translations: _translations,
            ks_uuids: (form.getFieldValue('ks_uuids') as Array<{ ks_uuid: string }>).filter(e => e.ks_uuid).map(e => e.ks_uuid),
            course_module_id
        }

    const options: AxiosRequestConfig = lesson_id ? { url: `/course-lesson/${lesson_id}`, method: "PUT", data } : { url: '/course-lesson/create', method: "POST", data }

    const response = await instance(options)

    return response.data;
}

