import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { FiEdit } from "react-icons/fi";
import DeleteIcon from "components/Common/deleteIcon";
import { deleteData, getData } from "./order.request";
import { AxiosError } from "axios";
import { IOrder } from "./types";
import CreateOrder from "./order_form_ui";
import { Button, Empty } from "antd";
import TableLoader from "components/loaders/table_loader";
import PageTitle from "components/Common/PageTitle";
import { is_permission } from "utils.functions";
import { useTranslation } from "react-i18next";

const Orders = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<IOrder | null>(null);
  const [openSidebarMenu, setOpenSidebarMenu] = useState<boolean>(false);

  const deletePost = useMutation({
    mutationFn: deleteData,
  });

  useEffect(() => {
    if (!deletePost.isLoading) {
      refetch();
    }
  }, [deletePost.isLoading]);

  const { isLoading, error, data, refetch } = useQuery<IOrder[], AxiosError>({
    queryKey: ["orders"],
    queryFn: () => getData(),
    retry: 0,
    staleTime: Infinity,
  });

  if (error) return "An error has occurred: " + error.message;

  return (
    <div>
      <div className="grid grid-cols-12 gap-4">
        <div className="main-card lg:col-span-8 col-span-12">
          <div className="flex mb-3 justify-between">
            <PageTitle title={"Orders"} />
            {is_permission("create_order") ? (
              <Button
                onClick={() => {
                  setOpenSidebarMenu(true);
                  setSelected(null);
                }}
                type="default"
                className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763] lg:hidden"
              >
                + {t("Create")}
              </Button>
            ) : null}
          </div>
          <div className="col-span-12 md:overflow-auto overflow-x-scroll">
            <Table className="table-striped table-hover">
              <Thead>
                <Tr>
                  <Th className="w-[50px]">№</Th>
                <Th className="w-[50px] text-center px-[0!important]">{t("Id")}</Th>
                  <Th>{t("Created by")}</Th>
                  <Th>{t("Payment provider")}</Th>
                  <Th>{t("Payment type")}</Th>
                  <Th>{t("Amount")}</Th>
                  <Th>{t("Is paid")}</Th>
                  <Th>{t("Actions")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isLoading
                  ? data?.map((item, index: number) => (
                      <Tr key={item.id}>
                        <Td>{index + 1}</Td>
                        <Td className="w-[50px] text-center px-[0!important]">{item?.id}</Td>
                        <Td>
                          {item?.user_pay_history?.created_by?.translations[0]?.first_name
                            ? item?.user_pay_history?.created_by?.translations[0]?.first_name
                            : item?.user_pay_history.created_by?.email}{" "}
                          {item?.user_pay_history?.created_by?.translations[0]?.last_name}
                        </Td>
                        <Td>{item?.payment_provider}</Td>
                        <Td>{item?.payment_type}</Td>
                        <Td>{item?.amount}</Td>
                        <Td>
                          {item?.is_paid ? (
                            <span className="text-emerald-500">Paid</span>
                          ) : (
                            <span className="text-amber-400">{t("Not paid")}</span>
                          )}
                        </Td>
                        <Td>
                          <div className="flex" style={{ alignItems: "center" }}>
                            {is_permission("update_order") ? (
                              <FiEdit
                                className="mx-2 text-[green] text-[18px] cursor-pointer"
                                onClick={() => {
                                  setSelected(item);
                                  setOpenSidebarMenu(true);
                                }}
                              />
                            ) : null}
                            {is_permission("delete_order") ? (
                              <DeleteIcon confirmFunc={() => deletePost.mutate(item?.id)} />
                            ) : null}
                          </div>
                        </Td>
                      </Tr>
                    ))
                  : null}
              </Tbody>
            </Table>
          </div>
          {data?.length === 0 ? <Empty className="w-[100%]" /> : null}
          <TableLoader isLoading={isLoading} />
        </div>
        {(is_permission("create_order") && !selected) || (is_permission("update_order") && selected) ? (
          <div className="main-card col-span-4 lg:block hidden">
            <CreateOrder
              element={selected}
              setElement={setSelected}
              refetch={refetch}
              openSidebarMenu={openSidebarMenu}
              setOpenSidebarMenu={setOpenSidebarMenu}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Orders;
