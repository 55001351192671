import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { BiPlus, BiX } from 'react-icons/bi';
import { ILanguage } from 'pages/languages/types';
import useGetData from 'custom-hooks/useGetData';
import FormLabelCrud from 'components/FormLabel/FormLabel';
import { useTranslation } from 'react-i18next';
import LanguageTitle from 'utils/LanguageTitle';

const { Option } = Select;

const MultipleLangInputsForLang = ({ form }: { form: FormInstance,}) => {



    const { t } = useTranslation();

    const { data: languages } = useGetData<ILanguage[]>({ queryKey: ['languages'], url: 'language/list/', options: { staleTime: Infinity, refetchOnWindowFocus: false } });

    const [languageIds, setLanguageIds] = React.useState<(string | undefined)[]>([]);
    const filterLanguage = () => {

        const tranlations = (form.getFieldValue("title") ?? []) as Array<{ code: string}>;

        const ids = tranlations?.map(e => {
            if (e?.code) {
                return e.code
            }
        })
        setLanguageIds(ids)
    }

    return (
        <div>
            <Form.List name="title">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <Row key={key} gutter={[12, 12]}>
                                <Col xl={14} lg={24}  xs={24}>
                                    <Form.Item
                                        className='mb-0'
                                        label={<FormLabelCrud>{t("Name")}</FormLabelCrud>}
                                        {...restField}
                                        name={[name, 'title']}
                                        rules={[{ required: index === 0, message: 'Please input title!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col xl={7} lg={18} sm={17} xs={12} >
                                    <Form.Item
                                        className='mb-0'
                                        label={<FormLabelCrud>{t("Languages")}</FormLabelCrud>}
                                        {...restField}
                                        name={[name, 'code']}
                                        rules={[{ required: index === 0, message: 'Please select language' }]}

                                    >
                                        <Select onClick={filterLanguage}>
                                            {
                                                languages?.map((element: ILanguage) => <Option key={element.code} value={element.code} disabled={languageIds.includes(element.code)}><LanguageTitle element={element}/></Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xl={3} lg={6}>
                                    <Form.Item className="w-[50px] mb-0 ml-2 mt-[24px]">
                                        <Button disabled={fields.length == 1} onClick={() => { remove(name); }} danger><BiX /></Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}
                        <div className="text-right mb-2">
                            <Button type="link" size="small" className="inline-flex align-middle items-center lg:text-sm text-xs font-medium uppercase sm:px-[15px] pl-0" onClick={() => add()} disabled={fields.length === languages?.length}>
                                <BiPlus size={20} className="mt-[2px] mr-1" />{t("Add translation")}
                            </Button>
                        </div>
                    </>
                )}
            </Form.List>
        </div>
    )
}
export default MultipleLangInputsForLang;