import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Form } from "antd";
import { submitLesson } from "./lesson.request";
import ToBack from "components/Common/ToBack";
import { useParams } from "react-router-dom";
import { validationErrors } from "utils.functions";
import { AxiosError } from "axios";
import LessonCreateUi from "./lesson_create_ui";
import { RButton, SButton } from "components/Common/CustomButtons";
import { useTranslation } from "react-i18next";

const CreateLesson = () => {

  const {t} = useTranslation()
  const { module_id } = useParams()

  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-lessons"],
    mutationFn: () => submitLesson({ form, course_module_id: module_id }),
    onSuccess: async (data) => {
      queryClient.setQueryData(["lessons"], data);
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data)
    },
    retry: 0,
  });



  return (
    <div className="main-card">
      <ToBack title={t("Lesson create")} />
      <hr className="my-3" />
      <Form
        layout={"vertical"}
        form={form}
        onFinish={() => mutate()}
        className="custom_form_style"
        initialValues={{
          is_active: true,
          is_free: false,
          is_homework: false,
          is_final: false,
          ks_uuids: [""],
          translations: [{ title: "", desc: "", content: "" }]
        }}
      >
        <LessonCreateUi form={form} />
        <div className="md:flex justify-end mt-2">
          <RButton className="mr-2" onClick={() => form.resetFields()} />
          <SButton loading={isLoading} />
        </div>
      </Form>
    </div>
  );
};

export default CreateLesson;