import instance from "config/axios.config";

export async function submitData(id: number | undefined, data: any) {

    const url = id ? `installment-payment/${id}` : `installment-payment/create`;
    const response = await instance({ url, method: id ? 'PUT' : "POST", data });
    return response.data

}

export async function getData() {
    const response = await instance({ url: `installment-payment/list/`, method: 'GET' });
    return response.data;
}


export function deleteData(id: any) {
    return instance({ url: `installment-payment/${id}`, method: 'DELETE' })
}
