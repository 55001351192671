
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";


type PropsInformerFieldSetUi = {
    title: string,
    text?: string | ReactNode
}

export function CCFieldSetUi({ title, text }: PropsInformerFieldSetUi) {


    const { t } = useTranslation();

    return (
        <fieldset className="field_set_box">
            <legend style={{ width: "auto", fontSize: "12px" }}>{t(title)}</legend>
            <span> {text ?? '-'}  </span>
        </fieldset>
    )

}