import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import DeleteIcon from "components/Common/deleteIcon";
import { deleteData, getData } from "./resume.request";
import { AxiosError } from "axios";
import { Empty, Pagination, Select, message } from "antd";
import TableLoader from "components/loaders/table_loader";
import PageTitle from "components/Common/PageTitle";
import { is_permission } from "utils.functions";
import { InformerDRDT } from "types/base.models";
import { useTranslation } from "react-i18next";
import { BiCheck } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { IResume } from "./types";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { resumeTypes } from "utils/variables";

const Resumes = () => {
  const { t } = useTranslation();
  const [page, setpage] = useState<number>(1);
  const [pageSize, setpageSize] = useState<number>(10);
  const [selectedResumeStatus, setSelectedResumeStatus] = useState<string>("pending");

  const deletePost = useMutation({
    mutationFn: deleteData,
    onSuccess: () => {
      refetch();
    },
  });
  const { isLoading, error, data, refetch } = useQuery<
    InformerDRDT<IResume>,
    AxiosError
  >({
    queryKey: ["resumes", page, pageSize,selectedResumeStatus],
    queryFn: () => getData(page, pageSize, selectedResumeStatus),
    retry: 0,
    refetchOnWindowFocus: false,
  });
  console.log(selectedResumeStatus)
  const copyToClipboard = async (data: any) => {
    await navigator.clipboard.writeText(data);
    message.success("ID copied");
  };

  if (error) return "An error has occurred: " + error.message;

  return (
    <div>
      <div className="grid grid-cols-12 gap-4">
        <div className="main-card lg:col-span-12 col-span-12">
          <div className="flex mb-3 justify-between">
            <PageTitle title={"Resumes"} />
            <Select
              className="min-w-[20vh]"
              defaultValue={"pending"}
              onChange={(e) => {
                refetch();
                setSelectedResumeStatus(e);
              }}
            >
              {resumeTypes.map((resum) => (
                <Select.Option key={resum} value={resum}>
                  {resum}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Table className="table-striped table-hover">
            <Thead>
              <Tr>
                <Th className="w-[50px]">№</Th>
                <Th>{t("First name")}</Th>
                <Th>{t("Last name")}</Th>
                <Th>{t("Email")}</Th>
                <Th>{t("Phone")}</Th>
                <Th>{t("Job title")}</Th>
                <Th>{t("Status")}</Th>
                <Th>{t("Verified")}</Th>
                <Th>{t("Actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading
                ? data?.results?.length > 0
                  ? data?.results?.map((item, index: number) => (
                      <Tr key={item?.id}>
                        <Td
                          onClick={() => copyToClipboard(item?.id)}
                          className="cursor-pointer"
                        >
                          {(page - 1) * pageSize + index + 1}
                        </Td>
                        <Td>{item?.first_name}</Td>
                        <Td>{item?.last_name}</Td>
                        <Td>{item?.email}</Td>
                        <Td>{item?.phone}</Td>
                        <Td>{item?.job_title}</Td>
                        <Td>
                          {item?.is_active ? (
                            <BiCheck className="mx-auto text-[24px] text-green-700" />
                          ) : (
                            <IoMdClose className="mx-auto text-[22px] text-rose-600" />
                          )}
                        </Td>
                        <Td>
                          {item?.is_verified ? (
                            <BiCheck className="mx-auto text-[24px] text-green-700" />
                          ) : (
                            <IoMdClose className="mx-auto text-[22px] text-rose-600" />
                          )}
                        </Td>
                        <Td>
                          <div
                            className="flex"
                            style={{ alignItems: "center" }}
                          >
                            {is_permission("read_resume") ? (
                              <Link to={`/resume/view/${item?.id}`}>
                                <AiOutlineEye className=" mr-2 text-[#faad14] text-[22px] cursor-pointer" />
                              </Link>
                            ) : null}
                            {is_permission("delete_resume") ? (
                              <DeleteIcon
                                confirmFunc={() => deletePost.mutate(item?.id)}
                              />
                            ) : null}
                          </div>
                        </Td>
                      </Tr>
                    ))
                  : null
                : null}
            </Tbody>
          </Table>
          {data?.results?.length === 0 ? <Empty className="w-[100%]" /> : null}
          <TableLoader isLoading={isLoading} />
          <div className="text-right mt-3">
            <Pagination
              onChange={(page_num, page_size) => {
                setpage(page_num);
                setpageSize(page_size);
              }}
              defaultCurrent={page}
              pageSize={pageSize}
              total={data?.total_results}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Resumes;
