import { Tabs, TabsProps } from "antd";
import ToBack from "components/Common/ToBack";
import LessonAttachmentFiles from "./attachment_files";
import LessonDetails from "./details";
import LessonBlocks from "./blocks/blocks";
import { useTranslation } from "react-i18next";
import useUrlQueryParams from "custom-hooks/useUrlQueryParams";

const LessonView = ({ id }: { id: string | undefined }) => {
  const { value, writeToUrl } = useUrlQueryParams({});
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Details`,
      children: <LessonDetails />,
    },
    // {
    //   key: "2",
    //   label: `Files`,
    //   children: <LessonAttachmentFiles />,
    // },
    {
      key: "3",
      label: `Blocks`,
      children: <LessonBlocks />,
    },
  ];

  const { t } = useTranslation();
  const onChange = (key: string) => {
    writeToUrl({ name: "tab_id", value: key });
  };

  return (
    <>
      <ToBack title={t("Back")} />
      <div className="col-span-12 main-card mt-4">
        <Tabs activeKey={String(value.filter.tab_id ?? 1)} items={items} onChange={onChange} />
      </div>
    </>
  );
};
export default LessonView;
