import { Form, FormInstance, Input, Select } from "antd";
import { useState } from "react";

const paymentTypes = [
    { value: "cash", label: "Cash" },
    { value: "plastic", label: "Plastic" }
]

const paymentprivider = [
    { value: "click", label: "Click" },
    { value: "paycom", label: "Paycom" },
    { value: "uzum", label: "Uzum" },
    { value: "rahmat", label: "Rahmat" }
]

const UpdateForm = ({ form, handleSubmit }: { form: FormInstance, handleSubmit: any }) => {

    const [paymentTypeValue, setpaymentTypeValue] = useState<string>()    

    return (
        <Form layout={"vertical"} form={form} onFinish={handleSubmit}>
            <div className="grid grid-cols-12 gap-x-4">
                <div className="lg:col-span-4 sm:col-span-6 col-span-12">
                    <Form.Item
                        label="Payment type"
                        name="payment_type"
                        rules={[{ required: true, message: 'Please select payment!' }]}
                    >
                        <Select
                            allowClear
                            onChange={(e) => setpaymentTypeValue(e)}
                            options={paymentTypes}
                        />
                    </Form.Item>
                </div>
                <div className="lg:col-span-4 sm:col-span-6 col-span-12">
                    <Form.Item
                        label="Payment provider"
                        name="payment_provider"
                        rules={[{ required: paymentTypeValue === "plastic", message: 'Please select payment provider!' }]}
                    >
                        <Select
                            allowClear
                            disabled={paymentTypeValue !== "plastic"}
                            options={paymentprivider}
                        />
                    </Form.Item>
                </div>
                <div className="col-span-12">
                    <Form.Item
                        label="Detail"
                        name="detail"
                        rules={[{ required: true, message: 'Please input is Detail!' }]}
                    >
                        <Input.TextArea  />
                    </Form.Item>
                </div>
            </div>
        </Form>
    );
};

export default UpdateForm;