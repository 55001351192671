import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getCourseGroup, getCourseStudents, submitCourses } from "./request";
import { IUser } from "types/data.models";
import { IGroup } from "../types";
import { Button, Input, Pagination, Skeleton, Transfer } from "antd";
import ToBack from "components/Common/ToBack";
import useDebounce from "custom-hooks/useDebounce";
import { useTranslation } from "react-i18next";

interface Course_Data {
  num_pages: number;
  page_number: number;
  page_size: number;
  results: IUser[];
  total_results: number;
}

interface RecordType {
  key: string;
  title: string;
  description: string;
  chosen: boolean;
}

const Course_group = () => {

  const {t} = useTranslation()
  const { course_group_id } = useParams();
  const [mockData, setMockData] = useState<RecordType[]>([]);
  const [currentMockData, setCurrentMockData] = useState<RecordType[]>([]);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [search_student_name, set_search_student_name] = useState<string>("");
  const [total_results, setTotalResults] = useState<number>(0);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const {
    data: course_group,
    isFetching: course_group_fetching,
    isLoading: course_group_loading,
    isRefetching: course_group_refetching,
  } = useQuery<IGroup, AxiosError>({
    queryKey: ["course-group"],
    queryFn: () => getCourseGroup(course_group_id),
    retry: 0,
    refetchOnWindowFocus: false,
  });
  const dataQuery = useQuery<Course_Data, AxiosError>({
    queryKey: ["course-students", pageNumber, pageSize],
    queryFn: () => getCourseStudents(course_group_id, search_student_name, pageNumber, pageSize),
    onSuccess: (res) => {
      setTotalResults(res.total_results);
      setPageSize(res.page_size);
    },
    retry: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (course_group) {
      let new_data: any = [];
      course_group?.students?.map((item) => {
        new_data.push(item?.id);
      });
      setSelectedItems(new_data);
      setTargetKeys(new_data);
    }
  }, [course_group]);

  const { mutate, isLoading: submit_loading } = useMutation({
    mutationFn: (newValues: string[]) => submitCourses(course_group_id, newValues, course_group),
    retry: 0,
  });

  function handleSubmit() {
    mutate(targetKeys);
  }

  const debouncedValue = useDebounce<string>(search_student_name, 1000);
  useEffect(() => {
    const tempMockData: any[] = [];
    dataQuery.data?.results?.map((item) => {
      const data = {
        key: item?.id,
        title:
          item?.translations[0]?.first_name && item?.translations[0]?.last_name
            ? `${item?.translations[0]?.first_name} ${item?.translations[0]?.last_name}`
            : item?.phone && item?.translations[0]?.first_name
            ? `${item?.translations[0]?.first_name} ${item?.phone}`
            : item?.phone
            ? item?.phone
            : item?.email,
      };
      tempMockData.push(data);
    });
    course_group?.students?.map((item) => {
      const data = {
        key: item?.id,
        title:
          item?.translations[0]?.first_name && item?.translations[0]?.last_name
            ? `${item?.translations[0]?.first_name} ${item?.translations[0]?.last_name}`
            : item?.phone && item?.translations[0]?.first_name
            ? `${item?.translations[0]?.first_name} ${item?.phone}`
            : item?.phone
            ? item?.phone
            : item?.email,
      };
      tempMockData.push(data);
    });

    setMockData([
      ...tempMockData,
      ...currentMockData.filter((item) => !tempMockData.map((it) => it.key).includes(item.key)),
    ]);

  }, [dataQuery.data, course_group, selectedItems, debouncedValue]);

  const handleChange = (newTargetKeys: string[]) => {
    let all_mock_data = [...mockData];
    currentMockData.forEach((item) => {
      if (!mockData.map((obj) => obj.key).includes(item.key)) {
        all_mock_data.push(item);
      }
    });

    setCurrentMockData(all_mock_data.filter((item) => newTargetKeys.includes(item.key)));
    setTargetKeys(newTargetKeys);
  };
  const handleSearch = (d: string, value: string) => {
    if (d === "left") {
      set_search_student_name(value);
    }
  };

  useEffect(() => {
    // if (search_student_name === "") {
    //   setPageSize(10);
    // }
    dataQuery.refetch();
  }, [debouncedValue]);

  // const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   set_search_student_name(e.target.value);
  // };

  return (
    <div>
      <ToBack title={t("Back")} />
      <div className="col-span-12 main-card mt-4">
        <div>
          <div className="flex justify-between mb-3">
            <h3 className="font-bold text-[18px] opacity-60">Guruhdagi talabalar</h3>
          </div>
          {/* <Input
            style={{ width: "350px" }}
            className="mb-2"
            placeholder="Search student"
            allowClear
            onChange={onChange}
          /> */}
          <div className="flex relative">
            {course_group_loading ? (
              <Skeleton active />
            ) : (
              <>
                <Transfer
                  disabled={submit_loading}
                  dataSource={mockData}
                  showSearch
                  listStyle={{
                    minWidth: 350,
                    minHeight: 450,
                  }}
                  onSearch={handleSearch}
                  operations={["to right", "to left"]}
                  targetKeys={targetKeys}
                  onChange={handleChange}
                  render={(item) => `${item.title}`}
                />
                {dataQuery.isInitialLoading && (
                  <div className="absolute top-[19.5%] h-[350px] left-[12px] w-[326px] bg-white flex flex-col justify-around">
                    <Skeleton active />
                    <Skeleton active />
                  </div>
                )}
              </>
            )}

            <Button loading={submit_loading} onClick={() => handleSubmit()} className="ml-3">
              Save
            </Button>
          </div>
          <div className="mt-3">
            <Pagination
              onChange={(page_num, page_size) => {
                setPageNumber(page_num);
                setPageSize(10);
              }}
              defaultCurrent={1}
              pageSize={pageSize}
              total={total_results}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Course_group;
