import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteData, getMentorInfo } from "./rating.request";
import { IMentorInfo } from "./types";
import CreateCourseRating from "./mudule_form_ui";
import { Badge, Button, Empty, Popconfirm } from "antd";
import TableLoader from "components/loaders/table_loader";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import { is_permission } from "utils.functions";
import { BiCheck } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { FILE_URL } from "config/utils";
import { RMButton } from "components/Common/CustomButtons";

const MentorInfo = () => {
  const { user_id: mentor_id } = useParams();

  const [selected, setSelected] = React.useState<IMentorInfo | null>(null);
  const [openSidebarMenu, setOpenSidebarMenu] = useState<boolean>(false);
  const [mentorInfo, setMentorInfo] = useState<IMentorInfo | null>(null);

  const deletePost = useMutation({
    mutationFn: deleteData,
    onSuccess: () => {
      setMentorInfo(null);
    },
  });

  const { isLoading, error, data, refetch } = useQuery<IMentorInfo, AxiosError>({
    queryKey: ["course-ratings", mentor_id],
    queryFn: () => getMentorInfo(mentor_id),
    retry: 0,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setMentorInfo(data);
    },
  });

  // if (error) return <div>'An error has occurred: '</div>;

  return (
    <div>
      <div className="">
        <div className="flex mb-3 justify-between">
          <h3 className="font-bold text-[16px]">Mentor info</h3>
          {is_permission("create_mentor_info") && !mentorInfo ? (
            <Button
              onClick={() => {
                setOpenSidebarMenu(true);
                setSelected(null);
              }}
              type="default"
              className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]"
            >
              + Create
            </Button>
          ) : null}
          {is_permission("update_mentor_info") && mentorInfo ? (
            <Button
              onClick={() => {
                setOpenSidebarMenu(true);
                setSelected(mentorInfo);
              }}
              type="default"
              className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]"
            >
              Edit
            </Button>
          ) : null}
        </div>

        {!mentorInfo ? (
          <Empty className="w-[100%]" />
        ) : (
          <div className="xl:col-span-3 md:col-span-6 col-span-12">
            <div className="flex justify-between mb-4">
              <p className="font- font-bold text-zinc-500">Contact number</p>
              <p>{mentorInfo?.contract_number}</p>
            </div>
            <div className="flex justify-between mb-4">
              <p className="font- font-bold text-zinc-500">Level</p>
              <p>{mentorInfo?.level}</p>
            </div>
            <div className="flex justify-between mb-4">
              <p className="font- font-bold text-zinc-500">Main work</p>
              <p>{mentorInfo?.main_work}</p>
            </div>
            <div className="flex justify-between mb-4">
              <p className="font- font-bold text-zinc-500">Is main</p>
              <p>{mentorInfo?.is_main ? <BiCheck color="green" size={24} /> : <CgClose color="red" size={24} />}</p>
            </div>
            {mentorInfo.resume?.path ? (
              <img
                className="lg:h-[200px] h-[100px] rounded-xl w-[100%] object-contain"
                src={FILE_URL + mentorInfo.resume?.path}
                alt=""
              />
            ) : (
              ""
            )}
            {is_permission("delete_mentor_info") && mentorInfo ? (
              <Popconfirm
                placement="topLeft"
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText={"Ok"}
                okType="default"
                cancelText={"Cancel"}
                onConfirm={() => {
                  deletePost.mutate({ mentor_id });
                }}
              >
                <RMButton disabled={deletePost.isLoading} className="ml-auto block" />
              </Popconfirm>
            ) : null}
          </div>
        )}
        <TableLoader isLoading={isLoading} />
      </div>
      {(is_permission("create_mentor_info") && !selected) || (is_permission("update_mentor_info") && selected) ? (
        <CreateCourseRating
          element={selected}
          setElement={setSelected}
          refetch={refetch}
          openSidebarMenu={openSidebarMenu}
          setOpenSidebarMenu={setOpenSidebarMenu}
        />
      ) : null}
    </div>
  );
};
export default MentorInfo;
