import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Drawer, Form, Input, message, Switch } from "antd";
import { AxiosError } from "axios";
import useWindowSize from "custom-hooks/useWindowSize";
import React from "react";
import { validationErrors } from "utils.functions";
import { submitData } from "./permission.request";
import { IFormValues, IPermission } from "./types";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";
import { RButton, SButton } from "components/Common/CustomButtons";
import FormLabelCrud from "components/FormLabel/FormLabel";
import EditingTitle from "components/Common/EditingTitle";
import PartTitle from "components/Common/PartTitle";
import { useTranslation } from "react-i18next";


type TypePropsCreatePermission = {
  element: IPermission | null;
  setElement: React.Dispatch<React.SetStateAction<IPermission | null>>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateLanguage = ({ element, setElement, refetch, openSidebarMenu, setOpenSidebarMenu }: TypePropsCreatePermission) => {

  const {t} = useTranslation()
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { width } = useWindowSize()

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue({
        title: element.title,
        desc: element.desc,
        is_active: element.is_active
      })
    } else {
      form.resetFields()
    }
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-permission"],
    mutationFn: (newValues: IFormValues) => submitData(element?.id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["permissions"], data);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data)
    },
  });

  function handleSubmit(values: IFormValues) {
    mutate(values);
  }

  const MemoizationFieldsFormPermission = React.memo(() => (
    <Form
      layout={"vertical"}
      form={form}
      onFinish={handleSubmit}
      className="custom_form_style"
    >
      <div className="grid grid-cols-12 gap-x-4">
        <div className="col-span-12">
          <Form.Item
            label={<FormLabelCrud>{t("Name")}</FormLabelCrud>}
            name="title"
            rules={[
              { required: true, message: "Please input language title!" },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col-span-12">
          <Form.Item
            label={<FormLabelCrud>{t("Description")}</FormLabelCrud>}
            name="desc"
            rules={[{ required: true, message: "" }]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
        </div>
        <div className="col-span-4">
          <Form.Item
            label={<FormLabelCrud>{t("Status")}</FormLabelCrud>}
            name="is_active"
            rules={[{ required: false, message: "" }]}
            valuePropName={'checked'}
          >
            <Switch defaultChecked className="bg-[#999]" />
          </Form.Item>
        </div>
        <div className="col-span-8">
          <div className="mt-7 text-right gap-2">
            <RButton
              onClick={() => {
                form.resetFields();
                setElement(null);
              }}
              className="md:mb-2 mb-1"
            />
            <SButton loading={isLoading} />
          </div>
        </div>
      </div>
    </Form>
  ));

  return (
    <div>
      <>{element ? <EditingTitle title={element.title} /> : <PartTitle children={"Create permission"} />}</>
      <hr className="my-3" />
      <MemoizationFieldsFormPermission />
      <Drawer
        title={<h3 className=" font-bold">{element?.title ? element?.title : t("New")}</h3>}
        placement="right"
        onClose={() => setOpenSidebarMenu(false)}
        open={width > 992 ? false : openSidebarMenu}
      >
        <MemoizationFieldsFormPermission />
      </Drawer>
    </div>
  );
};
export default CreateLanguage;
