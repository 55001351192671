import { createSlice, PayloadAction } from "@reduxjs/toolkit";


type TypeUiStoreInititalState = {
    collapsed: boolean
}

const initialState: TypeUiStoreInititalState = {
    collapsed: true
}


const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        sidebarCollapse(state, action: PayloadAction<{ collapsed: boolean }>) {
            state.collapsed = action.payload.collapsed
        }
    }
})


export const UI_ACTIONS = uiSlice.actions;

export default uiSlice;