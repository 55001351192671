import instance from "config/axios.config";

export async function submitData(id: number | undefined, data: any) {

    const url = id ? `/district/${id}` : `district/create`;
    const response = await instance({ url, method: id ? 'PUT' : "POST", data });
    return response.data

}

export async function getDistrict(region_id:number | undefined | null) {
    if(region_id){
        const response = await instance({ url: `region/${region_id}/district/list/`, method: 'GET' });
        return response.data;
    }else{
        return {}
    }
}


export function deleteData(id: any) {
    return instance({ url: `district/${id}`, method: 'DELETE' })
}
