import React, { useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Drawer, Form, Input, Select, Switch, Upload, UploadFile, UploadProps } from "antd";
import useWindowSize from "custom-hooks/useWindowSize";
import { submitData } from "./question.request";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import PartTitle from "components/Common/PartTitle";
import EditingTitle from "components/Common/EditingTitle";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { RButton, SButton } from "components/Common/CustomButtons";
import { useTranslation } from "react-i18next";
import { IQuestion } from "./types";
import MultipleLangInputQuestions from "components/MultipleLangInput/multipleLangsQuestion";

type TypeTagFormProps = {
  element: IQuestion | null;
  setElement: React.Dispatch<React.SetStateAction<IQuestion | null>>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateQuestion = ({ element, setElement, refetch, openSidebarMenu, setOpenSidebarMenu }: TypeTagFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { width } = useWindowSize();

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue(element);
    } else {
      form.resetFields();
      form.setFieldsValue({
        is_active: true,
        translations: [{ title: "", is_active: true, content: "" }],
      });
    }
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-question"],
    mutationFn: (newVals: any) => submitData(element?.id, newVals),
    onSuccess: async (data) => {
      // queryClient.setQueryData(["trainings"], data);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    values.translations.forEach((item: any) => {
      if (!item.content) {
        delete item.content;
      }
    });
    mutate({ payload: values });
  }
  const MemoizationFieldsFormTag = React.memo(() => (
    <Form layout={"vertical"} form={form} onFinish={handleSubmit} className="custom_form_style">
      <MultipleLangInputQuestions form={form} element={element} />
      <div className="grid grid-cols-12 gap-4">
        <div className="md:col-span-6 col-span-6">
          <Form.Item
            label={<FormLabelCrud>Type</FormLabelCrud>}
            name="type"
            rules={[{ required: true, message: "Please input sort!" }]}
          >
            <Select
              className="w-[100%]"
              placeholder="Select type"
              style={{ width: "100%" }}
              value={"single"}
              options={[
                { value: "single", label: "Single" },
                { value: "multiple", label: "Multiple" },
              ]}
            />
          </Form.Item>
        </div>
        <div className="md:col-span-3 col-span-6">
          <Form.Item
            name="is_active"
            label={<FormLabelCrud>Active</FormLabelCrud>}
            rules={[{ required: true, message: "Please input is active!" }]}
            valuePropName={"checked"}
            initialValue={true}
          >
            <Switch defaultChecked className="bg-[#999]" />
          </Form.Item>
        </div>
      </div>
      <div className="flex gap-2 mt-2 text-end">
        <RButton
          onClick={() => {
            form.resetFields();
            setElement(null);
          }}
        />
        <SButton loading={isLoading} />
      </div>
    </Form>
  ));

  return (
    <div className="bg-red-600">
      <>
        {element ? (
          <EditingTitle title={element?.translations[0]?.title} />
        ) : (
          <PartTitle children={"Create training"} />
        )}
      </>
      <hr className="my-3" />
      {/* <MemoizationFieldsFormTag /> */}
      <Drawer
        title={
          <h3 className=" font-bold">{element?.translations[0]?.title ? element?.translations[0]?.title : t("New")}</h3>
        }
        placement="right"
        onClose={() => setOpenSidebarMenu(false)}
        open={openSidebarMenu}
        width={width > 600 ? 600 : "100%"}
      >
        <MemoizationFieldsFormTag />
      </Drawer>
    </div>
  );
};
export default CreateQuestion;
