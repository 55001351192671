import { Button, Checkbox, DatePicker, Form, FormInstance, Input, Radio, Select } from "antd";
import useFilterLanguage from "custom-hooks/useFilterLanguage";
import InputMask from 'react-input-mask';
import { BiPlus, BiX } from 'react-icons/bi';
import FormLabelCrud from "components/FormLabel/FormLabel";
import useGetData from "custom-hooks/useGetData";
import { IRegion } from "pages/regions/types";
import React from "react";
import { IDistrict } from "pages/districts/types";
import { cf_filterOption } from "utils.functions";
import { ILanguage } from "pages/languages/types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LanguageTitle from "utils/LanguageTitle";

const { Option } = Select;


const create_required_fields: string[] = ['hashed_password', 'first_name', 'language_id'];
const update_required_fields: string[] = ['language_id', 'first_name']


const UserFormUi = ({ form, is_action }: { form: FormInstance, is_action: 'create' | 'update' }) => {

    const { user_id } = useParams()
    const { t } = useTranslation();
    const [districts, setDistricts] = React.useState<IDistrict[]>([])
    
    const { fl, languageIds } = useFilterLanguage(form);

    const languages = useGetData<ILanguage[]>({ queryKey: ['languages'], url: 'language/list/', options: { staleTime: Infinity, refetchOnWindowFocus: false } });
    const regions = useGetData<IRegion[]>({ queryKey: ['regions'], url: 'region/list/', options: { staleTime: Infinity, refetchOnWindowFocus: false } });

    const onRegionChange = (region_id: number) => {

        form.setFieldValue('district_id', undefined)
        const findRegion = regions.data?.find(e => e.id === region_id);

        if (findRegion) {
            setDistricts(findRegion.districts ?? [])
        }
    }

    React.useMemo(() => {
        if (!districts.length && regions.data && regions.data.length) {
            setDistricts(regions.data[0].districts ?? [])
        }
    }, [regions.data])

    const required_fields = React.useMemo(() => is_action === 'create' ? create_required_fields : update_required_fields, [])

    return (
        <>
            <Form.List
                name="translations"
                initialValue={[{ first_name: "", language_id: 1, last_name: "", street: "" }]}
            >
                {(fields, { add, remove }) => (
                    <div className="grid grid-cols-12 gap-4 mt-3">
                        {
                            fields.map(({ key, name, ...restField }, index) => (
                                <React.Fragment key={index}>
                                    <div className="md:col-span-3 col-span-6 ">
                                        <Form.Item
                                            className='mb-0'
                                            label={<FormLabelCrud>{t("First name")}</FormLabelCrud>}
                                            name={[name, 'first_name']}
                                            {...restField}
                                            rules={[{ required: index === 0 && required_fields.includes('first_name'), message: 'Please input title!' }]}
                                        >
                                            <Input placeholder={t("Please input data") ?? ""} />
                                        </Form.Item>
                                    </div>
                                    <div className="md:col-span-3 col-span-6">
                                        <Form.Item
                                            className='mb-0'
                                            label={<FormLabelCrud>{t("Last name")}</FormLabelCrud>}
                                            name={[name, 'last_name']}
                                            {...restField}
                                            rules={[{ required: index === 0 && required_fields.includes('last_name'), message: 'Please input title!' }]}
                                        >
                                            <Input placeholder={t("Please input data") ?? ""} />
                                        </Form.Item>
                                    </div>
                                    <div className="md:col-span-3 sm:col-span-6 col-span-12">
                                        <Form.Item
                                            className='mb-0'
                                            label={<FormLabelCrud>Street</FormLabelCrud>}
                                            name={[name, 'street']}
                                            {...restField}
                                            rules={[{ required: index === 0 && required_fields.includes('street'), message: 'Please input title!' }]}
                                        >
                                            <Input placeholder="Enter title" />
                                        </Form.Item>
                                    </div>
                                    <div className="md:col-span-3 sm:col-span-6 col-span-12 flex">
                                        <Form.Item
                                            label={<FormLabelCrud>Language</FormLabelCrud>}
                                            {...restField}
                                            name={[name, 'language_id']}
                                            rules={[{ required: true, message: 'Please select language' }]}
                                            className="w-[calc(100%-55px)] mb-0"
                                        >
                                            <Select onClick={fl}>
                                                {
                                                    languages.data?.map(element => <Option key={element.id} value={element.id} disabled={languageIds.includes(element.id)}><LanguageTitle element={element}/></Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item className="w-[50px] mb-0 ml-2 mt-[24px]">
                                            <Button disabled={fields.length == 1} onClick={() => { remove(name); }} danger><BiX /></Button>
                                        </Form.Item>
                                    </div>
                                </React.Fragment>
                            ))
                        }
                        <div className="col-span-12 text-right">
                            <Button type="link" size="small" className="inline-flex align-middle items-center lg:text-sm text-xs font-medium uppercase sm:px-[15px] pl-0" onClick={() => add()} disabled={fields.length === languages.data?.length}>
                                <BiPlus size={20} className="mt-[2px] mr-2" />{t("Add translation")}
                            </Button>
                        </div>
                    </div>
                )}
            </Form.List>
            <div className="grid grid-cols-12 gap-4 mt-3">
                <div className="md:col-span-3 sm:col-span-6 col-span-12">
                    <Form.Item
                        label={<FormLabelCrud>Birthday</FormLabelCrud>}
                        name="birthday"
                        rules={[{ required: required_fields.includes('bithday'), message: 'Please input birthday!' }]}
                    >
                        <DatePicker className="w-[100%]" />
                    </Form.Item>
                </div>
                <div className="md:col-span-3 sm:col-span-6 col-span-12">
                    <Form.Item
                        label={<FormLabelCrud>Gender</FormLabelCrud>}
                        name="gender"
                        rules={[{ required: required_fields.includes('gender'), message: 'Please input birthday!' }]}
                    >
                        <Radio.Group>
                            <Radio value="male" >{t("Male")}</Radio>
                            <Radio value="female" >{t("Female")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
                <div className="md:col-span-3 sm:col-span-6 col-span-12">
                    <Form.Item
                        label={<FormLabelCrud>Email</FormLabelCrud>}
                        name="email"
                        rules={[
                            {
                                required: required_fields.includes('email'),
                                type: "email",
                                message: t("The input is not valid E-mail!") ?? '',
                            }
                        ]}
                    >
                        <Input placeholder="example@gmail.com" />
                    </Form.Item>
                </div>
                <div className="md:col-span-3 sm:col-span-6 col-span-12">
                    <Form.Item
                        label={<FormLabelCrud>Phone</FormLabelCrud>}
                        name="phone"
                        rules={[{ required: required_fields.includes('phone'), message: 'Please input phone!' }]}
                    >
                        <InputMask mask="\9\98999999999" className='ant-input' placeholder='+998 (90) 999 99 99' />
                    </Form.Item>
                </div>
                <div className="md:col-span-3 col-span-6">
                    <Form.Item
                        label={<FormLabelCrud>Region</FormLabelCrud>}
                        name="region_id"
                        rules={[{ required: required_fields.includes('region_id'), message: 'Please input region!' }]}
                    >
                        <Select
                            onChange={onRegionChange}
                            allowClear
                            clearIcon
                            showSearch
                            filterOption={cf_filterOption}
                            placeholder="Ma'lumot tanlang"
                        >
                            {
                                regions.data && regions.data.length ?
                                    regions.data.map((element) => <Select.Option key={element.id} value={element.id}>{element.translations[0].title}</Select.Option>) :
                                    <Select.Option>{t("Information not found")}</Select.Option>
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div className="md:col-span-3 col-span-6">
                    <Form.Item
                        label={<FormLabelCrud>District</FormLabelCrud>}
                        name="district_id"
                        rules={[{ required: required_fields.includes('district_id'), message: 'Please input title!' }]}
                    >
                        <Select
                            allowClear
                            clearIcon
                            placeholder="Ma'lumot tanlang"
                            showSearch
                            filterOption={cf_filterOption}
                            disabled={!districts.length}
                        >
                            {
                                districts.length ?
                                    districts.map((element) => <Select.Option key={element.id} value={element.id}>{element.translations[0].title}</Select.Option>) :
                                    <Select.Option>{t("Information not found")}</Select.Option>
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div className="md:col-span-3 sm:col-span-6 col-span-12">
                    {
                        !user_id ? 
                            <Form.Item
                                label={<FormLabelCrud>Password</FormLabelCrud>}
                                name="hashed_password"
                                rules={[{ required: required_fields.includes('hashed_password'), message: 'Please input title!' }]}
                            >
                                <Input />
                            </Form.Item>
                         : ""
                    }
                </div>
                
            </div>
            <div className="grid grid-cols-12">
            <div className="md:col-span-3 col-span-6">
                    <Form.Item name="is_superuser" valuePropName="checked">
                        <Checkbox>{t("SUPERUSER")}</Checkbox>
                    </Form.Item>
                </div>
                <div className="md:col-span-3 col-span-6">
                    <Form.Item name="is_staff" valuePropName="checked">
                        <Checkbox>{t("STAFF")}</Checkbox>
                    </Form.Item>
                </div>
                <div className="md:col-span-3 col-span-6">
                    <Form.Item name="is_verified" valuePropName="checked" >
                        <Checkbox>{t("VERIFIED")}</Checkbox>
                    </Form.Item>
                </div>
                <div className="md:col-span-3 col-span-6">
                    <Form.Item name="is_active" valuePropName="checked">
                        <Checkbox>{t("ACTIVE")}</Checkbox>
                    </Form.Item>
                </div>
            </div>
            <hr className="mb-4" />
        </>
    )

}



export default UserFormUi;