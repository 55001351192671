import instance from "config/axios.config";

export async function submitData(id: number | undefined, data: any) {
  const url = id ? `/course_review/${id}` : `/course_review/create`;
  const response = await instance({ url, method: id ? "PUT" : "POST", data });
  return response.data;
}

export async function getCourseReviews(course_id: string | undefined | null) {
  if (course_id) {
    const response = await instance({
      url: `/course_review/list/`,
      method: "GET",
      params: { order_by: "created_at", desc: true, course_id },
    });
    return response.data;
  } else {
    return {};
  }
}

export async function getDataById(id: number) {
  const response = await instance({ url: `/course_review/${id}`, method: "GET" });
  return response.data;
}
export async function getStudentsForCourseReview(course_id: string|undefined, q: any) {
  const response = await instance({ url: `/course/${course_id}/student/list/`, method: "GET", params: { q } });
  return response.data;
}

export function deleteData(id: any) {
  return instance({ url: `/course_review/${id}`, method: "DELETE" });
}
