import React, { useEffect, useState } from "react";
import { useMutation, useQueries } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { FiEdit } from "react-icons/fi";
import DeleteIcon from "components/Common/deleteIcon";
import { deleteData, getData, getTopics } from "./category.request";
import { ICategory, ITopicSelect } from "./types";
import CreateCategory from "./category_form_ui";
import { Badge, Button, Empty, Pagination, Select } from "antd";
import TableLoader from "components/loaders/table_loader";
import { ITopic } from "pages/topics/types";
import PageTitle from "components/Common/PageTitle";
import { is_permission } from "utils.functions";
import { useTranslation } from "react-i18next";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";

const Categories = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<ICategory | null>(null);
  const [openSidebarMenu, setOpenSidebarMenu] = useState<boolean>(false);
  const [topicsArr, settopicsArr] = useState<ITopicSelect[]>([]);
  const [selctedTopicId, setselctedTopicId] = useState<number | null>(null);
  const [page, setpage] = useState<number>(1);
  const [pageSize, setpageSize] = useState<number>(10);
const {_translate} = useLocaleTranslation()

  const deletePost = useMutation({
    mutationFn: deleteData,
  });

  useEffect(() => {
    if (!deletePost.isLoading) {
      topicsQuery.refetch();
    }
  }, [deletePost.isLoading]);

  const [categoryQuery, topicsQuery] = useQueries({
    queries: [
      {
        queryKey: ["categories", page, pageSize],
        queryFn: () => getData(selctedTopicId, page, pageSize),
        retry: 0,
        staleTime: Infinity,
      },
      {
        queryKey: ["topics"],
        queryFn: () => getTopics(),
        retry: 0,
        staleTime: Infinity,
      },
    ],
  });

  useEffect(() => {
    if (selctedTopicId || selctedTopicId === undefined) {
      categoryQuery.refetch();
    }
  }, [selctedTopicId]);

  useEffect(() => {
    if (topicsQuery.data?.length > 0) {
      settopicsArr(
        topicsQuery.data?.map((i: ITopic) => {
          return { value: i?.id, label: _translate(i?.translations,"title") };
        })
      );
    }
  }, [topicsQuery.data]);

  if (categoryQuery.error) return "An error has occurred: " + topicsQuery.error;

  return (
    <div>
      <div className="grid grid-cols-12 gap-4">
        <div className="main-card lg:col-span-8 col-span-12">
          <div className="flex mb-3 justify-between">
            <PageTitle title={t("Categories")} />
            {is_permission("create_category") ? (
              <Button
                onClick={() => {
                  setOpenSidebarMenu(true);
                  setSelected(null);
                }}
                type="default"
                className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763] lg:hidden"
              >
                + {t("Create")}
              </Button>
            ) : null}
          </div>
          {t("By topic")}:
          <Select
            allowClear
            className="mb-3 ml-3"
            value={selctedTopicId}
            style={{ width: 150 }}
            onChange={(e) => setselctedTopicId(e)}
            options={topicsArr}
          />
          <Table className="table-striped table-hover">
            <Thead>
              <Tr>
                <Th className="w-[50px]">№</Th>
                <Th className="w-[50px] text-center px-[0!important]">{t("Id")}</Th>
                <Th>{t("Title")}</Th>
                <Th>{t("Description")}</Th>
                <Th>{t("Slug")}</Th>
                <Th>{t("Status")}</Th>
                <Th>{t("Actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!categoryQuery.isLoading
                ? categoryQuery.data?.results?.length > 0
                  ? categoryQuery.data?.results?.map((item: ICategory, index: number) => (
                      <Tr key={item.id}>
                        <Td>{index + 1}</Td>
                        <Td className="w-[50px] text-center px-[0!important]">{item?.id}</Td>
                        <Td>{_translate(item?.translations,"title")}</Td>
                        <Td>{_translate(item?.translations,"desc")}</Td>
                        <Td>{item?.slug}</Td>
                        <Td>
                          {item?.is_active ? (
                            <Badge count={"Active"} showZero color="#52c41a" />
                          ) : (
                            <Badge count={"InActive"} showZero color="#faad14" />
                          )}
                        </Td>
                        <Td>
                          <div className="flex" style={{ alignItems: "center" }}>
                            {is_permission("update_category") ? (
                              <FiEdit
                                className="mx-2 text-[green] text-[18px] cursor-pointer"
                                onClick={() => {
                                  setSelected(item);
                                  setOpenSidebarMenu(true);
                                }}
                              />
                            ) : null}
                            {is_permission("delete_category") ? (
                              <DeleteIcon confirmFunc={() => deletePost.mutate(item?.id)} />
                            ) : null}
                          </div>
                        </Td>
                      </Tr>
                    ))
                  : null
                : null}
            </Tbody>
          </Table>
          {categoryQuery.data?.results?.length === 0 ? <Empty className="w-[100%]" /> : null}
          <TableLoader isLoading={categoryQuery.isLoading} />
          <div className="text-right mt-3">
            <Pagination
              onChange={(page_num, page_size) => {
                setpage(page_num);
                setpageSize(page_size);
              }}
              defaultCurrent={page | 1}
              pageSize={pageSize}
              total={categoryQuery?.data?.total_results}
            />
          </div>
        </div>
        {(is_permission("create_category") && !selected) || (is_permission("update_category") && selected) ? (
          <div className="main-card col-span-4 lg:block hidden">
            <CreateCategory
              element={selected}
              alltopics={topicsArr}
              setElement={setSelected}
              refetch={categoryQuery.refetch}
              openSidebarMenu={openSidebarMenu}
              setOpenSidebarMenu={setOpenSidebarMenu}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Categories;
