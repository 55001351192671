import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { FiEdit } from "react-icons/fi";
import DeleteIcon from "components/Common/deleteIcon";
import { deleteData, getData } from "./sphere.request";
import { AxiosError } from "axios";
import { Button, Empty, Pagination } from "antd";
import TableLoader from "components/loaders/table_loader";
import PageTitle from "components/Common/PageTitle";
import { is_permission } from "utils.functions";
import { InformerDRDT } from "types/base.models";
import { useTranslation } from "react-i18next";
import { BiCheck } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { ISphere } from "./types";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import CreateSphere from "./sphere_form_ui";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";

const Spheres = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<ISphere | null>(null);
  const [openSidebarMenu, setOpenSidebarMenu] = useState<boolean>(false);
  const [page, setpage] = useState<number>(1);
  const [pageSize, setpageSize] = useState<number>(10);

  const deletePost = useMutation({
    mutationFn: deleteData,
    onSuccess: () => {
      refetch();
    },
  });
const {_translate} = useLocaleTranslation()
  const { isLoading, error, data, refetch } = useQuery<InformerDRDT<ISphere>, AxiosError>({
    queryKey: ["spheres", page, pageSize],
    queryFn: () => getData(page, pageSize),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  if (error) return "An error has occurred: " + error.message;

  return (
    <div>
      <div className="grid grid-cols-12 gap-4">
        <div className="main-card lg:col-span-12 col-span-12">
          <div className="flex mb-3 justify-between">
            <PageTitle title={"Spheres"} />
            {is_permission("create_sphere") ? (
              <Button
                onClick={() => {
                  setOpenSidebarMenu(true);
                  setSelected(null);
                }}
                type="default"
                className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]"
              >
                + {t("Create")}
              </Button>
            ) : null}
          </div>
          <Table className="table-striped table-hover">
            <Thead>
              <Tr>
                <Th className="w-[50px]">№</Th>
                <Th className="w-[50px] text-center px-[0!important]">{t("Id")}</Th>
                <Th>{t("Title")}</Th>
                <Th>{t("Description")}</Th>
                <Th>{t("Active")}</Th>
                <Th>{t("Date")}</Th>
                <Th>{t("Actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading
                ? data?.results?.length > 0
                  ? data?.results?.map((item, index: number) => (
                      <Tr key={item?.id}>
                        <Td>{(page - 1) * pageSize + index + 1}</Td>
                        <Td className="w-[50px] text-center px-[0!important]">{item?.id}</Td>
                        <Td>{_translate(item?.translations,"title")}</Td>
                        <Td>{_translate(item?.translations,"description")}</Td>
                        <Td>
                          {item?.is_active ? (
                            <BiCheck className="mx-auto text-[24px] text-green-700" />
                          ) : (
                            <IoMdClose className="mx-auto text-[22px] text-rose-600" />
                          )}
                        </Td>
                        <Td>
                          {item?.created_at?.slice(0, 10)}, {item?.created_at?.slice(11, 16)}
                        </Td>
                        <Td>
                          <div className="flex" style={{ alignItems: "center" }}>
                            {is_permission("read_sphere") ? (
                              <Link to={`/sphere/view/${item?.id}`}>
                                <AiOutlineEye className=" mr-2 text-[#faad14] text-[22px] cursor-pointer" />
                              </Link>
                            ) : null}
                            {is_permission("update_sphere") ? (
                              <FiEdit
                                className="mx-2 text-[green] text-[18px] cursor-pointer"
                                onClick={() => {
                                  setSelected(item);
                                  setOpenSidebarMenu(true);
                                }}
                              />
                            ) : null}
                            {is_permission("delete_sphere") ? (
                              <DeleteIcon confirmFunc={() => deletePost.mutate(item?.id)} />
                            ) : null}
                          </div>
                        </Td>
                      </Tr>
                    ))
                  : null
                : null}
            </Tbody>
          </Table>
          {data?.results?.length === 0 ? <Empty className="w-[100%]" /> : null}
          <TableLoader isLoading={isLoading} />
          <div className="text-right mt-3">
            <Pagination
              onChange={(page_num, page_size) => {
                setpage(page_num);
                setpageSize(page_size);
              }}
              defaultCurrent={page}
              pageSize={pageSize}
              total={data?.total_results}
            />
          </div>
        </div>
        {(is_permission("create_sphere") && !selected) || (is_permission("update_sphere") && selected) ? (
          <div className="main-card col-span-6 hidden">
            <CreateSphere
              element={selected}
              setElement={setSelected}
              refetch={refetch}
              openSidebarMenu={openSidebarMenu}
              setOpenSidebarMenu={setOpenSidebarMenu}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Spheres;
