import React, { Dispatch, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Drawer, Form, Input, Select } from "antd";
import { updateRating, getStudentsForCourseRating, postRating } from "./rating.request";
import { ICourseRating } from "./types";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import { IUser } from "types/data.models";
import useDebounce from "custom-hooks/useDebounce";
import { useTranslation } from "react-i18next";

const CreateCourseRating = ({
  element,
  setElement,
  refetch,
  openSidebarMenu,
  setOpenSidebarMenu,
}: {
  element: ICourseRating | null;
  setElement: Dispatch<ICourseRating | null>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: Dispatch<boolean>;
}) => {

  const {t} = useTranslation()
  const { id } = useParams();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [searcVal, setsearcVal] = useState<string>("");
  const debouncedValue = useDebounce<string>(searcVal, 400);
  const [studentsList, setStudentsList] = useState<IUser[]>([]);

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue(element);
    } else {
      form.resetFields();
      form.setFieldsValue({
        is_active: true,
        is_verified: false,
        translations: [{ title: "", desc: "" }],
      });
    }
    // student_mutate("");
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-course-rating"],
    mutationFn: (newValues) => (element ? updateRating(element?.id, newValues) : postRating(id, newValues)),
    onSuccess: async (data) => {
      queryClient.setQueryData(["course-ratings"], data);
      setOpenSidebarMenu(false);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  useEffect(() => {
    student_mutate(debouncedValue);
  }, [debouncedValue]);

  const {
    mutate: student_mutate,
    isLoading: review_student_loading,
    data: students,
  } = useMutation({
    mutationKey: ["course-students-review"],
    mutationFn: (newData: any) => getStudentsForCourseRating(id, newData),
    onSuccess: async (data) => {
      setStudentsList(data.results);
    },
    onError: (error: AxiosError<any>) => {
      // validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    values.course_id = id;
    mutate(values);
  }

  const onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
    setsearcVal(value);
  };
  const formFields = (
    <Form layout={"vertical"} form={form} onFinish={handleSubmit} onSubmitCapture={() => console.log("salom")}>
      <div className="grid grid-cols-12 gap-x-4">
        <div className="col-span-6">
          <Form.Item
            label="Rating"
            required
            name="rating"
            rules={[{ required: false, message: "Please input rating!" }]}
          >
            <Input placeholder="Rating" type="number" required={true} />
          </Form.Item>
        </div>
        <div className="col-span-6">
          <Form.Item
            required
            label="Student"
            name="user_id"
            rules={[{ required: false, message: "Please input sort!" }]}
          >
            <Select
              showSearch
              placeholder="Select a student"
              // optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              loading={review_student_loading}
              filterOption={false}
              options={studentsList?.map((i: IUser) => {
                return { value: i?.id, label: i?.translations[0]?.first_name + " " + i?.translations[0]?.last_name };
              })}
            />
          </Form.Item>
        </div>
      </div>

      <div className="mt-2 text-right">
        <Button
          onClick={() => {
            form.resetFields();
            setElement(null);
            form.setFieldsValue({
              is_active: true,
              translations: [{ title: "" }],
            });
          }}
          type="default"
          className="bg-[red] text-white hover:text-white hover:bg-[#FF2A2A] sm:mr-2 mb-2"
        >
          {t("Reset")}
        </Button>
        <Button
          loading={isLoading}
          type="default"
          className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]"
          htmlType="submit"
        >
          {t("Save")}
        </Button>
      </div>
    </Form>
  );

  return (
    <Drawer
      title={<h3 className=" font-bold">Izoh</h3>}
      placement="right"
      onClose={() => setOpenSidebarMenu(false)}
      open={openSidebarMenu}
      width={800}
    >
      {formFields}
    </Drawer>
  );
};
export default CreateCourseRating;
