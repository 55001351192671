import { FormInstance } from "antd";
import { ILesson } from "pages/module_lessons/types";
import React from "react";

const useFilterLanguage = (form: FormInstance, _translations?: ILesson['translations']) => {

    const [languageIds, setLanguageIds] = React.useState<(number | undefined)[]>([]);

    const fl = (): void => {

        const tranlations = _translations ? _translations : (form.getFieldValue("translations") ?? []) as Array<{ title: string, language_id: number }>;

        const ids = tranlations?.map(e => {
            if (e?.language_id) {
                return e.language_id
            }
        })
        setLanguageIds(ids)
    }


    return { languageIds, fl }
}



export default useFilterLanguage;