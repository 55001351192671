import instance from "config/axios.config";
import { ILesson } from "pages/module_lessons/types";
import { IFileBlock, IQuizBlock, ITextBlock, ITrainingBlock, IVideoBlock } from "./types";

export async function submitData(id: number | undefined, data: ILesson) {
  const url = id ? `/course-lesson/${id}` : `course-lesson/create`;
  const response = await instance({ url, method: id ? "PUT" : "POST", data });
  return response.data;
}

export async function submitTrainingBlock(data: ITrainingBlock) {
  const url = data?.training_block_id ? `/training-block/update/${data?.training_block_id}` : `training-block/create`;
  const response = await instance({ url, method: data?.training_block_id ? "PUT" : "POST", data });
  return response.data;
}

export async function removeBlock(removedItem: any) {
  const url =
    removedItem.code_slug === "text_block"
      ? `/text-block/${removedItem.id}`
      : removedItem.code_slug === "video_block"
      ? `/video-block/${removedItem.id}`
      : removedItem.code_slug === "training_block"
      ? `/training-block/${removedItem.id}`
      : removedItem.code_slug === "file_block"
      ? `/file-block/${removedItem.id}`
      : removedItem.code_slug === "quiz_block"
      ? `/quiz-block/${removedItem.id}`
      : "";
  const response = await instance({ url, method: "DELETE" });
  return response.data;
}

export async function submitTextBlock(data: ITextBlock) {
  const url = data?.id ? `/text-block/update/${data?.id}` : `text-block/create`;
  const response = await instance({ url, method: data?.id ? "PUT" : "POST", data });
  return response.data;
}

export async function submitVideoBlock(data: IVideoBlock) {
  const url = data?.id ? `/video-block/update/${data?.id}` : `video-block/create`;
  const response = await instance({ url, method: data?.id ? "PUT" : "POST", data });
  return response.data;
}

export async function submitFileBlock(data: any) {
  const formData = new FormData();
  if (data.file) {
    formData.append("media", data.file);
  }
  formData.append("payload", JSON.stringify(data.payload));
  const url = data?.payload?.id ? `/file-block/update/${data?.payload?.id}` : `file-block/create`;
  const response = await instance({ url, method: data?.payload?.id ? "PUT" : "POST", data: formData });
  return response.data;
}

export async function submitQuizBlock(data: any) {
  const url = data?.quiz_block_id ? `/quiz-block/update/${data?.quiz_block_id}` : `quiz-block/create`;
  const response = await instance({ url, method: data?.quiz_block_id ? "PUT" : "POST", data });
  return response.data;
}
