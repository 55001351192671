import instance from "config/axios.config";
import { IGroup } from "../types";

export async function submitCourses(course_group_id: string | undefined, students_ids: string[], course_group: IGroup | undefined) {

  const response = await instance({
    url: `/course-group/${course_group_id}`,
    method: "PUT",
    data: { students: students_ids, curator_id: course_group?.curator?.id },
  });
  return response.data;
}

export async function getCourseStudents(course_group_id: string | undefined, search_student_name: string, page_number: number, pageSize: number) {
  const response = await instance({
    url: `/course-group/${course_group_id}/users/list/?q=${search_student_name}&page_number=${page_number}&page_size=${pageSize}&desc=true`,
    method: "GET",
  });
  return response.data;
}

export async function getCourseGroup(course_group_id: string | undefined) {
  const response = await instance({ url: `/course-group/${course_group_id}`, method: "GET" });
  return response.data;
}
