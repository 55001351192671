import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { FiEdit } from "react-icons/fi";
import DeleteIcon from "components/Common/deleteIcon";
import { deleteData, getDistrict } from "./district.request";
import { IDistrict, IDistrictWithPagination, IRegionSelect } from "./types";
import CreateDistrict from "./district_form_ui";
import { Badge, Button, Empty, Select } from "antd";
import TableLoader from "components/loaders/table_loader";
import { IRegion } from "pages/regions/types";
import { AxiosError } from "axios";
import useGetData from "custom-hooks/useGetData";
import PageTitle from "components/Common/PageTitle";
import { is_permission } from "utils.functions";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const Districts = () => {
  const { t } = useTranslation();

  const { data: allRegions } = useGetData<IRegion[]>({
    queryKey: ["regions"],
    url: "region/list/",
    options: { staleTime: Infinity, refetchOnWindowFocus: true },
  });
  const [selected, setSelected] = React.useState<IDistrict | null>(null);
  const [openSidebarMenu, setOpenSidebarMenu] = useState<boolean>(false);
  const [regionsArr, setregionsArr] = useState<IRegionSelect[]>([]);
  const [selctedRegionId, setselctedRegionId] = useState<number | null>(allRegions ? allRegions[0]?.id : null);

  const deletePost = useMutation({
    mutationFn: deleteData,
  });

  const { isLoading, error, data, refetch } = useQuery<IDistrictWithPagination, AxiosError>({
    queryKey: ["districts"],
    queryFn: () => getDistrict(selctedRegionId),
    retry: 0,
    enabled: !!selctedRegionId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!deletePost.isLoading) {
      refetch();
    }
  }, [deletePost.isLoading]);

  useEffect(() => {
    refetch();
  }, [selctedRegionId]);

  useEffect(() => {
    if (allRegions) {
      setregionsArr(
        allRegions?.map((i: IRegion) => {
          return { value: i?.id, label: i?.translations[0]?.title };
        })
      );
      setselctedRegionId(allRegions[0]?.id);
    }
  }, [allRegions]);

  if (!(allRegions && allRegions[0]?.id)) return "Region kiritilmagan!";
  if (error) return "An error has occurred: " + error;

  return (
    <div className="districts-wrapper grid grid-cols-12 gap-4">
      <div className="main-card lg:col-span-8 col-span-12">
        <div className="flex mb-3 justify-between">
          <PageTitle title={"Districts"} />
          <div className="flex sm:flex-row flex-col gap-2">
            {is_permission("delete_district") ? (
              <Button
                onClick={() => {
                  setOpenSidebarMenu(true);
                  setSelected(null);
                }}
                type="default"
                className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763] lg:hidden"
              >
                + {t("Create")}
              </Button>
            ) : null}
            <Select
              className="mb-3"
              value={selctedRegionId}
              style={{ width: 200 }}
              onChange={(e) => setselctedRegionId(e)}
              options={allRegions?.map((i: IRegion) => {
                return { value: i?.id, label: i?.translations[0]?.title };
              })}
            />
          </div>
        </div>
        <Table className="table-striped table-hover">
          <Thead>
            <Tr>
              <Th className="w-[50px]">№</Th>
              <Th className="w-[50px] text-center px-[0!important]">{t("Id")}</Th>
              <Th>{t("Title")}</Th>
              <Th>{t("Code")}</Th>
              <Th>{t("Status")}</Th>
              <Th>{t("Actions")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading
              ? data?.results?.length > 0
                ? data?.results?.map((item: IDistrict, index: number) => (
                    <Tr key={item.id}>
                      <Td>{index + 1}</Td>
                      <Td className="w-[50px] text-center px-[0!important]">{item?.id}</Td>
                      <Td>{item?.translations[0]?.title}</Td>
                      <Td>{item?.code}</Td>
                      <Td>
                        {item?.is_active ? (
                          <Badge count={t("Active")} showZero color="#52c41a" />
                        ) : (
                          <Badge count={t("InActive")} showZero color="#faad14" />
                        )}
                      </Td>
                      <Td>
                        <div className="flex" style={{ alignItems: "center" }}>
                          {is_permission("update_district") ? (
                            <FiEdit
                              className="mx-2 text-[green] text-[18px] cursor-pointer"
                              onClick={() => {
                                setSelected(item);
                                setOpenSidebarMenu(true);
                              }}
                            />
                          ) : null}
                          {is_permission("delete_district") ? (
                            <DeleteIcon confirmFunc={() => deletePost.mutate(item?.id)} />
                          ) : null}
                        </div>
                      </Td>
                    </Tr>
                  ))
                : null
              : null}
          </Tbody>
        </Table>
        {data?.results?.length === 0 ? <Empty className="w-[100%]" /> : null}
        <TableLoader isLoading={isLoading} />
      </div>
      {(is_permission("create_district") && !selected) || (is_permission("update_district") && selected) ? (
        <div className="main-card col-span-4 lg:block hidden">
          <CreateDistrict
            allRegions={allRegions?.map((i: IRegion) => {
              return { value: i?.id, label: i?.translations[0]?.title };
            })}
            element={selected}
            setElement={setSelected}
            refetch={refetch}
            openSidebarMenu={openSidebarMenu}
            setOpenSidebarMenu={setOpenSidebarMenu}
          />
        </div>
      ) : null}
    </div>
  );
};
export default Districts;
