import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { ITextBlock } from "../types";
import { Button, Col, Form, FormInstance, Input, Row, Select, Switch } from "antd";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { submitTextBlock } from "../block.request";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { useTranslation } from "react-i18next";
import useGetData from "custom-hooks/useGetData";
import { ILanguage } from "pages/languages/types";
import { BiPlus, BiX } from "react-icons/bi";
import LanguageTitle from "utils/LanguageTitle";
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch } from "store/service";
import { BLOCK_ACTIONS } from "store/block";
import { IBlockForm } from "pages/module_lessons/types";

const { Option } = Select;

const TextBlock = ({
  uuid,
  sort,
  textData,
  setBlockForms,
  blockForms,
}: {
  uuid: string;
  sort: number;
  textData: ITextBlock | undefined;
  setBlockForms: (item: IBlockForm[]) => void;
  blockForms: IBlockForm[];
}) => {
  const [editorData, setEditorData] = useState<string[]>([]);
  const { id } = useParams();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [newUUID] = useState(uuidv4());
  const dispatch = useAppDispatch();
  const [newData, setNewData] = useState<ITextBlock | null>(null);

  const handleEditorChange = (event: any, editor: any, index: number) => {
    const data = editor.getData();
    let datas = [...editorData];
    datas[index] = data;
    setEditorData(datas);
  };

  React.useEffect(() => {
    if (textData) {
      setEditorData(textData.translations.map((it) => it.content));
      form.setFieldsValue(textData);
    } else {
      form.resetFields();
      form.setFieldsValue({
        is_active: true,
        translations: [{}],
      });
    }
  }, [textData?.id]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-training-block"],
    mutationFn: (newValues: any) => submitTextBlock(newValues),
    onSuccess: async (data) => {
      setNewData(data);
      dispatch(BLOCK_ACTIONS.changeBlockItem({ id: uuid, loading: false }));
    },
    onError: (error: AxiosError<any>) => {
      dispatch(BLOCK_ACTIONS.changeBlockItem({ id: uuid, loading: false }));
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });
  function handleSubmit(values: any) {
    values.translations.forEach((item: any, ind: number) => {
      item.content = editorData[ind];
    });
    values.sort = sort;
    values.course_lesson_id = id;
    if (textData) {
      values.id = textData.id;
    } else if (newData) {
      values.id = newData.id;
    }
    mutate(values);
    dispatch(BLOCK_ACTIONS.changeBlockItem({ id: uuid, loading: true }));
  }

  const { data: languages } = useGetData<ILanguage[]>({
    queryKey: ["languages"],
    url: "language/list/",
    options: { staleTime: Infinity, refetchOnWindowFocus: false },
  });

  const [languageIds, setLanguageIds] = React.useState<(number | undefined)[]>([]);
  const filterLanguage = () => {
    const tranlations = (form.getFieldValue("translations") ?? []) as Array<{ title: string; language_id: number }>;

    const ids = tranlations?.map((e) => {
      if (e?.language_id) {
        return e.language_id;
      }
    });
    setLanguageIds(ids);
  };

  useEffect(() => {
    if (!blockForms.map((it) => it.id).includes(uuid)) {
      let arr = blockForms;
      arr.push({ id: uuid, form: form });
      setBlockForms(arr);
    }
  }, [uuid]);

  return (
    <div className="lesson__text__block">
      <Form layout={"vertical"} form={form} onFinish={handleSubmit} className="custom_form_style">
        <Form.List name="translations">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <div key={index} className="grid grid-cols-12 justify-between align-middle mb-2">
                  <div className={`md:col-span-8 col-span-12 flex editor${newUUID}`}>
                    <CKEditor
                      editor={Editor}
                      data={editorData[index]}
                      onChange={(e: any, ed: any) => handleEditorChange(e, ed, index)}
                    />
                  </div>
                  <div className="md:col-span-4 col-span-12 flex">
                    <Form.Item
                      className="w-[calc(100%-55px)] mb-0 "
                      label={<FormLabelCrud>Languages</FormLabelCrud>}
                      {...restField}
                      name={[name, "language_id"]}
                      rules={[{ required: index === 0, message: "Please select language" }]}
                    >
                      <Select onClick={filterLanguage}>
                        {languages?.map((element: ILanguage) => (
                          <Option key={element.id} value={element.id} disabled={languageIds.includes(element.id)}>
                            <LanguageTitle element={element} />
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item className="w-[50px] mb-0 ml-2 mt-[24px] text-end">
                      <Button
                        disabled={fields.length == 1}
                        onClick={() => {
                          remove(name);
                        }}
                        danger
                      >
                        <BiX />
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              ))}
              <div className="text-right">
                <Button
                  type="link"
                  className="inline-flex align-middle items-center lg:text-sm text-xs font-medium uppercase sm:px-[15px] pl-0"
                  onClick={() => add()}
                  disabled={fields.length === languages?.length}
                >
                  <BiPlus className="mt-[2px] mr-1 lg:text-sm text-xs font-bold" />
                  {t("Add translation")}
                </Button>
              </div>
            </>
          )}
        </Form.List>
        <Form.Item
          label={<FormLabelCrud>Status</FormLabelCrud>}
          name="is_active"
          rules={[{ required: false, message: "Please input is status!" }]}
          valuePropName={"checked"}
        >
          <Switch defaultChecked className="bg-[#999]" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default TextBlock;
