import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Drawer, Form, Input } from "antd";
import useWindowSize from "custom-hooks/useWindowSize";
import { submitData } from "./contact.request";
import { IContact } from "./types";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import PartTitle from "components/Common/PartTitle";
import EditingTitle from "components/Common/EditingTitle";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { RButton, SButton } from "components/Common/CustomButtons";
import { useTranslation } from "react-i18next";


type TypeTagFormProps = {
  element: IContact | null;
  setElement: React.Dispatch<React.SetStateAction<IContact | null>>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: React.Dispatch<React.SetStateAction<boolean>>;
}


const CreateContact = ({ element, setElement, refetch, openSidebarMenu, setOpenSidebarMenu }: TypeTagFormProps) => {
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const { width } = useWindowSize();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue({
        name: element?.name,
        phone: element?.phone
      })
    } else {
      form.resetFields()
    }
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-contact"],
    mutationFn: (newVals) => submitData(element?.id, newVals),
    onSuccess: async (data) => {
      queryClient.setQueryData(["contacts"], data);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data)
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    mutate(values);
  }



  const MemoizationFieldsFormTag = React.memo(() => (
    <Form
      layout={"vertical"}
      form={form}
      onFinish={handleSubmit}
      className="custom_form_style"
    >
      <div className="grid grid-cols-12 gap-4">
        <div className="md:col-span-6 col-span-12">
          <Form.Item
            label={<FormLabelCrud>Name</FormLabelCrud>}
            name="name"
            rules={[
              { required: true, message: "Please input language name!" },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="md:col-span-6 col-span-12">
          <Form.Item
            label={<FormLabelCrud>Phone</FormLabelCrud>}
            name="phone"
            rules={[
              { required: true, message: "Please input language phone!" },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
      </div>
      <div className="flex gap-2 mt-2 text-end">
        <RButton
          onClick={() => {
            form.resetFields();
            setElement(null);
          }}
        />
        <SButton loading={isLoading} />
      </div>
    </Form>
  ));

  return (
    <div>
      <>{element ? <EditingTitle title={element?.name} /> : <PartTitle children={t("Create contact")} />}</>
      <hr className="my-3" />
      <MemoizationFieldsFormTag />
      <Drawer
        title={<h3 className=" font-bold">{element?.name ? element?.name : t("New")}</h3>}
        placement="right"
        onClose={() => setOpenSidebarMenu(false)}
        open={width > 992 ? false : openSidebarMenu}
      >
        <MemoizationFieldsFormTag />
      </Drawer>
    </div>
  );
};
export default CreateContact;
