import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getData, submitData } from "./course_mentor.requests";
import { Button, Card, Empty, message, Modal, Table } from "antd";
import TableLoader from "components/loaders/table_loader";
import { Link, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { InformerDRDT } from "types/base.models";
import { IUser } from "types/data.models";
import useGetData from "custom-hooks/useGetData";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import { useState } from "react";
import type { ColumnsType } from "antd/es/table";
import { ICourseMentor } from "./types";
import { useTranslation } from "react-i18next";

interface DataType {
  key: React.Key;
  name: string;
  phone: string;
  email: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Full name",
    dataIndex: "name",
  },
  {
    title: "Phone",
    dataIndex: "phone",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
];

const CourseMentors = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [tableData, settableData] = useState<DataType[]>([]);
  const [selectedIds, setselectedIds] = useState<React.Key[]>([]);

  const queryClient = useQueryClient();

  const { data: course, refetch } = useGetData({
    queryKey: ["course-by-id"],
    url: `/course/${id}`,
    options: { staleTime: Infinity, refetchOnWindowFocus: false },
  });

  const { data, isLoading, error } = useQuery<InformerDRDT<IUser>, AxiosError>({
    queryKey: ["course-mentors"],
    queryFn: () => getData(),
    onSuccess: (res) => {
      const arr = res?.results?.map((i) => {
        return {
          key: i?.id,
          name: i?.translations[0]
            ? `${i?.translations[0]?.first_name} ${i?.translations[0]?.last_name}`
            : "",
          phone: i?.phone,
          email: i?.email,
        };
      });
      settableData(arr);
    },
    retry: 0,
    refetchOnWindowFocus: false,
  });

  const submitMutation = useMutation({
    mutationKey: ["add-course-mentor"],
    mutationFn: (newValues: React.Key[]) => submitData(id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["courses"], data);
      refetch();
      setIsOpen(false);
    },
    retry: 0,
  });

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setselectedIds(selectedRowKeys);
    },
  };

  if (error) return <p>An error has occurred: + {error.message}</p>;

  return (
    <div>
      <div className="mb-3 text-right">
        <Button
          onClick={() => setIsOpen(true)}
          type="default"
          className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]"
        >
          + Mentor biriktirish
        </Button>
      </div>
      <div className="grid grid-cols-12 gap-4">
        {!isLoading
          ? course?.mentors?.length > 0
            ? course?.mentors?.map((item: ICourseMentor) => (
                <div
                  key={item?.id}
                  className="xl:col-span-3 lg:col-span-4 md:col-span-6 col-span-12"
                >
                  <Card
                    className="w-[100%]"
                    actions={[
                      <Link to={`/users/view/${item?.id}`}>
                        <EyeOutlined key="setting" />
                      </Link>,
                      <Link to={`/user/update/${item?.id}`}>
                        <EditOutlined key="edit" />
                      </Link>,
                    ]}
                  >
                    <Meta
                      title={
                        item?.translations[0]
                          ? `${item?.translations[0]?.first_name} ${item?.translations[0]?.last_name}`
                          : ""
                      }
                      description={[<p>{item?.phone}</p>, <p>{item?.email}</p>]}
                    />
                  </Card>
                </div>
              ))
            : null
          : null}
      </div>
      {course?.mentors?.length === 0 ? <Empty className="w-[100%]" /> : null}
      <TableLoader isLoading={isLoading} />

      <Modal
        title="Mentors"
        width={1000}
        open={isOpen}
        footer={[
          <Button onClick={() => submitMutation.mutate(selectedIds)}>
            {t("Save")}
          </Button>,
        ]}
        onCancel={() => setIsOpen(false)}
      >
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={columns}
          dataSource={tableData}
        />
      </Modal>
    </div>
  );
};
export default CourseMentors;
