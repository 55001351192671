import { Tabs, TabsProps } from "antd";
import ToBack from "components/Common/ToBack";
import ModuleLessons from "pages/module_lessons";
import ViewModuleDetails from "./details";
import { is_permission } from "utils.functions";
import { useTranslation } from "react-i18next";
import useUrlQueryParams from "custom-hooks/useUrlQueryParams";

const CourseModuleView = ({ id }: { id: string | undefined }) => {
  const { value, writeToUrl } = useUrlQueryParams({});

  const { t } = useTranslation();

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t(`Details`),
      children: <ViewModuleDetails />,
    },
    {
      key: "2",
      label: t(`Lessons`),
      children: <ModuleLessons />,
    },
  ];

  let filtered_items: TabsProps["items"] = [];
  items.forEach((item, index) => {
    if (!(!is_permission("read_course_lesson") && item.label === "Lessons")) {
      filtered_items?.push(item);
    }
  });
  const onChange = (key: string) => {
    writeToUrl({ name: "tab_id", value: key });
  };

  return (
    <>
      <ToBack title={t("Back")} />
      <div className="col-span-12 main-card mt-4">
        <Tabs activeKey={String(value.filter.tab_id ?? 2)} items={filtered_items} onChange={onChange} />
      </div>
    </>
  );
};
export default CourseModuleView;
