import { Button, Checkbox, Form, FormInstance, Input, Select } from "antd";
import FormLabelCrud from "components/FormLabel/FormLabel";
import useFilterLanguage from "custom-hooks/useFilterLanguage";
import { BiPlus, BiX } from "react-icons/bi";
import { ILanguage } from "pages/languages/types";
import "suneditor/dist/css/suneditor.min.css";
import useGetData from "custom-hooks/useGetData";
import { IDiscount } from "pages/discounts/types";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";
import LanguageTitle from "utils/LanguageTitle";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const LessonCreateUi = ({ form }: { form: FormInstance }) => {
    const {t} = useTranslation()
    const { _translate } = useLocaleTranslation();
    const { data: discounts } = useGetData<IDiscount[]>({ queryKey: ['discounts'], url: '/discount/list/', options: { staleTime: Infinity, refetchOnWindowFocus: false } });
    const { data: languages } = useGetData<ILanguage[]>({ queryKey: ['languages'], url: 'language/list/', options: { staleTime: Infinity, refetchOnWindowFocus: false } });
    const { fl, languageIds } = useFilterLanguage(form);

    return (

        <div className="grid grid-cols-12 gap-4">
            <Form.List name="translations">
                {(fields, { add, remove }) => (
                    <div className="col-span-12">
                        {fields.map(({ key, name, ...restField }, index) => (
                            <div key={index} className='grid grid-cols-12 gap-4 mb-4 bg-slate-50 py-1 px-3 rounded-md mt-2'>
                                <div className="lg:col-span-3 md:col-span-6 col-span-12">
                                    <Form.Item
                                        className='mb-0'
                                        label={<FormLabelCrud>Name</FormLabelCrud>}
                                        {...restField}
                                        name={[name, 'title']}
                                        rules={[{ required: index === 0, message: 'Please input title!' }]}
                                    >
                                        <Input placeholder="Ma'lumot kiriting" />
                                    </Form.Item>
                                </div>
                                <div className="md:col-span-6 col-span-12">
                                    <Form.Item
                                        className='mb-0'
                                        label={<FormLabelCrud>Description</FormLabelCrud>}
                                        {...restField}
                                        name={[name, 'desc']}
                                        rules={[{ required: false, message: 'Please input Description!' }]}
                                    >
                                        <Input placeholder="Description" />
                                    </Form.Item>
                                </div>

                                <div className="lg:col-span-3 md:col-span-6 col-span-12 flex items-end">
                                    <Form.Item
                                        label={<FormLabelCrud>Languages</FormLabelCrud>}
                                        {...restField}
                                        name={[name, 'language_id']}
                                        rules={[{ required: index === 0, message: 'Please select language' }]}
                                        className="w-[calc(100%-55px)] mb-0"
                                        hasFeedback
                                    >
                                        <Select onClick={fl}>
                                            {
                                                languages?.map(element => <Option key={element.id} value={element.id} disabled={languageIds.includes(element.id)}><LanguageTitle element={element}/></Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item className="w-[50px] mb-0 ml-2 mt-[25px]">
                                        <Button disabled={fields.length == 1} onClick={() => { remove(name); }} danger><BiX /></Button>
                                    </Form.Item>
                                </div>
                                {/* <div className="col-span-12">
                                    <Form.Item
                                        className='mb-0'
                                        label={<FormLabelCrud>Editor</FormLabelCrud>}
                                        {...restField}
                                        name={[name, 'content']}
                                        rules={[{ required: false, message: 'Please input Description!' }]}
                                    >
                                        <SunEditor
                                            setOptions={{
                                                buttonList: buttonList,
                                            }}
                                        />
                                    </Form.Item>
                                </div> */}
                            </div>
                        ))}
                        <div className="col-span-12 text-right">
                            <Button type="link" size="small" className="inline-flex align-middle items-center lg:text-sm text-xs font-medium uppercase sm:px-[15px] pl-0" onClick={() => add()} disabled={fields.length === languages?.length}>
                                <BiPlus size={20} className="mt-[2px] mr-2" />{t("Add translation")}
                            </Button>
                        </div>
                    </div>
                )}
            </Form.List>
            <div className="lg:col-span-3 sm:col-span-6 col-span-12">
                <Form.Item
                    label={<FormLabelCrud>Price</FormLabelCrud>}
                    name="price"
                    rules={[{ required: false, message: 'Please input Price!' }]}
                >
                    <Input placeholder="Percent" type="number" />
                </Form.Item>
            </div>
            <div className="lg:col-span-3 sm:col-span-6 col-span-12">
                <Form.Item
                    label={<FormLabelCrud>Sort</FormLabelCrud>}
                    name="sort"
                    rules={[{ required: true, message: 'Please input sort!' }]}
                >
                    <Input type="number" className="w-[100%]" onWheel={e => e.currentTarget.blur()} />
                </Form.Item>
            </div>
            <div className="lg:col-span-3 sm:col-span-6 col-span-12">
                <Form.Item
                    label={<FormLabelCrud>Slug</FormLabelCrud>}
                    name="slug"
                    rules={[{ required: false, message: 'Please input slug!' }]}
                >
                    <Input className="w-[100%]" />
                </Form.Item>
            </div>
            <div className="lg:col-span-3 sm:col-span-6 col-span-12">
                <Form.Item
                    label={<FormLabelCrud>Discounts</FormLabelCrud>}
                    name="discount_id"
                    rules={[{ required: false, message: 'Please input slug!' }]}
                >
                    <Select>
                        {
                            discounts?.map((element) => <Select.Option key={element.id} value={element.id}>{_translate(element.translations, 'title')}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
            </div>
            <div className="lg:col-span-3 sm:col-span-4 col-span-6">
                <Form.Item
                    name="is_active"
                    rules={[{ required: false, message: 'Please input is status!' }]}
                    valuePropName={'checked'}
                >
                    <Checkbox>{t("ACTIVE")}</Checkbox>
                </Form.Item>
            </div>
            <div className="lg:col-span-3 sm:col-span-4 col-span-6">
                <Form.Item
                    name="is_free"
                    rules={[{ required: false, message: 'Please check!' }]}
                    valuePropName={'checked'}
                >
                    <Checkbox>{t("FREE")}</Checkbox>
                </Form.Item>
            </div>
            <div className="lg:col-span-3 sm:col-span-4 col-span-6">
                <Form.Item
                    name="is_homework"
                    rules={[{ required: false, message: 'Please check!' }]}
                    valuePropName={'checked'}
                >
                    <Checkbox>{t("HOMEWORK")}</Checkbox>
                </Form.Item>
            </div>
            <div className="lg:col-span-3 sm:col-span-4 col-span-6">
                <Form.Item
                    name="is_final"
                    rules={[{ required: false, message: 'Please check!' }]}
                    valuePropName={'checked'}
                >
                    <Checkbox>{t("FINAL")}</Checkbox>
                </Form.Item>
            </div>
            {/* <div className="col-span-12 mb-4 bg-slate-50 rounded-md px-3 pb-2">
                <Form.List name="ks_uuids">
                    {(fields, { add, remove }) => (
                        <>
                            <div className='grid grid-cols-12 gap-4 rounded-md mt-2'>
                                {fields.map(({ key, name, ...restField }, index) => (
                                    <div key={index} className="sm:col-span-6 col-span-12 flex">
                                        <Form.Item
                                            name={[name, 'ks_uuid']}
                                            className='mb-0 w-[calc(100%-55px)]'
                                            label={<FormLabelCrud>Video ID</FormLabelCrud>}
                                            {...restField}
                                            rules={[{ required: false, message: 'Please input Video ID!' }]}
                                        >
                                            <Input placeholder="Ma'lumot kiriting" />
                                        </Form.Item>
                                        <Form.Item className="w-[50px] mb-0 ml-2 mt-[25px]">
                                            <Button disabled={fields.length == 1} onClick={() => { remove(name); }} danger><BiX /></Button>
                                        </Form.Item>
                                    </div>
                                ))}
                            </div>
                            <div className="col-span-12 text-end">
                                <Button type="link" size="small" className="inline-flex align-middle font-medium mt-1 lg:text-sm text-xs uppercase sm:px-[15px] pl-0" onClick={() => add()} disabled={fields.length === 20}>
                                    <BiPlus size={20} className="mt-[2px] mr-2" />{t("VIDEO QO'SHISH")}
                                </Button>
                            </div>
                        </>
                    )}
                </Form.List>
            </div> */}
        </div>
    );
};

export default LessonCreateUi;



// const buttonList = [
//     ["font", "fontSize", "formatBlock"],
//     [
//         "bold",
//         "underline",
//         "italic",
//         "strike",
//         "subscript",
//         "superscript",
//     ],
//     ["align", "horizontalRule", "list", "table"],
//     ["fontColor", "hiliteColor"],
//     ["outdent", "indent"],
//     ["undo", "redo"],
//     ["removeFormat"],
//     ["outdent", "indent"],
//     ["image"],
//     ["print"],
//     ["fullScreen", "showBlocks", "codeView"],
// ]
