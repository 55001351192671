import React, { Dispatch } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, DatePicker, Drawer, Form, Input, message, Select, Switch } from "antd";
import useWindowSize from "custom-hooks/useWindowSize";
import { submitData } from "./course_groups.requests";
import { IGroup } from "./types";
import MultipleLangInputs from "components/MultipleLangInput/multipleLangs";
import moment from "moment";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import { RButton, SButton } from "components/Common/CustomButtons";
import FormLabelCrud from "components/FormLabel/FormLabel";
import EditingTitle from "components/Common/EditingTitle";
import PartTitle from "components/Common/PartTitle";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";
import { IUser } from "types/data.models";
import { useTranslation } from "react-i18next";

type TypePropsCreateCourseGroup = {
  course_id: string | undefined;
  allCurators: IUser[];
  element: IGroup | null;
  setElement: React.Dispatch<React.SetStateAction<IGroup | null>>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateCourseGroup = ({
  course_id,
  allCurators,
  element,
  setElement,
  refetch,
  openSidebarMenu,
  setOpenSidebarMenu,
}: TypePropsCreateCourseGroup) => {
  const{ t } =useTranslation()
  const [form] = Form.useForm();
  const { width } = useWindowSize();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue({ curator_id: element.curator?.id, is_main:element.is_main});
    } else {
      form.resetFields();
      form.setFieldsValue({is_main:true});
    }
  }, [element, element?.id, form, openSidebarMenu, course_id]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-group"],
    mutationFn: (newValues) => submitData(course_id, newValues, element),
    onSuccess: async (data) => {
      queryClient.setQueryData(["course-groups"], data);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    mutate(values);
  }
  

  const MemoizationFieldsFormGroup = React.memo(() => (
    <Form layout={"vertical"} form={form} onFinish={handleSubmit} className="custom_form_style">
      <div className="grid grid-cols-12 gap-x-4">
        <div className="lg:col-span-9 md:col-span-8 col-span-12">
          <Form.Item
            label={<FormLabelCrud>{t("Curators")}</FormLabelCrud>}
            name="curator_id"
            required
            rules={[{ required: true, message: "Please select curator!" }]}
          >
            {allCurators ? <Select className="mb-3 w-[100%]" options={allCurators} /> : "Loading..."}
          </Form.Item>
          <Form.Item label={<FormLabelCrud>{t("Is Main")}</FormLabelCrud>} required  name="is_main" valuePropName={'checked'} >
            <Switch  className="bg-[#999]"/>
          </Form.Item>
        </div>
      <div className=" col-span-12 mt-2 sm:text-start text-right">
        <RButton
          onClick={() => {
            form.resetFields();
            setElement(null);
            form.setFieldsValue({});
          }}
          className="sm:mr-2 sm:mb-0 mr-0 mb-1"
        />
        <SButton loading={isLoading} />
      </div>
      </div>
    </Form>
  ));

  return (
    <div>
      <>{element ? <EditingTitle title={"title"} /> : <PartTitle children={t("Create group")} />}</>
      <hr className="my-3" />
      <MemoizationFieldsFormGroup />
      <Drawer
        title={<h3 className=" font-bold">{t("New")}</h3>}
        placement="right"
        onClose={() => setOpenSidebarMenu(false)}
        open={width > 992 ? false : openSidebarMenu}
      >
        <MemoizationFieldsFormGroup />
      </Drawer>
    </div>
  );
};
export default CreateCourseGroup;
