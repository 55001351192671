import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Form } from "antd";
import { getDataById, submitData } from "./course_access.request";
import ToBack from "components/Common/ToBack";
import { useParams } from "react-router-dom";
import { IAccessCourse, IAccessCourseForSubmit } from "./types";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import UpdateForm from "./course_access_update_form";
import { useTranslation } from "react-i18next";

const UpdateCourseAccess = () => {
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { course_id } = useParams();

  const { mutate, isLoading } = useMutation({
    mutationKey: ["update-course-access"],
    mutationFn: (newValues: IAccessCourseForSubmit) => submitData(newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["course-access"], data);
      getDataByIdQuery.refetch()
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data)
    },
    retry: 0,
  });

  const getDataByIdQuery = useQuery<IAccessCourse, AxiosError>({
    queryKey: ['course-access-by-id', course_id],
    queryFn: () => getDataById(Number(course_id)),
    onSuccess: (data:IAccessCourse) => {
        form.setFieldsValue(data)
    },
    retry: 0,
    refetchOnWindowFocus: false
  });

  function handleSubmit(values: IAccessCourseForSubmit) {

    const obj: any = {
      user_id: getDataByIdQuery?.data?.user?.id,      
      detail: values.detail,
      payment_type: values?.payment_type,
      payment_provider: values.payment_provider,
    }
    if(getDataByIdQuery?.data?.course) obj.course_id = getDataByIdQuery?.data?.course?.id;
    if(getDataByIdQuery?.data?.course_module) obj.course_module_id = getDataByIdQuery?.data?.course_module?.id;
    if(getDataByIdQuery?.data?.installment_payment) obj.installment_payment_id = getDataByIdQuery?.data?.installment_payment?.id;
    if(getDataByIdQuery?.data?.promocode) obj.promocode_id = getDataByIdQuery?.data?.promocode?.id;

    mutate(obj);
  }
  
  function saveAndAnother() {
    form.submit()
    setTimeout(() => {
        form.resetFields();
        form.setFieldsValue({
            is_active: true,
            is_archive: false,
            translations:[{title:"", desc:""}]
        })
    }, 300);
}
    function saveAndEdit() {
        form.submit()
    }

  return (
    <>
      <div className="main-card">
          <ToBack title={`${t("Course access to")} ${getDataByIdQuery?.data?.user?.translations[0]?.first_name} ${getDataByIdQuery?.data?.user?.translations[0]?.last_name}`} />
          <hr className="my-3" />
          <div className="grid grid-cols-12 gap-5">
            <div className="lg:col-span-6 col-span-12 col">
              <div className="flex justify-between mb-4">
                <p className="opacity-70 font-bold">{t("Course")}</p>
                <p className="opacity-70">{getDataByIdQuery?.data?.course?.translations[0]?.title}</p>
              </div>
              <div className="flex justify-between mb-4">
                <p className="opacity-70 font-bold">{t("Installment paymant")}</p>
                <p className="opacity-70">{getDataByIdQuery?.data?.installment_payment ? getDataByIdQuery?.data?.installment_payment?.translations[0]?.title : t("Full paid")}</p>
              </div>
            </div>
            <div className="lg:col-span-6 col-span-12">
              <div className="flex justify-between mb-4">
                <p className="opacity-70 font-bold">{t("Fullname")}</p>
                <p className="opacity-70">{getDataByIdQuery?.data?.user?.translations[0]?.first_name} {getDataByIdQuery?.data?.user?.translations[0]?.last_name}</p>
              </div>
              <div className="flex justify-between mb-4">
                <p className="opacity-70 font-bold">{t("Email")}</p>
                <p className="opacity-70">{getDataByIdQuery?.data?.user?.email}</p>
              </div>
              <div className="flex justify-between mb-4">
                <p className="opacity-70 font-bold">{t("Phone")}</p>
                <p className="opacity-70">{getDataByIdQuery?.data?.user?.phone}</p>
              </div>
            </div>
          </div>
          <hr />
          <p className="opacity-70 font-bold my-3">{t("HISTORY")}</p>
            {
              getDataByIdQuery?.data?.histories?.map((history, index) => (
                <div key={index} className={`grid grid-cols-12 gap-5 p-3 my-3 rounded-md ${history?.is_paid ? "bg-lime-200" : "bg-red-200"}`}>
                  <div className="lg:col-span-6 col-span-12">
                    <div className="flex justify-between">
                      <p className="opacity-70 font-bold">Detail</p>
                      <p className="opacity-70">{history?.detail}</p>
                    </div>
                  </div>
                  <div className="lg:col-span-6 col-span-12">
                    <div className="flex justify-between">
                      <p className="opacity-70 font-bold">{t("Amount paid")} {!history?.is_paid ? "(Not finished)" : ""}</p>
                      <p className="opacity-70">{history?.paid_money}</p>
                    </div>
                  </div>
                </div>
              ))
            }
      </div>
      {
        !getDataByIdQuery?.data?.is_full_amount ? 
      <div className="main-card mt-4">
          <UpdateForm form={form} handleSubmit={handleSubmit} />
          <div className="md:flex justify-end mt-2">
              <Button onClick={saveAndAnother} loading={isLoading} type="default" className='md:w-auto w-[100%] bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]' htmlType="submit">Save and add another</Button>
              <Button onClick={saveAndEdit} loading={isLoading} type="default" className='md:w-auto w-[100%] bg-[#045140] text-white hover:text-white hover:bg-[#1a7763] md:ml-3 md:my-0 my-3' htmlType="submit">Save and continue editing</Button>
          </div>
      </div> : ""
      }
    </>
  );
};

export default UpdateCourseAccess;