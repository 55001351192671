import React from "react";
import { useMutation } from "@tanstack/react-query";
import { Button, Transfer } from "antd";
import useGetData from "custom-hooks/useGetData";
import { attachPermissionFn, submitUser } from "../users.request";
import { IPermission } from "pages/permissions/types";
import { InformerDRDT } from "types/base.models";
import { useParams } from "react-router-dom";
import { IUser } from "types/data.models";
import "./styles.scss"


const AttachPermissions = ({ self_permissions }: { self_permissions: IUser['self_permissions'] }) => {


    const { user_id } = useParams();

    const [selectedPermissions, setSelectedPermissions] = React.useState<string[]>([]);

    const permissions = useGetData<InformerDRDT<IPermission>>({ queryKey: ['permissions'], url: 'permission/list/', urlParams: { page_size: 500 } });

    const attachPermission = useMutation({
        mutationKey: ['attach-role-to-user'],
        mutationFn: (data: string[]) => attachPermissionFn(user_id, data),
    })

    const renderItem = (item: any) => {
        const customLabel = (
            <span>
                {item.title}
            </span>
        );

        return {
            label: customLabel,
            value: item.title,
        };
    };


    React.useMemo(() => {
        if (self_permissions.length) {
            const selectedPermissionsId = self_permissions.map(e => String(e.id))
            setSelectedPermissions(selectedPermissionsId)
        }
    }, [self_permissions])

    if (!permissions.data) return <div>ma'lumot topilmadi</div>

    return (
        <div className="user-permissions">
            <div className="grid grid-cols-12 gap-4">
            </div>
            <div className="flex justify-between mb-3">
                <span className="text-[#045140] text-[13px] uppercase font-semibold">Permissions</span>
                <Button className="bg-[#045140] text-white" onClick={() => attachPermission.mutate(selectedPermissions)}>SAVE</Button>
            </div>
            <Transfer
                showSearch
                dataSource={permissions.data?.results.map((element) => { return { key: String(element.id), title: element.title, description: element.desc } })}
                titles={[
                    <span className="text-[#045140] uppercase text-[12px] font-semibold">Permissions</span>,
                    <span className="text-[#045140] uppercase text-[12px] font-semibold">User permissions</span>,
                ]}
                targetKeys={selectedPermissions}
                onChange={(nextTargetKeys: string[]) => setSelectedPermissions(nextTargetKeys)}
                render={renderItem}
                listStyle={{ width: '100%', height: "500px" }}
                operationStyle={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            />
        </div>
    )


}


export default AttachPermissions;