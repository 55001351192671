import { useMutation, useQuery } from "@tanstack/react-query";
import { Tabs, TabsProps } from "antd";
import { AxiosError } from "axios";
import AvatarFileUpload from "components/fileUploader/avatar";
import ToBack from "components/Common/ToBack";
import { FILE_URL } from "config/utils";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getDataById, submitOrganizationImage } from "../organization.request";
import { IOrganizationGet } from "../types";
import { BiMessageSquareDetail } from "react-icons/bi";
import { SiMicrosoftaccess } from "react-icons/si";
import OrganizationDetails from "./details";
import AccessCourse from "./access_course";
import useUrlQueryParams from "custom-hooks/useUrlQueryParams";
import GlobalBack from "components/Common/GlobalBack";

const ViewOrganization = () => {
  const [imageSrc, setImageSrc] = useState<{ url: string; file: any }>({
    url: "",
    file: "",
  });
  const { id } = useParams();

  const { data, isLoading } = useQuery<IOrganizationGet, AxiosError>({
    queryKey: ["discounts-by-id", id],
    queryFn: () => getDataById(id),
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data?.logo) {
        setImageSrc({
          url: FILE_URL + data?.logo?.media?.title?.path,
          file: "",
        });
      }
    },
    retry: 0,
  });

  const { mutate } = useMutation({
    mutationKey: ["update-organization"],
    mutationFn: (file: any) => submitOrganizationImage(id, file),
    onSuccess: async (data) => {},
    retry: 0,
  });

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <span className="flex gap-x-2 items-center">
          {" "}
          Details <BiMessageSquareDetail />
        </span>
      ),
      children: <OrganizationDetails data={data} />,
    },
    {
      key: "2",
      label: (
        <span className="flex gap-x-2 items-center">
          Access <SiMicrosoftaccess />
        </span>
      ),
      children: <AccessCourse data={data} organizationLoading={isLoading} />,
    },
  ];
  const { value, writeToUrl } = useUrlQueryParams({});
  let filtered_items: TabsProps["items"] = [];
  items.forEach((item) => {
    filtered_items?.push(item)
  })

  const onChange = (key: string) => {
    writeToUrl({ name: "tab_id", value: key });
  };

  return (
    <div className="grid grid-cols-12 gap-4 items-start">
      <GlobalBack className="col-span-12" title={data?.translations[0]?.title} href="/organizations" />
      <div className="lg:col-span-4 col-span-12 text-center main-card mt-4">
        <div className="py-6">
          <AvatarFileUpload
            imageSrc={imageSrc}
            setImageSrc={setImageSrc}
            mutate={mutate}
          />
          <p className="mt-4">{data?.translations[0]?.title}</p>
        </div>
      </div>

      <div className="md:col-span-8 col-span-12 flex main-card mt-4">
        <Tabs
          activeKey={String(value.filter.tab_id ?? 1)}
          onChange={onChange}
          defaultActiveKey={localStorage.getItem("organizationTabId") ?? "1"}
          type="card"
          className="w-full"
          items={filtered_items}
        />
      </div>
    </div>
  );
};
export default ViewOrganization;
