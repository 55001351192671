import instance from "config/axios.config";

export async function submitData(id: number | undefined, data: any) {
  const formData = new FormData();
  formData.append("payload", JSON.stringify(data.payload));
  if (data.input) {
    console.log(data.input);
    
    formData.append("input", data.input.originFileObj);
  }
  if (data.output) {
    formData.append("output", data.output.originFileObj);
  }

  const url = id ? `/training/update/${id}` : `training/create`;
  const response = await instance({
    url,
    method: id ? "PUT" : "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

export async function getData(page: number, pageSize: number) {
  const response = await instance({
    url: `training/list/`,
    method: "GET",
    params: { page_number: page, page_size: pageSize },
  });
  return response.data;
}

export function deleteData(id: any) {
  return instance({ url: `training/${id}`, method: "DELETE" });
}
