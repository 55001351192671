import BreadcrumbCustom from "components/breadcrumb";
import { useEffect, useState } from "react";
import type { TransferDirection } from 'antd/es/transfer';
import Transfer from "antd/es/transfer";
import { message } from "antd";
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import { getPermissions, getRoleById, postPermission } from "./role.request";
import { useParams } from "react-router-dom";
import { IPermission } from "pages/permissions/types";
import "./styles.scss"
const BreadcrumbcrumbData:any = [
    {
        path: '/',
        title: 'Home',
    },
    {
        path: '/roles',
        title: 'Roles',
    },
    {
        path: '/',
        title: 'Role permissions',
    },
]

type IParam = {
    id: string
}

const RolePermissions = () => {

    const {id} = useParams<IParam>()
    const queryClient = useQueryClient();

    const [permissionsQuery, roleByIdQuery] = useQueries({
        queries: [
          {
            queryKey: ['permissions-all'],
            queryFn: () => getPermissions(),
            retry: 0,
            staleTime: Infinity
          },
          {
            queryKey: ['role-by-id', id],
            queryFn: () => getRoleById(id),
            retry: 0,
            staleTime: Infinity
          },
        ],
    });

    useEffect(() => {
      permissionsQuery.refetch();
      roleByIdQuery.refetch();
    }, [])

    const { mutate } = useMutation({
        mutationKey: ["change-permissions"],
        mutationFn: ({toward, keys}) => postPermission(id,  toward, {permissions_id:keys}),
        onSuccess: async (data:any) => {
          queryClient.setQueryData(["permissions-all"], data);
          permissionsQuery.refetch();
          roleByIdQuery.refetch();
        },
        onError: (error:any, context: any) => {
          if (context.errorCb) {
            context.errorCb(error);
          }
        },
        retry: 0,
    });

    function handleSubmit(toward: any, keys: string[]) {
      mutate({toward, keys});
    }

    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [selectedKeysForAdd, setSelectedKeysForAdd] = useState<string[]>([]);
    const [selectedKeysForRemove, setSelectedKeysForRemove] = useState<string[]>([]);


    if(permissionsQuery.isLoading) return 'Loading ...'
    if(roleByIdQuery.isLoading) return 'Loading ...'
      
    const onChange = (nextTargetKeys: string[], direction: TransferDirection,) => {      
      if(direction === 'left'){
        handleSubmit(direction, selectedKeysForRemove)
      }else{
        handleSubmit(direction, selectedKeysForAdd)
      }
    };
    
    const onSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
        setSelectedKeysForRemove(targetSelectedKeys)
        setSelectedKeysForAdd(sourceSelectedKeys)        
    };

    const renderItem = (item:any) => {
        const customLabel = (
          <span>
            {item.title}
          </span>
        );
    
        return {
          label: customLabel,
          value: item.title,
        };
    };



    return (
        <div className="main-card role-permissions">
            <BreadcrumbCustom routes={BreadcrumbcrumbData} />
            <hr className='my-3' />
            
            <Transfer
                showSearch
                dataSource={permissionsQuery?.data?.results?.map((i:IPermission) => {return {key: i.id, title: i.title, description: i.desc}})}
                titles={['Other permissions', 'Current permissions']}
                targetKeys={roleByIdQuery?.data?.permissions?.map((i:IPermission) => i.id)}
                selectedKeys={selectedKeys}
                onChange={onChange}
                onSelectChange={onSelectChange}
                render={renderItem}
                operations={['to right', 'to left']}
            />
        </div>
    )
}
export default RolePermissions;