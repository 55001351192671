import  './mainLoader.scss'

const MainLoader = () => {
  return (
        <div className="main-loader-wrapper">
            <div className='mian-loader-item'> </div>
            <div className='mian-loader-item'> </div>
            <div className='mian-loader-item'> </div>
            <div className='mian-loader-item'> </div>
            <div className='mian-loader-item'> </div>
            <div className='mian-loader-item'> </div>
            <div className='mian-loader-item'> </div>
            <div className='mian-loader-item'> </div>
            <div className='mian-loader-item'> </div>
        </div>
  );
};
export default MainLoader;
