import { Space } from "antd";
import DashboardCard from "./dashboard_card";
import { useQuery } from "@tanstack/react-query";
import { getData } from "pages/courses/course.request";
import Statistic from "./statistic";
import { InformerDRDT } from "types/base.models";
import { IUser } from "types/data.models";
import { CLIENT_API } from "service/clieant.request";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const Dashboard = () => {
  const [mentors, setMentors] = useState<any>([]);

  const { t } = useTranslation();
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["courses"],
    queryFn: () => getData(1, 2),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  const users = useQuery<InformerDRDT<IUser>>({
    queryKey: ["users"],
    queryFn: () =>
      CLIENT_API.getAll({ url: "/users/list/?page_number=1&page_size=5000" }),
    refetchOnWindowFocus: false,
    retry: 0,
  });

  useEffect(() => {
    setMentors(
      users?.data?.results?.filter((item) =>
        item.roles.some((role) => role?.title === "mentor")
      )
    );
  }, [users?.data]);
  

  return (
    <div className="h-[88vh] rounded-md overflow-hidden">

      <div className="grid md:grid-cols-3  gap-4">
        <DashboardCard
          title={t("Number of users")}
          number={`${users?.data?.total_results}`}
          increase="2"
        />
        <DashboardCard
          title={t("Number of courses")}
          number={JSON.stringify(data?.total_results)}
          increase="2"
        />
        <DashboardCard
          title={t("Number of mentors")}
          number={mentors?.length}
          increase="15"
        />
      </div>
      <div className="main-card mt-3">
        <Statistic />
      </div>
    </div>
  );
};

export default Dashboard;
