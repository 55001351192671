import instance from "config/axios.config";
import { IOrganization } from "./types";

export async function submitData(id: string | undefined, data: IOrganization) {
  const url = id ? `/organization/${id}` : `organization/create`;
  const response = await instance({ url, method: id ? "PUT" : "POST", data });
  return response.data;
}

export async function getOrganizatsions() {
  const response = await instance({
    url: `/organization/list/`,
    method: "GET",
  });
  return response.data;
}

export async function getDataById(id: string | undefined) {
  const response = await instance({
    url: `/organization/${id}`,
    method: "GET",
  });
  return response.data;
}

export function deleteData(id: string) {
  return instance({ url: `organization/${id}`, method: "DELETE" });
}

export function submitOrganizationImage(id: string | undefined, file: any) {
  const formdata = new FormData();
  formdata.append("image_file", file?.originFileObj);
  formdata.append("title", file?.originFileObj?.name);
  return instance({
    url: `organization/${id}/image`,
    method: "POST",
    data: formdata,
  });
}

export async function AttachCourseToOrganization(id: string,courses_ids:any) {
  const url = `/organization/${id}/update/courses`;
  const response = await instance({ url, method: "POST",data: courses_ids });
  return response.data;
}
