import { useTranslation } from "react-i18next";
import { BiLoader, BiLoaderAlt } from "react-icons/bi";
import { FaPlusCircle } from "react-icons/fa";

export const CButton = (props: any) => {
  const { t } = useTranslation();

  return (
    <button
      {...props}
      className="bg-[#045140] text-white text-[14px] rounded-md w-[110px] text-center hover:text-[#A0E82F] py-1 active:bg-[#034335] uppercase "
    >
      <span className="flex justify-center align-middle">
        <FaPlusCircle size={15} className="mt-[4px] mr-2" />
        {t("Create")}
      </span>
    </button>
  );
};

export const SButton = ({
  onClick,
  loading,
}: {
  onClick?: () => void;
  loading?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={onClick}
      disabled={loading}
      type="submit"
      className="bg-[#045140] text-white sm:text-[14px] text-xs rounded-md text-center sm:w-[120px] w-[89px]  py-[8px]  hover:text-[#A0E82F]  active:bg-[#034335] disabled:bg-[#F1F5F9] disabled:text-[#8A8C8E] disabled:cursor-not-allowed"
    >
      <span className="flex justify-center align-middle">
        {loading ? (
          <BiLoaderAlt className="mt-[4px] mr-1 animate-spin" />
        ) : null}
        {t("Save")}
      </span>
    </button>
  );
};

export const RButton = ({
  onClick,
  disabled,
  className,
}: {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type="button"
      className={`bg-[#FF0000] text-white sm:text-[14px] text-xs rounded-md text-center sm:w-[120px] w-[89px] py-[8px] active:bg-[#D50000] disabled:bg-[#F1F5F9] disabled:text-[#8A8C8E] disabled:cursor-not-allowed ${className}`}
    >
      <span className="flex justify-center align-middle">{t("Reset")}</span>
    </button>
  );
};
export const RMButton = ({
  onClick,
  disabled,
  className,
}: {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type="button"
      className={`bg-[#FF0000] text-white text-[14px] rounded-md min-w-[120px] text-center py-[5px] active:bg-[#D50000] uppercase disabled:bg-[#F1F5F9] disabled:text-[#8A8C8E] disabled:cursor-not-allowed ${className}`}
    >
      {t("Remove")}
    </button>
  );
};
