import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import { getDataById } from "../lesson.request";
import { ILesson } from "../types";
import { Badge } from "antd";
import TableLoader from "components/loaders/table_loader";
import { BiCheck } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";

const LessonDetails = () => {

  const {t} = useTranslation()
  const { id } = useParams();

  const { data, isLoading, error } = useQuery<ILesson, AxiosError>({
    queryKey: ["lesson-by-id", id],
    queryFn: () => getDataById(id),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  if (error) return <span>{error.message}</span>;
  if (isLoading)
    return (
      <div>
        <TableLoader isLoading={isLoading} />;
      </div>
    );

  return (
      <div>
        <div className="grid grid-cols-12 gap-8">
          <div className="xl:col-span-6 md:col-span-6 col-span-11">
            <div className="flex sm:justify-between gap-4 mb-4">
              <p className="font-bold text-zinc-500">{t("Title")}</p>
              <p>{data?.translations[0].title}</p>
            </div>
            <div className="flex sm:justify-between gap-4 mb-4">
              <p className="font-bold text-zinc-500">{t("Price")}</p>
              <p>{data?.price}</p>
            </div>
            <div className="flex sm:justify-between gap-4 mb-4">
              <p className="font-bold text-zinc-500">{t("Slug")}</p>
              <p>{data?.slug}</p>
            </div>
            <div className="flex sm:justify-between gap-4 mb-4">
              <p className="font-bold text-zinc-500">{t("Description")}</p>
              <p>{data?.translations[0]?.desc}</p>
            </div>
          </div>
          <div className="xl:col-span-6 md:col-span-6 col-span-11">
            <div className="flex sm:justify-between gap-4 mb-4">
              <p className="font-bold text-zinc-500">{t("Status")}</p>
              <p>
                {data?.is_active ? (
                  <Badge count={t("Active")} showZero color="#52c41a" />
                ) : (
                  <Badge count={t("InActive")} showZero color="#faad14" />
                )}
              </p>
            </div>
            <div className="flex sm:justify-between gap-4 mb-4">
              <p className="font-bold text-zinc-500">{t("Free")}</p>
              <p>
                {data?.is_free ? (
                 <BiCheck className="text-[24px] text-green-700" />
                 ) : (
                   <IoMdClose className="ml-2 text-[22px] text-rose-600" />
                 )}
              </p>
            </div>
            <div className="flex sm:justify-between gap-4 mb-4">
              <p className="font-bold text-zinc-500">{t("Homework")}</p>
              <p>
                {data?.is_homework ? (
                  <Badge count={t("True")} showZero color="#52c41a" />
                ) : (
                  <Badge count={t("False")} showZero color="#52c41a" />
                )}
              </p>
            </div>
          </div>
        </div>
        <p className="font- font-bold text-zinc-500">{t("Content")}</p>
        <div dangerouslySetInnerHTML={{ __html: data?.translations[0].content as string }} />
      </div>
  );
};
export default LessonDetails;
