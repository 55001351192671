import { useTranslation } from "react-i18next";
import useGetData from "./useGetData";
import React from "react";
import { ILanguage } from "pages/languages/types";






const useLocaleTranslation = () => {

    const { i18n } = useTranslation();

    const [langId, setLangId] = React.useState<number>(1);

    const { data: languages } = useGetData<ILanguage[]>({ queryKey: ['languages'], url: 'language/list/', options: { refetchOnWindowFocus: false, staleTime: Infinity } });

    React.useEffect(() => {

        if (Array.isArray(languages) && languages.length) {
            const findId = languages.find(e => e.code === i18n.language)?.id;
            if (findId) {
                setLangId(findId);
            }
        }

    }, [languages, i18n.language])

    const _translate = (translations: Array<{ [key: string]: any }>, field_key: string) => {

        if (!translations.length) return null;

        if (translations.length === 0) return translations[0][field_key];

        const findField = translations.find(e => e.language_id === langId);

        if (!findField) return translations[0][field_key];

        return findField[field_key];

    }

    return { _translate }

}


export default useLocaleTranslation;