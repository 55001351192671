import React, { useEffect, useState } from "react";
import { Avatar, Button, Dropdown, Layout, theme, MenuProps, Drawer, Image, Divider, Select,} from "antd";
import { useDispatch } from "react-redux";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { UI_ACTIONS } from "store/ui";
import { useAppSelector } from "store/service";
import { BiCaretDown, BiLeftIndent, BiRightIndent } from "react-icons/bi";
import i18next from "i18next";
import { signOut } from "pages/login/service.request";
import { Link } from "react-router-dom";
import useGetData from "custom-hooks/useGetData";
import { ILanguage } from "pages/languages/types";
import LanguageTitle from "utils/LanguageTitle";
import { FiMenu } from "react-icons/fi";
import logo_xl from "../../../assets/images/logo_xl.jpg"
import "./styles.scss";
import { useTranslation } from "react-i18next";

const { Header } = Layout;
const CHeader = () => {

  const {t} = useTranslation()
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch<Dispatch<AnyAction>>();
  const ui = useAppSelector((state) => state.ui);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [items, setItems] = useState<any[]>([]);
  const [language, setLanguage] = useState<string>(
    localStorage.getItem("i18lang") ?? "uz"
  );  
  const [role, setRole] = useState<string>()

  const { data: _languages } = useGetData<ILanguage[]>({
    queryKey: ["languages"],
    url: "language/list/",
    options: { staleTime: Infinity, refetchOnWindowFocus: false },
  });
  const { data: me } = useGetData({
    queryKey: ["user-me"],
    url: "/users/me",
    options: { retry: 0, staleTime: Infinity, refetchOnWindowFocus: false },
  });

  useEffect(() => {
    if (Array.isArray(_languages) && _languages.length) {
      let new_languages: any[] = [];

      if (new_languages) {
        _languages.forEach((element) => {
          new_languages.push({
            id: element.id,
            key: element.code,
            label: (
              <span
                className="block"
                onClick={() => changeLanguage(element.code)}
              >
                <LanguageTitle element={element} />
              </span>
            ),
          });
        });
      }

      setItems(new_languages);
    }
  }, [_languages, language]);

  function handleCollapse(collapsed: boolean) {
    dispatch(UI_ACTIONS.sidebarCollapse({ collapsed }));
  }

  const profile_items: MenuProps["items"] = [
    {
      key: "1",
      label: <Link to="/profile/me">{t("Profile")}</Link>,
    },
    {
      key: "2",
      label: <span>{t("Sign Out")}</span>,
      danger: true,
      onClick: () => signOut(),
    },
  ];

  const changeLanguage = (key: string) => {
    localStorage.setItem("i18lang", key);
    setLanguage(key);
    i18next.changeLanguage(key);
  };

  const menuProps = {
    items: profile_items,
  };

  const { data: _roles } = useGetData({
    queryKey: ["roles"],
    url: "role/list/",
    options:{
      refetchOnWindowFocus:false
    }
  });

  return (
    <Header
      style={{ padding: 0, background: colorBgContainer }}
      className="flex items-center sticky top-0 z-10"
    >
      <div className="header-wrapper   ">
        <FiMenu className="menu-icon" onClick={showDrawer} />
        <Button type="link" className="collapse-btn">
          {ui.collapsed ? (
            <BiRightIndent
              size={20}
              className="text-[#045140]"
              onClick={() => handleCollapse(false)}
            />
          ) : (
            <BiLeftIndent
              size={20}
              className="text-[#045140]"
              onClick={() => handleCollapse(true)}
            />
          )}
        </Button>
        <Drawer
          closable={false}
          onClose={onClose}
          open={open}
          className="gap-2"
        >
          <Image width={180} src={logo_xl} alt="logo"/>
          <Divider/>
          <Dropdown
            menu={{
              items: _roles?.map((item: any) => ({
                key: item?.id,
                label: item?.title,
              })),
            }}
            trigger={["click"]}
            className="mr-2 "
          >
            <div className="w-[120px] cursor-pointer bg-slate-100 h-[34px] leading-[34px] text-center rounded-md flex align-middle justify-center">
              <span>{items?.find((e) => e?.key === language)?.label}</span>{" "}
              <span className="leading-[30px] inline">
                <BiCaretDown className="inline ml-2" />
              </span>
            </div>
          </Dropdown>
          <Dropdown menu={{ items }} trigger={["click"]} className="mr-2">
            <div className="w-[120px] cursor-pointer bg-slate-100 h-[34px] leading-[34px] text-center rounded-md flex align-middle justify-center">
              <span>{items?.find((e) => e?.key === language)?.label}</span>{" "}
              <span className="leading-[30px] inline">
                <BiCaretDown className="inline ml-2" />
              </span>
            </div>
          </Dropdown>
        </Drawer>
        <div className="flex flex-row">

        <div className="desktop-lunguage-role">
          {/* <Dropdown
            menu={{
              items: _roles?.map((item: any) => ({
                key: item?.id,
                label: item?.title,
              })),
            }}
            trigger={["click"]}
            className="mr-2 "
          >
            <div className="w-[120px] cursor-pointer bg-slate-100 h-[34px] leading-[34px] text-center rounded-md flex align-middle justify-center">
              <span>{items?.find((e) => e?.key === language)?.label}</span>
              <span className="leading-[30px] inline">
                <BiCaretDown className="inline ml-2" />
                
              </span>
            </div>
          </Dropdown> */}
      
          <Dropdown menu={{ items }} trigger={["click"]} className="mr-2">
            <div className="w-[120px] cursor-pointer bg-slate-100 h-[34px] leading-[34px] text-center rounded-md flex align-middle justify-center">
              <span>{items?.find((e) => e?.key === language)?.label}</span>
              <span className="leading-[30px] inline">
                <BiCaretDown className="inline ml-2" />
              </span>
            </div>
          </Dropdown>
        </div>
        <Dropdown menu={menuProps} trigger={["click"]}>
          <div className="w-auto cursor-pointer bg-slate-100 h-[34px] text-center rounded-md rounded-r-[20px] flex items-center justify-between">
            <span className=" ml-3 mr-2">{me?.email}</span>
            <Avatar src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80" />
          </div>
        </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default CHeader;
