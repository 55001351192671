import { Button, Empty, Form, Input, message, Modal, Select } from "antd";
import { FiEdit } from "react-icons/fi";
import { useEffect, useState } from "react";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { ILanguage } from "pages/languages/types";
import useFilterLanguage from "custom-hooks/useFilterLanguage";
import { BiX } from "react-icons/bi";
import { PlusOutlined } from "@ant-design/icons";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import DeleteIcon from "components/Common/deleteIcon";
import useGetData from "custom-hooks/useGetData";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { deleteWillLearn, getAllWillLearn, submitWillLearn } from "./details.request";
import { useParams } from "react-router-dom";
import { IWillLearn } from "./types";
import { is_permission, validationErrors } from "utils.functions";
import LanguageTitle from "utils/LanguageTitle";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const CourseWillLearn = () => {
  
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const [selectedItem, setSelectedItem] = useState<IWillLearn>();

  const { data: languages } = useGetData<ILanguage[]>({

    queryKey: ["languages"],
    url: "language/list/",
    options: { staleTime: Infinity, refetchOnWindowFocus: false },
  });
  const { fl, languageIds } = useFilterLanguage(form);

  const [isFirstModal, setisFirstModal] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldsValue({
      translations: [{ title: "" }],
    });
  }, []);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-course-learn"],
    mutationFn: (newValues: IWillLearn) => submitWillLearn(selectedItem?.id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["course-learns"], data);
      dataQuery.refetch();
      form.setFieldsValue({ translations: [{ key: "", value: "" }] });
      setisFirstModal(false);
      setSelectedItem(undefined);
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  const deleteMutation = useMutation({
    mutationFn: (id: number) => deleteWillLearn(id),
    onSuccess: () => {
      dataQuery.refetch();
    },
  });

  const dataQuery = useQuery<IWillLearn[], AxiosError>({
    queryKey: ["course-learns"],
    queryFn: () => getAllWillLearn(id),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  function handleSubmit(values: IWillLearn) {
    values.course_id = id;
    mutate(values);
  }

  return (
    <div>
      <div className="flex justify-between mb-2">
        <h3 className="font-bold text-[18px] opacity-40">{t("What will you learn")}</h3>
        {is_permission("create_what_learn_course_block") ? (
          <Button onClick={() => setisFirstModal(true)}>+ {t("Create")}</Button>
        ) : null}
      </div>
      <Table className="table-striped table-hover">
        <Thead>
          <Tr>
            <Th className="w-[50px]">№</Th>
            <Th className="w-[50px] text-center px-[0!important]">{t("Id")}</Th>
            <Th>{t("Title")}</Th>
            <Th>{t("Description")}</Th>
            <Th>{t("Actions")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {dataQuery?.data?.length
            ? dataQuery?.data?.map((item, index) => (
                <Tr>
                  <Td>{index + 1}</Td>
                  <Td className="w-[50px] text-center px-[0!important]">{item?.id}</Td>
                  <Td>{item?.translations[0]?.title}</Td>
                  <Td>{item?.translations[0]?.desc}</Td>
                  <Td>
                    <div className="flex" style={{ alignItems: "center" }}>
                      {is_permission("update_what_learn_course_block") ? (
                        <FiEdit
                          onClick={() => {
                            setisFirstModal(true);
                            form.setFieldsValue(item);
                            setSelectedItem(item);
                          }}
                          className="mx-2 text-[green] text-[18px] cursor-pointer"
                        />
                      ) : null}
                      {is_permission("delete_what_learn_course_block") ? (
                        <DeleteIcon confirmFunc={() => deleteMutation.mutate(item?.id)} />
                      ) : null}
                    </div>
                  </Td>
                </Tr>
              ))
            : ""}
        </Tbody>
      </Table>
      {dataQuery?.data?.length === 0 ? <Empty /> : ""}
      <Modal
        title={t("Course Detail")}
        width={1500}
        open={isFirstModal}
        onCancel={() => {
          setisFirstModal(false);
          form.setFieldsValue({ translations: [{ key: "", value: "" }] });
          setSelectedItem(undefined);
        }}
        footer={[<Button onClick={() => form.submit()}>Save</Button>]}
      >
        <Form layout={"vertical"} form={form} onFinish={handleSubmit}>
          <Form.List name="translations">
            {(fields, { add, remove }) => (
              <div className="col-span-12 mb-4 ">
                {fields.map(({ key, name, ...restField }, index) => (
                  <div key={index} className="grid grid-cols-12 gap-4 mb-4 bg-slate-50 p-2 rounded-md">
                    <div className="lg:col-span-4 md:col-span-6 col-span-12">
                      <Form.Item
                        className="mb-0"
                        label={<FormLabelCrud>{t("Title")}</FormLabelCrud>}
                        {...restField}
                        name={[name, "title"]}
                        rules={[{ required: index === 0, message: "Please input title!" }]}
                      >
                        <Input placeholder="Ma'lumot kiriting" />
                      </Form.Item>
                    </div>
                    <div className="lg:col-span-5 md:col-span-6 col-span-12">
                      <Form.Item
                        className="mb-0"
                        label={<FormLabelCrud>{t("Subtitle")}</FormLabelCrud>}
                        {...restField}
                        name={[name, "desc"]}
                        rules={[{ required: index === 0, message: "Please input Subtitle!" }]}
                      >
                        <Input placeholder="Ma'lumot kiriting" />
                      </Form.Item>
                    </div>
                    <div className="lg:col-span-3 md:col-span-6 col-span-12 flex items-end">
                      <Form.Item
                        label={<FormLabelCrud>{t("Language")}</FormLabelCrud>}
                        {...restField}
                        name={[name, "language_id"]}
                        rules={[{ required: index === 0, message: "Please select language" }]}
                        className="w-[calc(100%-55px)] mb-0"
                      >
                        <Select onClick={fl}>
                          {languages?.map((element) => (
                            <Option key={element.id} value={element.id} disabled={languageIds.includes(element.id)}>
                              <LanguageTitle element={element}/>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item className="w-[50px] mb-0 ml-2 mt-[25px]">
                        <Button
                          disabled={fields.length == 1}
                          onClick={() => {
                            remove(name);
                          }}
                          danger
                        >
                          <BiX />
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                ))}
                <div className="col-span-12">
                  <Button
                    type="dashed"
                    className="w-[200px] block text-[#959595]"
                    onClick={() => {
                      add();
                    }}
                    block
                    icon={<PlusOutlined />}
                    disabled={fields.length === languages?.length}
                  >
                    {t("Add field")}
                  </Button>
                </div>
              </div>
            )}
          </Form.List>
        </Form>
      </Modal>
    </div>
  );
};
export default CourseWillLearn;
