import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const GlobalBack = ({ title = '', className,href }: { title?: any, className?: string,href:string }) => {

    const navigate = useNavigate();

    const { t } = useTranslation();

    return (
        <div className={`flex items-center ${className}`} style={{ alignItems: "center" }} >

            <Button type="link" className='pl-0 flex items-center gap-2' onClick={() => navigate(href)}>
                <BiArrowBack size={18} className='mt-1' color='#045140' />
            <h4 className="font-semibold mt-1 text-[15px] text-[#045140]">{t(title)}</h4>
            </Button>
        </div>
    )
}


export default GlobalBack;