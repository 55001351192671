import { useQuery } from "@tanstack/react-query";
import { Button, Tabs, TabsProps, Image, Checkbox } from "antd";
import ToBack from "components/Common/ToBack";
import { CCFieldSetUi } from "components/FieldSet";
import { FILE_URL } from "config/utils";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";
import { FaEye } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { CLIENT_API } from "service/clieant.request";
import { IUser } from "types/data.models";
import AttachRole from "./Components/AttachRole";
import React from "react";
import AttachPermissions from "./Components/AttachPermissions";
import UserAttachCourses from "./Components/AttachCourses";

import "./styles.scss";

import MentorInfo from "./Components/mentorInfo";
import { is_permission } from "utils.functions";
import { useTranslation } from "react-i18next";
import useUrlQueryParams from "custom-hooks/useUrlQueryParams";
import GlobalBack from "components/Common/GlobalBack";

const UserView = () => {
  const { t } = useTranslation();
  const { user_id,users } = useParams();
  const navigate = useNavigate();
  const { _translate } = useLocaleTranslation();
  const { value, writeToUrl } = useUrlQueryParams({});


  const attachRef = React.useRef<null | { setField: (value: any) => void }>(
    null
  );

  const user = useQuery<IUser>({
    queryKey: ["user-get-one", user_id],
    queryFn: () => CLIENT_API.getOne({ url: `/users/get/${user_id}` }),
    retry: 2,
    enabled: !!user_id,
    staleTime: 1000,
  });

  const auth_data = useQuery<{ user_password: string }>({
    queryKey: ["user-auth-data", user_id],
    queryFn: () => CLIENT_API.getOne({ url: `/users/${user_id}/get/password` }),
    retry: 2,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000,
  });

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: <span className="uppercase">Rol va ruxsatlar</span>,
      children: (
        <div>
          <AttachRole roles={user.data?.roles ?? []} />
          <AttachPermissions
            self_permissions={user.data?.self_permissions ?? []}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: <span className="uppercase">{t("Courses")}</span>,
      children: <UserAttachCourses />,
    },
    {
      key: "3",
      label: <span className="uppercase">{t("Mentor info")}</span>,
      children: <MentorInfo />,
    },
  ].filter(
    (item, index) =>
      !(
        (!user.data?.roles.map((rol) => rol.title).includes("mentor") &&
          item.key === "3") ||
        (!is_permission("read_mentor_info") && item.key === "3")
      )
  );

  if (!user.data) return <span>{t("user not found")}</span>;

  const onChange = (key: string) => {
    writeToUrl({ name: "tab_id", value: key });
  };

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="xl:col-span-5 col-span-12">
        <div className="bg-white rounded-md p-3">
          <div className="grid grid-cols-12 mb-5">
            <div className="col-span-6">
              {users ? (

                <GlobalBack
                  title={`${_translate(user.data?.translations, "first_name")} ${
                    _translate(user.data?.translations, "last_name") ?? ""
                  }`.toUpperCase()}
                  href="/users"
                />
              ):(
                <ToBack
                  title={`${_translate(user.data?.translations, "first_name")} ${
                    _translate(user.data?.translations, "last_name") ?? ""
                  }`.toUpperCase()}
                />
              )}

            </div>
            <div className="col-span-6 text-end">
              <Button
                type="link"
                onClick={() => navigate(`/user/update/${user_id}`)}
              >
                {t("Edit")}
              </Button>
            </div>
            <div className="col-span-12 my-3">
              <hr />{" "}
            </div>
            <div className="col-span-12 text-center user-view-photo-wrapper">
              <Image
                width={200}
                className="user-view-photo"
                src={user?.data?.image?.path ? FILE_URL + user?.data?.image?.path: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"}
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4">
            <div className="lg:col-span-3 sm:col-span-6 col-span-12 flex md:gap-4 gap-2 bg-slate-50 px-2 py-1 rounded-md text-[#045140] text-[14px] font-semibold">
              <Checkbox disabled checked={user.data.is_superuser} />
              <span>{t("SUPERUSER")}</span>
            </div>
            <div className="lg:col-span-3 sm:col-span-6 col-span-12 flex md:gap-4 gap-2 bg-slate-50 px-2 py-1 rounded-md text-[#045140] text-[14px] font-semibold">
              <Checkbox disabled checked={user.data.is_verified} />
              <span>{t("VERIFIED")}</span>
            </div>
            <div className="lg:col-span-3 sm:col-span-6 col-span-12 flex md:gap-4 gap-2 bg-slate-50 px-2 py-1 rounded-md text-[#045140] text-[14px] font-semibold">
              <Checkbox disabled checked={user.data.is_staff} />
              <span>{t("STAFF")}</span>
            </div>
            <div className="lg:col-span-3 sm:col-span-6 col-span-12 flex md:gap-4 gap-2 bg-slate-50 px-2 py-1 rounded-md text-[#045140] text-[14px] font-semibold">
              <Checkbox disabled checked={user.data.is_active} />
              <span>{t("ACTIVE")}</span>
            </div>

            <div className="col-span-6">
              <CCFieldSetUi title="Email" text={user.data.email} />
            </div>
            <div className="col-span-6">
              <CCFieldSetUi title="Phone" text={user.data.phone} />
            </div>
            <div className="col-span-6">
              <CCFieldSetUi title="Birthday" text={user.data.birthday} />
            </div>
            <div className="col-span-6">
              <CCFieldSetUi title="Gender" text={user.data.gender} />
            </div>
            <div className="col-span-6">
              <CCFieldSetUi title="Region" text={user.data.region_id} />
            </div>
            <div className="col-span-6">
              <CCFieldSetUi title="District" text={user.data.district_id} />
            </div>
          </div>

          <div className="grid grid-cols-10 gap-4 bg-slate-50 mt-3 p-2 rounded-md">
            <div className="col-span-8">
              <CCFieldSetUi
                title="Password"
                text={auth_data.data?.user_password}
              />
            </div>
            <div className="password-wrapper col-span-2 text-center">
              <Button
                className="mt-[10px] w-[100%] flex justify-center align-middle pt-[7px]"
                onClick={() => auth_data.refetch()}
              >
                <FaEye size={18} />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="xl:col-span-7 col-span-12 permisson-tab">
        <div className="bg-white rounded-md p-3">
          <Tabs activeKey={String(value.filter.tab_id ?? 1)} items={items}  onChange={onChange}/>
        </div>
      </div>
    </div>
  );
};

export default UserView;
