import { Button, Empty, Form, Input, Modal, Select } from "antd";
import { FiEdit } from "react-icons/fi";
import { useEffect, useState } from "react";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { ILanguage } from "pages/languages/types";
import useFilterLanguage from "custom-hooks/useFilterLanguage";
import { BiX } from "react-icons/bi";
import { PlusOutlined } from "@ant-design/icons";
import useGetData from "custom-hooks/useGetData";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { submitFormat, deleteFormat } from "./details.request";
import { AxiosError } from "axios";
import { is_permission, validationErrors } from "utils.functions";
import { useParams } from "react-router-dom";
import { ICourseFormat } from "./types";
import LanguageTitle from "utils/LanguageTitle";
import { CLIENT_API } from "service/clieant.request";
import DeleteIcon from "components/Common/deleteIcon";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const CourseFormat = () => {

  const {t} = useTranslation()
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const [selectedItem, setSelectedItem] = useState<ICourseFormat>();

  const { data: languages } = useGetData<ILanguage[]>({
    queryKey: ["languages"],
    url: "language/list/",
    options: { staleTime: Infinity, refetchOnWindowFocus: false },
  });
  const { fl, languageIds } = useFilterLanguage(form);

  useEffect(() => {
    form.setFieldsValue({ translations: [{ key: "", value: "" }] });
  }, []);

  const dataQuery = useQuery<ICourseFormat[], AxiosError>({
    queryKey: ["course-formats"],
    queryFn: () => CLIENT_API.getAll({url: `course-about-format/list/${id}`}),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-course-format"],
    mutationFn: (newValues: ICourseFormat) => submitFormat(selectedItem?.id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["course-formats"], data);
      dataQuery.refetch();
      form.setFieldsValue({ translations: [{ key: "", value: "" }] });
      setisFirstModal(false);
      setSelectedItem(undefined);
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  const [isFirstModal, setisFirstModal] = useState<boolean>(false);

  function handleSubmit(values: ICourseFormat) {
    values.course_id = id;
    mutate(values);
  }

  const deleteFormatMutation = useMutation({
    mutationFn: deleteFormat,
    onSuccess: () => {
      dataQuery.refetch();
    }
  });

  return (
    <div>
      <div className="flex justify-between mb-3">
        <h3 className="font-bold text-[18px] opacity-40">{t("About the course format")}</h3>
        {is_permission("create_about_course_format_block") ? (
          <Button disabled={dataQuery?.data?.length === 5} onClick={() => setisFirstModal(true)}>
            + {t("Create")}
          </Button>
        ) : null}
      </div>
      <div className="main-card">
        <div className="grid grid-cols-10 gap-y-4">
          {dataQuery?.data?.length
            ? dataQuery?.data?.map((item) => (
                <div key={item.id} className="lg:col-span-2 sm:col-span-4 col-span-5 text-center">
                  <div className="flex justify-center items-center">
                    <div>
                      <p>{item?.translations[0]?.key}</p>
                      <p className="font-bold text-[18px] text-neutral-500">{item?.translations[0]?.value}</p>
                      <div className="flex justify-center items-center">
                        {is_permission("update_about_course_format_block") ? (
                          <FiEdit
                            onClick={() => {
                              setisFirstModal(true);
                              form.setFieldsValue(item);
                              setSelectedItem(item);
                            }}
                            className="mx-2 text-[20px] cursor-pointer text-green-500"
                          />
                        ) : null}
                        {is_permission("delete_about_course_format_block") ? <DeleteIcon confirmFunc={() => deleteFormatMutation.mutate(item?.id)} /> : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : ""}
        </div>
        <div className="text-center">{dataQuery?.data?.length === 0 ? <Empty /> : ""}</div>
      </div>
      <Modal
        title={t("Course Detail")}
        width={800}
        open={isFirstModal}
        onCancel={() => {
          setisFirstModal(false);
          form.setFieldsValue({ translations: [{ key: "", value: "" }] });
          setSelectedItem(undefined);
        }}
        footer={[<Button onClick={() => form.submit()}>Save</Button>]}
      >
        <Form layout={"vertical"} form={form} onFinish={handleSubmit}>
          <Form.List name="translations">
            {(fields, { add, remove }) => (
              <div className="col-span-12 mb-4 ">
                {fields.map(({ key, name, ...restField }, index) => (
                  <div key={index} className="grid grid-cols-12 gap-4 mb-4 bg-slate-50 p-2 rounded-md">
                    <div className="lg:col-span-4 md:col-span-6 col-span-12">
                      <Form.Item
                        className="mb-0"
                        label={<FormLabelCrud>Title</FormLabelCrud>}
                        {...restField}
                        name={[name, "key"]}
                        rules={[{ required: index === 0, message: "Please input title!" }]}
                      >
                        <Input placeholder="Ma'lumot kiriting" />
                      </Form.Item>
                    </div>
                    <div className="lg:col-span-5 md:col-span-6 col-span-12">
                      <Form.Item
                        className="mb-0"
                        label={<FormLabelCrud>Subtitle</FormLabelCrud>}
                        {...restField}
                        name={[name, "value"]}
                        rules={[{ required: index === 0, message: "Please inputSubtitle!" }]}
                      >
                        <Input placeholder="Ma'lumot kiriting" />
                      </Form.Item>
                    </div>
                    <div className="lg:col-span-3 md:col-span-6 col-span-12 flex items-end">
                      <Form.Item
                        label={<FormLabelCrud>Language</FormLabelCrud>}
                        {...restField}
                        name={[name, "language_id"]}
                        rules={[{ required: index === 0, message: "Please select language" }]}
                        className="w-[calc(100%-55px)] mb-0"
                      >
                        <Select onClick={fl}>
                          {languages?.map((element) => (
                            <Option key={element.id} value={element.id} disabled={languageIds.includes(element.id)}>
                              <LanguageTitle element={element}/>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item className="w-[50px] mb-0 ml-2 mt-[25px]">
                        <Button
                          disabled={fields.length == 1}
                          onClick={() => {
                            remove(name);
                          }}
                          danger
                        >
                          <BiX />
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                ))}
                <div className="col-span-12">
                  <Button
                    type="dashed"
                    loading={isLoading}
                    className="w-[200px] block text-[#959595]"
                    onClick={() => {
                      add();
                    }}
                    block
                    icon={<PlusOutlined />}
                    disabled={fields.length === languages?.length}
                  >
                    {t("Add field")}
                  </Button>
                </div>
              </div>
            )}
          </Form.List>
        </Form>
      </Modal>
    </div>
  );
};
export default CourseFormat;
