import { Card } from "antd";
import { useTranslation } from "react-i18next";
import { AiOutlineRise, AiOutlineUser } from "react-icons/ai";

const DashboardCard = ({
  title,
  number,
  increase,
}: {
  title: string;
  number: string;
  increase: string;
}) => {
  const { t } = useTranslation();

  return (
    <Card
      title={<h2 className="text-base">{title}</h2>}
      extra={
        <div className="p-2 bg-green-100 rounded-full">
          <AiOutlineUser size={"2em"} />
        </div>
      }
      style={{ width: "100%" }}
    >
      <h2 className="text-3xl font-bold mb-4 mt-7">
        {number && number !== "undefined" ? (
          number
        ) : (
          <span className="text-xl">Loading...</span>
        )}
      </h2>
      <div className="flex items-center">
        <p className="text-base mr-2">{t("Monthly growth")}:</p>{" "}
        <AiOutlineRise size={"2em"} color="green" />{" "}
        <span className="text-lg text-green-800">{increase}%</span>
      </div>
    </Card>
  );
};

export default DashboardCard;
