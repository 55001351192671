import { Button, DatePicker, Form, FormInstance, Input, Select, Switch } from "antd";
import FormLabelCrud from "components/FormLabel/FormLabel";
import useFilterLanguage from "custom-hooks/useFilterLanguage";
import { BiX } from "react-icons/bi";
import { PlusOutlined } from '@ant-design/icons';
import { ILanguage } from "pages/languages/types";
import useGetData from "custom-hooks/useGetData";
import LanguageTitle from "utils/LanguageTitle";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const FormItems = ({ form, handleSubmit }: { form: FormInstance, handleSubmit: any }) => {

    const {t} = useTranslation()
    const { data: languages } = useGetData<ILanguage[]>({ queryKey: ['languages'], url: 'language/list/', options: { staleTime: Infinity, refetchOnWindowFocus: false } });
    const { fl, languageIds } = useFilterLanguage(form);

    return (
        <Form layout={"vertical"} form={form} onFinish={handleSubmit}>
            <div className="grid grid-cols-12 gap-4">
                <Form.List name="translations">
                    {(fields, { add, remove }) => (
                        <div className="col-span-12">
                            {fields.map(({ key, name, ...restField }, index) => (
                                <div key={index} className='grid grid-cols-12 gap-4 mb-4 bg-slate-50 p-2 rounded-md'>
                                    <div className="lg:col-span-4 sm:col-span-6 col-span-12">
                                        <Form.Item
                                            className='mb-0'
                                            label={<FormLabelCrud>Title</FormLabelCrud>}
                                            {...restField}
                                            name={[name, 'title']}
                                            rules={[{ required: index === 0, message: 'Please input title!' }]}
                                        >
                                            <Input placeholder="Ma'lumot kiriting" />
                                        </Form.Item>
                                    </div>
                                    <div className="lg:col-span-4 sm:col-span-6 col-span-12">
                                        <Form.Item
                                            className='mb-0'
                                            label={<FormLabelCrud>Description</FormLabelCrud>}
                                            {...restField}
                                            name={[name, 'desc']}
                                            rules={[{ required: true, message: 'Please input Description!' }]}
                                        >
                                            <Input placeholder="Description" />
                                        </Form.Item>
                                    </div>

                                    <div className="lg:col-span-4 sm:col-span-6 col-span-12 flex items-end">
                                        <Form.Item
                                            label={<FormLabelCrud>Language</FormLabelCrud>}
                                            {...restField}
                                            name={[name, 'language_id']}
                                            rules={[{ required: index === 0, message: 'Please select language' }]}
                                            className="w-[calc(100%-55px)] mb-0"
                                        >
                                            <Select onClick={fl}>
                                                {
                                                    languages?.map(element => <Option key={element.id} value={element.id} disabled={languageIds.includes(element.id)}><LanguageTitle element={element}/></Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item className="w-[50px] mb-0 ml-2 mt-[25px]">
                                            <Button disabled={fields.length == 1} onClick={() => { remove(name); }} danger><BiX /></Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            ))}
                            <div className="col-span-12">
                                <Button type="dashed" className="w-[200px] block text-[#959595]" onClick={() => { add() }} block icon={<PlusOutlined />} disabled={fields.length === languages?.length}>
                                    {t("Add field")}
                                </Button>
                            </div>
                        </div>
                    )}
                </Form.List>
                <div className="md:col-span-3 sm:col-span-6 col-span-12">
                    <Form.Item
                        label={t("Percent")}
                        name="percent"
                        rules={[{ required: true, message: 'Please input percent!' }]}
                    >
                        <Input placeholder="Percent" type="number" />
                    </Form.Item>
                </div>
                <div className="md:col-span-3 sm:col-span-6 col-span-12">
                    <Form.Item
                        label={t("Start date")}
                        name="start"
                        rules={[{ required: true, message: 'Please input start date!' }]}
                    >
                        <DatePicker format={'YYYY-MM-DD'} className="w-[100%]" />
                    </Form.Item>
                </div>
                <div className="md:col-span-3 sm:col-span-6 col-span-12">
                    <Form.Item
                        label={t("End date")}
                        name="stop"
                        rules={[{ required: true, message: 'Please input end date!' }]}
                    >
                        <DatePicker format={'YYYY-MM-DD'} className="w-[100%]" />
                    </Form.Item>
                </div>
                <div className="md:col-span-1 sm:col-span-2 col-span-6">
                    <Form.Item
                        label={t("Status")}
                        name="is_active"
                        rules={[{ required: false, message: 'Please input is status!' }]}
                        valuePropName={'checked'}
                    >
                        <Switch defaultChecked className="bg-[#999]" />
                    </Form.Item>
                </div>
                <div className="sm:col-span-2 col-span-6">
                    <Form.Item
                        label={t("Archive")}
                        name="is_archive"
                        rules={[{ required: false, message: 'Please input is archive!' }]}
                        valuePropName={'checked'}
                    >
                        <Switch defaultChecked className="bg-[#999]" />
                    </Form.Item>
                </div>
            </div>
        </Form>
    );
};

export default FormItems;