import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { FiEdit } from "react-icons/fi";
import DeleteIcon from "components/Common/deleteIcon";
import { deleteData, getData } from "./profession.request";
import { AxiosError } from "axios";
import { IProfession } from "./types";
import CreateRegion from "./profession_form_ui";
import { Badge, Button, Empty } from "antd";
import TableLoader from "components/loaders/table_loader";
import PageTitle from "components/Common/PageTitle";
import { is_permission } from "utils.functions";
import { useTranslation } from "react-i18next";

const Professions = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<IProfession | null>(null);
  const [openSidebarMenu, setOpenSidebarMenu] = useState<boolean>(false);

  const deletePost = useMutation({
    mutationFn: deleteData,
  });

  useEffect(() => {
    if (!deletePost.isLoading) {
      refetch();
    }
  }, [deletePost.isLoading]);

  const { isLoading, error, data, refetch } = useQuery<IProfession[], AxiosError>({
    queryKey: ["professions"],
    queryFn: () => getData(),
    retry: 0,
    staleTime: Infinity,
  });

  if (error) return "An error has occurred: " + error.message;

  return (
    <div>
      <div className="grid grid-cols-12 gap-4">
        <div className="main-card lg:col-span-8 col-span-12">
          <div className="flex mb-3 justify-between">
            <PageTitle title={"Professions"} />
            {is_permission("create_profession") ? (
              <Button
                onClick={() => {
                  setOpenSidebarMenu(true);
                  setSelected(null);
                }}
                type="default"
                className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763] lg:hidden"
              >
                + {t("Create")}
              </Button>
            ) : null}
          </div>
          <Table className="table-striped table-hover">
            <Thead>
              <Tr>
                <Th className="w-[50px]">№</Th>
                <Th className="w-[50px] text-center px-[0!important]">{t("Id")}</Th>
                <Th>{t("Title")}</Th>
                <Th>{t("Description")}</Th>
                <Th>{t("Slug")}</Th>
                <Th>{t("Status")}</Th>
                <Th>{t("Actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading
                ? data?.length > 0
                  ? data?.map((item, index: number) => (
                      <Tr key={item.id}>
                        <Td>{index + 1}</Td>
                        <Td className="w-[50px] text-center px-[0!important]">{item?.id}</Td>
                        <Td>{item?.translations[0]?.title}</Td>
                        <Td>{item?.translations[0]?.desc}</Td>
                        <Td>{item?.slug}</Td>
                        <Td>
                          {item?.is_active ? (
                            <Badge count={t("Active")} showZero color="#52c41a" />
                          ) : (
                            <Badge count={t("InActive")} showZero color="#faad14" />
                          )}
                        </Td>
                        <Td>
                          <div className="flex" style={{ alignItems: "center" }}>
                            {is_permission("update_profession") ? (
                              <FiEdit
                                className="mx-2 text-[green] text-[18px] cursor-pointer"
                                onClick={() => {
                                  setSelected(item);
                                  setOpenSidebarMenu(true);
                                }}
                              />
                            ) : null}
                            {is_permission("delete_profession") ? (
                              <DeleteIcon confirmFunc={() => deletePost.mutate(item?.id)} />
                            ) : null}
                          </div>
                        </Td>
                      </Tr>
                    ))
                  : null
                : null}
            </Tbody>
          </Table>
          {data?.length === 0 ? <Empty className="w-[100%]" /> : null}
          <TableLoader isLoading={isLoading} />
        </div>
        {(is_permission("create_profession") && !selected) || (is_permission("update_profession") && selected) ? (
          <div className="main-card col-span-4 lg:block hidden">
            <CreateRegion
              element={selected}
              setElement={setSelected}
              refetch={refetch}
              openSidebarMenu={openSidebarMenu}
              setOpenSidebarMenu={setOpenSidebarMenu}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Professions;
