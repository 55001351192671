import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IBlockForm, ILesson } from "../../types";
import { AxiosError } from "axios";
import { getBlocksByCourseLessonId } from "../../lesson.request";
import TableLoader from "components/loaders/table_loader";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import "./style.scss";
import CodeBlock from "./codeBlock/code_block";
import { IFileBlock, IQuizBlock, ITextBlock, ITrainingBlock, IVideoBlock } from "./types";
import { removeBlock } from "./block.request";
import TextBlock from "./textBlock/textBlock";
import VideoBlock from "./videoBlock/videoBlock";
import FileBlock from "./fileBlock/fileBlock";
import QuizBlock from "./quizBlock/quizBlock";
import { useAppDispatch, useAppSelector } from "store/service";
import { BLOCK_ACTIONS } from "store/block";
import { Spin } from "antd";
const svg = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2498_1291)">
        <path
          d="M18.0001 15.8791L25.4251 8.4541L27.5461 10.5751L20.1211 18.0001L27.5461 25.4251L25.4251 27.5461L18.0001 20.1211L10.5751 27.5461L8.4541 25.4251L15.8791 18.0001L8.4541 10.5751L10.5751 8.4541L18.0001 15.8791Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2498_1291">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

interface Item {
  uuid: string;
  id: string;
  content: string;
  sort: number;
  slug: "training_block" | "text_block" | "video_block" | "file_block" | "quiz_block";
  trainingData?: ITrainingBlock | undefined;
  textData?: ITextBlock | undefined;
  videoData?: IVideoBlock | undefined;
  fileData?: IFileBlock | undefined;
  quizData?: IQuizBlock | undefined;
  isExist: boolean;
}

const initialItems: Item[] = [
  { uuid: uuidv4(), id: uuidv4(), content: "🤖 Training block", slug: "training_block", sort: 1, isExist: false },
  { uuid: uuidv4(), id: uuidv4(), content: "📝 Text block", slug: "text_block", sort: 2, isExist: false },
  { uuid: uuidv4(), id: uuidv4(), content: "🎥 Video block", slug: "video_block", sort: 3, isExist: false },
  { uuid: uuidv4(), id: uuidv4(), content: "📂 File block", slug: "file_block", sort: 4, isExist: false },
  { uuid: uuidv4(), id: uuidv4(), content: "❔ Quiz block", slug: "quiz_block", sort: 5, isExist: false },
];

const LessonBlocks = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [leftItems, setLeftItems] = useState<Item[]>(initialItems);
  const [rightItems, setRightItems] = useState<Item[]>([]);
  const [removedBlocks, setRemovedBlocks] = useState<any>([]);
  const { block } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [blockForms, setBlockForms] = useState<IBlockForm[]>([]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    if (result.source.droppableId === "left" && result.destination.droppableId === "right") {
      const movedItem = leftItems.find((item) => item.uuid === result.draggableId);

      setRightItems((prevItems) => {
        const updatedItems = [...prevItems];
        if (result.destination) {
          updatedItems.splice(result.destination.index, 0, {
            ...movedItem!,
            uuid: uuidv4(),
            sort: prevItems.length + 1,
          });
        }

        const sortedItems = updatedItems.map((item, index) => ({
          ...item,
          sort: index + 1,
        }));

        return sortedItems;
      });
    }

    if (result.source.droppableId === "right" && result.destination.droppableId === "right") {
      const updatedItems = Array.from(rightItems);
      const [movedItem] = updatedItems.splice(result.source.index, 1);
      updatedItems.splice(result.destination.index, 0, movedItem);

      const sortedItems = updatedItems.map((item, index) => ({
        ...item,
        sort: index + 1,
      }));

      setRightItems(sortedItems);
    }
  };

  const handleDelete = (itemId: string, code_slug: string, isExist: boolean, uuid: string) => {
    setRightItems((prevItems) =>
      prevItems
        .filter((item) => item.uuid !== uuid)
        .map((item, index) => ({
          ...item,
          sort: index + 1,
        }))
    );
    if (isExist) {
      setRemovedBlocks([...removedBlocks, { id: itemId, code_slug }]);
    }
    dispatch(BLOCK_ACTIONS.removeBlockItem({ id: uuid }));
    setBlockForms([...blockForms.filter((it) => it.id !== uuid)]);
  };

  const { data, isLoading, error, refetch } = useQuery<ILesson, AxiosError>({
    queryKey: ["lesson-blocks-by-course-lesson-id", id],
    queryFn: () => getBlocksByCourseLessonId(id),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    let elements: Item[] = [];
    data?.blocks.training_block.map((block, index) => {
      elements.push({
        uuid: uuidv4(),
        id: String(block.id),
        content: "Training block",
        slug: "training_block",
        sort: block.sort,
        trainingData: block,
        isExist: true,
      });
    });
    data?.blocks.text_block.map((block, index) => {
      elements.push({
        uuid: uuidv4(),
        id: String(block?.id),
        content: "Text block",
        slug: "text_block",
        sort: block.sort,
        textData: block,
        isExist: true,
      });
    });
    data?.blocks.video_block.map((block, index) => {
      elements.push({
        uuid: uuidv4(),
        id: String(block?.id),
        content: "Video block",
        slug: "video_block",
        sort: block.sort,
        videoData: block,
        isExist: true,
      });
    });
    data?.blocks.file_block.map((block, index) => {
      elements.push({
        uuid: uuidv4(),
        id: String(block?.id),
        content: "File block",
        slug: "file_block",
        sort: block.sort,
        fileData: block,
        isExist: true,
      });
    });
    data?.blocks.quiz_block.map((block, index) => {
      elements.push({
        uuid: uuidv4(),
        id: String(block?.id),
        content: "Quiz block",
        slug: "quiz_block",
        sort: block.sort,
        quizData: block,
        isExist: true,
      });
    });
    elements = elements.sort((a, b) => a.sort - b.sort);
    setRightItems(
      elements.map((item, index) => ({
        ...item,
        sort: index + 1,
      }))
    );
    // setRightItems(elements);
  }, [data]);

  useEffect(() => {
    rightItems.map((item) => {
      if (!block.find((p) => p.id === item.uuid)) {
        dispatch(BLOCK_ACTIONS.addBlockItem({ id: item.uuid, loading: false }));
      }
    });
  }, [rightItems]);

  if (error) return <span>{error.message}</span>;
  if (isLoading)
    return (
      <div>
        <TableLoader isLoading={isLoading} />;
      </div>
    );
  const removeOldElements = () => {
    if (removedBlocks.length) {
      removedBlocks.forEach((item: any, index: number) => {
        removeBlock(item);
      });
      setRemovedBlocks([]);
    }
  };

  const submitForms = () => {
    blockForms.forEach((item) => {
      item.form.submit();
    });
  };

  return (
    <div className="lesson__blocks">
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="lesson__blocks__wrapper">
          <Droppable droppableId="left">
            {(provided) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="lesson__blocks__left lesson__blocks__list"
              >
                {leftItems.map((item, index) => (
                  <Draggable key={item.uuid} draggableId={item.uuid} index={index}>
                    {(provided) => (
                      <li
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={{
                          ...provided.draggableProps.style,
                        }}
                        className="lesson__block__item"
                      >
                        {item.content}
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
          <Droppable droppableId="right">
            {(provided) => (
              <div className="w-full">
                <ul
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="lesson__blocks__right lesson__blocks__list"
                >
                  {/* <TableLoader isLoading={true} /> */}
                  <>
                    {rightItems.map((item, index) => (
                      <Draggable key={item.uuid} draggableId={item.uuid} index={index}>
                        {(provided) => (
                          <li
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            style={{
                              ...provided.draggableProps.style,
                            }}
                            className="lesson__block__element"
                          >
                            {item.slug === "training_block" ? (
                              <CodeBlock
                                uuid={item.uuid}
                                sort={item.sort}
                                trainingData={item.trainingData}
                                setBlockForms={setBlockForms}
                                blockForms={blockForms}
                              />
                            ) : item.slug === "text_block" ? (
                              <TextBlock
                                uuid={item.uuid}
                                sort={item.sort}
                                textData={item.textData}
                                setBlockForms={setBlockForms}
                                blockForms={blockForms}
                              />
                            ) : item.slug === "video_block" ? (
                              <VideoBlock
                                uuid={item.uuid}
                                sort={item.sort}
                                videoData={item.videoData}
                                setBlockForms={setBlockForms}
                                blockForms={blockForms}
                              />
                            ) : item.slug === "file_block" ? (
                              <FileBlock
                                uuid={item.uuid}
                                sort={item.sort}
                                fileData={item.fileData}
                                setBlockForms={setBlockForms}
                                blockForms={blockForms}
                              />
                            ) : item.slug === "quiz_block" ? (
                              <QuizBlock
                                uuid={item.uuid}
                                sort={item.sort}
                                quizData={item.quizData}
                                setBlockForms={setBlockForms}
                                blockForms={blockForms}
                              />
                            ) : (
                              item.content
                            )}
                            <button
                              onClick={() => handleDelete(item.id, item.slug, item.isExist, item.uuid)}
                              className="lesson__block__remover"
                            >
                              {svg()}
                            </button>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </>
                </ul>
                <button
                  disabled={block?.map((it) => it.loading).includes(true)}
                  className="saveButton"
                  onClick={() => {
                    removeOldElements();
                    submitForms();
                  }}
                >
                  {t("Save")} {block?.map((it) => it.loading).includes(true) ? <Spin /> : ""}
                </button>
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  );
};

export default LessonBlocks;
