import { useMutation, useQuery } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { FiEdit } from "react-icons/fi";
import DeleteIcon from "components/Common/deleteIcon";
import { deleteData, getDiscounts } from "./discount.request";
import { IDiscount } from "./types";
import { Badge, Button, Empty, message } from "antd";
import TableLoader from "components/loaders/table_loader";
import { Link, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import PageTitle from "components/Common/PageTitle";
import { CButton } from "components/Common/CustomButtons";
import { is_permission } from "utils.functions";
import { useTranslation } from "react-i18next";

const Discounts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const deleteMutation = useMutation({
    mutationFn: (id: number) => deleteData(id),
    onSuccess: () => {
      refetch();
    },
  });

  const { data, isLoading, error, refetch } = useQuery<IDiscount[], AxiosError>({
    queryKey: ["discounts"],
    queryFn: () => getDiscounts(),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  if (error) return "An error has occurred: " + error.message;
  const copyToClipboard = async (data: any) => {
    await navigator.clipboard.writeText(data);
    message.success("ID copied");
  };
  return (
    <div className="main-card">
      <div className="grid grid-cols-12">
        <div className="col-span-6">
          <PageTitle title="Discounts" />
        </div>
        {is_permission("create_discount") ? (
          <div className="col-span-6 text-end">
            <CButton onClick={() => navigate("/discount/create")} />
          </div>
        ) : null}
      </div>
      <Table className="table-striped table-hover mt-3">
        <Thead>
          <Tr>
            <Th className="w-[50px]">№</Th>
            <Th>{t("Title")}</Th>
            {/* <Th>Percent</Th> */}
            <Th>{t("Start date")}</Th>
            <Th>{t("End date")}</Th>
            <Th>{t("Status")}</Th>
            <Th>{t("Archive")}</Th>
            {/* <Th>Description</Th> */}
            <Th>{t("Actions")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!isLoading
            ? data?.length > 0
              ? data?.map((item: IDiscount, index: number) => (
                  <Tr key={item?.id}>
                    <Td onClick={() => copyToClipboard(item?.id)} className="cursor-pointer">{index + 1}</Td>
                    <Td>{item?.translations[0]?.title}</Td>
                    {/* <Td>{item?.percent}</Td> */}
                    <Td>{item?.start}</Td>
                    <Td>{item?.stop}</Td>
                    <Td>
                      {item?.is_active ? (
                        <Badge count={t("Active")} showZero color="#52c41a" />
                      ) : (
                        <Badge count={t("InActive")} showZero color="#faad14" />
                      )}
                    </Td>
                    <Td>
                      {item?.is_archive ? (
                        <Badge count={t("Archive")} showZero color="#faad14" />
                      ) : (
                        <Badge count={t("Not archive")} showZero color="#52c41a" />
                      )}
                    </Td>
                    {/* <Td>{item?.translations[0]?.desc}</Td> */}
                    <Td>
                      <div className="flex" style={{ alignItems: "center" }}>
                        {is_permission("update_discount") ? (
                          <Link to={`/discount/update/${item?.id}`}>
                            <FiEdit className="mx-2 text-[green] text-[18px] cursor-pointer" />
                          </Link>
                        ) : null}
                        {is_permission("delete_discount") ? (
                          <DeleteIcon confirmFunc={() => deleteMutation.mutate(item?.id)} />
                        ) : null}
                      </div>
                    </Td>
                  </Tr>
                ))
              : null
            : null}
        </Tbody>
      </Table>
      {data?.length === 0 ? <Empty className="w-[100%]" /> : null}
      <TableLoader isLoading={isLoading} />
    </div>
  );
};
export default Discounts;
