import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Drawer, Form, Input, message, Switch } from "antd";
import useWindowSize from "custom-hooks/useWindowSize";
import { submitData } from "./region.request";
import { IInstallmentPayment } from "./types";
import MultipleLangInputs from "components/MultipleLangInput/multipleLangs";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import { RButton, SButton } from "components/Common/CustomButtons";
import FormLabelCrud from "components/FormLabel/FormLabel";
import EditingTitle from "components/Common/EditingTitle";
import PartTitle from "components/Common/PartTitle";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";
import { useTranslation } from "react-i18next";


type TypePropsCreateInstallmentPayment = {
  element: IInstallmentPayment | null;
  setElement: React.Dispatch<React.SetStateAction<IInstallmentPayment | null>>;
  refetch: Function;
  openSidebarMenu: boolean;
  setOpenSidebarMenu: React.Dispatch<React.SetStateAction<boolean>>;
}
const CreateInstallmentPayment = ({ element, setElement, refetch, openSidebarMenu, setOpenSidebarMenu }: TypePropsCreateInstallmentPayment) => {
  
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const { width } = useWindowSize();
  const { _translate } = useLocaleTranslation();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (element) {
      form.setFieldsValue(element)
    } else {
      form.resetFields()
      form.setFieldsValue({
        is_active: true,
        is_archive: false,
        translations: [{ title: "", desc: "" }]
      })
    }
  }, [element?.id, openSidebarMenu]);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-region"],
    mutationFn: (newValues) => submitData(element?.id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["regions"], data);
      refetch();
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data)
    },
    retry: 0,
  });

  function handleSubmit(values: any) {
    mutate(values);
  }

  const MemoizationFieldsFormInstallmentPayment = React.memo(() => (
    <Form
      layout={"vertical"}
      form={form}
      onFinish={handleSubmit}
      className="custom_form_style"
    >
      <MultipleLangInputs form={form} additionalItems={['desc']} />
      <div className="grid grid-cols-12 gap-4">
        <div className="sm:col-span-6 col-span-12">
          <Form.Item
            label={<FormLabelCrud>Parts count</FormLabelCrud>}
            name="parts_count"
            rules={[{ required: true, message: 'Please input Parts count!' }]}
          >
            <Input min={1} type="number" />
          </Form.Item>
        </div>
        <div className="sm:col-span-6 col-span-12">
          <Form.Item
            label={<FormLabelCrud>Extra percent</FormLabelCrud>}
            name="extra_percent"
            rules={[{ required: true, message: 'Please input Extra percent!' }]}
          >
            <Input max={100} min={2} type="number" />
          </Form.Item>
        </div>
      </div>
      <div className="grid grid-cols-12 ">
        <div className="xl:col-span-3 col-span-6">
          <Form.Item
            name="is_active"
            label={<FormLabelCrud>Status</FormLabelCrud>}
            rules={[{ required: false, message: 'Please input is status!' }]}
            valuePropName={'checked'}
          >
            <Switch defaultChecked className="bg-[#999]" />
          </Form.Item>
        </div>
        <div className="xl:col-span-3 col-span-6">
          <Form.Item
            name="is_archive"
            label={<FormLabelCrud>Archieve</FormLabelCrud>}
            rules={[{ required: false, message: 'Please input is status!' }]}
            valuePropName={'checked'}
          >
            <Switch defaultChecked className="bg-[#999]" />
          </Form.Item>
        </div>
        <div className="xl:col-span-6 col-span-12  sm:text-right mt-5">
          <RButton
            onClick={() => {
              form.resetFields();
              setElement(null);
              form.setFieldsValue({
                is_active: true,
                is_archive: false,
                translations: [{ title: "", desc: "" }]
              })
            }}
            className="2xl:mr-2 xl:mb-2 xl:mr-0 md:mr-2 mr-1"
          />
          <SButton loading={isLoading} />
        </div>
      </div>
    </Form>
  ));

  return (
    <div>
      <>{element ? <EditingTitle title={_translate(element?.translations ?? [], 'title')} /> : <PartTitle children={"Installment payment create"} />}</>
      <hr className="my-3" />
      <MemoizationFieldsFormInstallmentPayment />
      <Drawer
        title={<h3 className=" font-bold">{element?.translations[0]?.title ? element?.translations[0]?.title : t("New")}</h3>}
        placement="right"
        onClose={() => setOpenSidebarMenu(false)}
        open={width > 992 ? false : openSidebarMenu}
      >
        <MemoizationFieldsFormInstallmentPayment />
      </Drawer>
    </div>
  );
};
export default CreateInstallmentPayment;
