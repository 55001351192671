import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Form } from "antd";
import { submitData } from "./course_access.request";
import ToBack from "components/Common/ToBack";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { IAccessCourseForSubmit } from "./types";
import FormItems from "./course_access_form_ui";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import { useTranslation } from "react-i18next";

const CreateCourseAccess = () => {

  const {t} = useTranslation()
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { course_id } = useParams();

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-course-access"],
    mutationFn: (newValues: IAccessCourseForSubmit) => submitData(newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["course-access"], data);
      if (data?.redirect_url) {
        window.open(data?.redirect_url, "_blank");
      }
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  useEffect(() => {
    form.setFieldsValue({
      is_full: false,
    });
  }, []);

  function handleSubmit(values: any) {
    if (values?.is_full) {
      values.course_id = course_id;
    }
    delete values?.is_full;
    mutate(values);
  }

  function saveAndAnother() {
    form.submit();
    setTimeout(() => {
      form.resetFields();
      form.setFieldsValue({
        is_full: false,
      });
    }, 300);
  }
  function saveAndEdit() {
    form.submit();
  }

  return (
    <div className="main-card">
      <ToBack title={t("Course access create")} />
      <hr className="my-3" />
      <FormItems form={form} handleSubmit={handleSubmit} />
      <div className="md:flex justify-end mt-2">
        <Button
          onClick={saveAndAnother}
          loading={isLoading}
          type="default"
          className="md:w-auto w-[100%] bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]"
          htmlType="submit"
        >
         {t("Save and add another")}
        </Button>
        <Button
          onClick={saveAndEdit}
          loading={isLoading}
          type="default"
          className="md:w-auto w-[100%] bg-[#045140] text-white hover:text-white hover:bg-[#1a7763] md:ml-3 md:my-0 my-3"
          htmlType="submit"
        >
          {t("Save and continue editing")}
        </Button>
      </div>
    </div>
  );
};

export default CreateCourseAccess;
