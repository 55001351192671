import instance from "config/axios.config";
import { ICourse } from "./types";

export async function submitData(id: number | undefined, data: ICourse) {

    const url = id ? `/course/${id}` : `course/create`;
    const response = await instance({ url, method: id ? 'PUT' : "POST", data });
    return response.data

}

export async function getData(page:number, pageSize:number) {
    const response = await instance({ url: `/course/list/`, method: 'GET',params: {page_number: page, page_size: pageSize }});
    return response.data;
}

export async function getDataById(id: number) {

    const response = await instance({ url: `/course/${id}`, method: 'GET' });
    return response.data;
}


export function deleteData(id: number) {
    return instance({ url: `course/${id}`, method: 'DELETE' })
}

export async function submitCourseMedia(id: string | undefined, data:any) {

    const formdata = new FormData()

    Object.keys(data).map(i =>  data[i] && formdata.append(i, data[i]))

    const response = await instance({ url: `/course/upload/media/${id}`, method: "POST", data: formdata });
    return response.data

}