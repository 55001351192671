import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import uz_tranlation from "./languages/uz.translation.json";
import ru_tranlation from "./languages/ru.translation.json";
import en_tranlation from "./languages/en.translation.json";



const resources = {
    uz: {
        translation: uz_tranlation
    },
    ru: {
        translation: ru_tranlation
    },
    en: {
        translation: en_tranlation
    },
}

const language = localStorage.getItem('i18lang');


if (!language) {
    localStorage.setItem('i18lang', 'uz')
}


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: resources,
        lng: localStorage.getItem("i18lang") || 'uz',
        fallbackLng: "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;