import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { FiEdit } from "react-icons/fi";
import DeleteIcon from "components/Common/deleteIcon";
import { deleteData, getCourseReviews } from "./reivew.request";
import { ICourseReviews } from "./types";
import CreateCourseModules from "./mudule_form_ui";
import { Badge, Button, Empty } from "antd";
import TableLoader from "components/loaders/table_loader";
import { AxiosError } from "axios";
import { Link, useParams } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { is_permission } from "utils.functions";
import { useTranslation } from "react-i18next";
import PageTitle from "components/Common/PageTitle";

const CourseReviews = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [selected, setSelected] = React.useState<ICourseReviews | null>(null);
  const [openSidebarMenu, setOpenSidebarMenu] = useState<boolean>(false);

  const deletePost = useMutation({
    mutationFn: deleteData,
  });

  const { isLoading, error, data, refetch } = useQuery<ICourseReviews[], AxiosError>({
    queryKey: ["course-reviews", id],
    queryFn: () => getCourseReviews(id),
    retry: 0,
    staleTime: Infinity,
    onSuccess: () => {
      // mutate();
    },
  });

  useEffect(() => {
    if (!deletePost.isLoading) {
      refetch();
    }
  }, [deletePost.isLoading]);

  if (error) return <div>'An error has occurred: '</div>;

  return (
    <div>
      <div className="grid grid-cols-12 items-center">
        <div className="col-span-6">
          <PageTitle title="Course reviews" />
        </div>
        {is_permission("create_course_review") ? (
          <div className="col-span-6 text-end">
            <Button
              onClick={() => {
                setOpenSidebarMenu(true);
                setSelected(null);
              }}
              type="default"
              className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]"
            >
              + {t("Create")}
            </Button>
          </div>
        ) : null}
        <div className="col-span-12 md:overflow-auto overflow-x-scroll">
          <Table className="table-striped table-hover mt-3">
            <Thead>
              <Tr>
                <Th className="w-[50px]">№</Th>
                <Th className="w-[50px] text-center px-[0!important]">{t("Id")}</Th>
                <Th>{t("Text")}</Th>
                <Th>{t("Sort")}</Th>
                <Th>{t("Status")}</Th>
                <Th>{t("Verified")}</Th>
                <Th>{t("Actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading
                ? data?.length > 0
                  ? data?.map((item: ICourseReviews, index: number) => (
                      <Tr key={item.id}>
                        <Td>{index + 1}</Td>
                        <Td className="w-[50px] text-center px-[0!important]">{item?.id}</Td>
                        <Td>{item?.translations[0]?.text}</Td>
                        <Td>{index + 1}</Td>
                        <Td>
                          {item?.is_active ? (
                            <Badge count={t("Active")} showZero color="#52c41a" />
                          ) : (
                            <Badge count={t("InActive")} showZero color="#faad14" />
                          )}
                        </Td>
                        <Td>
                          {item?.is_verified ? (
                            <Badge count={t("Verified")} showZero color="#52c41a" />
                          ) : (
                            <Badge count={t("Not verified")} showZero color="#faad14" />
                          )}
                        </Td>
                        <Td>
                          <div className="flex" style={{ alignItems: "center" }}>
                            {is_permission("read_course_review") ? (
                              <Link to={`/course/reviews/view/${item?.id}`}>
                                <AiOutlineEye className=" mr-2 text-[#faad14] text-[22px] cursor-pointer" />
                              </Link>
                            ) : null}
                            {is_permission("update_course_review") ? (
                              <FiEdit
                                className="mx-2 text-[green] text-[18px] cursor-pointer"
                                onClick={() => {
                                  setSelected(item);
                                  setOpenSidebarMenu(true);
                                }}
                              />
                            ) : null}
                            {is_permission("delete_course_review") ? (
                              <DeleteIcon confirmFunc={() => deletePost.mutate(item?.id)} />
                            ) : null}
                          </div>
                        </Td>
                      </Tr>
                    ))
                  : null
                : null}
            </Tbody>
          </Table>
        </div>
        {data?.length === 0 ? <Empty className="w-[100%]" /> : null}
        <TableLoader isLoading={isLoading} />
      </div>
      {(is_permission("create_course_review") && !selected) || (is_permission("update_course_review") && selected) ? (
        <CreateCourseModules
          element={selected}
          setElement={setSelected}
          refetch={refetch}
          openSidebarMenu={openSidebarMenu}
          setOpenSidebarMenu={setOpenSidebarMenu}
        />
      ) : null}
    </div>
  );
};
export default CourseReviews;
