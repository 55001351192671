import { useMutation, useQuery } from "@tanstack/react-query";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { FiEdit } from "react-icons/fi";
import { BiCheck } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { deleteData, getLessons } from "./lesson.request";
import { ILesson } from "./types";
import { Button, Empty } from "antd";
import TableLoader from "components/loaders/table_loader";
import { Link, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { AiOutlineEye } from "react-icons/ai";
import DeleteIcon from "components/Common/deleteIcon";
import { is_permission } from "utils.functions";
import { useTranslation } from "react-i18next";
import PageTitle from "components/Common/PageTitle";

const ModuleLessons = () => {

  const {t} = useTranslation()
  const { id } = useParams();

  const deleteMutation = useMutation({
    mutationFn: (id: number) => deleteData(id),
    onSuccess: () => {
      refetch();
    },
  });

  const { data, isLoading, error, refetch } = useQuery<ILesson[], AxiosError>({
    queryKey: ["lessons"],
    queryFn: () => getLessons(id),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  if (error) return <p>An error has occurred: + {error.message}</p>;

  return (
    <div className="grid grid-cols-12 items-center">
      <div className="col-span-6">
       <PageTitle title={t("Module lessons")}/>
        </div>
        {is_permission("create_course_lesson") ? (
          <Link to={`/course/module/lesson/create/${id}`} className="col-span-6 text-right">
            <Button type="default" className="bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]">
              + {t("Create")}
            </Button>
          </Link>
        ) : null}
     <div className="col-span-12 md:overflow-auto overflow-x-hidden">
     <Table className="table-striped table-hover mt-3">
        <Thead>
          <Tr>
            <Th className="w-[50px]">№</Th>
            <Th>{t("Title")}</Th>
            <Th>{t("Slug")}</Th>
            <Th>{t("Sort")}</Th>
            <Th>{t("Status")}</Th>
            <Th>{t("Free")}</Th>
            <Th>{t("Homework")}</Th>
            <Th>{t("Actions")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!isLoading
            ? data?.length > 0
              ? data?.map((item: ILesson, index: number) => (
                  <Tr key={item?.id}>
                    <Td>{index + 1}</Td>
                    <Td>{item?.translations[0]?.title}</Td>
                    <Td>{item?.slug}</Td>
                    <Td>{item?.sort ? item?.sort : index+1}</Td>
                    <Td>
                      {item?.is_active ? (
                        <BiCheck className="text-[24px] text-green-700" />
                      ) : (
                        <IoMdClose className="text-[22px] text-rose-600" />
                      )}
                    </Td>
                    <Td>
                      {item?.is_free ? (
                         <BiCheck className="text-[24px] text-green-700" />
                         ) : (
                           <IoMdClose className="ml-2 text-[22px] text-rose-600" />
                         )}
                    </Td>
                    <Td>
                      {item?.is_homework ? (
                        <BiCheck className="text-[24px] text-green-700" />
                        ) : (
                          <IoMdClose className="ml-2 text-[22px] text-rose-600" />
                        )}
                    </Td>
                    
                    <Td>
                      <div className="flex" style={{ alignItems: "center" }}>
                        {is_permission("read_course_lesson") ? (
                            <Link to={`/course/module/lesson/view/${item?.id}`}>
                              <AiOutlineEye className=" mr-2 text-[#faad14] text-[22px] cursor-pointer" />
                            </Link>
                          ) : null}
                          {is_permission("update_course_lesson") ? (
                            <Link to={`/course/module/lesson/update/${item?.id}`}>
                              <FiEdit className="mx-2 text-[green] text-[18px] cursor-pointer" />
                            </Link>
                          ) : null}
                          {is_permission("delete_course_lesson") ? (
                            <DeleteIcon confirmFunc={() => deleteMutation.mutate(item?.id)} />
                          ) : null}
                      </div>
                    </Td>
                  </Tr>
                ))
              : null
            : null}
        </Tbody>
      </Table>
     </div>
      {data?.length === 0 ? <Empty className="w-[100%]" /> : null}
      <TableLoader isLoading={isLoading} />
    </div>
  );
};
export default ModuleLessons;
