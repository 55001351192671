import { useState } from "react";
import "video-react/dist/video-react.css";
import { Button } from "antd";
import { Link, useParams } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getDataById, submitCourseMedia } from "../course.request";
import { ICourse } from "../types";
import { AxiosError } from "axios";
import { FILE_URL } from "config/utils";
import CourseCategory from "./details/category";
import { Player } from "video-react";
import { is_permission } from "utils.functions";
import { BiCheck } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";

const ViewCourse = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [imageSrc, setImageSrc] = useState({ url: "", file: "" });
  const [videoSrcTreyler, setvideoSrctreyler] = useState({ url: "", file: "" });
  const [videoSrcBanner, setvideoSrcBanner] = useState({ url: "", file: "" });

  const handleImageChange = (e: any) => {
    setImageSrc({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  const handleVideoTreylerChange = (e: any) => {
    setvideoSrctreyler({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  const handleVideoBannerChange = (e: any) => {
    setvideoSrcBanner({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };

  const getDataByIdQuery = useQuery<ICourse, AxiosError>({
    queryKey: ["course-by-id"],
    queryFn: () => getDataById(Number(id)),
    onSuccess: (data: any) => {
      if (!imageSrc.file && data?.banner_image) {
        setImageSrc({ url: FILE_URL + data?.banner_image?.path, file: "" });
      }
      if (!videoSrcBanner.file && data?.banner_file) {
        setvideoSrcBanner({
          url: FILE_URL + data?.banner_file?.path,
          file: "",
        });
      }
      if (!videoSrcTreyler.file && data?.trailer) {
        setvideoSrctreyler({ url: FILE_URL + data?.trailer?.path, file: "" });
      }
    },
    retry: 0,
    refetchOnWindowFocus: false,
  });

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-course-media"],
    mutationFn: (newValues: any) => submitCourseMedia(id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["courses"], data);
    },
    onError: (error, context: any) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
    retry: 0,
  });

  const saveMedia = () => {
    mutate({
      banner_image: imageSrc.file,
      banner_file: videoSrcBanner.file,
      trailer: videoSrcTreyler.file,
    });
  };


  return (
    <div>
      <div className="grid grid-cols-12 mt-4 gap-4">
        <div className="md:col-span-4 sm:col-span-3 col-span-12">
          <div className="">
            <div className="relative">
              {imageSrc?.url ? (
                <img
                  className="lg:h-[200px] h-[100px] rounded-xl w-full object-cover"
                  src={imageSrc?.url}
                  alt=""
                />
              ) : (
                <h1 className="lg:h-[200px] h-[100px] rounded-xl w-full bg-slate-100 text-center align-middle text-[30px] text-[#999]">
                  {t("Image Not Uploaded")}
                </h1>
              )}
              <input
                onChange={(e) => handleImageChange(e)}
                className="hidden"
                accept="image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/tiff, image/webp, image/x-icon"
                type="file"
                id="course-image"
              />
              <label
                htmlFor="course-image"
                className="absolute bottom-5 right-5 cursor-pointer"
              >
                <FaRegEdit className="text-[22px] text-[#045140]" />
              </label>
            </div>
            <div className="my-4 relative">
              {videoSrcTreyler.url ? (
                <Player playsInline src={videoSrcTreyler.url} fluid={true} />
              ) : (
                <h2 className="h-[150px] rounded-xl w-full bg-slate-100 text-center align-middle text-[20px] text-[#999]">
                  {t("Treyler video Not Uploaded")}
                </h2>
              )}
              <input
                onChange={(e) => handleVideoTreylerChange(e)}
                className="hidden"
                accept="video/*,.mkv"
                type="file"
                id="course-treyler"
              />
              <label
                htmlFor="course-treyler"
                className="absolute bottom-5 right-5 cursor-pointer"
              >
                <FaRegEdit className="text-[22px] text-[#045140] ml-auto" />
              </label>
            </div>
            <div className="relative mb-4">
              {videoSrcBanner.url ? (
                <img
                  className="lg:h-[200px] h-[100px] rounded-xl w-full object-cover"
                  src={videoSrcBanner?.url}
                  alt=""
                />
              ) : (
                <h2 className="h-[150px] rounded-xl w-full bg-slate-100 text-center align-middle text-[20px] text-[#999]">
                  {t("Banner photo Not Uploaded")}
                </h2>
              )}
              <input
                onChange={(e) => handleVideoBannerChange(e)}
                className="hidden"
                accept="image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/tiff, image/webp, image/x-icon"
                type="file"
                id="course-banner-video"
              />
              <label
                htmlFor="course-banner-video"
                className="absolute bottom-5 right-5 cursor-pointer"
              >
                <FaRegEdit className="text-[22px] text-[#045140] ml-auto" />
              </label>
            </div>
            <Button
              onClick={saveMedia}
              loading={isLoading}
              className="md:w-full sm:w-auto w-full "
            >
              {t("Save media")}
            </Button>
          </div>
        </div>
        <div className="md:col-span-8 sm:col-span-9 col-span-12">
          <div className="">
            <div className="grid grid-cols-12 md:gap-8 gap-2">
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="flex justify-between gap-2 mb-4">
                  <p className="font-bold text-zinc-500">{t("Title")}</p>
                  <p>{getDataByIdQuery.data?.translations[0].title}</p>
                </div>
                <div className="flex justify-between gap-2 mb-4">
                  <p className=" font-bold text-zinc-500">{t("Subtitle")}</p>
                  <p>{getDataByIdQuery.data?.translations[0].sub_title}</p>
                </div>
                <div className="flex justify-between gap-2 mb-4">
                  <p className=" font-bold text-zinc-500">{t("Price")}</p>
                  <p>{getDataByIdQuery.data?.price}</p>
                </div>
                <div className="flex justify-between gap-2 mb-4">
                  <p className=" font-bold text-zinc-500">{t("Level")}</p>
                  <p>{getDataByIdQuery.data?.level}</p>
                </div>
                <div className="flex justify-between gap-2 mb-4">
                  <p className="font-bold text-zinc-500">{t("Slug")}</p>
                  <p>{getDataByIdQuery.data?.slug}</p>
                </div>
              </div>
              <div className="xl:col-span-6 md:col-span-6 col-span-12">
                <div className="flex justify-between gap-2 mb-4">
                  <p className="font-bold text-zinc-500">{t("Security")}</p>
                  <p>{getDataByIdQuery.data?.status}</p>
                </div>
                <div className="flex justify-between gap-2 mb-4">
                  <p className="font-bold text-zinc-500">{t("Status")}</p>
                  <p>
                    {getDataByIdQuery.data?.is_active ? (
                      <BiCheck className="text-[24px] text-green-700" />
                    ) : (
                      <IoMdClose className="ml-2 text-[22px] text-rose-600" />
                    )}
                  </p>
                </div>
                <div className="flex justify-between gap-2 mb-4">
                  <p className="font-bold text-zinc-500">{t("Verified")}</p>
                  <p>
                    {getDataByIdQuery.data?.is_verified ? (
                      <BiCheck className="text-[24px] text-green-700" />
                    ) : (
                      <IoMdClose className="ml-2 text-[22px] text-rose-600" />
                    )}
                  </p>
                </div>
                <div className="flex justify-between gap-2 mb-4">
                  <p className="font- font-bold text-zinc-500">{t("Free")}</p>
                  <p>
                    {getDataByIdQuery.data?.is_free ? (
                      <BiCheck className="text-[24px] text-green-700" />
                    ) : (
                      <IoMdClose className="ml-2 text-[22px] text-rose-600" />
                    )}
                  </p>
                </div>
                <div className="flex justify-between mb-4">
                  <p className="font- font-bold text-zinc-500">
                    {t("For child")}
                  </p>
                  <p>
                    {getDataByIdQuery.data?.is_for_child ? (
                      <BiCheck className="text-[24px] text-green-700" />
                    ) : (
                      <IoMdClose className="ml-2 text-[22px] text-rose-600" />
                    )}
                  </p>
                </div>
              </div>
            </div>
            {is_permission("update_course") ? (
              <div className="text-right">
                <Link to={`/courses/update/${getDataByIdQuery.data?.id}`}>
                  <Button>{t("Edit item")}</Button>
                </Link>
              </div>
            ) : null}
          </div>
          <hr className="my-4" />
          <CourseCategory categories={getDataByIdQuery?.data?.categories} />
        </div>
      </div>
    </div>
  );
};
export default ViewCourse;
