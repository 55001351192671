import instance from "config/axios.config";

export async function updateRating(rating_id: number | undefined, data: any) {
  const url = `/course_rating/${rating_id}`;
  const response = await instance({ url, method: "PUT", data });
  return response.data;
}
export async function postRating(course_id: string | undefined, data: any) {
  const url = `/course_rating/create`;
  const response = await instance({ url, method: "POST", data });
  return response.data;
}

export async function getCourseRatings(course_id: string | undefined | null, page: number, pageSize: number) {
  if (course_id) {
    const response = await instance({
      url: `/course_rating/list/`,
      method: "GET",
      params: { order_by: "created_at", desc: true, course_id, page_number: page, page_size: pageSize },
    });
    return response.data;
  } else {
    return {};
  }
}

// export async function getDataById(id: number) {
//   const response = await instance({ url: `/course_review/${id}`, method: "GET" });
//   return response.data;
// }
export async function getStudentsForCourseRating(course_id: string | undefined, q: any) {
  const response = await instance({ url: `/course/${course_id}/student/list/`, method: "GET", params: { q } });
  return response.data;
}

export function deleteData(id: any) {
  return instance({ url: `/course_rating/${id}`, method: "DELETE" });
}
