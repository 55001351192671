import { Button, Form, Input, Spin } from "antd";
import UserFormUi from "./user_form_ui";
import { useMutation, useQuery } from "@tanstack/react-query";
import { NewPassword, submitUser } from "./users.request";
import { AxiosError } from "axios";
import ToBack from "components/Common/ToBack";
import { useParams } from "react-router-dom";
import { CLIENT_API } from "service/clieant.request";
import { IUser } from "types/data.models";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { validationErrors } from "utils.functions";
import { useTranslation } from "react-i18next";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { useState } from "react";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

dayjs.extend(weekday);
dayjs.extend(localeData);

const UserUpdate = () => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(false);

  const [form] = Form.useForm();

  const { user_id } = useParams();

  const userOne = useQuery({
    queryKey: ["user-get-one", user_id],
    queryFn: () => CLIENT_API.getOne({ url: `/users/get/${user_id}` }),
    retry: 2,
    enabled: !!user_id,
    refetchOnWindowFocus: false,
    onSuccess: (user: IUser) => {
      let fieldVals: any = {
        district_id: user?.district_id,
        email: user?.email,
        gender: user?.gender,
        hashed_password: user?.hashed_password,
        is_active: user?.is_active,
        is_staff: user?.is_staff,
        is_superuser: user?.is_superuser,
        is_verified: user?.is_verified,
        phone: user?.phone,
        region_id: user?.region_id,
        translations: user?.translations,
      };

      if (user?.birthday) {
        fieldVals.birthday = dayjs(user?.birthday, "YYYY-MM-DD");
      }
      form.setFieldsValue(fieldVals);
    },
  });

  const user_update = useMutation({
    mutationKey: ["user-update"],
    mutationFn: (data: Record<string, any>) => submitUser(data, user_id),
    onSuccess: (data) => {
      console.log("saved data -> ", data);
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
  });
  function handleSubmit(values: Record<string, any>) {
    values.birthday = dayjs(values.birthday).format("YYYY-MM-DD");
    if (values.phone == "") {
      delete values.phone;
    }
    delete values["hashed_password"];
    user_update.mutate(values);
  }

  const [newPass, setNewPass] = useState("salom");
  const newPassMutate = useMutation({
    mutationKey: ["user-update"],
    mutationFn: (data: any) => NewPassword(data, user_id),
    onSuccess: (data) => {
      console.log("saved data -> ", data);
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
  });
  const auth_data = useQuery<{ user_password: string }>({
    queryKey: ["user-auth-data", user_id],
    queryFn: () => CLIENT_API.getOne({ url: `/users/${user_id}/get/password` }),
    retry: 2,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000,
    onSuccess: (data) => {
      setNewPass(data?.user_password);
      setIsShow(true);
      form.setFieldsValue({
        new_password: data?.user_password,
      });
    },
  });

  return (
    <div className="bg-white rounded-md p-3">
      <ToBack title={t("Edit user")} />
      <hr className="mt-2 mb-5" />
      <Spin spinning={userOne.isFetching}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            gender: "male",
            is_active: true,
          }}
          onFinish={handleSubmit}
          className="custom_form_style"
        >
          <UserFormUi form={form} is_action="update" />
          <div className="grid grid-cols-12 gap-1 mt-3 items-end">
            <div className="md:col-span-3 col-span-6">
              <Form.Item style={{ marginBottom: "0" }} label={<FormLabelCrud>New password</FormLabelCrud>} name={["new_password"]}>
                <Input type={isShow ? "text" : "password"} onChange={(e) => setNewPass(e.target.value)} placeholder={t("Please input data") ?? ""} />
              </Form.Item>
            </div>
            <div className="md:col-span-2 col-span-6 flex">
              {isShow ? (
                <Button className="w-[100%] flex justify-center align-middle ml-[10px] pt-[7px]" onClick={() => setIsShow(false)}>
                  <EyeInvisibleOutlined />
                </Button>
              ) : (
                <Button className="w-[100%] flex justify-center align-middle ml-[10px] pt-[7px]" onClick={() => setIsShow(true)}>
                  <EyeOutlined />
                </Button>
              )}
              <Button
                type="default"
                className="bg-[#045140] ml-[10px] text-white sm:text-[14px] text-xs rounded-md text-center sm:w-[120px] w-[100%]  py-[8px]  hover:text-[#A0E82F]  active:bg-[#034335] uppercase disabled:bg-[#F1F5F9] disabled:text-[#8A8C8E] disabled:cursor-not-allowed"
                onClick={() => newPassMutate.mutate({ new_password: newPass })}
                disabled={newPassMutate.isLoading}
              >
                {t("Save")}
              </Button>
              <Button className="w-[100%] flex justify-center align-middle ml-[10px]" onClick={() => auth_data.refetch()}>
                Old password
              </Button>
            </div>
          </div>
          <div className="sm:text-end text-center">
            <Button
              onClick={() => {
                form.resetFields();
                form.setFieldsValue({
                  is_active: true,
                  translations: [{ title: "" }],
                });
              }}
              type="default"
              className="bg-[#FF0000] text-white sm:text-[14px] text-xs rounded-md text-center sm:w-[120px] w-[48%] py-[8px] active:bg-[#D50000] uppercase disabled:bg-[#F1F5F9] disabled:text-[#8A8C8E] disabled:cursor-not-allowed mr-2"
            >
              {t("Reset")}
            </Button>
            <Button
              type="default"
              className="bg-[#045140] text-white sm:text-[14px] text-xs rounded-md text-center sm:w-[120px] w-[48%]  py-[8px]  hover:text-[#A0E82F]  active:bg-[#034335] uppercase disabled:bg-[#F1F5F9] disabled:text-[#8A8C8E] disabled:cursor-not-allowed"
              htmlType="submit"
            >
              {t("Save")}
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default UserUpdate;
