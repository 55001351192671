import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Form } from "antd";
import { getDataById, submitData } from "./course.request";
import ToBack from "components/Common/ToBack";
import { useParams } from "react-router-dom";
import { ICourse } from "./types";
import FormItems from "./course_form_ui";
import { AxiosError } from "axios";
import { validationErrors } from "utils.functions";
import { useTranslation } from "react-i18next";

const UpdateCourse = () => {

  const {t} = useTranslation()
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const { mutate, isLoading } = useMutation({
    mutationKey: ["update-course"],
    mutationFn: (newValues: ICourse) => submitData(Number(id), newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["courses"], data);
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  const getDataByIdQuery = useQuery<ICourse, AxiosError>({
    queryKey: ["course-by-id"],
    queryFn: () => getDataById(Number(id)),
    onSuccess: (data: ICourse) => {
      form.setFieldsValue({ ...data, discount_id: data?.discount?.id });
    },
    retry: 0,
    refetchOnWindowFocus: false,
  });

  function handleSubmit(values: ICourse) {

    mutate(values);
  }

  function saveAndAnother() {
    form.submit();
    setTimeout(() => {
      form.resetFields();
      form.setFieldsValue({
        is_active: true,
        is_archive: false,
        is_free: false,
        is_verified: true,
        is_for_child: false,
        translations: [{ title: "", desc: "", sub_title: "" }],
      });
    }, 300);
  }
  function saveAndEdit() {
    form.submit();
  }

  return (
    <div className="main-card">
      <ToBack title={t("Edit course")} />
      <hr className="my-3" />
      <FormItems form={form} handleSubmit={handleSubmit} />
      <div className="md:flex justify-end mt-2">
        <Button
          onClick={saveAndAnother}
          loading={isLoading}
          type="default"
          className="md:w-auto w-[100%] bg-[#045140] text-white hover:text-white hover:bg-[#1a7763]"
          htmlType="submit"
        >
          {t("Save and add another")}
        </Button>
        <Button
          onClick={saveAndEdit}
          loading={isLoading}
          type="default"
          className="md:w-auto w-[100%] bg-[#045140] text-white hover:text-white hover:bg-[#1a7763] md:ml-3 md:my-0 my-3"
          htmlType="submit"
        >
          {t("Save and continue editing")}
        </Button>
      </div>
    </div>
  );
};

export default UpdateCourse;
