import { Button, Checkbox, Form, FormInstance, Input, Select } from "antd";
import FormLabelCrud from "components/FormLabel/FormLabel";
import useFilterLanguage from "custom-hooks/useFilterLanguage";
import { BiPlay, BiPlus, BiX } from "react-icons/bi";
import { ILanguage } from "pages/languages/types";
import "suneditor/dist/css/suneditor.min.css";
import { ICourseLessons, ILesson } from "./types";
import useGetData from "custom-hooks/useGetData";
import SunEditor from "suneditor-react";
import { IDiscount } from "pages/discounts/types";
import useLocaleTranslation from "custom-hooks/useLocaleTranslation";
import LanguageTitle from "utils/LanguageTitle";
import { useTranslation } from "react-i18next";
import FreeVideoModal from "components/modals/videoModal";
import { useState } from "react";

const { Option } = Select;

type TypePropsLessonEditUi = {
    form: FormInstance,
    values?: ILesson,
    translations: ILesson['translations'],
    setTranslations: React.Dispatch<React.SetStateAction<ILesson['translations']>>
}

const LessonEditUi = ({ form, values, translations, setTranslations }: TypePropsLessonEditUi) => {
    const {t} = useTranslation()
    const { _translate } = useLocaleTranslation();
    const { data: discounts } = useGetData<IDiscount[]>({ queryKey: ['discounts'], url: '/discount/list/', options: { staleTime: Infinity, refetchOnWindowFocus: false } });
    const { data: languages } = useGetData<ILanguage[]>({ queryKey: ['languages'], url: 'language/list/', options: { staleTime: Infinity, refetchOnWindowFocus: false } });
   
    const { fl, languageIds } = useFilterLanguage(form, translations);
    
  const [videoId, setVideoId] = useState("");
    const handleChage = (language_id: number, name: string, value: string | number) => {

        setTranslations((prevState) => {

            return prevState.map((element) => {

                if (language_id === element.language_id) {

                    return { ...element, [name]: value }

                }

                return element;
            })
        })

    }
    


    const onRemoveItem = (language_id: number) => {
        setTranslations(prevState => ([...prevState.filter(e => e.language_id !== language_id)]))
    }

    const onAddItem = () => {
        if (languages?.length) {

            const selectedIds = translations.map(e => e.language_id);
            const findLangIds = languages.filter(e => !selectedIds.includes(e.id));

            setTranslations(prevState => ([...prevState, { title: '', content: '', desc: '', language_id: findLangIds[0].id }]))

        }
    }
    if (!values) return <div>ma'lumot yo'q</div>

    return (

        <div className="grid grid-cols-12 gap-4">
            {
                <div className="col-span-12">
                    {translations.length ? translations.map((element, index) => (
                        <div key={element.language_id} className='grid grid-cols-12 gap-4 mb-4 bg-slate-50 py-1 px-3 rounded-md mt-2'>
                            <div className="lg:col-span-3 md:col-span-6 col-span-12">
                                <Form.Item
                                    className='mb-0'
                                    label={<FormLabelCrud><span style={{color: "#ff4d4f"}}>*</span>Name</FormLabelCrud>}
                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                >
                                    <Input  placeholder="Ma'lumot kiriting" value={element.title} onChange={(e) => handleChage(element.language_id, 'title', e.target.value)} />
                                </Form.Item>
                            </div>
                            <div className="md:col-span-6 col-span-12">
                                <Form.Item
                                    className='mb-0'
                                    label={<FormLabelCrud>Description</FormLabelCrud>}
                                    rules={[{ required: index === 0, message: 'Please input Description!' }]}
                                >
                                    <Input placeholder="Description" value={element.desc} onChange={(e) => handleChage(element.language_id, 'desc', e.target.value)} />
                                </Form.Item>
                            </div>

                            <div className="lg:col-span-3 md:col-span-6 col-span-12 flex items-end">
                                <Form.Item
                                    label={<FormLabelCrud>Languages</FormLabelCrud>}
                                    rules={[{ required: index === 0, message: 'Please select language' }]}
                                    className="w-[calc(100%-55px)] mb-0"
                                >
                                    <Select onClick={fl} value={element.language_id} onChange={(e) => handleChage(element.language_id, 'language_id', e)}>
                                        {
                                            languages?.map(element => <Option key={element.id} value={element.id} disabled={languageIds.includes(element.id)}><LanguageTitle element={element}/></Option>)
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item className="w-[50px] mb-0 ml-2 mt-[25px]">
                                    <Button disabled={translations.length == 1} danger onClick={() => onRemoveItem(element.language_id)}><BiX /></Button>
                                </Form.Item>
                            </div>
                            {/* <div className="col-span-12">
                                <Form.Item
                                    className='mb-0'
                                    label={<FormLabelCrud>Editor</FormLabelCrud>}
                                    rules={[{ required: false, message: 'Please input Description!' }]}
                                >
                                    <SunEditor
                                        setOptions={{
                                            buttonList: buttonList,
                                        }}
                                        setContents={element.content}
                                        onChange={(e) => handleChage(element.language_id, 'content', e)}
                                    />
                                </Form.Item>
                            </div> */}
                        </div>
                    )) : null}
                    <div className="col-span-12 text-right">
                        <Button
                            type="link"
                            size="small"
                            className="inline-flex align-middle items-center lg:text-sm text-xs font-medium uppercase sm:px-[15px] pl-0"
                            disabled={translations.length === languages?.length}
                            onClick={onAddItem}
                        >
                            <BiPlus size={20} className="mt-[2px] mr-2" />{t("Add translation")}
                        </Button>
                    </div>
                </div>
            }
            <div className="lg:col-span-3 sm:col-span-6 col-span-12">
                <Form.Item
                    label={<FormLabelCrud>Price</FormLabelCrud>}
                    name="price"
                    rules={[{ required: false, message: 'Please input Price!' }]}
                >
                    <Input placeholder="Percent" type="number" />
                </Form.Item>
            </div>
            <div className="lg:col-span-3 sm:col-span-6 col-span-12">
                <Form.Item
                    label={<FormLabelCrud>Sort</FormLabelCrud>}
                    name="sort"
                    rules={[{ required: false, message: 'Please input sort!' }]}
                >
                    <Input type="number" className="w-[100%]" onWheel={e => e.currentTarget.blur()} />
                </Form.Item>
            </div>
            <div className="lg:col-span-3 sm:col-span-6 col-span-12">
                <Form.Item
                    label={<FormLabelCrud>Slug</FormLabelCrud>}
                    name="slug"
                    rules={[{ required: false, message: 'Please input slug!' }]}
                >
                    <Input className="w-[100%]" />
                </Form.Item>
            </div>
            <div className="lg:col-span-3 sm:col-span-6 col-span-12">
                <Form.Item
                    label={<FormLabelCrud>Discounts</FormLabelCrud>}
                    name="discount_id"
                    rules={[{ required: false, message: 'Please input slug!' }]}

                >
                    <Select allowClear>
                        {
                            discounts?.map((element) => <Select.Option key={element.id} value={element.id}>{_translate(element.translations, 'title')}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
            </div>
            <div className="lg:col-span-3 sm:col-span-6 col-span-12">
                <Form.Item
                    name="is_active"
                    rules={[{ required: false, message: 'Please input is status!' }]}
                    valuePropName={'checked'}
                >
                    <Checkbox>STATUS</Checkbox>
                </Form.Item>
            </div>
            <div className="lg:col-span-3 sm:col-span-6 col-span-12">
                <Form.Item
                    name="is_free"
                    rules={[{ required: false, message: 'Please check!' }]}
                    valuePropName={'checked'}
                >
                    <Checkbox>FREE</Checkbox>
                </Form.Item>
            </div>
           
            <div className="lg:col-span-3 sm:col-span-6 col-span-12">
                <Form.Item
                    name="is_homework"
                    rules={[{ required: false, message: 'Please check!' }]}
                    valuePropName={'checked'}
                >
                    <Checkbox>{t("HOMEWORK")}</Checkbox>
                </Form.Item>
            </div>
            <div className="lg:col-span-3 sm:col-span-6 col-span-12">
                <Form.Item
                    name="is_final"
                    rules={[{ required: false, message: 'Please check!' }]}
                    valuePropName={'checked'}
                >
                    <Checkbox>{t("FINAL")}</Checkbox>
                </Form.Item>
            </div>
            {/* <div className="col-span-12 mb-4 bg-slate-50 rounded-md px-3 pb-2">
                <Form.List name="ks_uuids">
                    {(fields, { add, remove }) => (
                        <>
                            <div className='grid grid-cols-12 gap-4 rounded-md mt-2'>
                                {fields.map(({ key, name, ...restField }, index) => (
                                    <div className="col-span-6 flex items-center">
                                        <Form.Item
                                            className='mb-0 w-[calc(100%-55px)]'
                                            label={<FormLabelCrud>Video ID</FormLabelCrud>}
                                            {...restField}
                                            name={[name, 'ks_uuid']}
                                            rules={[{ required: false, message: 'Please input title!' }]}
                                        >
                                            <Input placeholder="Ma'lumot kiriting" />
                                        </Form.Item>
                                        <Form.Item className="w-[40px] mb-0 ml-2 mt-[25px]">
                                            <Button onClick={() => { setVideoId(form.getFieldValue('ks_uuids')[key].ks_uuid) }} className="border-lime-500 px-2"><BiPlay size={20} color="green"/></Button>
                                        </Form.Item>
                                        <Form.Item className="w-[50px] mb-0 ml-2 mt-[25px]">
                                            <Button onClick={() => { remove(name); }} danger><BiX /></Button>
                                        </Form.Item>
                                    </div>
                                ))}
                            </div>
                            <div className="col-span-12 text-end">
                                <Button type="link" size="small" className="inline-flex align-middle font-medium mt-1" onClick={() => add()} disabled={fields.length === 20}>
                                    <BiPlus size={20} className="mt-[2px] mr-2" />VIDEO QO'SHISH
                                </Button>
                            </div>
                        </>
                    )}
                </Form.List>
            </div> */}
         {videoId&&<FreeVideoModal videoId={videoId} setVideoId={setVideoId} />}   
        </div>
    );
};

export default LessonEditUi;



const buttonList = [
    ["font", "fontSize", "formatBlock"],
    [
        "bold",
        "underline",
        "italic",
        "strike",
        "subscript",
        "superscript",
    ],
    ["align", "horizontalRule", "list", "table"],
    ["fontColor", "hiliteColor"],
    ["outdent", "indent"],
    ["undo", "redo"],
    ["removeFormat"],
    ["outdent", "indent"],
    ["image"],
    ["print"],
    ["fullScreen", "showBlocks", "codeView"],
]
