import { Button, Empty, Form, Modal, Select } from "antd";
import { FiEdit } from "react-icons/fi";
import { useEffect, useState } from "react";
import FormLabelCrud from "components/FormLabel/FormLabel";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import DeleteIcon from "components/Common/deleteIcon";
import useGetData from "custom-hooks/useGetData";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { deleteStudentWork, submitCourseStudentsWorks } from "./details.request";
import { useParams } from "react-router-dom";
import { ICourseStudentWorks } from "./types";
import { is_permission, validationErrors } from "utils.functions";
import { FaRegEdit } from "react-icons/fa";
import { CLIENT_API } from "service/clieant.request";
import { IUser } from "types/data.models";
import { InformerDRDT } from "types/base.models";
import { FILE_URL } from "config/utils";
import { useTranslation } from "react-i18next";

const StudentPortfolios = () => {
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const [selectedItem, setSelectedItem] = useState<ICourseStudentWorks>();
  const [imageFile, setimageFile] = useState<any>();

  const [isFirstModal, setisFirstModal] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldsValue({
      translations: [{ title: "" }],
    });
  }, []);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["add-student-work"],
    mutationFn: (newValues: any) => submitCourseStudentsWorks(selectedItem?.id, newValues),
    onSuccess: async (data) => {
      queryClient.setQueryData(["student-work"], data);
      dataQuery.refetch();
      form.setFieldsValue({ translations: [{ key: "", value: "" }] });
      setisFirstModal(false);
      setSelectedItem(undefined);
    },
    onError: (error: AxiosError<any>) => {
      validationErrors(form, error?.response?.data);
    },
    retry: 0,
  });

  const deleteMutation = useMutation({
    mutationFn: (id: number) => deleteStudentWork(id),
    onSuccess: () => {
      dataQuery.refetch();
    },
  });

  const dataQuery = useQuery<ICourseStudentWorks[], AxiosError>({
    queryKey: ["student-work", id],
    queryFn: () => CLIENT_API.getAll({url: `/student-work/list/${id}`}),
    retry: 0,
    refetchOnWindowFocus: false,
    enabled: !!id
  });

  const {data: users} = useGetData<InformerDRDT<IUser>>({
    queryKey: ["allowed-users-list", id],
    url: `/course/${id}/allowed-users/list/`,
    urlParams: {page_number: 1, page_size: 100},
    options:{
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: !!id
    }
  });  

  function handleSubmit(values:any) {
    const newObj: any = {};

    newObj.media = imageFile;
    newObj.payload = JSON.stringify({
      course_id: id,
      student_id: values?.student_id
    })  
    mutate(newObj);
  }

  return (
    <div>
      <div className="flex justify-between mb-2">
        <h3 className="font-bold text-[18px] opacity-40">{t("Student works")}</h3>
        {is_permission("create_what_learn_course_block") ? (
          <Button onClick={() => setisFirstModal(true)}>+ {t("Create")}</Button>
        ) : null}
      </div>
      <Table className="table-striped table-hover">
        <Thead>
          <Tr>
            <Th className="w-[50px]">№</Th>
            <Th className="w-[50px] text-center px-[0!important]">{t("Id")}</Th>
            <Th>{t("Student")}</Th>
            <Th>{t("Description")}</Th>
            <Th>{t("Actions")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {dataQuery?.data?.length
            ? dataQuery?.data?.map((item, index) => (
                <Tr>
                  <Td>{index + 1}</Td>
                  <Td className="w-[50px] text-center px-[0!important]">{item?.id}</Td>
                  <Td>{item?.student?.translations[0]?.first_name} {item?.student?.translations[0]?.last_name}</Td>
                  <Td><a href={FILE_URL + item?.media?.path} target="_blank">{item?.media?.filename}</a></Td>
                  <Td>
                    <div className="flex" style={{ alignItems: "center" }}>
                      {is_permission("update_what_learn_course_block") ? (
                        <FiEdit
                          onClick={() => {
                            setisFirstModal(true);
                            form.setFieldsValue(item);
                            setSelectedItem(item);
                          }}
                          className="mx-2 text-[green] text-[18px] cursor-pointer"
                        />
                      ) : null}
                      {is_permission("delete_what_learn_course_block") ? (
                        <DeleteIcon confirmFunc={() => deleteMutation.mutate(item?.id)} />
                      ) : null}
                    </div>
                  </Td>
                </Tr>
              ))
            : ""}
        </Tbody>
      </Table>
      {dataQuery?.data?.length === 0 ? <Empty /> : ""}
      <Modal
        title={t("Course Detail")}
        width={1500}
        open={isFirstModal}
        onCancel={() => {
          setisFirstModal(false);
          form.setFieldsValue({ translations: [{ key: "", value: "" }] });
          setSelectedItem(undefined);
        }}
        footer={[<Button loading={isLoading} onClick={() => form.submit()}>{t("Save")}</Button>]}
      >
        <Form layout={"vertical"} form={form} onFinish={handleSubmit}>
          <div className="grid grid-cols-12 gap-x-5">
            <div className="sm:col-span-6 col-span-12">
                <Form.Item
                  label={<FormLabelCrud>Student</FormLabelCrud>}
                  name="student_id"
                  rules={[{ required: true, message: "Please input student!" }]}
                >
                  <Select
                    showSearch
                    placeholder="Select a student"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={users?.results?.map((item) => ({value: item?.id, label: item?.translations[0]?.first_name + " " + item?.translations[0]?.last_name }))}
                  />
                </Form.Item>
            </div>
            <div className="col-span-6">
              <Form.Item
                  label={<FormLabelCrud>Image</FormLabelCrud>}
                  name="media"
                  rules={[{ required: false, message: "Please input sort!" }]}
                >
                  <input
                    onChange={(e:any) => setimageFile(e.target.files[0])}
                    className="hidden"
                    accept="image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/tiff, image/webp, image/x-icon, video/mp4"
                    type="file"
                    id="course-image"
                  />
                  <label htmlFor="course-image" className="absolute bottom-5 sm:right-5 -right-3  cursor-pointer">
                    <FaRegEdit className="text-[22px] text-[#045140]" />
                  </label>
                </Form.Item>
                
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};
export default StudentPortfolios;
